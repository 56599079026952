export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Photo Identification",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "PhotoIdentification"
    },
    {
        "DefaultLanguageValue": "Approve",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Status",
        "TranslationKey": "Approve"
    },
    {
        "DefaultLanguageValue": "Reject",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Status",
        "TranslationKey": "Reject"
    },
    {
        "DefaultLanguageValue": "Rejection Reason",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "RejectionReason"
    },
    {
        "DefaultLanguageValue": "User request has been amended successfully.",
        "Scope": "UserApproval",
        "Category": "Amend",
        "SubCategory": "Success",
        "TranslationKey": "AmendSuccess"
    },
    {
        "DefaultLanguageValue": "Failed to amend user request.",
        "Scope": "UserApproval",
        "Category": "Amend",
        "SubCategory": "Failure",
        "TranslationKey": "AmendFailure"
    },
    {
        "DefaultLanguageValue": "User account successfully approved.",
        "Scope": "UserApproval",
        "Category": "Approve",
        "SubCategory": "Success",
        "TranslationKey": "UserApprovalApproveSuccess"
    },
    {
        "DefaultLanguageValue": "User account successfully rejected.",
        "Scope": "UserApproval",
        "Category": "Reject",
        "SubCategory": "Success",
        "TranslationKey": "UserApprovalRejectSuccess"
    }
]