import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert, toastError } from '../../Shared//Forms';
//import { getRoles, userRolesMapper } from '../../Shared/SmartMe/Components/AssignRolesToUser/Utils';
import history from '../../Shared/History';
import { withPageNotFound } from '../../Shared/Error';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'User/UpdateUser';

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined || state.UserCheckType === undefined) {
            loader.done();
            pageNotFound();
        }
        else{
            let response = await fetchRequest(URL + "User/GetUser/" + state.ID, getParams(), false);
            //let userRolesResp = await fetchRequest(URL + "User/GetUserRoles/" + this.props.match.params.id, getParams(), false);

            const { IsSuccess, Data } = response.body;
    
            if (response.success && IsSuccess
                //&& userRolesResp.success && userRolesResp.body.IsSuccess
            ) {
    
                //map user roles to grid values
                // const userRoles = userRolesResp.body.Data;
                // let rolesResponse = await getRoles(loader);
                // let mappedUserRoles = userRolesMapper(userRoles.RoleToUser, rolesResponse.allRoles);
    
                const profileData = Data.Profile;
                const middleName = profileData.MiddleName;
                const idNumbersArr = profileData.IdNumbers === '' ? [{ IdType: '', IdNumber: '', IdCountry: '' }] : JSON.parse(profileData.IdNumbers);
    
                this.setState({
                    values: {
                        Id: Data.Id,
                        Profile: {
                            FirstName: profileData.FirstName,
                            MiddleName: (middleName === '' || middleName === "null" || middleName === null) ? "" : middleName,
                            LastName: profileData.LastName,
                            DateOfBirth: profileData.DateOfBirth,
                            Gender: profileData.Gender,
                            Salutation: profileData.Salutation === null ? "" : profileData.Salutation,
                            Nationality: profileData.Nationality,
                            AltEmailAddress: profileData.AltEmailAddress === null ? "" : profileData.AltEmailAddress,
                            PrimaryContact: profileData.PrimaryContact,
                            MobileNumber: {
                                CountryCode: (profileData.MobileNumber === null || profileData.MobileNumber.CountryCode === null) ? "" : profileData.MobileNumber.CountryCode,
                                Number: (profileData.MobileNumber === null || profileData.MobileNumber.Number === null) ? "" : profileData.MobileNumber.Number
                            },
                            OfficeNumber: {
                                CountryCode: (profileData.OfficeNumber === null || profileData.OfficeNumber.CountryCode === null) ? "" : profileData.OfficeNumber.CountryCode,
                                Number: (profileData.OfficeNumber === null || profileData.OfficeNumber.Number === null) ? "" : profileData.OfficeNumber.Number
                            },
                            HomeNumber: {
                                CountryCode: (profileData.HomeNumber === null || profileData.HomeNumber.CountryCode === null) ? "" : profileData.HomeNumber.CountryCode,
                                Number: (profileData.HomeNumber === null || profileData.HomeNumber.Number === null) ? "" : profileData.HomeNumber.Number
                            },
                            FaxNumber: {
                                CountryCode: (profileData.FaxNumber === null || profileData.FaxNumber.CountryCode === null) ? "" : profileData.FaxNumber.CountryCode,
                                Number: (profileData.FaxNumber === null || profileData.FaxNumber.Number === null) ? "" : profileData.FaxNumber.Number
                            },
                            Address: {
                                AddressType: profileData.Address.AddressType,
                                PostalCode: profileData.Address.PostalCode === null ? "" : profileData.Address.PostalCode,
                                Country: profileData.Address.Country === null ? "" : profileData.Address.Country, // Only applicable to Address Type: Overseas
                                City: profileData.Address.City === null ? "" : profileData.Address.City,
                                State: profileData.Address.State === null ? "" : profileData.Address.State,
                                AddressLine1: {
                                    AddressLine1: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.AddressLine1 === null) ? "" : profileData.Address.AddressLine1.AddressLine1,
                                    BlockNo: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.BlockNo === null) ? "" : profileData.Address.AddressLine1.BlockNo,
                                    StreetName: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.StreetName === null) ? "" : profileData.Address.AddressLine1.StreetName
                                },
                                AddressLine2: {
                                    AddressLine2: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.AddressLine2 === null) ? "" : profileData.Address.AddressLine2.AddressLine2,
                                    LevelNo: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.LevelNo === null) ? "" : profileData.Address.AddressLine2.LevelNo,
                                    UnitNo: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.UnitNo === null) ? "" : profileData.Address.AddressLine2.UnitNo,
                                    BuildingName: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.BuildingName === null) ? "" : profileData.Address.AddressLine2.BuildingName,
                                }
                            },
                            IdNumbersArr: idNumbersArr,
                            IdNumbers: JSON.stringify(idNumbersArr)
                        },
                        Organisation: Data.Organisation,
                        GlobalUserGroups: Data.GlobalUserGroups,
                        UserGroups: Data.UserGroups,
                        // Roles: mappedUserRoles,
                        UserType: Data.UserType,
                        Username: Data.Username,
                        Email: Data.Email,
                        UserTypeCheck: state.UserCheckType
                    },
                    // roles: rolesResponse,
                    isLoaded: true
                });
            }
            else {
                toastError("Unable to fetch data");
            }
        }
        
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <>
                {this.state.isLoaded &&
                    <Form
                        loader={this.props.loader}
                        values={this.state.values}
                        server_url={SERVER_URL}
                        genders={this.state.genders}
                        userTypes={this.state.userTypes}
                        // roles={this.state.roles}
                        title="Update User"
                        contentHeader={MODAL_TITLE(getStaticText).UPDATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).UPDATE.BODY}
                        action="Update"
                    />
                }
            </>
        )
    }
}
export default withPageNotFound(withFormAlert(withLoader(withRouter(Update))));