export const TableTranslationKeys = [
    {
        "DefaultLanguageValue": "No global user groups found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoGlobalUGsFound"
    },
    {
        "DefaultLanguageValue": "No user groups found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoUGsFound"
    },
    {
        "DefaultLanguageValue": "No users found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoUsersFound"
    },
    {
        "DefaultLanguageValue": "Pending User Activation",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "PendingUserActivation"
    },
    {
        "DefaultLanguageValue": "Pending Admin Approval",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "PendingAdminApproval"
    },
    {
        "DefaultLanguageValue": "Expired",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "Expired"
    },
    {
        "DefaultLanguageValue": "Rejected",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "Rejected"
    },
    {
        "DefaultLanguageValue": "Approved",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "Approved"
    },
    {
        "DefaultLanguageValue": "Activated",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "Status",
        "TranslationKey": "Activated"
    }
]