import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { EDIT_SCOPE_URL, LAYOUT_TYPE, SCOPE_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { CONTENT_TYPE, formParams, navigateTo } from '../../Shared/Actions';
import { withSMARTWrapper, Paragraph, Plaintext, SMARTForm } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';


const FORM_CONTEXT = "EditScope";
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;
const FORM_INITIAL_VALUES = {
    Id: '',
    Description: ''
}

class EditScope extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES
        }
        if (history.location.state) {
            this.state = {
                data: history.location.state.data.RowData,
                serverUrl: EDIT_SCOPE_URL
            }
            FORM_INITIAL_VALUES.Description = this.state.data.Description
        }

    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        Yup.object().shape({
            Description: Yup.string()
            .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
            .max(2000, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT))
        });
    };

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(SCOPE_MANAGEMENT_URL, "");
                }
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATESCOPE, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.SCOPE);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    massageValues = (values, submitPlainForm) => {
        values.Id = this.state.data.Id;
        submitPlainForm();
    }

    render() {
        const { Name } = this.state.data
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITSCOPE, DEFAULT_TEXT.EDIT_SCOPE)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={FORM_INITIAL_VALUES}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        formParams={formParams("POST", CONTENT_TYPE.JSON)}
                        serverURL={this.state.serverUrl}
                        nextURL={SCOPE_MANAGEMENT_URL}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col>
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                        name="Name"
                                        value={Name}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Paragraph
                                        name="Description"
                                        value={values.Description}
                                        placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                        onChange={onChange}
                                        rows={3}
                                        maxLength={2000}
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                        error={errors.Description}
                                    />
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(SCOPE_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}

                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(EditScope));
