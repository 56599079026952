export const CountryCodeTranslationKeys = [
    {
        "DefaultLanguageValue": "Afghanistan (+93)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AF"
    },
    {
        "DefaultLanguageValue": "Albania (+355)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AL"
    },
    {
        "DefaultLanguageValue": "Algeria (+213)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DZ"
    },
    {
        "DefaultLanguageValue": "American Samoa (+1-684)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AS"
    },
    {
        "DefaultLanguageValue": "Andorra (+376)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AD"
    },
    {
        "DefaultLanguageValue": "Angola (+244)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AO"
    },
    {
        "DefaultLanguageValue": "Anguilla (+1-264)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AI"
    },
    {
        "DefaultLanguageValue": "Antarctica (+672)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AQ"
    },
    {
        "DefaultLanguageValue": "Antigua and Barbuda (+1-268)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AG"
    },
    {
        "DefaultLanguageValue": "Argentina (+54)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AR"
    },
    {
        "DefaultLanguageValue": "Armenia (+374)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AM"
    },
    {
        "DefaultLanguageValue": "Aruba (+297)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AW"
    },
    {
        "DefaultLanguageValue": "Australia (+61)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AU"
    },
    {
        "DefaultLanguageValue": "Austria (+43)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AT"
    },
    {
        "DefaultLanguageValue": "Azerbaijan (+994)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AZ"
    },
    {
        "DefaultLanguageValue": "Bahamas (+1-242)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BS"
    },
    {
        "DefaultLanguageValue": "Bahrain (+973)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BH"
    },
    {
        "DefaultLanguageValue": "Bangladesh (+880)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BD"
    },
    {
        "DefaultLanguageValue": "Barbados (+1-246)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BB"
    },
    {
        "DefaultLanguageValue": "Belarus (+375)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BY"
    },
    {
        "DefaultLanguageValue": "Belgium (+32)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BE"
    },
    {
        "DefaultLanguageValue": "Belize (+501)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BZ"
    },
    {
        "DefaultLanguageValue": "Benin (+229)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BJ"
    },
    {
        "DefaultLanguageValue": "Bermuda (+1-441)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BM"
    },
    {
        "DefaultLanguageValue": "Bhutan (+975)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BO"
    },
    {
        "DefaultLanguageValue": "Bolivia (+591)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BT"
    },
    {
        "DefaultLanguageValue": "Bosnia and Herzegovina (+387)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BA"
    },
    {
        "DefaultLanguageValue": "Botswana (+267)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BW"
    },
    {
        "DefaultLanguageValue": "Brazil (+55)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BR"
    },
    {
        "DefaultLanguageValue": "British Indian Ocean Territory (+246)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BN"
    },
    {
        "DefaultLanguageValue": "British Virgin Islands (+1-284)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VG"
    },
    {
        "DefaultLanguageValue": "Brunei (+673)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IO"
    },
    {
        "DefaultLanguageValue": "Bulgaria (+359)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BG"
    },
    {
        "DefaultLanguageValue": "Burkina Faso (+226)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BF"
    },
    {
        "DefaultLanguageValue": "Burundi (+257)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BI"
    },
    {
        "DefaultLanguageValue": "Cambodia (+855)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KH"
    },
    {
        "DefaultLanguageValue": "Cameroon (+237)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CM"
    },
    {
        "DefaultLanguageValue": "Canada (+1)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CA"
    },
    {
        "DefaultLanguageValue": "Cape Verde (+238)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CV"
    },
    {
        "DefaultLanguageValue": "Cayman Islands (+1-345)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KY"
    },
    {
        "DefaultLanguageValue": "Central African Republic (+236)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CF"
    },
    {
        "DefaultLanguageValue": "Chad (+235)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TD"
    },
    {
        "DefaultLanguageValue": "Chile (+56)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CL"
    },
    {
        "DefaultLanguageValue": "China (+86)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CN"
    },
    {
        "DefaultLanguageValue": "Christmas Island (+61)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CX"
    },
    {
        "DefaultLanguageValue": "Cocos Islands (+61)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CC"
    },
    {
        "DefaultLanguageValue": "Colombia (+57)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CO"
    },
    {
        "DefaultLanguageValue": "Comoros (+269)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KM"
    },
    {
        "DefaultLanguageValue": "Cook Islands (+682)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CK"
    },
    {
        "DefaultLanguageValue": "Costa Rica (+506)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CR"
    },
    {
        "DefaultLanguageValue": "Croatia (+385)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "HR"
    },
    {
        "DefaultLanguageValue": "Cuba (+53)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CU"
    },
    {
        "DefaultLanguageValue": "Curacao (+599)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CW"
    },
    {
        "DefaultLanguageValue": "Cyprus (+357)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CY"
    },
    {
        "DefaultLanguageValue": "Czech Republic (+420)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CZ"
    },
    {
        "DefaultLanguageValue": "Democratic Republic of the Congo (+243)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CD"
    },
    {
        "DefaultLanguageValue": "Denmark (+45)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DK"
    },
    {
        "DefaultLanguageValue": "Djibouti (+253)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DJ"
    },
    {
        "DefaultLanguageValue": "Dominica (+1-767)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DM"
    },
    {
        "DefaultLanguageValue": "Dominican Republic (+1-809, 1-829, 1-849)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DO"
    },
    {
        "DefaultLanguageValue": "East Timor (+670)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TL"
    },
    {
        "DefaultLanguageValue": "Ecuador (+593)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "EC"
    },
    {
        "DefaultLanguageValue": "Egypt (+20)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "EG"
    },
    {
        "DefaultLanguageValue": "El Salvador (+503)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SV"
    },
    {
        "DefaultLanguageValue": "Equatorial Guinea (+240)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GQ"
    },
    {
        "DefaultLanguageValue": "Eritrea (+291)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ER"
    },
    {
        "DefaultLanguageValue": "Estonia (+372)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "EE"
    },
    {
        "DefaultLanguageValue": "Ethiopia (+251)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ET"
    },
    {
        "DefaultLanguageValue": "Falkland Islands (+500)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FK"
    },
    {
        "DefaultLanguageValue": "Faroe Islands (+298)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FO"
    },
    {
        "DefaultLanguageValue": "Fiji (+679)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FJ"
    },
    {
        "DefaultLanguageValue": "Finland (+358)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FI"
    },
    {
        "DefaultLanguageValue": "France (+33)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FR"
    },
    {
        "DefaultLanguageValue": "French Polynesia (+689)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PF"
    },
    {
        "DefaultLanguageValue": "Gabon (+241)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GA"
    },
    {
        "DefaultLanguageValue": "Gambia (+220)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GM"
    },
    {
        "DefaultLanguageValue": "Georgia (+995)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GE"
    },
    {
        "DefaultLanguageValue": "Germany (+49)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "DE"
    },
    {
        "DefaultLanguageValue": "Ghana (+233)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GH"
    },
    {
        "DefaultLanguageValue": "Gibraltar (+350)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GI"
    },
    {
        "DefaultLanguageValue": "Greece (+30)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GR"
    },
    {
        "DefaultLanguageValue": "Greenland (+299)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GL"
    },
    {
        "DefaultLanguageValue": "Grenada (+1-473)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GD"
    },
    {
        "DefaultLanguageValue": "Guam (+1-671)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GU"
    },
    {
        "DefaultLanguageValue": "Guatemala (+502)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GT"
    },
    {
        "DefaultLanguageValue": "Guernsey (+44-1481)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GG"
    },
    {
        "DefaultLanguageValue": "Guinea (+224)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GN"
    },
    {
        "DefaultLanguageValue": "Guinea-Bissau (+245)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GW"
    },
    {
        "DefaultLanguageValue": "Guyana (+592)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GY"
    },
    {
        "DefaultLanguageValue": "Haiti (+509)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "HT"
    },
    {
        "DefaultLanguageValue": "Honduras (+504)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "HN"
    },
    {
        "DefaultLanguageValue": "Hong Kong (+852)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "HK"
    },
    {
        "DefaultLanguageValue": "Hungary (+36)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "HU"
    },
    {
        "DefaultLanguageValue": "Iceland (+354)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IS"
    },
    {
        "DefaultLanguageValue": "India (+91)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IN"
    },
    {
        "DefaultLanguageValue": "Indonesia (+62)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ID"
    },
    {
        "DefaultLanguageValue": "Iran (+98)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IR"
    },
    {
        "DefaultLanguageValue": "Iraq (+964)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IQ"
    },
    {
        "DefaultLanguageValue": "Ireland (+353)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IE"
    },
    {
        "DefaultLanguageValue": "Isle of Man (+44-1624)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IM"
    },
    {
        "DefaultLanguageValue": "Israel (+972)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IL"
    },
    {
        "DefaultLanguageValue": "Italy (+39)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IT"
    },
    {
        "DefaultLanguageValue": "Ivory Coast (+225)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "IV"
    },
    {
        "DefaultLanguageValue": "Jamaica (+1-876)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "JM"
    },
    {
        "DefaultLanguageValue": "Japan (+81)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "JP"
    },
    {
        "DefaultLanguageValue": "Jersey (+44-1534)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "JE"
    },
    {
        "DefaultLanguageValue": "Jordan (+962)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "JO"
    },
    {
        "DefaultLanguageValue": "Kazakhstan (+7)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KZ"
    },
    {
        "DefaultLanguageValue": "Kenya (+254)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KE"
    },
    {
        "DefaultLanguageValue": "Kiribati (+686)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KI"
    },
    {
        "DefaultLanguageValue": "Kosovo (+383)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KR"
    },
    {
        "DefaultLanguageValue": "Kuwait (+965)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KW"
    },
    {
        "DefaultLanguageValue": "Kyrgyzstan (+996)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KG"
    },
    {
        "DefaultLanguageValue": "Laos (+856)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LA"
    },
    {
        "DefaultLanguageValue": "Latvia (+371)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LV"
    },
    {
        "DefaultLanguageValue": "Lebanon (+961)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LB"
    },
    {
        "DefaultLanguageValue": "Lesotho (+266)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LS"
    },
    {
        "DefaultLanguageValue": "Liberia (+231)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LR"
    },
    {
        "DefaultLanguageValue": "Libya (+218)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LY"
    },
    {
        "DefaultLanguageValue": "Liechtenstein (+423)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LI"
    },
    {
        "DefaultLanguageValue": "Lithuania (+370)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LT"
    },
    {
        "DefaultLanguageValue": "Luxembourg (+352)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LU"
    },
    {
        "DefaultLanguageValue": "Macau (+853)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MAU"
    },
    {
        "DefaultLanguageValue": "Macedonia (+389)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MO"
    },
    {
        "DefaultLanguageValue": "Madagascar (+261)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MG"
    },
    {
        "DefaultLanguageValue": "Malawi (+265)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MW"
    },
    {
        "DefaultLanguageValue": "Malaysia (+60)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MY"
    },
    {
        "DefaultLanguageValue": "Maldives (+960)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MV"
    },
    {
        "DefaultLanguageValue": "Mali (+223)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ML"
    },
    {
        "DefaultLanguageValue": "Malta (+356)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MT"
    },
    {
        "DefaultLanguageValue": "Marshall Islands (+692)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MH"
    },
    {
        "DefaultLanguageValue": "Mauritania (+222)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MR"
    },
    {
        "DefaultLanguageValue": "Mauritius (+230)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MU"
    },
    {
        "DefaultLanguageValue": "Mayotte (+262)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "YT"
    },
    {
        "DefaultLanguageValue": "Mexico (+52)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MX"
    },
    {
        "DefaultLanguageValue": "Micronesia (+691)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "FM"
    },
    {
        "DefaultLanguageValue": "Moldova (+373)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MD"
    },
    {
        "DefaultLanguageValue": "Monaco (+377)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MC"
    },
    {
        "DefaultLanguageValue": "Mongolia (+976)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MN"
    },
    {
        "DefaultLanguageValue": "Montenegro (+382)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ME"
    },
    {
        "DefaultLanguageValue": "Montserrat (+1-664)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MS"
    },
    {
        "DefaultLanguageValue": "Morocco (+212)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MA"
    },
    {
        "DefaultLanguageValue": "Mozambique (+258)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MZ"
    },
    {
        "DefaultLanguageValue": "Myanmar (+95)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MM"
    },
    {
        "DefaultLanguageValue": "Namibia (+264)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NA"
    },
    {
        "DefaultLanguageValue": "Nauru (+674)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NR"
    },
    {
        "DefaultLanguageValue": "Nepal (+977)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NP"
    },
    {
        "DefaultLanguageValue": "Netherlands (+31)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NL"
    },
    {
        "DefaultLanguageValue": "Netherlands Antilles (+599)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NLA"
    },
    {
        "DefaultLanguageValue": "New Caledonia (+687)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NC"
    },
    {
        "DefaultLanguageValue": "New Zealand (+64)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NZ"
    },
    {
        "DefaultLanguageValue": "Nicaragua (+505)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NI"
    },
    {
        "DefaultLanguageValue": "Niger (+227)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NE"
    },
    {
        "DefaultLanguageValue": "Nigeria (+234)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NG"
    },
    {
        "DefaultLanguageValue": "Niue (+683)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NU"
    },
    {
        "DefaultLanguageValue": "North Korea (+850)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NF"
    },
    {
        "DefaultLanguageValue": "Northern Mariana Islands (+1-670)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MP"
    },
    {
        "DefaultLanguageValue": "Norway (+47)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "NO"
    },
    {
        "DefaultLanguageValue": "Oman (+968)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "OM"
    },
    {
        "DefaultLanguageValue": "Pakistan (+92)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PK"
    },
    {
        "DefaultLanguageValue": "Palau (+680)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PW"
    },
    {
        "DefaultLanguageValue": "Palestine (+970)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PS"
    },
    {
        "DefaultLanguageValue": "Panama (+507)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PA"
    },
    {
        "DefaultLanguageValue": "Papua New Guinea (+675)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PG"
    },
    {
        "DefaultLanguageValue": "Paraguay (+595)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PY"
    },
    {
        "DefaultLanguageValue": "Peru (+51)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PE"
    },
    {
        "DefaultLanguageValue": "Philippines (+63)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PH"
    },
    {
        "DefaultLanguageValue": "Pitcairn (+64)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PN"
    },
    {
        "DefaultLanguageValue": "Poland (+48)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PL"
    },
    {
        "DefaultLanguageValue": "Portugal (+351)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PT"
    },
    {
        "DefaultLanguageValue": "Puerto Rico (+1-787, 1-939)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PR"
    },
    {
        "DefaultLanguageValue": "Qatar (+974)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "QA"
    },
    {
        "DefaultLanguageValue": "Republic of the Congo (+242)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CG"
    },
    {
        "DefaultLanguageValue": "Reunion (+262)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "RE"
    },
    {
        "DefaultLanguageValue": "Romania (+40)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "RO"
    },
    {
        "DefaultLanguageValue": "Russia (+7)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "RU"
    },
    {
        "DefaultLanguageValue": "Rwanda (+250)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "RW"
    },
    {
        "DefaultLanguageValue": "Saint Barthelemy (+590)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "BL"
    },
    {
        "DefaultLanguageValue": "Saint Helena (+290)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SH"
    },
    {
        "DefaultLanguageValue": "Saint Kitts and Nevis (+1-869)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "KN"
    },
    {
        "DefaultLanguageValue": "Saint Lucia (+1-758)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LC"
    },
    {
        "DefaultLanguageValue": "Saint Martin (+590)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "MF"
    },
    {
        "DefaultLanguageValue": "Saint Pierre and Miquelon (+508)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "PM"
    },
    {
        "DefaultLanguageValue": "Saint Vincent and the Grenadines (+1-784)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VC"
    },
    {
        "DefaultLanguageValue": "Samoa (+685)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "WS"
    },
    {
        "DefaultLanguageValue": "San Marino (+378)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SM"
    },
    {
        "DefaultLanguageValue": "Sao Tome and Principe (+239)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ST"
    },
    {
        "DefaultLanguageValue": "Saudi Arabia (+966)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SA"
    },
    {
        "DefaultLanguageValue": "Senegal (+221)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SN"
    },
    {
        "DefaultLanguageValue": "Serbia (+381)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "RS"
    },
    {
        "DefaultLanguageValue": "Seychelles (+248)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SC"
    },
    {
        "DefaultLanguageValue": "Sierra Leone (+232)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SL"
    },
    {
        "DefaultLanguageValue": "Singapore (+65)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SG"
    },
    {
        "DefaultLanguageValue": "Sint Maarten (+1-721)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SX"
    },
    {
        "DefaultLanguageValue": "Slovakia (+421)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SK"
    },
    {
        "DefaultLanguageValue": "Slovenia (+386)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SI"
    },
    {
        "DefaultLanguageValue": "Solomon Islands (+677)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SB"
    },
    {
        "DefaultLanguageValue": "Somalia (+252)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SO"
    },
    {
        "DefaultLanguageValue": "South Africa (+27)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ZA"
    },
    {
        "DefaultLanguageValue": "South Korea (+82)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GS"
    },
    {
        "DefaultLanguageValue": "South Sudan (+211)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SS"
    },
    {
        "DefaultLanguageValue": "Spain (+34)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ES"
    },
    {
        "DefaultLanguageValue": "Sri Lanka (+94)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "LK"
    },
    {
        "DefaultLanguageValue": "Sudan (+249)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SD"
    },
    {
        "DefaultLanguageValue": "Suriname (+597)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SR"
    },
    {
        "DefaultLanguageValue": "Svalbard and Jan Mayen (+47)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SJ"
    },
    {
        "DefaultLanguageValue": "Swaziland (+268)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SZ"
    },
    {
        "DefaultLanguageValue": "Sweden (+46)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SE"
    },
    {
        "DefaultLanguageValue": "Switzerland (+41)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "CH"
    },
    {
        "DefaultLanguageValue": "Syria (+963)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SYA"
    },
    {
        "DefaultLanguageValue": "Taiwan (+886)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "SY"
    },
    {
        "DefaultLanguageValue": "Tajikistan (+992)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TJ"
    },
    {
        "DefaultLanguageValue": "Tanzania (+255)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TZ"
    },
    {
        "DefaultLanguageValue": "Thailand (+66)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TH"
    },
    {
        "DefaultLanguageValue": "Togo (+228)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TG"
    },
    {
        "DefaultLanguageValue": "Tokelau (+690)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TK"
    },
    {
        "DefaultLanguageValue": "Tonga (+676)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TO"
    },
    {
        "DefaultLanguageValue": "Trinidad and Tobago (+1-868)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TT"
    },
    {
        "DefaultLanguageValue": "Tunisia (+216)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TN"
    },
    {
        "DefaultLanguageValue": "Turkey (+90)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TR"
    },
    {
        "DefaultLanguageValue": "Turkmenistan (+993)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TM"
    },
    {
        "DefaultLanguageValue": "Turks and Caicos Islands (+1-649)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TC"
    },
    {
        "DefaultLanguageValue": "Tuvalu (+688)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "TV"
    },
    {
        "DefaultLanguageValue": "U.S. Virgin Islands (+1-340)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VI"
    },
    {
        "DefaultLanguageValue": "Uganda (+256)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "UG"
    },
    {
        "DefaultLanguageValue": "Ukraine (+380)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "UA"
    },
    {
        "DefaultLanguageValue": "United Arab Emirates (+971)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "AE"
    },
    {
        "DefaultLanguageValue": "United Kingdom (+44)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "GB"
    },
    {
        "DefaultLanguageValue": "United States (+1)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "US"
    },
    {
        "DefaultLanguageValue": "Uruguay (+598)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "UY"
    },
    {
        "DefaultLanguageValue": "Uzbekistan (+998)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "UZ"
    },
    {
        "DefaultLanguageValue": "Vanuatu (+678)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VU"
    },
    {
        "DefaultLanguageValue": "Vatican (+379)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VA"
    },
    {
        "DefaultLanguageValue": "Venezuela (+58)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VE"
    },
    {
        "DefaultLanguageValue": "Vietnam (+84)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "VN"
    },
    {
        "DefaultLanguageValue": "Wallis and Futuna (+681)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "WF"
    },
    {
        "DefaultLanguageValue": "Western Sahara (+212)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "EH"
    },
    {
        "DefaultLanguageValue": "Yemen (+967)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "YE"
    },
    {
        "DefaultLanguageValue": "Zambia (+260)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ZM"
    },
    {
        "DefaultLanguageValue": "Zimbabwe (+263)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "CountryCode",
        "TranslationKey": "ZW"
    }
]