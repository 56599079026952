export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "New User",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewUser"
    },
    {
        "DefaultLanguageValue": "Add New User (Email Check)",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "AddNewUser_EmailCheck"
    },
    {
        "DefaultLanguageValue": "Update User",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdateUser"
    },
    {
        "DefaultLanguageValue": "User Details",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UserDetails"
    },
    {
        "DefaultLanguageValue": "List of Non-Active Users",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfNonActiveUsers"
    },
    {
        "DefaultLanguageValue": "Non-Active User Details",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NonActiveUserDetails"
    },
]