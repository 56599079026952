import PropTypes from 'prop-types';
import React from 'react';
import Select from "react-select";
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FIELD_SIZE, ID_CONFIG_TYPES } from '../../../../Shared/Constants';
import { SelectList, withSMARTWrapper } from '../../../Forms';
import { GlobalTranslationKeys } from '../../../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationOptions, getTranslationValue } from '../../../../Shared/Forms/FormUtils';
import { LANGUAGE_APPLICATION_NAME } from '../../../../App/AppSettings';

const App = LANGUAGE_APPLICATION_NAME;
const IdTranslationKeys = getTranslationKeys(App, GlobalTranslationKeys.concat(TranslationKeys));

class IdComponent extends React.Component {

    clearIdentificationNumber = () => {
        const { idNumbersField, onChangeField} = this.props;

        const IdNumber = [{
            IdType: '',
            IdNumber: '',
            IdCountry: ''
        }]

        onChangeField(idNumbersField.name, IdNumber);
    }

    constructIdTypeOptions = (filteredIdTypes, getStaticText) => {
        const { idNumbersField } = this.props;

        let value = idNumbersField.value;
        let idTypeOptions = [];
        filteredIdTypes.forEach(idType =>
            idTypeOptions.push({
                label: idType,
                value: idType
            })
        );

        if (getStaticText) {
            idTypeOptions =  getTranslationOptions(idTypeOptions, getStaticText, IdTranslationKeys);
        }

        if (idNumbersField.required && filteredIdTypes.length === 1) {
            value[0].IdType = filteredIdTypes[0];
        }
        return idTypeOptions;
    }

    constructIssuingCountryOptions = (getStaticText) => {
        const { idNumbersField, idTypesConfig } = this.props;
        const value = idNumbersField.value;
        const idType = value[0].IdType;
        const idNumberRequired = idNumbersField.required;
        
        let issuingCountryOptions = [];
        let issuingCountryValueOptions = [];
        if (idType !== '') {
            issuingCountryValueOptions = idTypesConfig[idType].IssuingCountryOptions;
            issuingCountryValueOptions.forEach(issuingCountry =>
                issuingCountryOptions.push({
                    label: issuingCountry,
                    value: issuingCountry
                })
            );
        }

        if (getStaticText) {
            issuingCountryOptions =  getTranslationOptions(issuingCountryOptions, getStaticText, IdTranslationKeys);
        }
        if (idNumberRequired && issuingCountryValueOptions.length === 1) {
            value[0].IdCountry = issuingCountryValueOptions[0];
        }
        return issuingCountryOptions === undefined ? [] : issuingCountryOptions;
    }

    handleChange = (inputValue, field) => {
        const { idNumbersField, onChangeField } = this.props;
        const { name, value } = idNumbersField;

        value[0][field] = inputValue;

        if (field === "IdType") {
            value[0].IdCountry = ''; // Reset Id Country
        }

        onChangeField(name, value);
    }

    findIdTypeBasedOnNationality = () => {
        const { idTypesConfig, nationalityField } = this.props;

        const nationalitySelected = nationalityField.value;
        if (nationalitySelected !== '') {
            const filteredIdTypes = Object.keys(idTypesConfig)
                .filter(key => idTypesConfig[key].NationalityOptions.includes(nationalitySelected));

            return filteredIdTypes;
        }
        return (Object.keys(idTypesConfig));
    }

    renderIdComponent({ getStaticText, type, nationalityField, idNumbersField, onChangeField }) {

        // Id Type
        const filteredIdTypes = this.findIdTypeBasedOnNationality(); // Filtered id types based on idTypesConfig: Nationality defined
        const idTypeOptions = this.constructIdTypeOptions(filteredIdTypes, getStaticText);
        const selectedIdType = idTypeOptions.filter(id => id.value === idNumbersField.value[0].IdType);

        // Issuing Country
        const issuingCountryTypeOptions = this.constructIssuingCountryOptions(getStaticText);
        const selectedIssuingCountry =
            issuingCountryTypeOptions.filter(country => country.value === idNumbersField.value[0].IdCountry);

        // Id Number
        const idNumber = idNumbersField.value[0].IdNumber;

        switch (type) {
            case ID_CONFIG_TYPES.EITHER_ONE:
            default:
                return (
                    <>
                        <SelectList
                            name="Profile.Nationality"
                            value={nationalityField.value}
                            options={nationalityField.countriesOptions}
                            onChangeField={(name, value) => {
                                onChangeField(name, value);
                                this.clearIdentificationNumber();
                            }}
                            placeholder={nationalityField.placeholder}
                            isMulti={false}
                            isClearable={true}
                            label={nationalityField.label}
                            inputSize={FIELD_SIZE.XLARGE}
                            error={nationalityField.error}
                            required={nationalityField.required}
                            translation={nationalityField.translation}
                        />
                        <FormGroup>
                            <Row>
                                <Label lg="3" className={` 
                                ${idNumbersField.required ? "required" : ""}
                                ${idNumbersField.error !== undefined ? "label-error" : "label-default"}`}>
                                    {idNumbersField.label}
                                </Label>
                                <Col lg="3" md="5" xs="12">
                                    <Select
                                        className="select-list"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={getTranslationValue("ID Type", getStaticText, IdTranslationKeys)}
                                        options={idTypeOptions}
                                        onChange={(selected) => this.handleChange(selected === null ? '' : selected.value, "IdType")}
                                        value={selectedIdType}
                                        isDisabled={idNumbersField.required && filteredIdTypes.length === 1}
                                    />
                                </Col>
                                <Col lg="2" md="3" xs="12">
                                    <Input
                                        placeholder={getTranslationValue("ID Number", getStaticText, IdTranslationKeys)}
                                        onChange={(event) => this.handleChange(event.target.value, "IdNumber")}
                                        min={idNumbersField.minLength}
                                        max={idNumbersField.maxLength}
                                        autoComplete="off"
                                        value={idNumber}
                                    />
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Select
                                        className="select-list"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={getTranslationValue("Issuing Country", getStaticText, IdTranslationKeys)}
                                        options={issuingCountryTypeOptions}
                                        onChange={(selected) => this.handleChange(selected === null ? '' : selected.value, "IdCountry")}
                                        value={selectedIssuingCountry}
                                        isDisabled={idNumbersField.required && issuingCountryTypeOptions.length <= 1}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col lg={{ size: 'auto', offset: 3 }}>
                                    {
                                        idNumbersField.error !== '' &&
                                        <div className="label-feedback">
                                            {idNumbersField.error}
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </>
                );
        }
    }

    render() {
        return (
            <>
                {this.renderIdComponent(this.props)}
            </>
        );
    }
}

// PropTypes: For prop validation
IdComponent.propTypes = {
    type: PropTypes.string,
    idTypesConfig: PropTypes.object
};

// PropTypes: Defaulting value for optional props
IdComponent.defaultProps = {
    type: ID_CONFIG_TYPES.EITHER_ONE
};

export default withSMARTWrapper(IdComponent);