import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { Plaintext } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE } from '../../Localization';

const GET_WEBHOOK_URL = URL + "Webhook/GetWebhook/";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const WebhookTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {

            let response = await fetchRequest(GET_WEBHOOK_URL + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;
                this.setState({
                    values: {
                        AppName: data.AppName || "-",
                        DataType: data.DataType || "-",
                        NotifyUrl: data.NotifyUrl || "-",
                        Description: data.Description || "-"
                    },
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }
        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: WebhookTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Webhook Details">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col className="smart-me-content">
                                    <Plaintext
                                        label="Application"
                                        translation={translation}
                                        name="AppName"
                                        className="mb-1"
                                        value={this.state.values.AppName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Data Type"
                                        translation={translation}
                                        translateValue={true}
                                        name="DataType"
                                        className="mb-1"
                                        value={this.state.values.DataType}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Notify URL"
                                        translation={translation}
                                        name="NotifyUrl"
                                        className="mb-1"
                                        value={this.state.values.NotifyUrl}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Description"
                                        translation={translation}
                                        name="Description"
                                        className="mb-1"
                                        value={this.state.values.Description}
                                        labelSize={LabelSize}
                                    />
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}

export default withPageNotFound(withLoader(withRouter(View)));