import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'Webhook/UpdateWebhook';
const GET_WEBHOOK_URL = URL + "Webhook/GetWebhook/";

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();
        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(GET_WEBHOOK_URL + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;

                this.setState({
                    values: {
                        Id: data.Id,
                        AppId: data.AppId,
                        DataType: data.DataType || "",
                        NotifyUrl: data.NotifyUrl || "",
                        Description: data.Description || ""
                    },
                    AppName: data.AppName,
                    DataType: data.DataType,
                    AppIds: "",
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }
        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Form
                        values={this.state.values}
                        server_url={SERVER_URL} title="Update Webhook"
                        contentHeader={MODAL_TITLE(getStaticText).UPDATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).UPDATE.BODY}
                        prevState={this.state} />)
                    : null}
            </React.Fragment>
        )
    }
}
export default withPageNotFound(withLoader(withRouter(Update)));