import React from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    getPermissionNames = (data) => {
        let names = [];
        if (data.length > 0) {
            data.map(x => names.push(x.PermissionName));
        }
        return names;
    }

    renderPermissionList = () => {
        const permissions = this.state.values.Permissions.map((x, index) => {
            return (
                <React.Fragment key={index}>
                    <Label>{x}</Label> <br/>
                </React.Fragment>
            )
        })
        return permissions
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;

        loader.start();
        let response = await fetchRequest(URL + "Role/GetRolePermissions/" + this.props.match.params.id, getParams(), false);
        if (response.success && response.body.IsSuccess) {

            let data = response.body.Data;
            let permissions = this.getPermissionNames(data.Permissions);
            this.setState({
                values: {
                    RoleName: data.RoleName,
                    AppName: data.AppName,
                    DisplayRoleName: data.DisplayRoleName,
                    Description: data.Description || "-",
                    Permissions: permissions
                },
                isLoaded: true
            });
        }
        else {
            pageNotFound();
        }
        loader.done();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} title="Role Permissions Details">
                        <React.Fragment>
                            <Row className="body-content">

                                <Col md={3}>
                                    <Label className="label-default">Application</Label>
                                </Col>
                                <Col md={9}>
                                    <Label>{this.state.values.AppName}</Label>
                                </Col>

                                <Col md={3}>
                                    <Label className="label-default">Role Name</Label>
                                </Col>
                                <Col md={9}>
                                    <Label>{this.state.values.RoleName}</Label>
                                </Col>

                                <Col md={3}>
                                    <Label className="label-default">Display Role Name</Label>
                                </Col>
                                <Col md={9}>
                                    <Label>{this.state.values.DisplayRoleName}</Label>
                                </Col>

                                <Col md={3}>
                                    <Label className="label-default">Description</Label>
                                </Col>
                                <Col md={9}>
                                    <Label>{this.state.values.Description}</Label>
                                </Col>

                                <Col md={3}>
                                    <Label className="label-default">Permissions</Label>
                                </Col>
                                <Col md={9}>
                                    {
                                      this.renderPermissionList()
                                    }
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>Back</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}

export default withPageNotFound(withLoader(withRouter(View)));