import * as Yup from 'yup';
import { ERROR } from '../../Localization';

export const FORM_CONTEXT = "Tree";

export const FORM_VALIDATIONS_CHAPTER = Yup.object().shape({
    TreeChapter: Yup.string().required(ERROR.REQUIRED),
    TreeChapterContent: Yup.string()
});

//Validations for Add Function
export const FORM_VALIDATIONS_SECTION = Yup.object().shape({
    TreeChapter: Yup.string().required(ERROR.REQUIRED),
    TreeSection: Yup.string().required(ERROR.REQUIRED),
    TreeSectionContent: Yup.string()
});

export const FORM_VALIDATIONS_SUBSECTION = Yup.object().shape({
    TreeChapter: Yup.string().required(ERROR.REQUIRED),
    TreeSection: Yup.string().required(ERROR.REQUIRED),
    TreeSubSection: Yup.string().required(ERROR.REQUIRED),
    TreeSubSecContent: Yup.string()
});

export const FORM_VALIDATIONS_TITLE = Yup.object().shape({
    TreeChapter: Yup.string().required(ERROR.REQUIRED),
    TreeSection: Yup.string().required(ERROR.REQUIRED),
    TreeSubSection: Yup.string().required(ERROR.REQUIRED),
    TreeTitle: Yup.string().required(ERROR.REQUIRED),
    TreeTitleContent: Yup.string()
});

//Validations for View/Edit Function
export const FORM_VALIDATIONS_CHAPTER_EDIT = Yup.object().shape({
    TreeChapter: Yup.string().required(ERROR.REQUIRED),
    TreeChapterContent: Yup.string()
});

export const FORM_VALIDATIONS_SECTION_EDIT = Yup.object().shape({
    TreeSection: Yup.string().required(ERROR.REQUIRED),
    TreeSectionContent: Yup.string()
});

export const FORM_VALIDATIONS_SUBSECTION_EDIT = Yup.object().shape({
    TreeSubSection: Yup.string().required(ERROR.REQUIRED),
    TreeSubSecContent: Yup.string()
});

export const FORM_VALIDATIONS_TITLE_EDIT = Yup.object().shape({
    TreeTitle: Yup.string().required(ERROR.REQUIRED),
    TreeTitleContent: Yup.string()
});

export const FORM_INITIAL_VALUES_TOPIC = {
    FaqTitle: '',
    FaqId: '',
    Name: '',
    Title: '',
    Description: ''
};

export const FORM_INITIAL_VALUES_SUB_TOPIC = {
    FaqName: '',
    FaqTitle: '',
    FaqId: '',
    FaqTopicTitle: '',
    FaqTopicId: '',
    Name: '',
    Title: '',
    Description: ''
};

export const FORM_INITIAL_VALUES_QUESTION_SET = {
    FaqName: '',
    FaqId: '',
    FaqTopicTitle: '',
    FaqTopicId: '',
    FaqSubTopicTitle: '',
    FaqSubTopicId: '',
    Name: '',
    Description: '',
    Question: {0: ''},
    Tags: [],
    TagList: []
};

export const FORM_INITIAL_VALUES_FAQ_VIEW = {
    Name: '',
    Title: '',
    Id: '',
    Description: '',
    FormattedLastCreatedDate: '',
    CreatedBy: '',
    FormattedLastUpdatedDate: '',
    LastUpdatedBy: ''
}

export const FORM_INITIAL_VALUES_FAQ_TOPIC_VIEW = {
    Name: '',
    Title: '',
    Id: '',
    Description: '',
    FormattedLastCreatedDate: '',
    CreatedBy: '',
    FormattedLastUpdatedDate: '',
    LastUpdatedBy: ''
}

export const FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_VIEW = {
    Name: '',
    Title: '',
    Id: '',
    Description: '',
    FormattedLastCreatedDate: '',
    CreatedBy: '',
    FormattedLastUpdatedDate: '',
    LastUpdatedBy: ''
}

export const FORM_INITIAL_VALUES_FAQ_QUESTION_SET_VIEW = {
    FaqName: '',
    FaqTopicName: '',
    FaqSubTopicName: '',
    Id: '',
    FormattedLastCreatedDate: '',
    CreatedBy: '',
    FormattedLastUpdatedDate: '',
    LastUpdatedBy: '',
    Tags: [],
    Questions: []
}

export const FORM_INITIAL_VALUES_FAQ_EDIT = {
    Name: '',
    Id: '',
    Description: ''
}

export const FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_EDIT = {
    FaqName: '',
    FaqTitle: '',
    FaqTopicName: '',
    FaqTopicTitle: '',
    Name: '',
    Title: '',
    Id: '',
    Description: ''
}

export const FORM_INITIAL_VALUES_FAQ_QUESTION_SET_EDIT = {
    Name: '',
    Id: '',
    Description: ''
}

export const FORM_INITIAL_VALUES_FAQ_TOPIC_EDIT = {
    FaqName: '',
    FaqTitle: '',
    FaqId: '',
    Name: '',
    Title: '',
    Description: ''
};

//Initial values for Add Function
export const FORM_INITIAL_VALUES_CHAPTER = {
    TreeChapter: '',
    TreeChapterContent: ''
};

export const FORM_INITIAL_VALUES_SECTION = {
    TreeChapter: '',
    TreeSection: '',
    TreeSectionContent: '',
};

export const FORM_INITIAL_VALUES_SUBSECTION = {
    TreeChapter: '',
    TreeSection: '',
    TreeSubSection: '',
    TreeSubSecContent: '',
};

export const FORM_INITIAL_VALUES_TITLE = {
    TreeChapter: '',
    TreeSection: '',
    TreeSubSection: '',
    TreeTitle: '',
    TreeTitleContent: '',
};

//Initial values for View/Edit Function
export const FORM_INITIAL_VALUES_CHAPTER_EDIT = {
    TreeSection: '',
    TreeSectionContent: '',
};

export const FORM_INITIAL_VALUES_SECTION_EDIT = {
    TreeSection: '',
    TreeSectionContent: '',
};

export const FORM_INITIAL_VALUES_SUBSECTION_EDIT = {
    TreeSubSection: '',
    TreeSubSecContent: '',
};

export const FORM_INITIAL_VALUES_TITLE_EDIT = {
    TreeTitle: '',
    TreeTitleContent: '',
};

export const DELETE_MODAL = {
    hasModal: true,
    name: "Delete"
};

export const UPDATE_TREE_STATUS = {
    addTopicStatus: "addTopicStatus",
    addSubTopicStatus: "addSubTopicStatus",
    addQuestionSetStatus: "addQuestionSetStatus",
    viewQuestionSetListStatus: "viewQuestionSetListStatus",
    viewFaqStatus: "viewFaqStatus", 
    viewFaqTopicStatus: "viewFaqTopicStatus", 
    viewFaqSubTopicStatus: "viewFaqSubTopicStatus",
    viewFaqQuestionSetStatus: "viewFaqQuestionSetStatus",
 

    editFaqStatus: "editFaqStatus",
    editFaqTopicStatus: "editFaqTopicStatus",
    editFaqSubTopicStatus: "editFaqSubTopicStatus",
    editFaqQuestionSetStatus: "editFaqQuestionSetStatus",

    addChaptStatus: "addChaptStatus",
    addSectionStatus: "addSectionStatus",
    addSubSectionStatus: "addSubSectionStatus",
    addTitleStatus: "addTitleStatus",
    editChaptStatus: "editChaptStatus",
    editSectionStatus: "editSectionStatus",
    editSubSectionStatus: "editSubSectionStatus",
    editTitleStatus: "editTitleStatus",
}