import React from 'react';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { TIMEOUT_LOADER } from '../../App/AppSettings';
import { ErrorPage } from '../Error';
import Spinner from './Spinner.js';
import history from '../History';

// Const: State of Content
const LOADER_STATE = {
    LOADING: "Loading",
    SUCCESS: "Success",
    TIMEOUT: "Timeout",
    ERROR: "Error"
};

// Const: Custom Message to display for timeout
const TIMEOUT = {
    header: "Oh, snap! Request timeout!",
    content: "Looks like the server is taking too long to respond, please try again."
};


export const withLoader = (LoadedComponent, givenTimeout = TIMEOUT_LOADER) => {
    class Loading extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                pageState: LOADER_STATE.SUCCESS,
                timeout: givenTimeout,
                serverErrorId: undefined
            };
        }

        // Fn: Change state to display Timeout
        loadingTimeout = () => {
            this.setState({ pageState: LOADER_STATE.TIMEOUT });
            clearTimeout(this.timeoutID);
        };

        // Fn: Change state to display Error
        loadingError = (serverErrorId) => {
            this.setState({ pageState: LOADER_STATE.ERROR, serverErrorId });
            clearTimeout(this.timeoutID);
        };

        // Fn: Change state to display Loading state
        loadingStart = () => {
            this.setState({ pageState: LOADER_STATE.LOADING });
            this.timeoutID = setTimeout(() => this.loadingTimeout(), this.state.timeout);
        };

        // Fn: Change state to display Loading state        
        loadingDone = () => {
            // this.loadingError("<ERROR_ID>");
            this.setState({ pageState: LOADER_STATE.SUCCESS });
            clearTimeout(this.timeoutID);
        };

        render() {
            switch (this.state.pageState) {

                // If loading timeout, show timeout page
                case LOADER_STATE.TIMEOUT:
                    return (
                        <ErrorPage
                            icon={faRedo}
                            iconStyle="reload-icon"
                            backCallback={() => history.goBack()}
                            reloadCallback={this.loadingStart}
                            errorHeader={TIMEOUT.header}
                            errorContent={TIMEOUT.content}
                            clearTimeout={() => clearTimeout(this.timeoutID)}
                        />
                    );

                // If loading error, show error page
                case LOADER_STATE.ERROR:
                    return (
                        <ErrorPage
                            backCallback={() => { clearTimeout(this.timeoutID); history.goBack(); }}
                            reloadCallback={() => { clearTimeout(this.timeoutID); this.loadingStart(); }}
                            errorId={this.state.serverErrorId.toString()}
                            clearTimeout={() => clearTimeout(this.timeoutID)}
                        />
                    );


                // If state is loading or done, return component
                default:
                    return (
                        <React.Fragment>

                            {/* Always render an overlay spinner */}
                            <Spinner
                                visible={this.state.pageState === LOADER_STATE.LOADING}
                            />

                            {/* Wrapped component to be loaded */}
                            <LoadedComponent
                                loader={{
                                    start: this.loadingStart,
                                    done: this.loadingDone,
                                    error: this.loadingError
                                }}
                                {...this.props}
                            />

                        </React.Fragment>
                    );
            }
        }
    }

    return Loading;
};