export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "List of Organisations",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfOrganisations"
    },
    {
        "DefaultLanguageValue": "New Organisation",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewOrganisation"
    },
    {
        "DefaultLanguageValue": "Update Organisation",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdateOrganisation"
    },
    {
        "DefaultLanguageValue": "Organisation Details",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "OrganisationDetails"
    }
]