export const ModalTranslationKeys = [
    {
        "DefaultLanguageValue": "User Approval Confirmation",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "UserApprovalHeader"
    },
    {
        "DefaultLanguageValue": "Are you sure you want to approve this user?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "UserApprovalApproveBody"
    },
    {
        "DefaultLanguageValue": "Are you sure you want to reject this user?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "UserApprovalRejectBody"
    },
    {
        "DefaultLanguageValue": "Are you sure you want to amend this user request?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "UserApprovalAmendBody"
    }
]