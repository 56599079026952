import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { ActionButton, DataTable, MobileCardActionButton } from '../../../DataTable';
import { withLoader } from '../../../Loading';
import { AssignRolesToUserModal } from '../../Modal';

const DELETE_MODAL = {
    hasModal: true,
    modalHeader: "Delete Role",
    modalContent: "Are you sure you want to delete Role?"
};

class AssignRolesToUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: props.data || [],
            deleteItemArray: props.deleteItemArray,
            initialValues: { email: '', effectiveStartDate: "", effectiveEndDate: "", role: [] },
            isOpen: false,
            selectable: props.selectable,
            allRoles: props.allRoles,
            isUpdatePage: props.isUpdatePage
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    deleteItem = (rowIndex) => {
        const { data, deleteItemArray } = this.props;

        var array = [...this.state.data];
        var item = array[rowIndex];

        if (item.Existing === true) {
            // update parent data
            data.splice(rowIndex, 1);
            deleteItemArray.push(item);

            // update grid data
            array.splice(rowIndex, 1);
            this.setState({
                deleteItemArray: [...this.state.deleteItemArray, item],
                data: array
            })
        }
        else {
            // update parent data
            data.splice(rowIndex, 1);

            // update grid data
            array.splice(rowIndex, 1);
            this.setState({ data: array })
        }
    }

    addItem = (values) => {
        const { data } = this.props;

        let displayRoleName = "";
        let id = "";
        let roleName = "";

        //map the values so that the grid can display them
        this.state.allRoles.map(x => {
            if (values.role === x["Id"]) {
                displayRoleName = x["DisplayRoleName"]
                id = x["Id"]
                roleName = x["RoleName"]
            }
            return null;
        })

        var item = { Id: id, RoleName: roleName, DisplayRoleName: displayRoleName, MeRoleId: values.role, EffectiveStartDate: values.effectiveStartDate, EffectiveEndDate: values.effectiveEndDate, Existing: false }
        var updatedData = [...this.state.data, item];

        //update parent data
        data.push(item);

        //update grid data
        this.setState({
            data: updatedData,
            modal: !this.state.modal,
        });
    }

    getTableDefinition = () => {
        if (this.state.isUpdatePage) {
            return {
                RunningNumber: { title: 'No.', width: '5%' },
                Actions: { title: 'Actions', width: '5%' },
                RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                DisplayRoleName: { title: 'Display Role Name', width: '15%', DBkey: 'DisplayRoleName' },
                EffectiveStartDate: { title: 'Effective Start Date', width: '20%', DBkey: 'EffectiveStartDate' },
                EffectiveEndDate: { title: 'Effective End Date', width: '15%', DBkey: 'EffectiveEndDate' }
            }
        }
        else {
            return {
                RunningNumber: { title: 'No.', width: '5%' },
                RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                DisplayRoleName: { title: 'Display Role Name', width: '15%', DBkey: 'DisplayRoleName' },
                EffectiveStartDate: { title: 'Effective Start Date', width: '20%', DBkey: 'EffectiveStartDate' },
                EffectiveEndDate: { title: 'Effective End Date', width: '15%', DBkey: 'EffectiveEndDate' }
            }

        }
    }

    renderMobileCardActions = (data) => {
        //Set unique id for data
        if (data["Key"] === undefined) {
            data["Key"] = parseInt(_.uniqueId());
        }

        //Find row index in the array
        let array = this.state.data;
        let rowIndex = _.findIndex(array, { 'Key': data["Key"] });

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'Delete' + rowIndex}
                    onClick={() => this.deleteItem(rowIndex)}
                    modal={DELETE_MODAL}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }

    render() {
        let tableDef = this.getTableDefinition();
        return (
            <>
                <Col md={12}>
                    {this.state.isUpdatePage ?
                        <Button color="primary" className="float-right" type="button" onClick={this.toggle}>Add</Button> :
                        null
                    }
                </Col>
                <br />
                <br />
                <Col md={12}>
                    <DataTable data={this.state.data}
                        pageSize='5'
                        minFilterChars='2'
                        noResultsMessage='No user roles found'
                        columns={
                            tableDef
                        }
                        id="MeRoleId"
                        renderActions={(rowData) =>
                            (
                                <React.Fragment>
                                    {
                                        this.state.isUpdatePage ?
                                            <ActionButton key={'Delete' + rowData.RowNumber} tooltip="Delete" color="danger" onClick={() => this.deleteItem(rowData.RowNumber - 1)} modal={DELETE_MODAL}><FontAwesomeIcon icon={faTrash} /></ActionButton>
                                            : null

                                    }
                                </React.Fragment>
                            )
                        }
                        renderMobileCardActions={this.renderMobileCardActions}
                    />
                </Col>
                <AssignRolesToUserModal
                    toggle={this.toggle}
                    initialValues={this.state.initialValues}
                    selection={this.state.selectable}
                    modalState={this.state.modal}
                    data={this.state.data}
                    addItem={this.addItem}
                    modalTitle="Add new role to user"
                />
            </>
        );
    }
}

export default withLoader(withRouter(AssignRolesToUser));