import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Container, Row, Table } from 'reactstrap';
import { STATUS_BADGE_COLOR } from '../../App/AppSettings.js';
import KeyCard from '../../Content/LanguageList/KeyCard.js';
import QuestionSetCard from '../../Content/Faq/QuestionSetCard.js';
import { getTranslationValue } from '../Forms/FormUtils.js';


const renderCardRowStatus = (title, data, i) => {
    if ((STATUS_BADGE_COLOR.success).indexOf(data) > -1) {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='success' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
    else if ((STATUS_BADGE_COLOR.warning).indexOf(data) > -1) {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='warning' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
    else if ((STATUS_BADGE_COLOR.danger).indexOf(data) > -1) {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='danger' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
    else {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='normal' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
}

const renderCardRow = (title, data, key, translateValue, translation) => {

    if (translation && translateValue) {
        const { getStaticText, translationKeys } = translation;
        data = getTranslationValue(data, getStaticText, translationKeys);
    }

    return (
        <tr key={key}>
            <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
            <td width="60%" className="card-table-td card-table-data">{data}</td>
        </tr>
    )
}

const renderCardRowBooleanStatus = ({ title, trueText, falseText }, data, i) => {
    if (data === true) {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='success' className="badge-icon">{trueText}</Badge></td>
            </tr>
        );
    } else {
        return (
            <tr key={i}>
                <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
                <td width="60%" className="card-table-td card-table-data"><Badge color='normal' className="badge-icon">{falseText}</Badge></td>
            </tr>
        );
    }

}

const renderCustomContent = (title, data, key) => {
    return (
        <tr key={key}>
            <td width="40%" className="card-table-td card-table-title"><b>{title}</b>:</td>
            <td width="60%" className="card-table-td card-table-data">{data}</td>
        </tr>
    )
}

class CardTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderCardBody = (data) => {
        let cols = [];
        const { columns, translation } = this.props;

        for (var item in columns) { // item is an array of  ['DBkey', 'display title']
            for (var key in data) {
                if (key === columns[item].DBkey) {
                    if (data.hasOwnProperty(key)) {
                        if (columns[item] !== columns.NumberHeader) {
                            if (columns[item] === columns.StatusBadge) {
                                cols.push(renderCardRowStatus(columns[item].title, data[key], item));
                            }
                            else if (columns[item] === columns.StatusBooleanBadge) {
                                cols.push(renderCardRowBooleanStatus(columns[item], data[key], item));
                            }
                            else if (columns[item] === columns.Custom) {
                                const { setContent } = columns.Custom;
                                const content = setContent !== undefined ? setContent(data) : '';

                                cols.push(renderCustomContent(columns[item].title, content, item));
                            }
                            else {
                                cols.push(renderCardRow(columns[item].title, data[key], item, columns[item].translateValue, translation));
                            }
                        }
                    }
                }
            }
        }
        return cols;
    }

    mobileCardActionContainer = (data, renderMobileCardActions) => {
        return (
            <Container fluid className='card-icon-grp'>
                <Row className="card-icon-grp-row" id="mobileBtnContainer">
                    <div className="horizontal-line" />
                    {renderMobileCardActions(data)}
                </Row>
            </Container>
        )
    }

    renderMobileCard = ({ data, columns, renderMobileCardActions, renderActions, graphicUrl, islanguageCard, isQuestionSetCard, isMobile }) => {
        if (data.length !== 0) {
            if (isMobile) {
                return _.map(data, (data, i) => {
                    return (
                        <Card className={(graphicUrl && (graphicUrl === data.Path)) ? "card-container card-container-highlighted" : "card-container"} key={i}>
                            {columns.NumberHeader &&
                                <CardHeader className={((data[columns.NumberHeader.DBkey] !== "") ? "mobilecard-header" : "draft-mobilecard-header")}>
                                    {((data[columns.NumberHeader.DBkey] !== "") ? ((columns.NumberHeader.title).substring(0, 3) + ". " + data[columns.NumberHeader.DBkey]) : "Draft")}
                                </CardHeader>}
                            <CardBody className="card-table-body">
                                <Table className="card-table">
                                    <tbody>
                                        {this.renderCardBody(data)}
                                    </tbody>
                                </Table>
                            </CardBody>
                            <div className="horizontal-line" />
                            {renderMobileCardActions && this.mobileCardActionContainer(data, renderMobileCardActions)}
                        </Card>
                    );
                });
            }
            if (islanguageCard) {
                return (
                    <div className="card-container">
                        {_.map(data, (data, i) => {
                            return (
                                <KeyCard
                                    key={i}
                                    keyData={data}
                                >
                                    {renderActions(data)}
                                </KeyCard>

                            );
                        }
                        )}
                    </div>
                );
            }
            if (isQuestionSetCard) {
                return (
                    <div className="card-container">
                        {_.map(data, (data, i) => {
                            return (
                                <QuestionSetCard
                                    key={i}
                                    keyData={data}
                                >
                                    {renderActions(data)}
                                </QuestionSetCard>
                            );
                        }
                        )}
                    </div>
                );
            }


        }
        else {
            return (
                <Card className="card-container">
                    <CardBody className="card-table-body">
                        {this.props.noResultsMessage}
                    </CardBody>
                </Card>
            )
        }

    };

    togglePaginationDisplay = () => {
        if (this.props.NoOfPage === 0 || this.props.NoOfPage === undefined) {
            return "no-pagination";
        }
        return "";
    }

    render() {
        const { getPrev, onChange, value, NoOfPage, getNext } = this.props;
        return (
            <React.Fragment>
                {this.renderMobileCard(this.props)}
                {/*temporary props to hide pagination in carousel */
                    !this.props.carousel &&
                    <div className={"pagination-group " + this.togglePaginationDisplay()}>
                        {/* <Button size="sm" onClick={getFirst} color="neutral">First</Button> */}
                        {this.props.currentPage !== 1 &&
                            <React.Fragment>
                                <Button size="sm" onClick={getPrev} color="neutral"><FontAwesomeIcon icon={faCaretLeft} /></Button>
                            </React.Fragment>}

                        <select
                            className="griddle-page-select"
                            onChange={onChange}
                            value={value}
                        >
                            {[...Array(NoOfPage)].map((item, i) => (
                                <option key={i}> {i + 1} </option>
                            ))}
                        </select>

                        {this.props.currentPage !== NoOfPage &&
                            <React.Fragment>
                                <Button size="sm" onClick={getNext} color="neutral"><FontAwesomeIcon icon={faCaretRight} /></Button>

                            </React.Fragment>}
                        {/* <Button size="sm" onClick={getLast} color="neutral">Last</Button> */}
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default CardTable;