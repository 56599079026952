import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Container, Row, Table } from 'reactstrap';
import { STATUS_BADGE_COLOR } from '../../App/AppSettings.js';
import { getTranslationValue } from '../Forms/FormUtils.js';


const renderCardRowStatus = (title, data, i, column) => {
    if ((STATUS_BADGE_COLOR.success).indexOf(data) > -1) {
        return(
            <tr key={i}>
                <td width="40%" className="card-table-td"><b>{title}</b>:</td> 
                <td width="60%" className="card-table-td"><Badge color='success' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    } 
    else if ((STATUS_BADGE_COLOR.warning).indexOf(data) > -1) {
        return(
            <tr key={i}>
                <td width="40%" className="card-table-td"><b>{title}</b>:</td> 
                <td width="60%" className="card-table-td"><Badge color='warning' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
    else if ((STATUS_BADGE_COLOR.danger).indexOf(data) > -1) {
        return(
            <tr key={i}>
                <td width="40%" className="card-table-td"><b>{title}</b>:</td> 
                <td width="60%" className="card-table-td"><Badge color='danger' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
    else {
        return(
            <tr key={i}>
                <td width="40%" className="card-table-td"><b>{title}</b>:</td> 
                <td width="60%" className="card-table-td"><Badge color='normal' className="badge-icon">{data}</Badge></td>
            </tr>
        )
    }
}

const renderCardRow = (title, data, key, translateValue, translation) => {
    
    if (translation && translateValue) {
        const { getStaticText, translationKeys } = translation;
        data = getTranslationValue(data, getStaticText, translationKeys);
    }

    return(
        <tr key={key}>
            <td width="40%" className="card-table-td"><b>{title}</b>:</td> 
            <td width="60%" className="card-table-td">{data}</td>
        </tr>
    )            
}

class MobileCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderCardBody = (data) => {
        let cols = [];
        const { columns, translation } = this.props;

        for (var item in columns) { // item is an array of  ['DBkey', 'display title']
            for (var key in data) {
                if (key === columns[item].DBkey) {
                    if (data.hasOwnProperty(key)) {
                        if (columns[item] !== columns.NumberHeader){
                            if (columns[item] === columns.StatusBadge){
                                cols.push(renderCardRowStatus(columns[item].title, data[key], item, columns[item]));
                            }
                            else {
                                cols.push(renderCardRow(columns[item].title, data[key], item, columns[item].translateValue, translation));
                            }
                        }
                    }
                }
            }
        }
        return cols;
    }

    mobileCardActionContainer = (data, renderMobileCardActions) => {
        return (
            <Container fluid className='card-icon-grp'>
                <Row>
                    <div className="horizontal-line" />
                    {renderMobileCardActions(data)}
                </Row>
            </Container>
        )
    }

    renderMobileCard = ({ data, columns, renderMobileCardActions}) => {
        if( data.length !== 0) {
            return _.map(data, (data, i) => {
                return (
                    <Card className="card-container" key={i}>
                        {columns.NumberHeader && 
                            <CardHeader className={((data[columns.NumberHeader.DBkey] !== "") ? "mobilecard-header" : "draft-mobilecard-header")}>
                                {((data[columns.NumberHeader.DBkey] !== "") ? ( (columns.NumberHeader.title).substring(0,3) + ". "+  data[columns.NumberHeader.DBkey]) : "Draft")}
                            </CardHeader>}
                        <CardBody className="card-table-body">
                            <Table className="card-table">
                                <tbody>
                                    {this.renderCardBody(data)}
                                </tbody>
                            </Table>
                        </CardBody>
                        <div className="horizontal-line" />
                        {renderMobileCardActions && this.mobileCardActionContainer(data, renderMobileCardActions)}
                    </Card>
                );
            });
        }
        else {
            return (
                <Card className="card-container">
                    <CardBody className="card-table-body">
                        {this.props.noResultsMessage}
                    </CardBody>
                </Card>
            )
        }
        
    };

    togglePaginationDisplay = () =>{
        if (this.props.NoOfPage === 0 || this.props.NoOfPage === undefined){
            return "no-pagination";
        } 
    }

    render() {
        const { getPrev, onChange, value, NoOfPage, getNext } = this.props;
        return (
            <React.Fragment>
                {this.renderMobileCard(this.props)}
                <div className={"pagination-group " + this.togglePaginationDisplay()}>
                {/* <Button size="sm" onClick={getFirst} color="neutral">First</Button> */}
                {this.props.currentPage !== 1 && 
                <React.Fragment>
                    
                    <Button size="sm" onClick={getPrev} color="neutral"><FontAwesomeIcon icon={faCaretLeft} /></Button>
                </React.Fragment>}

                    <select
                        className="griddle-page-select"
                        onChange={onChange}
                        value={value}
                    >
                        {[...Array(NoOfPage)].map((item, i) => (
                            <option key={i}> {i + 1} </option>
                        ))}
                    </select>

                    {this.props.currentPage !== NoOfPage && 
                    <React.Fragment>
                        <Button size="sm" onClick={getNext} color="neutral"><FontAwesomeIcon icon={faCaretRight} /></Button>
                        
                    </React.Fragment>}
                    {/* <Button size="sm" onClick={getLast} color="neutral">Last</Button> */}
                </div>
            </React.Fragment>
        );
    }
}

export default MobileCard;