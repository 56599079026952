import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, postParams } from '../../Shared/Actions';
import { toastError, toastSuccess, withSMARTWrapper } from '../../Shared/Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';

const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

const DELETE_URL = URL + 'UserGroup/DeleteUserGroup/';
const GRID_ENDPOINT = URL + "UserGroup/ListUserGroups";
const VIEW_USER_GROUP_URL_PAGE = "UserGroup/View/";
const UPDATE_USER_GROUP_URL_PAGE = "UserGroup/Update/"
const NEW_USER_GROUP_URL_PAGE = "/UserGroup/New";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UGTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleUpdate: false
        };
    }

    //Fn: Delete Row via Id
    async deleteRow(Id) {
        const { loader, getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UGTranslationKeys
        };

        if (Id) {
            // Start loading
            loader.start();

            // Send request to backend to delete Row
            const response = await fetchRequest(DELETE_URL + Id, postParams(), false);

            // Done loading
            loader.done();

            const { IsSuccess, Messages } = response.body;

            // Based on response, show appropriate toaster
            (response.success && IsSuccess) ?
                toastSuccess(Messages, null, translation) :
                toastError(Messages, translation);

            if (IsSuccess) {
                this.setState({ toggleUpdate: !this.state.toggleUpdate });
            }
        }
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;
        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_USER_GROUP_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                {rowData.IsEditable &&
                    <MobileCardActionButton
                        key={'Update' + Id}
                        onClick={() => navigateTo(UPDATE_USER_GROUP_URL_PAGE, ID)}
                        icon={faEdit}
                        label={BUTTON_TITLE(getStaticText).UPDATE}
                    />
                }
                {rowData.IsDeletable &&
                    <MobileCardActionButton
                        key={'Delete' + Id}
                        onClick={() => this.deleteRow(Id)}
                        modal={DELETE_MODAL(getStaticText)}
                        icon={faTrash}
                        label={BUTTON_TITLE(getStaticText).DELETE}
                        formatter={{ component: getTranslationValue('User Group', getStaticText, UGTranslationKeys) }}
                    />
                }
            </React.Fragment>
        )
    }

    setContent = (rowData) => {
        return <div className="div-link" onClick={() => navigateTo(VIEW_USER_GROUP_URL_PAGE, { ID: rowData.Id })}>{rowData.GroupName}</div>
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UGTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of User Groups" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ActionButton className='add-button' color="neutral" onClick={() => navigateTo(NEW_USER_GROUP_URL_PAGE)}><FontAwesomeIcon icon={faPlus} /></ActionButton>
                            <ServerSideDataTable
                                translation={translation}
                                toggleUpdate={this.state.toggleUpdate}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No user groups found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        AppName: { title: 'Application', width: '20%', DBkey: 'AppName' },
                                        OrganisationName: { title: 'Organisation', width: '15%', DBkey: 'OrganisationName', translateValue: true },
                                        GroupName: { title: 'Group Name', width: '15%', DBkey: 'GroupName', setContent: this.setContent },
                                        Description: { title: 'Description', width: '20%', DBkey: 'Description' },
                                        MeRoleToUserGroupCount: { title: 'No. of Roles', width: '5%', DBkey: 'MeRoleToUserGroupCount' },
                                        MeUserToUserGroupCount: { title: 'No. of Users', width: '5%', DBkey: 'MeUserToUserGroupCount' },
                                        LastUpdatedDate: { title: 'Last Updated', width: '12%', DBkey: 'LastUpdatedDate' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <React.Fragment>
                                        {RowData.IsEditable &&
                                            <ActionButton key={'Update' + RowData.Id} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                                onClick={() => navigateTo(UPDATE_USER_GROUP_URL_PAGE, { ID: RowData.Id })}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionButton>
                                        }
                                        {RowData.IsDeletable &&
                                            <ActionButton key={'Delete' + RowData.Id} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                                onClick={() => this.deleteRow(RowData.Id)} modal={DELETE_MODAL(getStaticText)}
                                                formatter={{ component: getTranslationValue('User Group', getStaticText, UGTranslationKeys) }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </ActionButton>
                                        }
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);