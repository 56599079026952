// Const: Variable Length for Field/Label
export const FIELD_SIZE = {
    NONE: 0,
    XSMALL: 2,
    SMALL: 3,
    MEDIUM: 5,
    LARGE: 7,
    XLARGE: 9,
    MAX: 12
};

// Const: Field/Label Orientation
export const ORIENTATION = {
    ROW: 'ROW',
    INLINE: 'INLINE'
};

export const SECTION_WRAPPER_TYPES = {
    WIZARD: "Wizard",
    TAB: "Tab",
    ACCORDION: "Accordion"
}

export const ID_CONFIG_TYPES = {
    EITHER_ONE: "Either One" // Extensible in future
}

// Const: Filters
export const FILTER = "Filter";
export const SELECT_FILTERS = "SelectFilters";
export const SORT_COLUMN = "SortColumn";
export const SORT_DIR = "SortDir";
export const SORT_BY_KEYS = "SortByKeys";

// Const: Response Object
export const Response = (isSuccess = false, responseData) => {
    return {
        success: isSuccess,
        body: responseData
    }
};

export const FILE_UPLOAD_SECTION_FILES = "FileUploadSectionFiles";

export const PARAGRAPH_TYPES = {
    DEFAULT: 'DEFAULT',
    WITH_ICON: 'WITH_ICON'
};

//Const: Rich text toolbar
export const RICHTEXT = {
    DEFAULT: {
        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image'],
    }
}

export const DIRECTIONS = {
    RTL: 'Right To Left',
    LTR: 'Left To Right'
}

export const TEXT_DIRECTIONS = {
    RTL: 'rtl',
    LTR: 'ltr',
    AUTO: 'auto'
}

// Const: Display format for date
export const DATE_DISPLAY_FORMAT = {
    DATE_TIME: "DD-MMM-YYYY HH:mm A",
    TIME: "HH:mm A",
    TIME_12HOUR: "hh:mm A",
    DATE: "DD-MMM-YYYY"
}