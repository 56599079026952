import React, { Component } from 'react';
import Oidc from 'oidc-client'
import { withRouter } from 'react-router-dom'

class Silent extends Component {
  constructor() {
    super();
    this.state = {
      path: 'Error/Page404'
    }
  }
  
  componentDidMount() {
    new Oidc.UserManager().signinSilentCallback();
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default withRouter(Silent);