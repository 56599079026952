import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { selectListMapper }from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert } from '../../Shared//Forms';

const SERVER_URL = URL+'Role/UpdateRolePermissions';

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    assignedValues = (data) =>{
        let values = [];
        if(data.length > 0){
            data.map(x => values.push(x.PermissionId));
        }
        return values;
    }

    async componentDidMount(){
        const { loader } = this.props;
        loader.start();

        const Id = this.props.match.params.id;
        let response = await fetchRequest(URL+"Role/GetRolePermissions/" + Id, getParams(), false);
        let selectionResponse = await fetchRequest(URL+"Permission/GetAllRoleAllowedPermissions/" + Id, getParams(), false);      
      
        if(response.success && response.body.IsSuccess && selectionResponse.success && selectionResponse.body.IsSuccess){
            let data = response.body.Data;
            let selection = selectionResponse.body.Data;

            const mappedSelection = selectListMapper(selection, "Id", "PermissionName");
            let assigned = this.assignedValues(data.Permissions);
            this.setState({
                readOnlyValues:{
                    RoleName: data.RoleName,
                    AppName: data.AppName,
                    DisplayRoleName: data.DisplayRoleName,
                    Description: data.Description || "",
                    Selection: mappedSelection
                },
                values: {
                    RoleId : data.RoleId,
                    Assigned: assigned,
                },
                isLoaded: true 
            });
        }
        else{
            const { formAlert } = this.props;
            formAlert.showAlert(response.body.messages || response.body.Messages);
        }
        loader.done();
    }
    
    render() {
        return(
            <React.Fragment>
                {this.state.isLoaded ? 
                (<Form values={this.state.values} server_url={SERVER_URL} readOnlyValues={this.state.readOnlyValues} title="Update Role Permissions" contentHeader={"Update Role Permissions Confirmation"} contentBody={"Confirm update of Role Permissions?"}/>) 
                :null}
            </React.Fragment>
        )
    }
}
export default withFormAlert(withLoader(withRouter(Update)));