import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { SMARTForm, Radio, Text, toastSuccess, toastError, SelectList, Plaintext, withSMARTWrapper } from '../../Shared/Forms';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { isValidForm, createLabelAndValueArray } from '../../Shared/SmartMe/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ModalConfirm } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { FIELD_SIZE } from '../../Shared/Constants';
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const FORM_VALIDATIONS = (getStaticText) => {
    const requiredMsg = ERROR_MSG(getStaticText).REQUIRED;
    return Yup.object().shape({
        AppId: Yup.string().required(requiredMsg),
        PermissionName: Yup.string().required(requiredMsg),
        PermissionType: Yup.string().required(requiredMsg),
        IsMonitored: Yup.string().required(requiredMsg),
        FeatureName: Yup.string().required(requiredMsg)
    });
}

const FORM_VALIDATIONS_UPDATE = (getStaticText) => {
    const requiredMsg = ERROR_MSG(getStaticText).REQUIRED;
    return Yup.object().shape({
        PermissionName: Yup.string().required(requiredMsg),
        PermissionType: Yup.string().required(requiredMsg),
        IsMonitored: Yup.string().required(requiredMsg),
        FeatureName: Yup.string().required(requiredMsg)
    });
}

const GET_PERMISSION_TYPE_URL = URL + "Code/GetPermissionTypes";
const GET_FEATURE_NAME_URL = URL + "Code/GetFeatureNames";


const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const PermissionTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            permissionTypes: null,
            isLoaded: false,
            server_url: props.server_url,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            prevState: props.prevState
        };
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;

        loader.start();
        let permissionTypesResponse = await fetchRequest(GET_PERMISSION_TYPE_URL, getParams(), false);
        let featureNameResponse = await fetchRequest(GET_FEATURE_NAME_URL, getParams(), false);

        if (permissionTypesResponse.success && permissionTypesResponse.body.IsSuccess &&
            featureNameResponse.success && featureNameResponse.body.IsSuccess) {

            let permissionData = createLabelAndValueArray(permissionTypesResponse.body.Data);
            let featureNameData = createLabelAndValueArray(featureNameResponse.body.Data);

            this.setState({
                permissionTypes: permissionData,
                featureNames: featureNameData,
                isLoaded: true
            });
        }
        else {
            pageNotFound();
        }
        loader.done();
    }

    render() {
        const { modal, getStaticText } = this.props;
        const validationSchema = this.state.prevState.AppIds !== "" ? FORM_VALIDATIONS(getStaticText) : FORM_VALIDATIONS_UPDATE(getStaticText);

        const translation = {
            getStaticText: getStaticText,
            translationKeys: PermissionTranslationKeys
        };

        return (
            <React.Fragment>

                {this.state.isLoaded ? (
                    <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                        <SMARTForm
                            formValues={this.state.values}
                            validationSchema={validationSchema}
                            validateOnSubmit={true}
                            serverURL={this.state.server_url}
                            isDummy={false}
                            submitCallback={({ response }) => {

                                if (response.success && response.body.IsSuccess) {
                                    navigateTo("/Permission");
                                    toastSuccess(response.body.Messages, null, translation)
                                }
                                else {
                                    toastError(response.body.Messages, translation)
                                }
                            }
                            }
                        >
                            {({ values, errors, onChange, submitForm, onChangeField }) => (
                                <React.Fragment>
                                    <Row className="body-content">
                                        <Col>
                                            {this.state.prevState.AppIds !== "" ?
                                                <SelectList
                                                    name="AppId"
                                                    value={values.AppId}
                                                    options={this.state.prevState.AppIds}
                                                    onChangeField={onChangeField}
                                                    isMulti={false}
                                                    error={errors.AppId}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label="Application"
                                                    translation={translation}
                                                    required
                                                /> :
                                                <Plaintext
                                                    label="Application"
                                                    translation={translation}
                                                    name="AppName"
                                                    value={this.state.prevState.AppName}
                                                />
                                            }
                                            
                                            <SelectList
                                                name="FeatureName"
                                                value={values.FeatureName}
                                                options={this.state.featureNames}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                error={errors.FeatureName}
                                                inputSize={FIELD_SIZE.MEDIUM}
                                                label="Feature"
                                                translation={translation}
                                                required
                                            /> 

                                            <Text
                                                name="PermissionName"
                                                value={values.PermissionName}
                                                placeholder="Permission Name"
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Permission Name"
                                                translation={translation}
                                                error={errors.PermissionName}
                                                required
                                            />

                                            <SelectList
                                                name="PermissionType"
                                                value={values.PermissionType}
                                                options={this.state.permissionTypes}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                error={errors.PermissionType}
                                                inputSize={FIELD_SIZE.MEDIUM}
                                                label="Permission Type"
                                                translation={translation}
                                                required
                                            />
                                            <Radio
                                                name="IsMonitored"
                                                value={values.IsMonitored}
                                                options={[
                                                    { label: "Yes", value: "1" },
                                                    { label: "No", value: "0" }]}
                                                onChange={onChange}
                                                inputSize={7}
                                                label="Monitor Permission"
                                                translation={translation}
                                                error={errors.IsMonitored}
                                                required
                                            />
                                            <Text
                                                name="Description"
                                                value={values.Description}
                                                placeholder="Description"
                                                translation={translation}
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Description"
                                                error={errors.Description}
                                            />
                                        </Col>
                                    </Row>
                                    <PageFooter>
                                        <Button color="primary" type="button" onClick={() => isValidForm(validationSchema, values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                    </PageFooter>

                                    <ModalConfirm
                                        isOpen={modal.modalState === "Submit"}
                                        contentHeader={this.state.contentHeader}
                                        contentBody={this.state.contentBody}
                                        confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                        cancelCallback={() => modal.toggleModal("Submit")}
                                        translation={translation}
                                        formatter={{ component: getTranslationValue('Permission', getStaticText, PermissionTranslationKeys) }}
                                    />
                                </React.Fragment>
                            )}
                        </SMARTForm>
                    </Layout>)
                    : null}
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Form);