import React from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import { LAYOUT_TYPE, DATE_DISPLAY_FORMAT, ADDRESS, OPTIONAL_ADDRESS_TYPE, DEFAULT_HOME_COUNTRY, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SMARTForm, Radio, Text, DateInput, toastSuccess, toastError, SelectList, Plaintext, withSMARTWrapper } from '../../Shared/Forms';
import { isValidForm, processGenders, createLabelAndValueArray, selectListMapper, resetAddressDetails } from '../../Shared/SmartMe/Actions';
import { FIELD_SIZE, SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { URL, USER_CHECK_TYPE } from '../../Shared/SmartMe/Constants';
import PageFooter from '../../Shared/PageFooter';
import { ModalConfirm } from '../../Shared/Modal';
import SubSection from '../../Section/SubSection';
import { getSalutations, getCountries, getCountryCodes } from '../../Shared/SmartMe/Utils/FormUtils';
import * as ValidationConfig from '../../Shared/SmartMe/Utils/Validation/ValidationConfig';
import * as ValidationUtils from '../../Shared/SmartMe/Utils/Validation/ValidationUtils';
import { getIdTypesConfig } from '../../Shared/SmartMe/Components/IdComponent/Utils';
import IdComponent from '../../Shared/SmartMe/Components/IdComponent';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
// import { mapUserRoles } from '../../Shared/SmartMe/Components/AssignRolesToUser/Utils';
import AssignGlobalUserGroupsToUser from '../../Shared/SmartMe/Components/AssignGlobalUserGroupsToUser';
import AssignUserGroupsToUser from '../../Shared/SmartMe/Components/AssignUserGroupsToUser';
import { getUserGroups } from '../../Shared/SmartMe/Components/AssignUserGroupsToUser/Utils';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { PATH } from '../../App/AppSettings';
import { BUTTON_TITLE } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue, getTranslationOptions, getTranslationObjects } from '../../Shared/Forms/FormUtils';

const inputSize = 8;

const GET_GENDER_URL = URL + 'User/GetGenders';
const GET_USER_TYPE_URL = URL + 'User/GetUserType';
const GET_USER_VALIDATION_URL = URL + 'User/GetInternalUserValidationConfig';
const GET_USER_ORGANISATION_LIST_URL = URL + 'Organisation/GetUserOrganisationList';

const APP_ADMIN_WIZARD_TAB_NAMES = {
    USER_INFORMATION: "User Information",
    ASSIGN_GLOBAL_USER_GROUPS: "Assign Global User Groups",
    ASSIGN_USER_GROUPS: "Assign User Groups"
    // ASSIGN_ROLES: "Assign Roles"
}

const ORGANISATION_ADMIN_WIZARD_TAB_NAMES = {
    USER_INFORMATION: "User Information",
    ASSIGN_USER_GROUPS: "Assign User Groups"
    // ASSIGN_ROLES: "Assign Roles"
}

const MODAL_NAMES = {
    SUBMIT: "Submit",
    ORGANISATION_UPDATE: "OrganisationUpdate"
}

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            action: props.action,
            userType: props.values.UserType,
            loader: props.loader,
            values: props.values,
            server_url: props.server_url,
            genders: [],
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            userTypes: [],
            countriesOptions: [],
            salutationsOptions: [],
            countryCodesOptions: [],
            appOptions: [],
            organisationOptions: [],
            organisationSelectedOptions: [],
            idTypesConfig: {},
            updateUserGroupGrid: true,
            // selectable: props.roles && props.roles.selectable,
            // allRoles: props.roles && props.roles.allRoles,
            // deleteItemArray: [],
            // updateUserRoleGrid: true,
            userTypeCheck: props.values.UserTypeCheck
        };
    }

    prepareToSubmit = (values, _submitForm) => {

        /* Assign user to roles
        const { deleteItemArray } = this.state;
        const { Roles, Id } = values;

        const userRoles = mapUserRoles(Roles, deleteItemArray, Id);
        values = Object.assign(values, { UserRole: userRoles });
        */

        // Populate identification numbers (IdNumbers)
        values.Profile.IdNumbers = JSON.stringify(values.Profile.IdNumbersArr);

        // Re-populate Existing Organisations
        values.Organisation = values.Organisation.concat(this.state.existingOrganisations);

        // Reset address details
        const Address = values.Profile.Address;
        resetAddressDetails(Address);

        _submitForm();
    }

    async componentDidMount() {
        const { loader } = this.state;

        let genderResponse = await fetchRequest(GET_GENDER_URL, getParams(), false);
        let userTypeResponse = await fetchRequest(GET_USER_TYPE_URL, getParams(), false);;
        let userValidationResponse = await fetchRequest(GET_USER_VALIDATION_URL, getParams(), false);
        let organisationListResponse = await fetchRequest(GET_USER_ORGANISATION_LIST_URL, getParams(), false);
        let userAppAdminResponse = await fetchRequest(URL + "App/GetAppsForApplicationAdminByUserId", getParams(), false);

        await this.setGenderData(genderResponse);
        await this.setUserTypeData(userTypeResponse);
        await this.setUserValidationConfigData(userValidationResponse);
        await this.setUserOrganisationList(organisationListResponse);
        await this.setUpUserAppAdminList(userAppAdminResponse);

        this.setState({
            salutationsOptions: await getSalutations(loader),
            countriesOptions: await getCountries(loader),
            countryCodesOptions: await getCountryCodes(loader)
        }, () => {
            const { countriesOptions } = this.state;
            this.setState({
                idTypesConfig: getIdTypesConfig(countriesOptions),
            }, () => {
                loader.done();
            });
        })
    }

    setGenderData = async (response) => {
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            let genderData = processGenders(Data);
            this.setState({
                genders: genderData
            });
        }
        else {
            toastError(Messages);
        }
    }

    setUserTypeData = async (response) => {
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            let userTypeData = createLabelAndValueArray(Data);
            //set first item as value if list only have 1
            if (userTypeData.length === 1) {
                this.setState({
                    userTypes: userTypeData,
                    values: Object.assign(this.state.values, { UserType: userTypeData[0].value })

                });
            } else {
                this.setState({
                    userTypes: userTypeData
                });
            }

        }
        else {
            toastError(Messages);
        }
    }

    setUserValidationConfigData = async (response) => {
        const { getStaticText } = this.props;
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            this.setState({
                userValidation: Data,
                formValidation: ValidationUtils.constructFormValidation(Data, getStaticText)
            });
        }
        else {
            toastError(Messages);
        }
    }

    setUserOrganisationList = async (response) => {
        const { IsSuccess, Data, Messages } = response.body;
        const { Organisation } = this.state.values;

        const orgList = Data.OrganisationList;
        const SelectableOrganisationIds = orgList.map(x => x.Id);

        const organisationOptions = this.getOrganisationOptions(Data);

        if (response.success && IsSuccess) {
            this.setState({
                isAppAdmin: Data.IsAppAdmin,
                isOrganisationAdmin: Data.IsOrganisationAdmin,
                organisationList: Data.OrganisationList,
                appOptions: selectListMapper(Data.AppList, "Id", "Name"),
                organisationOptions: organisationOptions,
                existingOrganisations: Organisation.filter(opt => !SelectableOrganisationIds.includes(opt)),
                organisationSelectedOptions: organisationOptions.filter(opt => Organisation.includes(opt.value)),
                updateUserGroupGrid: !this.state.updateUserGroupGrid,
                values: {
                    ...this.state.values,
                    Organisation: Organisation.filter(opt => SelectableOrganisationIds.includes(opt)),
                    OrganisationRequired: (!Data.IsAppAdmin && Data.IsOrganisationAdmin).toString() // For Yup validation, ValidationUtils.js
                }
            });
        }
        else {
            toastError(Messages);
        }
    }

    getOrganisationOptions = (Data) => {
        const { getStaticText } = this.props;
        const organisationOptions = selectListMapper(Data.OrganisationList, "Id", "FullName");

        return getTranslationOptions(organisationOptions, getStaticText, UserTranslationKeys);
    }

    getFormValues = () => {
        const { action, values, userValidation, userType } = this.state;
        const addressValidation = userValidation[userType].Fields.Address;

        // Set default address type
        if (action === 'New') {
            if (addressValidation === undefined) {
                values.Profile.Address.AddressType = OPTIONAL_ADDRESS_TYPE.LOCAL;
            }
            else {
                values.Profile.Address.AddressType =
                    addressValidation.Required ? OPTIONAL_ADDRESS_TYPE.LOCAL : OPTIONAL_ADDRESS_TYPE.NONE;
            }
        }
        return values;
    }

    getValidationSchema = () => {
        const { formValidation, userType } = this.state;
        return formValidation && formValidation[userType];
    }

    handleUserTypeChange = (value) => {
        this.setState({ userType: value })
    }

    handleOrganisationChange = (values, onChangeField, name, value) => {
        const { updateUserGroupGrid, organisationOptions, organisationSelectedOptions } = this.state;
        const { Organisation, UserGroups } = values;
        const { modal } = this.props;

        // Orgnisation option is going to be deleted, check if User Groups are selected
        //check against selected options.
        let deletedOrganisationId = undefined;

        if (value.length < organisationSelectedOptions.length) {
            deletedOrganisationId = organisationSelectedOptions.filter(ob => !value.includes(ob.value));
        }

        if (deletedOrganisationId) {

            let filteredOrganisation = Organisation.filter(val => (deletedOrganisationId && val !== deletedOrganisationId[0].value));

            let filteredUserGroup = UserGroups.filter(val =>
                val.OrganisationId === null || (deletedOrganisationId && val.OrganisationId !== deletedOrganisationId[0].value));

            modal.toggleModal(MODAL_NAMES.ORGANISATION_UPDATE);

            this.setState({
                organisationValue: filteredOrganisation,
                userGroupsValue: filteredUserGroup
            });
        }
        else {
            onChangeField(name, value);

            this.setState({
                organisationSelectedOptions: organisationOptions.filter(opt => value.includes(opt.value)),
                updateUserGroupGrid: !updateUserGroupGrid
            });

        }
    }

    updateOrganisation = (onChangeField) => {
        const { updateUserGroupGrid, organisationOptions, organisationValue, userGroupsValue } = this.state;

        onChangeField("UserGroups", userGroupsValue)
        onChangeField("Organisation", organisationValue);

        this.setState({
            organisationSelectedOptions: organisationOptions.filter(opt => organisationValue.includes(opt.value)),
            updateUserGroupGrid: !updateUserGroupGrid
        });
    }

    setUpUserAppAdminList = async (response) => {
        const { IsSuccess, Data } = response.body;

        if (response.success && IsSuccess) {
            const appAdminApplicationsMap = selectListMapper(Data, "AppId", "AppName");

            this.setState({
                appAdminApplications: appAdminApplicationsMap,
            });
        }
    }

    render() {
        const { modal, getStaticText } = this.props;
        const { salutationsOptions, countriesOptions, idTypesConfig, countryCodesOptions,
            userValidation, userType, updateUserGroupGrid,
            isAppAdmin, isOrganisationAdmin, appOptions, organisationOptions,
            organisationList, organisationSelectedOptions, userTypeCheck, appAdminApplications
        } = this.state;
        // const { selectable, allRoles, deleteItemArray, updateUserRoleGrid } = this.state;

        const WIZARD_TAB_NAMES = getTranslationObjects(
            isAppAdmin ? APP_ADMIN_WIZARD_TAB_NAMES : ORGANISATION_ADMIN_WIZARD_TAB_NAMES,
            getStaticText, UserTranslationKeys);

        const IS_READ_ONLY = userTypeCheck === USER_CHECK_TYPE.ADD_EXISTING;

        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        return (
            <>
                {userValidation && getStaticText &&
                    <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                        <SMARTForm
                            sectionNames={WIZARD_TAB_NAMES}
                            defaultSection={WIZARD_TAB_NAMES.USER_INFORMATION}
                            formValues={this.getFormValues()}
                            validationSchema={this.getValidationSchema()}
                            validateOnSubmit={true}
                            serverURL={this.state.server_url}
                            isDummy={false}
                            submitCallback={async ({ response }) => {
                                const { IsSuccess, Messages, MessageLanguageKey, MessageDataValues } = response.body;
                                if (response.success && IsSuccess) {
                                    navigateTo(PATH.USER.ACTIVE_USER); // return back to grid

                                    toastSuccess(response.body.Messages, MessageDataValues, translation);
                                }
                                else {
                                    let errorMsg = Messages;
                                    if (MessageLanguageKey) {
                                        errorMsg = getStaticText(MessageLanguageKey, Messages);
                                    }
                                    toastError(errorMsg, translation, MessageDataValues);
                                }
                            }}
                        >
                            {({ toggleSection, sectionState, values, errors, onChange, submitForm, onChangeField, currentStep, prevStep, nextStep }) => {

                                const validationConfig = userValidation[values.UserType];

                                // Show IdNumbers error
                                if (errors.Profile && errors.Profile.IdNumbers) {
                                    toastError(errors.Profile.IdNumbers);
                                }

                                return (
                                    <>
                                        <Row key={userType} className="body-content">
                                            <Col>
                                                <SectionWrapper
                                                    type={SECTION_LAYOUT_TYPE}
                                                    tabs={WIZARD_TAB_NAMES}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}>

                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={WIZARD_TAB_NAMES.USER_INFORMATION}
                                                        key={WIZARD_TAB_NAMES.USER_INFORMATION}
                                                    >
                                                        <SubSection orientation="left" translation={translation} title="Account Info">
                                                            <SelectList
                                                                name="UserType"
                                                                value={values.UserType}
                                                                options={this.state.userTypes}
                                                                onChangeField={(name, value) => {
                                                                    onChangeField(name, value);
                                                                    this.handleUserTypeChange(value);
                                                                }}
                                                                inputSize={FIELD_SIZE.SMALL}
                                                                label={ValidationConfig.getLabel("User Type", "UserType", validationConfig, getStaticText)}
                                                                error={errors.UserType}
                                                                isMulti={false}
                                                                required={ValidationConfig.getFieldRequired(true, "UserType", validationConfig)}
                                                                translation={translation}
                                                            />

                                                            {IS_READ_ONLY ?
                                                                <Plaintext
                                                                    label={ValidationConfig.getLabel("Username", "Username", validationConfig, getStaticText)}
                                                                    name="Username"
                                                                    value={values.Username}
                                                                />
                                                                :
                                                                <Text
                                                                    name="Username"
                                                                    value={values.Username}
                                                                    placeholder={ValidationConfig.getPlaceholder("Username", "Username", validationConfig, getStaticText)}
                                                                    onChange={onChange}
                                                                    minLength={ValidationConfig.getMinValue(0, "Username", validationConfig)}
                                                                    maxLength={ValidationConfig.getMaxValue(140, "Username", validationConfig)}
                                                                    inputSize={FIELD_SIZE.SMALL}
                                                                    label={ValidationConfig.getLabel("Username", "Username", validationConfig, getStaticText)}
                                                                    error={errors.Username}
                                                                    required={ValidationConfig.getFieldRequired(true, "Username", validationConfig)}
                                                                    translation={translation}
                                                                />
                                                            }

                                                            <SelectList
                                                                name="Organisation"
                                                                value={values.Organisation}
                                                                options={organisationOptions}
                                                                onChangeField={(name, value) => {
                                                                    this.handleOrganisationChange(values, onChangeField, name, value);
                                                                }}
                                                                placeholder="Organisation"
                                                                inputSize={FIELD_SIZE.XLARGE}
                                                                label="Organisation"
                                                                error={errors.Organisation}
                                                                isMulti={true}
                                                                isClearable={false}
                                                                required={!isAppAdmin && isOrganisationAdmin}
                                                                translation={translation}
                                                            />
                                                        </SubSection>
                                                        <SubSection orientation="left" translation={translation} title="Personal Details">
                                                            <SelectList
                                                                name="Profile.Salutation"
                                                                value={values.Profile.Salutation}
                                                                options={salutationsOptions}
                                                                onChangeField={onChangeField}
                                                                placeholder={ValidationConfig.getPlaceholder("Salutation", "Salutation", validationConfig, getStaticText)}
                                                                isMulti={false}
                                                                isClearable={true}
                                                                inputSize={FIELD_SIZE.SMALL}
                                                                label={ValidationConfig.getLabel("Salutation", "Salutation", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.Salutation}
                                                                required={ValidationConfig.getFieldRequired(true, "Salutation", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <Text
                                                                name="Profile.FirstName"
                                                                value={values.Profile.FirstName}
                                                                placeholder={ValidationConfig.getPlaceholder("First Name", "FirstName", validationConfig, getStaticText)}
                                                                onChange={onChange}
                                                                minLength={ValidationConfig.getMinValue(0, "FirstName", validationConfig)}
                                                                maxLength={ValidationConfig.getMaxValue(140, "FirstName", validationConfig)}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label={ValidationConfig.getLabel("First Name", "FirstName", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.FirstName}
                                                                required={ValidationConfig.getFieldRequired(true, "FirstName", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <Text
                                                                name="Profile.MiddleName"
                                                                value={values.Profile.MiddleName}
                                                                placeholder={ValidationConfig.getPlaceholder("Middle Name", "MiddleName", validationConfig, getStaticText)}
                                                                onChange={onChange}
                                                                minLength={ValidationConfig.getMinValue(0, "MiddleName", validationConfig)}
                                                                maxLength={ValidationConfig.getMaxValue(140, "MiddleName", validationConfig)}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label={ValidationConfig.getLabel("Middle Name", "MiddleName", validationConfig, getStaticText)}
                                                                required={ValidationConfig.getFieldRequired(false, "MiddleName", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <Text
                                                                name="Profile.LastName"
                                                                value={values.Profile.LastName}
                                                                placeholder={ValidationConfig.getPlaceholder("Last Name", "LastName", validationConfig, getStaticText)}
                                                                onChange={onChange}
                                                                minLength={ValidationConfig.getMinValue(0, "LastName", validationConfig)}
                                                                maxLength={ValidationConfig.getMaxValue(140, "LastName", validationConfig)}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label={ValidationConfig.getLabel("Last Name", "LastName", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.LastName}
                                                                required={ValidationConfig.getFieldRequired(true, "LastName", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <Radio
                                                                name="Profile.Gender"
                                                                value={values.Profile.Gender}
                                                                options={this.state.genders}
                                                                onChange={onChange}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label={ValidationConfig.getLabel("Gender", "Gender", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.Gender}
                                                                required={ValidationConfig.getFieldRequired(true, "Gender", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <DateInput
                                                                name="Profile.DateOfBirth"
                                                                value={values.Profile.DateOfBirth}
                                                                placeholder={ValidationConfig.getPlaceholder("Date Of Birth", "DateOfBirth", validationConfig, getStaticText)}
                                                                onChangeField={onChangeField}
                                                                time={false}
                                                                date={true}
                                                                max={new Date()}
                                                                format={DATE_DISPLAY_FORMAT.DATE}
                                                                inputSize={FIELD_SIZE.SMALL}
                                                                label={ValidationConfig.getLabel("Date Of Birth", "DateOfBirth", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.DateOfBirth}
                                                                disableKeyboardInput={false}
                                                                required={ValidationConfig.getFieldRequired(true, "DateOfBirth", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            {Object.keys(idTypesConfig).length > 0 &&
                                                                <IdComponent
                                                                    nationalityField={{
                                                                        name: "Profile.Nationality",
                                                                        label: ValidationConfig.getLabel("Nationality", "Nationality", validationConfig, getStaticText),
                                                                        placeholder: ValidationConfig.getLabel("Nationality", "Nationality", validationConfig, getStaticText),
                                                                        value: values.Profile.Nationality,
                                                                        error: errors.Profile && errors.Profile.Nationality,
                                                                        required: ValidationConfig.getFieldRequired(true, "Nationality", validationConfig),
                                                                        countriesOptions: countriesOptions,
                                                                        translation: translation
                                                                    }}
                                                                    idNumbersField={{
                                                                        name: "Profile.IdNumbersArr",
                                                                        label: ValidationConfig.getLabel("Identification Number", "IdNumbers", validationConfig, getStaticText),
                                                                        placeholder: ValidationConfig.getPlaceholder("Identification Number", "IdNumbers", validationConfig, getStaticText),
                                                                        value: values.Profile.IdNumbersArr,
                                                                        minLength: ValidationConfig.getMinValue(0, "IdNumbers", validationConfig),
                                                                        maxLength: ValidationConfig.getMaxValue(140, "IdNumbers", validationConfig),
                                                                        error: errors.Profile && errors.Profile.IdNumbers,
                                                                        required: ValidationConfig.getFieldRequired(true, "IdNumbers", validationConfig),
                                                                        translation: translation
                                                                    }}
                                                                    idTypesConfig={idTypesConfig}
                                                                    onChangeField={onChangeField}
                                                                />
                                                            }
                                                        </SubSection>
                                                        <SubSection orientation="left" translation={translation} title="Contact Info">
                                                            {IS_READ_ONLY ?
                                                                <Plaintext
                                                                    label={ValidationConfig.getLabel("Email Address", "Email", validationConfig, getStaticText)}
                                                                    name="Email"
                                                                    value={values.Email}
                                                                />
                                                                :
                                                                <Text
                                                                    name="Email"
                                                                    value={values.Email}
                                                                    placeholder={ValidationConfig.getPlaceholder("Email Address", "Email", validationConfig, getStaticText)}
                                                                    onChange={onChange}
                                                                    minLength={ValidationConfig.getMinValue(0, "Email", validationConfig)}
                                                                    maxLength={ValidationConfig.getMaxValue(140, "Email", validationConfig)}
                                                                    inputSize={FIELD_SIZE.LARGE}
                                                                    label={ValidationConfig.getLabel("Email Address", "Email", validationConfig, getStaticText)}
                                                                    error={errors.Email}
                                                                    required={ValidationConfig.getFieldRequired(true, "Email", validationConfig)}
                                                                    translation={translation}
                                                                />
                                                            }

                                                            <Text
                                                                name="Profile.AltEmailAddress"
                                                                value={values.Profile.AltEmailAddress}
                                                                placeholder={ValidationConfig.getPlaceholder("Alternate Email Address", "AltEmailAddress", validationConfig, getStaticText)}
                                                                onChange={onChange}
                                                                minLength={ValidationConfig.getMinValue(0, "AltEmailAddress", validationConfig)}
                                                                maxLength={ValidationConfig.getMaxValue(140, "AltEmailAddress", validationConfig)}
                                                                labelSize={FIELD_SIZE.SMALL}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label={ValidationConfig.getLabel("Alternate Email Address", "AltEmailAddress", validationConfig, getStaticText)}
                                                                error={errors.Profile && errors.Profile.AltEmailAddress}
                                                                required={ValidationConfig.getFieldRequired(false, "AltEmailAddress", validationConfig)}
                                                                translation={translation}
                                                            />
                                                            <SelectList
                                                                name="Profile.PrimaryContact"
                                                                value={values.Profile.PrimaryContact}
                                                                options={[
                                                                    { label: "Office Number", value: "Office" },
                                                                    { label: "Mobile Number", value: "Mobile" },
                                                                    { label: "Home Number", value: "Home" }]}
                                                                onChangeField={onChangeField}
                                                                placeholder={ValidationConfig.getPlaceholder("Primary Contact", "PrimaryContact", validationConfig, getStaticText)}
                                                                isMulti={false}
                                                                inputSize={inputSize}
                                                                label={ValidationConfig.getLabel("Primary Contact", "PrimaryContact", validationConfig, getStaticText)}
                                                                translation={translation}
                                                                error={errors.Profile && errors.Profile.PrimaryContact}
                                                                required={ValidationConfig.getFieldRequired(true, "PrimaryContact", validationConfig)}
                                                            />
                                                            <Row>
                                                                <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(true, "MobileNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.MobileNumber !== undefined &&
                                                                        errors.Profile.MobileNumber.CountryCode !== undefined &&
                                                                        errors.Profile.MobileNumber.Number !== undefined ?
                                                                        "label-error" : "label-default"}`}>
                                                                    {ValidationConfig.getLabel("Mobile Number", "MobileNumber", validationConfig, getStaticText)}
                                                                </Label>
                                                                <Col lg="5">
                                                                    <SelectList
                                                                        name="Profile.MobileNumber.CountryCode"
                                                                        value={values.Profile.MobileNumber.CountryCode}
                                                                        options={countryCodesOptions}
                                                                        onChangeField={onChangeField}
                                                                        placeholder="Country Code"
                                                                        isMulti={false}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.MobileNumber && errors.Profile.MobileNumber.CountryCode}
                                                                    />
                                                                </Col>
                                                                <Col lg="3">
                                                                    <Text
                                                                        name="Profile.MobileNumber.Number"
                                                                        placeholder="Number"
                                                                        value={values.Profile.MobileNumber.Number}
                                                                        onChange={onChange}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.MobileNumber && errors.Profile.MobileNumber.Number}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "OfficeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.OfficeNumber !== undefined &&
                                                                        errors.Profile.OfficeNumber.CountryCode !== undefined &&
                                                                        errors.Profile.OfficeNumber.Number !== undefined ?
                                                                        "label-error" : "label-default"}`}>
                                                                    {ValidationConfig.getLabel(" Office Number", "OfficeNumber", validationConfig, getStaticText)}
                                                                </Label>
                                                                <Col lg="5">
                                                                    <SelectList
                                                                        name="Profile.OfficeNumber.CountryCode"
                                                                        value={values.Profile.OfficeNumber.CountryCode}
                                                                        options={countryCodesOptions}
                                                                        onChangeField={onChangeField}
                                                                        placeholder="Country Code"
                                                                        isClearable={true}
                                                                        isMulti={false}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.OfficeNumber && errors.Profile.OfficeNumber.CountryCode}
                                                                    />
                                                                </Col>
                                                                <Col lg="3">
                                                                    <Text
                                                                        name="Profile.OfficeNumber.Number"
                                                                        placeholder="Number"
                                                                        value={values.Profile.OfficeNumber.Number}
                                                                        onChange={onChange}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.OfficeNumber && errors.Profile.OfficeNumber.Number}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "HomeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.HomeNumber !== undefined &&
                                                                        errors.Profile.HomeNumber.CountryCode !== undefined &&
                                                                        errors.Profile.HomeNumber.Number !== undefined ?
                                                                        "label-error" : "label-default"}`}>
                                                                    {ValidationConfig.getLabel(" Home Number", "HomeNumber", validationConfig, getStaticText)}
                                                                </Label>
                                                                <Col lg="5">
                                                                    <SelectList
                                                                        name="Profile.HomeNumber.CountryCode"
                                                                        value={values.Profile.HomeNumber.CountryCode}
                                                                        options={countryCodesOptions}
                                                                        onChangeField={onChangeField}
                                                                        placeholder="Country Code"
                                                                        isClearable={true}
                                                                        isMulti={false}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.HomeNumber && errors.Profile.HomeNumber.CountryCode}
                                                                    />
                                                                </Col>
                                                                <Col lg="3">
                                                                    <Text
                                                                        name="Profile.HomeNumber.Number"
                                                                        placeholder="Number"
                                                                        value={values.Profile.HomeNumber.Number}
                                                                        onChange={onChange}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.HomeNumber && errors.Profile.HomeNumber.Number}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "HomeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.FaxNumber !== undefined &&
                                                                        errors.Profile.FaxNumber.CountryCode !== undefined &&
                                                                        errors.Profile.FaxNumber.Number !== undefined ?
                                                                        "label-error" : "label-default"}`}>
                                                                    {ValidationConfig.getLabel(" Fax Number", "FaxNumber", validationConfig, getStaticText)}
                                                                </Label>
                                                                <Col lg="5">
                                                                    <SelectList
                                                                        name="Profile.FaxNumber.CountryCode"
                                                                        value={values.Profile.FaxNumber.CountryCode}
                                                                        options={countryCodesOptions}
                                                                        onChangeField={onChangeField}
                                                                        placeholder="Country Code"
                                                                        isClearable={true}
                                                                        isMulti={false}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.FaxNumber && errors.Profile.FaxNumber.CountryCode}
                                                                    />
                                                                </Col>
                                                                <Col lg="3">
                                                                    <Text
                                                                        name="Profile.FaxNumber.Number"
                                                                        placeholder="Number"
                                                                        value={values.Profile.FaxNumber.Number}
                                                                        onChange={onChange}
                                                                        labelSize={FIELD_SIZE.NONE}
                                                                        inputSize={FIELD_SIZE.MAX}
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.FaxNumber && errors.Profile.FaxNumber.Number}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </SubSection>
                                                        <SubSection orientation="left" translation={translation} title="Address Details">
                                                            <Radio
                                                                name="Profile.Address.AddressType"
                                                                value={values.Profile.Address.AddressType}
                                                                options={ValidationConfig.getAddressTypeOptions(true, "Address", validationConfig, values, getStaticText, UserTranslationKeys)}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    resetAddressDetails(values.Profile.Address);
                                                                }}
                                                                labelSize={FIELD_SIZE.SMALL}
                                                                inputSize={FIELD_SIZE.LARGE}
                                                                label="Address Type"
                                                                translation={translation}
                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressType}
                                                                required
                                                            />
                                                            {values.Profile.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                                                <>
                                                                    <Text
                                                                        name="Profile.Address.PostalCode"
                                                                        value={values.Profile.Address.PostalCode}
                                                                        onChange={onChange}
                                                                        labelSize={FIELD_SIZE.SMALL}
                                                                        inputSize={FIELD_SIZE.SMALL}
                                                                        placeholder="ZIP / Postal Code"
                                                                        label="ZIP / Postal Code"
                                                                        translation={translation}
                                                                        error={errors.Profile && errors.Profile.Address && errors.Profile.Address.PostalCode}
                                                                        required
                                                                    />
                                                                    {values.Profile.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                                        <>
                                                                            <Text
                                                                                name="Profile.Address.AddressLine1.BlockNo"
                                                                                value={values.Profile.Address.AddressLine1.BlockNo}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.SMALL}
                                                                                placeholder="Block / House No."
                                                                                label="Block / House No."
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.BlockNo}
                                                                                required
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine1.StreetName"
                                                                                value={values.Profile.Address.AddressLine1.StreetName}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.MEDIUM}
                                                                                placeholder="Street Name"
                                                                                label="Street Name"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.StreetName}
                                                                                required
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine2.LevelNo"
                                                                                value={values.Profile.Address.AddressLine2.LevelNo}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.XSMALL}
                                                                                placeholder="Level"
                                                                                label="Level"
                                                                                translation={translation}
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine2.UnitNo"
                                                                                value={values.Profile.Address.AddressLine2.UnitNo}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.XSMALL}
                                                                                placeholder="Unit No."
                                                                                label="Unit No."
                                                                                translation={translation}
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine2.BuildingName"
                                                                                value={values.Profile.Address.AddressLine2.BuildingName}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.MEDIUM}
                                                                                placeholder="Building Name"
                                                                                label="Building Name"
                                                                                translation={translation}
                                                                            />
                                                                        </>
                                                                    }
                                                                    {values.Profile.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                                                        <>
                                                                            <SelectList
                                                                                name="Profile.Address.Country"
                                                                                value={values.Profile.Address.Country}
                                                                                options={countriesOptions.filter((country) => {
                                                                                    return country.value !== DEFAULT_HOME_COUNTRY
                                                                                })}
                                                                                onChangeField={onChangeField}
                                                                                placeholder="Country"
                                                                                isMulti={false}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.LARGE}
                                                                                label="Country"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.Country}
                                                                                required
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.City"
                                                                                value={values.Profile.Address.City}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.SMALL}
                                                                                placeholder="City"
                                                                                label="City"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.City}
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.State"
                                                                                value={values.Profile.Address.State}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.SMALL}
                                                                                placeholder="State / Province / Region"
                                                                                label="State / Province / Region"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.State}
                                                                                required
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine1.AddressLine1"
                                                                                value={values.Profile.Address.AddressLine1.AddressLine1}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.LARGE}
                                                                                placeholder="Address Line 1"
                                                                                // helpLabel="Address Line 1"
                                                                                label="Address Line 1"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.AddressLine1}
                                                                                required
                                                                            />
                                                                            <Text
                                                                                name="Profile.Address.AddressLine2.AddressLine2"
                                                                                value={values.Profile.Address.AddressLine2.AddressLine2}
                                                                                onChange={onChange}
                                                                                labelSize={FIELD_SIZE.SMALL}
                                                                                inputSize={FIELD_SIZE.LARGE}
                                                                                placeholder="Address Line 2"
                                                                                // helpLabel="Address Line 2"
                                                                                label="Address Line 2"
                                                                                translation={translation}
                                                                                error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine2 && errors.Profile.Address.AddressLine2.AddressLine2}
                                                                                required
                                                                            />
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </SubSection>
                                                    </Section>
                                                    {isAppAdmin && appAdminApplications &&
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={WIZARD_TAB_NAMES.ASSIGN_GLOBAL_USER_GROUPS}
                                                            key={WIZARD_TAB_NAMES.ASSIGN_GLOBAL_USER_GROUPS}
                                                        >
                                                            <AssignGlobalUserGroupsToUser
                                                                appOptions={appOptions}
                                                                data={values.GlobalUserGroups}
                                                                isUpdatePage={true}
                                                                appAdminApplications={appAdminApplications}
                                                                translation={translation}
                                                            />
                                                        </Section>
                                                    }
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={WIZARD_TAB_NAMES.ASSIGN_USER_GROUPS}
                                                        key={WIZARD_TAB_NAMES.ASSIGN_USER_GROUPS}
                                                    >
                                                        <AssignUserGroupsToUser
                                                            key={updateUserGroupGrid}
                                                            organisationList={organisationList}
                                                            organisationSelectedOptions={organisationSelectedOptions}
                                                            data={getUserGroups(values.UserGroups, translation)}
                                                            isUpdatePage={true}
                                                            availableOrganisationOptions={organisationOptions}
                                                            translation={translation}
                                                        />
                                                    </Section>
                                                    {/* <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={WIZARD_TAB_NAMES.ASSIGN_ROLES}
                                                        key={WIZARD_TAB_NAMES.ASSIGN_ROLES}
                                                    >
                                                        <AssignRolesToUser
                                                            key={updateUserRoleGrid}
                                                            deleteItemArray={deleteItemArray}
                                                            data={values.Roles}
                                                            selectable={selectable}
                                                            allRoles={allRoles}
                                                            isUpdatePage={true}
                                                        />
                                                    </Section> */}
                                                </SectionWrapper>
                                            </Col>
                                        </Row>

                                        <PageFooter>
                                            {/* Wizard Previous Button */}
                                            {((SECTION_LAYOUT_TYPE === SECTION_WRAPPER_TYPES.WIZARD) && (currentStep !== 0)) ? <Button color="backward" type="button" onClick={prevStep}>{BUTTON_TITLE(getStaticText).PREVIOUS}</Button> : ""}

                                            {/* Submit Button */}
                                            {((SECTION_LAYOUT_TYPE === SECTION_WRAPPER_TYPES.WIZARD) && (currentStep === Object.keys(WIZARD_TAB_NAMES).length - 1)) ? <Button color="primary" type="button" onClick={() => isValidForm(this.getValidationSchema(), values) ? modal.toggleModal(MODAL_NAMES.SUBMIT) : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button> :
                                                ((SECTION_LAYOUT_TYPE !== SECTION_WRAPPER_TYPES.WIZARD) ? <Button color="primary" type="button" onClick={() => isValidForm(this.getValidationSchema(), values) ? modal.toggleModal(MODAL_NAMES.SUBMIT) : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button> : "")}

                                            {/* Wizard Next Button */}
                                            {((SECTION_LAYOUT_TYPE === SECTION_WRAPPER_TYPES.WIZARD) && (currentStep !== Object.keys(WIZARD_TAB_NAMES).length - 1)) ? <Button color="forward" type="button" onClick={nextStep}>{BUTTON_TITLE(getStaticText).NEXT}</Button> : ""}
                                        </PageFooter>

                                        <ModalConfirm
                                            isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                                            contentHeader={this.state.contentHeader}
                                            contentBody={this.state.contentBody}
                                            translation={translation}
                                            confirmCallback={() => {
                                                modal.toggleModal(MODAL_NAMES.SUBMIT);
                                                this.prepareToSubmit(values, submitForm);
                                            }}
                                            cancelCallback={() => modal.toggleModal(MODAL_NAMES.SUBMIT)}
                                            formatter={{ component: getTranslationValue('User', getStaticText, UserTranslationKeys) }}
                                        />

                                        <ModalConfirm
                                            isOpen={modal.modalState === MODAL_NAMES.ORGANISATION_UPDATE}
                                            contentHeader="Confirm Update in Organisation?"
                                            contentBody="Upon confirmation, selected User Groups will be cleared. Do you wish to proceed?"
                                            translation={translation}
                                            confirmCallback={() => {
                                                modal.toggleModal(MODAL_NAMES.ORGANISATION_UPDATE);
                                                this.updateOrganisation(onChangeField);
                                            }}
                                            cancelCallback={() => modal.toggleModal(MODAL_NAMES.ORGANISATION_UPDATE)}
                                        />
                                    </>
                                )
                            }}
                        </SMARTForm>
                    </Layout>
                }
            </>
        );
    }
}


export default withSMARTWrapper(Form);