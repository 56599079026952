import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { getTranslationValue } from '../Shared/Forms/FormUtils';

class SubSection extends React.Component {

    render() {

        const { className, orientation, title, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const titleValue = getStaticText && translationKeys ? getTranslationValue(title, getStaticText, translationKeys) : title;

        return (
            <div className={"sub-section " + className}>
                <Divider className="sub-section-divider" orientation={orientation}>
                    {titleValue}
                </Divider>
                {this.props.children}
            </div>
        );
    }
};

// PropTypes: For prop validation
SubSection.propTypes = {
    orientation: PropTypes.string,
    title: PropTypes.string.isRequired,
    className: PropTypes.string
};

// PropTypes: Defaulting value for optional props
SubSection.defaultProps = {
    orientation: undefined,      // Note: Undefined is seen as "center" for this ant-design component
    title: undefined,
    className: ""
};


export default SubSection;