import { LANGUAGE_APPLICATION_NAME } from "../../App/AppSettings";

export const DEFAULT_APPLICATION_NAME = LANGUAGE_APPLICATION_NAME;

// Get namespace based on key and value
export const GetLangKey = (translationKey, ...replacementKeys) => {
    //spread operator for the keys that need to be replaced in
    let arr = [...replacementKeys];
    let count = 0;

    //assign each matched substring to argument array index on FCFS basis
    const replaceMatchedSubstring = () => {
        let str = arr[count];
        count++;
        return str;
    }
    const replacedStr = translationKey.replace(/%[0-9a-zA-Z]*%/g, replaceMatchedSubstring);

    return replacedStr;
}

export const DEFAULT_SCOPE = {
    APPCONFIGSTATIC: 'AppConfigStatic',
    CODESTATIC: 'CodeStatic',
    MENU: 'Menu',
    DEFAULTSTATIC: 'DefaultStatic',
    FAQSTATIC: 'FaqStatic',
    LOCALESTATIC: 'LocaleStatic',
    PUBLICHOLIDAYSTATIC: 'PublicHolidayStatic',
    NOTITEMPLATESTATIC: 'NotiTemplateStatic'
}

export const DEFAULT_CATEGORY_SUBCATEGORY = {
    CONTENT_TITLE: 'Content.Title',
    CONTENT_DROPDOWN: 'Content.Dropdown',
    NAVIGATION_BUTTON: 'Navigation.Button',
    CONTENT_PLACEHOLDER: 'Content.Placeholder',
    CONTENT_TABLE: 'Content.Table',
    FORM_TITLE: 'Form.Title',
    FORM_VALUE: 'Form.Value',
    FORM_HELPTEXT: 'Form.HelpText',
    FORM_PLACEHOLDER: 'Form.Placeholder',
    MODAL_TITLE: 'Modal.Title',
    MODAL_TEXT: 'Modal.Text',
    TABLE_PLACEHOLDER: 'Table.Placeholder',
    TABLE_TOOLTIP: 'Table.Tooltip',
    TABLE_TEXT: 'Table.Text',
    SUCCESS_TITLE: 'Success.Title',
    ERROR_TITLE: 'Error.Title',
    TREE_TITLE: 'Tree.Title',
    BADGE_TEXT: 'Badge.Text',
    CONTENT_TEXT: 'Content.Text',
    FORM_DIVIDER: 'Form.Divider'
}

export const DEFAULT_KEY = {
    APPLICATIONCONFIGURATIONMANAGEMENT: 'ApplicationConfigurationManagement',
    NEWCONFIGURATION: 'NewConfiguration',
    ALLCATEGORIES: 'AllCategories',
    FILTER: 'Filter',
    NO: 'No',
    ACTIONS: 'Actions',
    CATEGORY: 'Category',
    NAME: 'Name',
    VALUETYPE: 'ValueType',
    VALUE: 'Value',
    EFFECTIVESTARTDATE: 'EffectiveStartDate',
    EFFECTIVEENDDATE: 'EffectiveEndDate',
    UPDATECONFIGURATION: 'UpdateConfiguration',
    LOCALESETTINGS: 'LocaleSettings',
    ISDEFAULTGLOBALAPPENABLED: 'IsDefaultGlobalAppEnabled',
    GENERAL: 'General',
    CURRENCY: 'Currency',
    DATE: 'Date',
    EMAIL: 'Email',
    NUMBER: 'Number',
    DECIMAL: 'Decimal',
    BACK: 'Back',
    VIEWCONFIGURATION: 'ViewConfiguration',
    CREATEDBY: 'CreatedBy',
    CREATEDDATE: 'CreatedDate',
    LASTUPDATEDBY: 'LastUpdatedBy',
    LASTUPDATEDDATE: 'LastUpdatedDate',
    NORECORD: 'NoRecord',
    DELETEAPPCONFIG: 'DeleteAppConfig',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    CREATE: 'Create',
    CODETABLEMANAGEMENT: 'CodeTableManagement',
    NEWCODE: 'NewCode',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    STATUS: 'Status',
    PARENTCODE: 'ParentCode',
    UPDATECODETABLEENTRY: 'UpdateCodeTableEntry',
    PARENTCODECATEGORY: 'ParentCodeCategory',
    PARENTCATEGORY: 'ParentCategory',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SEQUENCENUMBER: 'SequenceNumber',
    COUNTRY: 'Country',
    SAVECHANGES: 'SaveChanges',
    CODETABLEENTRYDETAILS: 'CodeTableEntryDetails',
    NEWCODETABLEENTRY: 'NewCodeTableEntry',
    UPDATECODE: 'UpdateCode',
    VIEWCODE: 'ViewCode',
    VIEWCONFIG: 'ViewConfiguration',
    DELETECONFIG: 'DeleteConfiguration',
    LANGUAGE: 'Language',
    CODECATEGORY: 'CodeCategory',
    SERVERUNREACHABLE: 'ServerUnreachable',
    UPDATE: 'Update',
    CATEGORYREQUIRED: 'CategoryRequired',
    NAMEREQUIRED: 'NameRequired',
    QUESTION: 'Question',
    TAGS: 'Tags',
    REQUIRED: 'Required',
    MINLENGTH: 'MinLength',
    MAXLENGTH: 'MaxLength',
    DATEINVALID: 'DateInvalid',
    DELETECONFIGURATION: 'DeleteConfiguration',
    FAQMANAGEMENT: 'FaqManagement',
    NEWFAQ: 'NewFaq',
    APPLICATION: 'Application',
    LASTPUBLISHED: 'LastPublished',
    MULTILANG: 'MultiLang',
    QNAMAKER: 'QnAMaker',
    ADDFAQ: 'AddFaq',
    SELECTLANG: 'SelectLang',
    ENABLEAI: 'EnableAI',
    ENABLEMULTILANG: 'EnableMultiLang',
    EDITFAQ: 'EditFaq',
    PREVIEW: 'Preview',
    DELETEDRAFT: 'DeleteDraft',
    FAQTREE: 'FaqTree',
    DELETETOPIC: 'DeleteTopic',
    DELETESUBTOPIC: 'DeleteSubTopic',
    DELETEQNSET: 'DeleteQnSet',
    UPDATEQNSET: 'UpdateQnSet',
    UPDATETOPIC: 'UpdateTopic',
    UPDATEFAQ: 'UpdateFaq',
    UPDATEFAQSUBTOPIC: 'UpdateSubTopic',
    CREATETOPIC: 'CreateTopic',
    CREATESUBTOPIC: 'CreateSubTopic',
    CREATEQNSET: 'CreateQnSet',
    SETTINGS: 'Settings',
    SAVE: 'Save',
    AICHATBOT: 'AIChatbot',
    ANSWER: 'Answer',
    YES: 'Yes',
    FAQDESC: 'FaqDesc',
    RANK: 'Rank',
    TYPE: 'Type',
    SORTRANKUP: 'SortRankUp',
    SORTRANKDOWN: 'SortRankDown',
    QNSETNORECORD: 'QnSetNoRecord',
    FAQTOPICTITLE: 'FaqTopicTitle',
    ADD: 'Add',
    FAQSUBTOPICTITLE: 'FaqSubTopicTitle',
    FAQANS: 'FaqAns',
    TOGGLETREEVIEW: 'ToggleTreeView',
    SEARCH_QNSET: 'SearchQnSet',
    FAQQNSETS: "FaqQnSets",
    MOVEUP: "MoveUp",
    MOVEDOWN: "MoveDown",
    FAQSUBTOPICDESC: "FaqSubTopicDesc",
    FAQTOPICDESC: "FaqTopicDesc",
    ADDQN: "AddQn",
    PRIQN: "PriQn",
    REMOVE: "Remove",
    EDITFAQTOPIC: "EditFaqTopic",
    NEWFAQTOPIC: "NewFaqTopic",
    NEWFAQSUBTOPIC: "NewFaqSubTopic",
    NEWFAQQNSET: "NewFaqQnSet",
    EDITFAQSUBTOPIC: "EditFaqSubTopic",
    EDITFAQQNSET: "EditFaqQnSet",
    VIEWFAQ: "ViewFaq",
    VIEWFAQTOPIC: "ViewFaqTopic",
    VIEWFAQSUBTOPIC: "ViewFaqSubTopic",
    VIEWFAQQNSET: "ViewFaqQnSet",
    VIEWQNSET: "ViewQnSet",
    EDITQNSET: "EditQnSet",
    PRIMARY: "Primary",
    ADDNEWFAQTOPIC: "AddNewFaqTopic",
    ADDNEWFAQSUBTOPIC: "AddNewFaqSubTopic",
    ADDNEWQNSET: "AddNewQnSet",
    LOADING: "Loading",
    FAQ: "Faq",
    TOPIC: "Topic",
    SUBTOPIC: "SubTopic",
    SCOPEMANAGEMENT: "ScopeManagement",
    CATEGORYMANAGEMENT: "CategoryManagement",
    SUBCATEGORYMANAGEMENT: "SubCategoryManagement",
    NEWSCOPE: "NewScope",
    SCOPENORECORD: "ScopeNoRecord",
    CATEGORYNORECORD: "CategoryNoRecord",
    SUBCATEGORYNORECORD: "SubCategoryNoRecord",
    NEWCATEGORY: "NewCategory",
    NEWSUBCATEGORY: "NewSubCategory",
    EDITSCOPE: "EditScope",
    EDITCATEGORY: "EditCategory",
    EDITSUBCATEGORY: "EditSubCategory",
    VIEWSCOPE: "ViewScope",
    VIEWCATEGORY: "ViewCategory",
    VIEWSUBCATEGORY: "ViewSubCategory",
    UPDATESCOPE: "UpdateScope",
    UPDATECATEGORY: "UpdateCategory",
    UPDATESUBCATEGORY: "UpdateSubCategory",
    CREATESCOPE: "CreateScope",
    CREATECATEGORY: "CreateCategory",
    CREATESUBCATEGORY: "CreateSubCategory",
    ADDSCOPE: "AddScope",
    ADDCATEGORY: "AddCategory",
    ADDSUBCATEGORY: "AddSubCategory",
    SUBCATEGORY: "SubCategory",
    SCOPE: "Scope",
    LANGUAGEPACK: "LanguagePack",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOADFILE: "UploadFile",
    TRANSDATANORECORD: "TransDataNoRecord",
    LANGUAGEREG: "LanguageReg",
    REGLANGUAGE: "RegLanguage",
    APPNORECORD: "AppNoRecord",
    DEFAULT: "Default",
    EDITAPPLANG: "EditAppLang",
    ADDLANG: "AddLang",
    LANGSETTINGS: "LangSettings",
    DEFAULTLANG: "DefaultLang",
    UPDATEAPPLANG: "UpdateAppLang",
    VALIDATE: "Validate",
    TRANSDATA: "TransData",
    LANGKEYUPDATE: "LangKeyUpdate",
    LANGKEY: "LangKey",
    ALIGNRIGHT: "AlignRight",
    SUBMIT: "Submit",
    FULLKEY: "FullKey",
    EDITLANGVALUE: "EditLangValue",
    UPDATEKEY: "UpdateKey",
    CREATETRANS: "CreateTrans",
    ADDLANGENTRY: "AddLangEntry",
    ADDVALUE: "AddValue",
    KEY: "Key",
    FILTERBYSCOPE: "FilterByScope",
    FILTERBYCAT: "FilterByCat",
    FILTERBYSUBCAT: "FilterBySubCat",
    FILTERBYPUBLISHED: "FilterByPublished",
    PUBLISHED: "Published",
    SORTBY: "SortBy",
    SEARCH: "Search",
    NEWENTRY: "NewEntry",
    TRANSNORECORD: "TransNoRecord",
    DEFAULTVALUE: "DefaultValue",
    UPDATEDBY: "UpdatedBy",
    UPDATEDDATE: "UpdatedDate",
    REVERT: "Revert",
    TRANSMANAGEMENT: "TransManagement",
    DELETETRANSKEY: "DeleteTransKey",
    PUBLISHTRANSKEY: "PublishTransKey",
    SOFTDELETETRANSKEY: "SoftDeleteTransKey",
    REVERTTRANSKEY: "RevertTransKey",
    HARDDELETETRANSKEY: "HardDeleteTransKey",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASCKEY: "AscKey",
    DESCKEY: "DescKey",
    ASCUPDATEDDATE: "AscUpdatedDate",
    DESCUPDATEDDATE: "DescUpdatedDate",
    LASTEDITEDBY: "LastEditedBy",
    PUBLISHTRANS: "PublishTrans",
    DELETETRANS: "DeleteTrans",
    REVERTTRANS: "RevertTrans",
    ENTERVALUE: "EnterValue",
    CHARREMAINING: "CharRemaining",
    FAQTITLE: "FaqTitle",
    DELETEFAQ: "DeleteFaq",
    PUBLISHFAQ: "PublishFaq",
    PREVIEWFAQ: "PreviewFaq",
    PUBLISHFAQLOCALE: "PublishFaqLocale",
    ALLQUESTIONSREQUIRED: "AllQuestionsRequired",
    ANSWERREQUIRED: "AnswerRequired",
    DELETENODE: "DeleteNode",
    NOQUESTIONS: "NoQuestions",
    DISPLAYANSWER: "DisplayAnswer",
    ADDPUBLICHOLIDAY: "AddPublicHoliday",
    CREATEPUBLICHOLIDAY: "CreatePublicHoliday",
    PUBLICHOLIDAY: "PublicHoliday",
    REMARKS: "Remarks",
    PUBLICHOLIDAYMGMT: "PublicHolidayMgmt",
    YEAR: "Year",
    ALLYEARS: "AllYears",
    NEWPUBLICHOLIDAY: "NewPublicHoliday",
    PUBLICHOLIDAYNORECORD: "PublicHolidayNoRecord",
    DELETEPUBLICHOLIDAY: "DeletePublicHoliday",
    UPDATEPUBLICHOLIDAY: "UpdatePublicHoliday",
    NOTITEMPLATEMANAGEMENT: "NotiTemplateManagement",
    SMSTEMPLATEMANAGEMENT: "SmsTemplateManagement",
    NEWSMSTEMPLATE: "NewSmsTemplate",
    DELETESMSTEMPLATE: "DeleteSmsTemplate",
    SMSTEMPLATENORECORD: "SmsTemplateNoRecord",
    TEMPLATENAME: "TemplateName",
    VERSION: "Version",
    CREATESMSTEMPLATE: "CreateSmsTemplate",
    TEMPLATESUBJECT: "TemplateSubject",
    TEMPLATEBODY: "TemplateBody",
    UPDATESMSTEMPLATE: "UpdateSmsTemplate",
    VIEWSMSTEMPLATE: "ViewSmsTemplate",
    VIEWPUBLICHOLIDAY: "ViewPublicHoliday",
    PUBLISHBYSCOPE: "PublishByScope",
    PUBLISHBYAPP: "PublishByApp",
    CREATEEMAILTEMPLATE: "CreateEmailTemplate",
    NEWEMAILTEMPLATE: "NewEmailTemplate",
    DELETEEMAILTEMPLATE: "DeleteEmailTemplate",
    UPDATEEMAILTEMPLATE: "UpdateEmailTemplate",
    EMAILTEMPLATENORECORD: "EmailTemplateNoRecord"
}

export const DEFAULT_TEXT = {
    ALL_CATEGORIES: "All Categories",
    ALL_CONFIGURATIONS: "All Configurations",
    FILTER: "Filter",
    NEW_CONFIGURATION: "New Configuration",
    BACK: "Back",
    NUM: "No.",
    ACTIONS: "Actions",
    CATEGORY: "Category",
    NAME: "Name",
    VALUE_TYPE: "Value Type",
    VALUE: "Value",
    EFFECTIVE_START_DATE: "Effective Start Date",
    EFFECTIVE_END_DATE: "Effective End Date",
    APPCONFIG_MANAGEMENT: "Application Configuration Management",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    DELETE_APPCONFIG_MODAL_TEXT: "<div>Are you sure you want to delete the Application Configuration? <br />Please be reminded that this action cannot be undone.</div>",
    DELETE_APPCONFIG_MODAL_TITLE: "Delete Application Configuration?",
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    UPDATE: 'Update',
    APPCONFIG_NO_RECORD: "No Application Configuration(s) Found",
    HELPTEXT_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    CURRENCY: "Currency",
    DECIMAL: "Decimal",
    EMAIL: "Email",
    GENERAL: "General",
    NUMBER: "Number",
    PLACEHOLDER_VALUETYPE: "Value Type",
    HELPTEXT_VALUETYPE: "Select a Value Type",
    DESCRIPTION: "Description",
    HELPTEXT_DESCRIPTION: "Please key in a description",
    HELPTEXT_CURRENCY: "Currency: $10.00 or 10.00",
    HELPTEXT_DATE: "Date: 01-Aug-2020",
    HELPTEXT_DECIMAL: "Decimal: 10.0 or 10",
    HELPTEXT_EMAIL: "Email: abc@xyz.com",
    HELPTEXT_NUMBER: "Number: 1",
    VIEWCONFIGURATION: "View Configuration",
    CREATED_BY: "Created By",
    CREATED_DATE: "Created Date",
    LAST_UPDATED_DATE: "Last Updated Date",
    LAST_UPDATED_BY: "Last Updated By",
    CODE_TABLE_MANAGEMENT: "Code Table Management",
    CODE_NO_RECORD: "No Code(s) Found",
    STATUS: "Status",
    TOOLTIP_UPDATE_CODE: "Update Code",
    TOOLTIP_VIEW_CODE: "View Code",
    UPDATE_CONFIGURATION: "Update Configuration",
    VIEW_CONFIGURATION: "View Configuration",
    DELETE_CONFIGURATION: "Delete Configuration",
    NEW_CODE: "New Code Table Entry",
    HELPTEXT_PARENT_CATEGORY: "Choose a parent category to help narrow down the parent code list when choosing.",
    HELPTEXT_SEQUENCE_NUMBER: "Sequence number used for ordering purpose. Default order by ascending.",
    SEQUENCE_NO: "Sequence Number",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    PARENT_CODE: "Parent Code",
    PARENT_CODE_CATEGORY: "Parent Code Category",
    HELPTEXT_CODE_CATEGORY: "Please choose an existing code category, or type a new one then press enter to create a new option.",
    CREATE: "Create",
    UPDATE_CODE: "Update Code Table Entry",
    VIEW_CODE: "Code Table Entry Details",
    TITLE: "Title",
    CODE_CATEGORY: "Code Category",
    FAQ_MANAGEMENT: "FAQ Management",
    NEW_FAQ: "New FAQ",
    APPLICATION: "Application",
    LAST_PUBLISHED: "Last Published",
    MULTI_LANGUAGE: "Multi-Language",
    QNAMAKER: "QnAMaker",
    FAQ_NO_RECORD: "No FAQ(s) were found",
    SETTINGS: "Settings",
    CREATE_DRAFT: "Create Draft",
    ADD_FAQ: "Add FAQ",
    SELECT_APP: "Select an Application",
    ENABLE_AI: "Enable AI Chatbot",
    ENABLE_MULTI_LANG: "Enable Mult-Language",
    SELECT_LANG: "Select a Language",
    EDIT_FAQ: "Edit FAQ",
    FAQ_TREE: "FAQ Tree",
    FAQ_SETTINGS: "FAQ Settings",
    SAVE: "Save",
    AI_CHATBOT: "AI Chatbot",
    FAQ: "FAQ",
    FAQ_TOPIC: "FAQ Topic",
    FAQ_SUB_TOPIC: "FAQ Sub-Topic",
    FAQ_QN_SET: "FAQ Question Set",
    FAQ_QN_SET_UPDATE_SUCCESS: "FAQ question set, questions and answers have been created succesfully.",
    HELPTEXT_TAGS: "Please input the value and press enter to create a new tag.",
    ANSWER: "Answer",
    QUESTION: "Question",
    YES: "Yes",
    NO: "No",
    FAQ_TITLE: "FAQ Title",
    FAQ_DESCRIPTION: "FAQ Description",
    FAQ_TOPIC_DESCRIPTION: "FAQ Topic Description",
    FAQ_SUB_TOPIC_DESCRIPTION: "FAQ Sub-Topic Description",
    PRIMARY_QN: "PrimaryQuestion",
    TYPE: "Type",
    RANK: "Rank",
    FAQQNSET_NO_RECORD: "No Question Sets in Sub-Topic",
    FAQ_TOPIC_TITLE: "FAQ Topic Title",
    ADD: "Add",
    FAQ_SUB_TOPIC_TITLE: "FAQ Sub-Topic Title",
    TAGS: "Tags",
    FAQ_ANSWER: "FAQ Answer",
    TOGGLE_TREE_VIEW: "Toggle Tree View",
    SEARCH_QNSET: "Search Question Set...",
    BTN_FAQ_QNSETS: "Question Sets",
    MOVEUP: "Move Up",
    MOVEDOWN: "Move Down",
    ADD_MORE_QN: "Add More Question",
    REMOVE: "Remove",
    EDIT_FAQ_TOPIC: "Edit FAQ Topic",
    NEW_FAQ_TOPIC: "New FAQ Topic",
    NEW_FAQ_SUB_TOPIC: "New FAQ Sub-Topic",
    NEW_FAQ_QNSET: "New FAQ Question Set",
    EDIT_FAQ_SUB_TOPIC: "Edit FAQ Sub-Topic",
    EDIT_FAQ_QNSET: "Edit FAQ Question Set",
    VIEW_FAQ: "View FAQ",
    VIEW_FAQ_TOPIC: "View FAQ Topic",
    VIEW_FAQ_SUB_TOPIC: "View FAQ Sub-Topic",
    VIEW_FAQ_QNSET: "View FAQ QUestion Set",
    VIEW_QNSET: "View Question Set",
    EDIT_QN_SET: "Edit Question Set",
    PRIMARY: "(Primary)",
    ADD_NEW_FAQ_TOPIC: "Add new FAQ Topic",
    ADD_NEW_FAQ_SUB_TOPIC: "Add new FAQ Sub-Topic",
    ADD_NEW_QN_SET: "Add new Question Set",
    LOADING: "Loading...",
    TOPIC: "Topic",
    SUB_TOPIC: "Sub-Topic",
    SCOPE_MANAGEMENT: "Scope Management",
    CATEGORY_MANAGEMENT: "Category Management",
    SUB_CATEGORY_MANAGEMENT: "Sub-Category Management",
    NEW_SCOPE: "New Scope",
    SCOPE_NO_RECORD: "No Scope(s) found.",
    CATEGORY_NO_RECORD: "No Category(s) found.",
    SUBCATEGORY_NO_RECORD: "No Sub-Category(s) found.",
    NEW_CATEGORY: "New Category",
    NEW_SUB_CATEGORY: "New Sub-Category",
    EDIT_SCOPE: "Edit Scope",
    EDIT_CATEGORY: "Edit Category",
    EDIT_SUB_CATEGORY: "Edit Sub-Category",
    SCOPE_NAME: "Scope Name",
    SUB_CATEGORY_NAME: "Sub-Category Name",
    CATEGORY_NAME: "Category Name",
    VIEW_SCOPE: "View Scope",
    VIEW_CATEGORY: "View Category",
    VIEW_SUB_CATEGORY: "View Sub-Category",
    ADD_SCOPE: "Add Scope",
    ADD_CATEGORY: "Add Category",
    ADD_SUB_CATEGORY: "Add Sub-Category",
    HELPTEXT_SCOPENAME: "Scope name is required.",
    HELPTEXT_CATEGORY_NAME: "Category name is required.",
    HELPTEXT_SUB_CATEGORY_NAME: "Sub-Category name is required.",
    LANGUAGE_PACK: "Language Pack",
    ERROR_SELECT_APP: "Please select an application.",
    SELECT_SCOPE: "Select a scope",
    SCOPE: "Scope",
    ERROR_SELECT_SCOPE: "Please select a scope.",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOAD_FILE: "Please upload a file.",
    TRANS_DATA_NO_RECORD: "No Translation Data was found.",
    DIVIDER_LANG_PACK: "Review of Updated Information",
    LANGUAGE_REG: "Language Registration",
    REG_LANGUAGE: "Registered Language",
    APP_NO_RECORD: "No Applications were found.",
    DEFAULT: "Default",
    EDIT_APP_LANG: "Edit Application Languages",
    ADD_LANG: "Add Language",
    LANG_SETTINGS: "Language Settings",
    ERROR_DEFAULT_LANG: "A default language has to be active.",
    VALIDATE: "Validate",
    SELECTLIST_SCOPE_NO_RECORD: "There are no scopes available.",
    NO_TRANS_DATA: "There is no translation data that has been updated.",
    LANG_KEY_UPDATE: "Please confirm the list of language keys below that has an update in the language translation.",
    LANG_KEY: "Language Key",
    LANGUAGE: "Language",
    ALIGN_TO_RIGHT: "Align To Right",
    SELECT_A_LANGUAGE: "Select a Language",
    UPLOAD_FILE_TITLE: "Upload File",
    SUBMIT: "Submit",
    FULL_KEY: "Full Key",
    EDIT_LANG_VALUE: "Edit Language Value",
    VALUE_REQUIRED: "Value is required.",
    ADD_LANG_ENTRY: "Add New Language Key And Value Entry",
    KEY: "Key",
    ADD_VALUE_FOR_KEY: "Add Value for Key",
    SELECT_SUB_CATEGORY: "Select a Sub-Category",
    SELECT_CATEGORY: "Select a Category",
    FILTER_BY_SCOPE: "Filter by Scope",
    FILTER_BY_CATEGORY: "Filter by Category",
    FILTER_BY_SUBCATEGORY: "Filter by Sub-Category",
    SUB_CATEGORY: "Sub-Category",
    FILTER_BY_PUBLISHED: "Filter by Published",
    PUBLISHED: "Published",
    SORT_BY: "Sort By",
    SEARCH: "Search",
    NEW_ENTRY: "New Entry",
    TRANS_NO_RECORD: "No Translations were found.",
    DEFAULT_VALUE: "Default Value",
    UPDATED_BY: "Updated By",
    UPDATED_DATE: "Updated Date",
    REVERT: "Revert",
    TRANSLATION_MANAGEMENT: "Translation Management",
    DELETE_TRANSKEY_MODAL_TITLE: "Delete Translation Key",
    REVERT_TRANSKEY_MODAL_TITLE: "Revert Translation Key",
    PUBLISH_TRANSKEY_MODAL_TITLE: "Publish entries",
    HARD_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please be reminded that this action cannot be undone.</div>",
    SOFT_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please note that this action <b>can</b> be reverted.</div>",
    REVERT_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to revert back to the published values for all languages in this entry? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to publish the entries for all languages in this application? <br />Please be reminded that this action cannot be undone.</div>",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASC_KEY: "Key (asc)",
    DESC_KEY: "Key (desc)",
    ASC_UPDATEDDATE: "Updated Date (asc)",
    DESC_UPDATEDDATE: "Updated Date (desc)",
    LAST_EDITED_BY: "Last edited by",
    DEFAULT_LANGUAGE: "Default Language",
    SUBCATEGORY: "Sub-Category",
    ADD_LANGUAGE: "Add Language",
    CHAR_REMAINING: "characters remaining",
    PUBLISH_FAQ_MODAL_TEXT: "<div>Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_FAQ_MODAL_TITLE: "Publish Faq",
    DELETE_FAQ_MODAL_TITLE: "Delete FAQ draft",
    DELETE_FAQ_MODAL_TEXT: "<div>Are you sure you want to delete the Faq draft? <br />Please be reminded that this action cannot be undone.</div>",
    PREVIEW_FAQ_MODAL_TITLE: "Preview FAQ",
    PUBLISH_FAQ_LOCALE_MODAL_TEXT: "<br /><br />Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.",
    DELETE_DRAFT: "Delete Draft",
    PREVIEW: "Preview",
    FILL_UP_ALL_QUESTIONS: "Please fill up all the questions.",
    FILL_UP_ANSWER: "Please fill up the answer.",
    DELETE_NODE_MODAL_TITLE: "Delete Node",
    DELETE_NODE_MODAL_TEXT: "Are you sure you want to delete this node?",
    APPLICATION_CONFIGURATION: "Application Configuration",
    CODE: "Code",
    FAQ_DRAFT: "FAQ Draft",
    TRANSLATION: "Translation",
    APPLICATION_LANGUAGE: "Application Language",
    APPLICATION_TRANSLATION: "Application Translation",
    NO_QUESTIONS_AVAILABLE: "No questions available",
    DISPLAY_ANSWER: "Display Answer",
    ADD_PUBLIC_HOLIDAY: "Add Public Holiday",
    NAME_OF_PH: "Name of Public Holiday",
    PUBLIC_HOLIDAY: "Public Holiday",
    REMARKS: "Remarks",
    PUBLIC_HOLIDAY_MANAGEMENT: "Public Holiday Management",
    YEAR: "Year",
    DATE: "Date",
    ALL_YEARS: "All Years",
    NEW_PUBLIC_HOLIDAY: "New Public Holiday",
    PH_NO_RECORD: "No Public Holiday records found",
    DELETE_PH_MODAL_TEXT: "Are you sure you want to delete this public holiday?",
    DELETE_PH_MODAL_TITLE: "Delete Public Holiday",
    UPDATE_PUBLIC_HOLIDAY: "Update Public Holiday",
    SMS_TEMPLATE_MANAGEMENT: "SMS Template Management",
    SMS_TEMPLATE: "SMS Template",
    NEW_SMS_TEMPLATE: "New SMS Template",
    SMSTEMPLATE_NO_RECORD: "No SMS Template records found",
    TEMPLATE_NAME: "Template Name",
    VERSION: "Version",
    DELETE_SMSTEMP_MODAL_TITLE: "Delete SMS Template",
    DELETE_SMSTEMP_MODAL_TEXT: "<div>Are you sure you want to delete this SMS Template? <br />Please be reminded that this action cannot be undone.</div>",
    CREATE_SMS_TEMPLATE: "Create SMS Template",
    HELPTEXT_NOTITEMP_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    TEMPLATE_SUBJECT: "Template Subject",
    TEMPLATE_BODY: "Template Body",
    HELPTEXT_NOTITEMP_TEMPLATESUBJECT: "Please type @ to get the list of available placeholders.",
    HELPTEXT_NOTITEMP_TEMPLATEBODY: "Please type @ to get the list of available placeholders.",
    VIEW_SMS_TEMPLATE: "View SMS Template",
    PUBLISH_BY_SCOPE: "Publish by Scope",
    PUBLISH_BY_APP_MODAL_TEXT: "<div>Note: Choosing <b>No</b> for Publish by Scope,<br />Translation Data will be published by <b>Application</b> level.<br />" +
        "Please also note that Scope(s) with no translations will not be displayed in the list of scopes.<br /><br /></div>",
    PUBLISH_BY_SCOPE_MODAL_TEXT: "<div>Note: Choosing <b>Yes</b> for Publish by Scope,<br />Translation Data will be published by <b>Scope</b> level.<br /><br /></div>",
    EMAIL_TEMPLATE: "Email Template",
    CREATE_EMAIL_TEMPLATE: "Create Email Template",
    NEW_EMAIL_TEMPLATE: "New Email Template",
    EMAILTEMPLATE_NO_RECORD: "No Email Template records found",
}

export const DEFAULT_NAMESPACE = {
    // Application Configuration Management
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_APPCONFIGMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.APPLICATIONCONFIGURATIONMANAGEMENT}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_NEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_UPDATECONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.UPDATECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_VIEWCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_DELETECONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,

    // Update Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_UPDATECONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_LOCALESETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.LOCALESETTINGS}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_ISDEFAULTGLOBALAPPENABLED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.ISDEFAULTGLOBALAPPENABLED}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_GENERAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.GENERAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_CURRENCY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.CURRENCY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DECIMAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.DECIMAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_EMAIL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.EMAIL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_NUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.NUMBER}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_SAVECHANGES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVECHANGES}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CURRENCY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CURRENCY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DECIMAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DECIMAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_EMAIL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.EMAIL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_NUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.NUMBER}`,

    // View Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_VIEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,

    // Delete Configuration
    GLOBAL_APPCONFIGSTATIC_MODAL_TITLE_DELETEAPPCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEAPPCONFIG}`,
    GLOBAL_APPCONFIGSTATIC_MODAL_TEXT_DELETEAPPCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEAPPCONFIG}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,

    // New Application Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_NEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.NEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUETYPE}`,

    // Success message Application Configuration
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATE}`,

    // Error message Application Configuration
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_NAMEREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.NAMEREQUIRED}`,
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_CATEGORYREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.CATEGORYREQUIRED}`,


    // Code Table Management
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_NEWCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCODE}`,
    GLOBAL_CODESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CODETABLEMANAGEMENT}`,
    GLOBAL_CODESTATIC_TABLE_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,
    GLOBAL_CODESTATIC_TABLE_TOOLTIP_UPDATECODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.UPDATECODE}`,
    GLOBAL_CODESTATIC_TABLE_TOOLTIP_VIEWCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEWCODE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CODECATEGORY}`,

    // Update Code Table entry
    GLOBAL_CODESTATIC_CONTENT_TITLE_UPDATECODETABLEENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATECODETABLEENTRY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_INACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.INACTIVE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCODE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_COUNTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.COUNTRY}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_SAVECHANGES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVECHANGES}`,

    // Code Table entry details 
    GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEENTRYDETAILS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CODETABLEENTRYDETAILS}`,
    GLOBAL_CODESTATIC_FORM_TITLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCODE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    // New Code Table entry
    GLOBAL_CODESTATIC_CONTENT_TITLE_NEWCODETABLEENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.NEWCODETABLEENTRY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_CODESTATIC_ERROR_TITLE_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    // Menu
    GLOBAL_MENU_CONTENT_DROPDOWN_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.MENU}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.LANGUAGE}`,

    // Success message Code Table
    GLOBAL_CODESTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_CODESTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_CODESTATIC_SUCCESS_TITLE_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATE}`,

    // Default Static: Error
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SERVERUNREACHABLE}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.REQUIRED}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.MINLENGTH}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.MAXLENGTH}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.DATEINVALID}`,

    // FAQ Management
    GLOBAL_FAQSTATIC_CONTENT_TITLE_FAQMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.FAQMANAGEMENT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_NEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_LASTPUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LASTPUBLISHED}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_QNAMAKER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.QNAMAKER}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.CREATE}`,

    // Add new FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_ADDFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDFAQ}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SELECTLANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEAI: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEAI}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEMULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEMULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // View FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,

    // Edit FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_PREVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.PREVIEW}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETEDRAFT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETEDRAFT}`,
    GLOBAL_FAQSTATIC_TREE_TITLE_FAQTREE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TREE_TITLE}.${DEFAULT_KEY.FAQTREE}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PREVIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PREVIEWFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQLOCALE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQLOCALE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_PUBLISH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.PUBLISH}`,

    // FAQ Settings
    GLOBAL_FAQSTATIC_CONTENT_TITLE_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_SAVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVE}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_AICHATBOT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.AICHATBOT}`,

    // Preview FAQ
    GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.NOQUESTIONS}`,

    // QuestionSetCard
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_DISPLAYANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DISPLAYANSWER}`,


    // FAQ Tree
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEQNSET}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_HELPTEXT_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICDESC}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_PRIQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.PRIQN}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_RANK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.RANK}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TYPE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKUP}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKDOWN}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_QNSETNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.QNSETNORECORD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICTITLE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICTITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQANS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_TOGGLETREEVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.TOGGLETREEVIEW}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SEARCHQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEARCH_QNSET}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_FAQQNSETS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.FAQQNSETS}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEUP}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEDOWN}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADDQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADDQN}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_YES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.YES}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_REMOVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REMOVE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PRIMARY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWQNSET}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_LOADING: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.LOADING}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SUBTOPIC}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ALLQUESTIONSREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ALLQUESTIONSREQUIRED}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ANSWERREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ANSWERREQUIRED}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.VIEW}`,

    // Scope Management
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_SCOPEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SCOPEMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_SCOPENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SCOPENORECORD}`,

    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_CATEGORYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.CATEGORYNORECORD}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_SUBCATEGORYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SUBCATEGORYNORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_CATEGORYMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CATEGORYMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_SUBCATEGORYMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SUBCATEGORYMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,

    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATESCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESCOPE}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATESUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATECATEGORY}`,


    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATECATEGORY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATESCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESCOPE}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATESUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESUBCATEGORY}`,

    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,

    GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLISHBYSCOPE}`,

    // Language Pack
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.LANGUAGEPACK}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_DOWNLOAD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DOWNLOAD}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.PUBLISH}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_UPLOADFILE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.UPLOADFILE}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSDATANORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.TRANSDATANORECORD}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGUAGEPACK}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LANGKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TEXT_TRANSDATA: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.TRANSDATA}`,
    GLOBAL_LOCALESTATIC_CONTENT_TEXT_LANGKEYUPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.LANGKEYUPDATE}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGUAGEPACK}`,

    // Language Registration
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEREG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.LANGUAGEREG}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_REGLANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.REGLANGUAGE}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_APPNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.APPNORECORD}`,
    GLOBAL_LOCALESTATIC_BADGE_TEXT_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.BADGE_TEXT}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_LOCALESTATIC_BADGE_TEXT_DEFAULT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.BADGE_TEXT}.${DEFAULT_KEY.DEFAULT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_VALIDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.VALIDATE}`,

    // Edit Application Languages
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITAPPLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITAPPLANG}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_ADDLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDLANG}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_LANGSETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGSETTINGS}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REMOVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REMOVE}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_DEFAULTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.DEFAULTLANG}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEAPPLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEAPPLANG}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_SCOPENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.SCOPENORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DEFAULT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ALIGNRIGHT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ALIGNRIGHT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SELECTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SELECTLANG}`,

    // Upload File
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_UPLOADFILE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPLOADFILE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_SUBMIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SUBMIT}`,

    // Edit Key
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITLANGVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITLANGVALUE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_FULLKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FULLKEY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEKEY}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.VALUE}`,

    // CreateNewKey
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATETRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATETRANS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDLANGENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDLANGENTRY}`,

    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.KEY}`,

    GLOBAL_LOCALESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.KEY}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_ADDVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.ADDVALUE}`,

    // Translation Management
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYSCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYCAT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYCAT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSUBCAT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYSUBCAT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYPUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYPUBLISHED}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SORTBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SORTBY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SEARCH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEARCH}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SORTBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SORTBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLISHED}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWENTRY}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.TRANSNORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.KEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_FULLKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.FULLKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULTVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DEFAULTVALUE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDBY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REVERT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REVERT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_TRANSMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.TRANSMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_DELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_REVERTTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.REVERTTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_PUBLISHTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PUBLISHTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_SOFTDELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.SOFTDELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_REVERTTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.REVERTTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_HARDDELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.HARDDELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHTRANSKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_PUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.PUBLISHED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DRAFTED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DRAFTED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DELETED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DELETED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ASCKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DESCKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ASCUPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DESCUPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTEDITEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTEDITEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_DEFAULTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DEFAULTLANG}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_YES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.YES}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_PUBLISHTRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.PUBLISHTRANS}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_DELETETRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETETRANS}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_REVERTTRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.REVERTTRANS}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SEARCH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SEARCH}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_ENTERVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.ENTERVALUE}`,
    GLOBAL_DEFAULTSTATIC_FORM_HELPTEXT_CHARREMAINING: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CHARREMAINING}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHBYSCOPE}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYAPP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHBYAPP}`,

    // Public Holiday Management
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_PUBLICHOLIDAYMGMT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.PUBLICHOLIDAYMGMT}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_YEAR: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.YEAR}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_DROPDOWN_ALLYEARS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLYEARS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_NEWPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TEXT_PUBLICHOLIDAYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.PUBLICHOLIDAYNORECORD}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TITLE_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TEXT_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,

    // Add Public Holiday 
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_ADDPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_PUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_CREATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // Update Public Holiday
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_UPDATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_UPDATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,

    // View Public Holiday
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_VIEWPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    // SMS Template Management
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_SMSTEMPLATEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SMSTEMPLATEMANAGEMENT}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_NEWSMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TEXT_SMSTEMPLATENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SMSTEMPLATENORECORD}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_VERSION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VERSION}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_UPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDDATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TITLE_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TEXT_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,

    // Create SMS Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_CREATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_CREATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CREATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TYPE}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATESUBJECT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATESUBJECT}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATEBODY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATEBODY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATESUBJECT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TEMPLATESUBJECT}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATEBODY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TEMPLATEBODY}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // Update SMS Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_UPDATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_UPDATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,

    // View SMS Template
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_VIEWSMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSMSTEMPLATE}`,

    // Create Email Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_CREATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_CREATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CREATEEMAILTEMPLATE}`,

    // Email Template list
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_NEWEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TEXT_EMAILTEMPLATENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.EMAILTEMPLATENORECORD}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TITLE_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TEXT_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_UPDATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_UPDATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATEEMAILTEMPLATE}`,

}

// Note: To be changed accordingly
export const ERROR = {
    BACKEND_ERROR_MESSAGE: `${DEFAULT_APPLICATION_NAME}.%Key%`
}
