export const UserTranslationKeys = [
    {
        "DefaultLanguageValue": "User",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "User"
    },
    {
        "DefaultLanguageValue": "Username",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Username"
    },
    {
        "DefaultLanguageValue": "Internal",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "UserType",
        "TranslationKey": "Internal"
    },
    {
        "DefaultLanguageValue": "External",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "UserType",
        "TranslationKey": "External"
    },
    {
        "DefaultLanguageValue": "User Type",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "UserType"
    },
    {
        "DefaultLanguageValue": "Email",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Email"
    },
    {
        "DefaultLanguageValue": "Status",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Status"
    },
    {
        "DefaultLanguageValue": "Female",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "GenderOptions",
        "TranslationKey": "Female"
    },
    {
        "DefaultLanguageValue": "Male",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "GenderOptions",
        "TranslationKey": "Male"
    },
    {
        "DefaultLanguageValue": "Country Code",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "ContactNoPlaceholder",
        "TranslationKey": "CountryCode"
    },
    {
        "DefaultLanguageValue": "Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "ContactNoPlaceholder",
        "TranslationKey": "Number"
    },
    {
        "DefaultLanguageValue": "Salutation",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Salutation"
    },
    {
        "DefaultLanguageValue": "Identification Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "IdentificationNumber"
    },
    {
        "DefaultLanguageValue": "First Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "FirstName"
    },
    {
        "DefaultLanguageValue": "Middle Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "MiddleName"
    },
    {
        "DefaultLanguageValue": "Last Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "LastName"
    },
    {
        "DefaultLanguageValue": "Gender",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Gender"
    },
    {
        "DefaultLanguageValue": "Date Of Birth",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "DateOfBirth"
    },
    {
        "DefaultLanguageValue": "Nationality",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Nationality"
    },
    {
        "DefaultLanguageValue": "NRIC",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IDType",
        "TranslationKey": "NRIC"
    },
    {
        "DefaultLanguageValue": "Passport",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IDType",
        "TranslationKey": "Passport"
    },
    {
        "DefaultLanguageValue": "Primary Email Address",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "PrimaryEmailAddress"
    },
    {
        "DefaultLanguageValue": "Alternate Email Address",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "AltEmailAddress"
    },
    {
        "DefaultLanguageValue": "Primary Contact",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "PrimaryContact"
    },
    {
        "DefaultLanguageValue": "Mobile Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "MobileNumber"
    },
    {
        "DefaultLanguageValue": "Office Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "OfficeNumber"
    },
    {
        "DefaultLanguageValue": "Home Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "HomeNumber"
    },
    {
        "DefaultLanguageValue": "Fax Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "FaxNumber"
    },
    {
        "DefaultLanguageValue": "Personal Details",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "PersonalDetails"
    },
    {
        "DefaultLanguageValue": "Contact Info",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "ContactInfo"
    },
    {
        "DefaultLanguageValue": "Address Details",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "AddressDetails"
    }
]