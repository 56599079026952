import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select from "react-select";
import { getTranslationValue, getTranslationOptions } from '../../Shared/Forms/FormUtils';
import { withBaseControl } from './BaseControl';

class SelectList extends React.Component {

    // Fn: Handle changes in checkbox group 
    handleSelectChange = (event, { name, isMulti, onSelectListChange }) => {
        if (event !== null) {
            // Take in an event, and convert it into
            let selections = (isMulti) ? event : [event];

            // Only takes in 'value' prop for each object
            let value = _.map(selections, (selection) => selection.value);

            if (!isMulti) {
                value = value[0];
            }

            // Update changes in Formik handleChange event
            this.updateValues(value);

            // Execute other SelectList onChange
            if (onSelectListChange) {
                onSelectListChange({ [name]: value });
            }
        } else {
            this.setState({ value: '' });
            this.updateValues('');
        }
    };

    matchObjectValue = (valueArray, key, option) => {
        if (key === option.value) {
            return valueArray.push(option)
        }
    }

    // Fn: Retrieve value and convert it to react-select readable label-value pair
    mapValuesToOptions = (options) => {
        const { value } = this.props;

        // Only takes in 'value' prop for each object
        let valueArr = [];
        if (value !== "") {
            if (Array.isArray(value)) {
                value.forEach(element => {
                    options.forEach(option => {
                        if (element === option.value) {
                            valueArr.push(option);
                        }
                    });
                })
            }
            else if (typeof value === "object") {
                Object.keys(value).map(key =>
                    options.forEach(option => {
                        this.matchObjectValue(valueArr, value[key], option)
                    })
                );
            }
            else {
                options.forEach(option => {
                    if (value === option.value) {
                        valueArr.push(option);
                    }
                });
            }
            return valueArr;
        }
        return valueArr;
    };

    componentDidUpdate(prevProps) {
        let { value, isMulti } = this.props;
        if (this.props.value !== prevProps.value) {        // Map & update value to array
            if (isMulti && value !== undefined && value !== "" && !Array.isArray(value)) {
                this.updateValues(value.split(","));
            } else if (!isMulti && value !== undefined && value !== "") {
                this.updateValues(value);
            }
        }
    }

    updateValues = (value) => {
        const { onChange, onChangeField, name } = this.props;

        if (onChange)
            onChange(value);

        if (onChangeField)
            onChangeField(name, value);
    }

    render() {
        const { options, placeholder, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const placeholderValue = getStaticText && translationKeys ? getTranslationValue(placeholder, getStaticText, translationKeys) : placeholder;
        const selectListOptions = getStaticText && translationKeys ? getTranslationOptions(options, getStaticText, translationKeys) : options;

        return (
            <React.Fragment>
                <Select
                    name={this.props.name}
                    value={this.mapValuesToOptions(selectListOptions)}
                    placeholder={placeholderValue}
                    onChange={(e) => this.handleSelectChange(e, this.props)}
                    options={selectListOptions}
                    isMulti={this.props.isMulti}
                    isDisabled={this.props.isDisabled}
                    isClearable={this.props.isClearable}
                    maxMenuHeight={150}
                    menuPlacement={(this.props.menuPlacement ? this.props.menuPlacement : "auto")}
                    // menuPortalTarget={document.body}
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }), //  >= dialog's z-index
                        multiValue: (base, state) => {
                            return state.isDisabled ? { ...base, backgroundColor: 'gray' } : base;
                        },
                        multiValueLabel: (base, state) => {
                            return state.isDisabled
                                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                                : base;
                        },
                        multiValueRemove: (base, state) => {
                            return state.isDisabled ? { ...base, display: 'none' } : base;
                        } // hide clear button for multi value if isDisabled
                    }}
                    className={this.props.className + " select-list"} //  class for css
                />
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
SelectList.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
    placeholder: PropTypes.string,
    options: PropTypes.array.isRequired,
    isMulti: PropTypes.bool.isRequired
};


export default withBaseControl(SelectList);