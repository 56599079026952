import moment from 'moment';
import React from 'react';
import { Button, Col, Row, FormGroup, Table } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, ADD_WORKING_HOUR_URL, WORKING_HOUR_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { DateInput, SMARTForm, Text, ToggleSwitchBoolean } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { ModalConfirm } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "WorkingHourConfig";
const NEXT_URL = '/admin/workinghourconfig/list';

const MODAL_NAMES = { SET_WORKING_HOURS: "SetWorkingHours" };

const ModalLabelSize = 4;
const ModalInputSize = 8;

const DAYS = {
    WEEKDAY: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
    WEEKEND: ['SAT', 'SUN']
}

const ALL_DAYS = {
    Monday: 'MON', Tuesday: 'TUE', Wednesday: 'WED', Thursday: 'THU', Friday: 'FRI',
    Saturday: 'SAT', Sunday: 'SUN'
}

const WORKING_HOUR_TYPE = {
    WORK_START: 'WorkingHourWorkStart',
    WORK_END: 'WorkingHourWorkEnd',
    BREAK_START: 'WorkingHourBreakStart',
    BREAK_END: 'WorkingHourBreakEnd'
}

const DEFAULT = {
    WORK_START: `Default${WORKING_HOUR_TYPE.WORK_START}`,
    WORK_END: `Default${WORKING_HOUR_TYPE.WORK_END}`,
    BREAK_START: `Default${WORKING_HOUR_TYPE.BREAK_START}`,
    BREAK_END: `Default${WORKING_HOUR_TYPE.BREAK_END}`
}

const IS_WORKING_DAY = 'WorkingHourWorkingDay';

const GET_FORM_VALIDATIONS_BY_CATEGORY = (Days) => {
    let VALIDATIONS = {};
    for (var Type in WORKING_HOUR_TYPE) {
        const type = WORKING_HOUR_TYPE[Type];
        for (const day of Days) {
            const workingHoursField = `${type}${day}`;

            VALIDATIONS[workingHoursField] = Yup.string();
        }
    }
    return VALIDATIONS;
}

const GET_FORM_VALIDATIONS = () => {
    let VALIDATIONS = {
        WorkingHourCategory: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT)
    };
    VALIDATIONS = Object.assign(VALIDATIONS,
        GET_FORM_VALIDATIONS_BY_CATEGORY(DAYS.WEEKDAY),
        GET_FORM_VALIDATIONS_BY_CATEGORY(DAYS.WEEKEND)
    );
    return VALIDATIONS;
}

const GET_INITIAL_VALUES = (Days, isWorkingDay) => {
    let values = {};
    for (var Type in WORKING_HOUR_TYPE) {
        const type = WORKING_HOUR_TYPE[Type];
        for (const day of Days) {
            const workingHoursField = `${type}${day}`;
            values[workingHoursField] = ''; // Default

            const isWorkingDayField = `${IS_WORKING_DAY}${day}`;
            values[isWorkingDayField] = isWorkingDay;
        }
    }
    return values;
}

const FORM_VALIDATIONS = Yup.object().shape(GET_FORM_VALIDATIONS());

const FORM_INITIAL_VALUES = () => {
    let values = {
        WorkingHourCategory: '',

        [`${DEFAULT.WORK_START}`]: '',
        [`${DEFAULT.WORK_END}`]: '',
        [`${DEFAULT.BREAK_START}`]: '',
        [`${DEFAULT.BREAK_END}`]: ''
    };

    Object.assign(values,
        GET_INITIAL_VALUES(DAYS.WEEKDAY, true),
        GET_INITIAL_VALUES(DAYS.WEEKEND, false)
    );
    return values;
}

// Time validation: Check Overlap Times
function checkOverlapTimes(field, errors, start, end, errorMsg) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);

    if (mEnd.isBefore(mStart)) {
        errors = Object.assign(errors, { [field]: errorMsg });
        return errors;
    } else {
        delete errors[field];
    }
}

//Time validation Work End
function checkWorkEnd(field, errors, start, end) {
    return checkOverlapTimes(field, errors, start, end, ERROR.TIME_INVALID_WE);
}

//Time validation Break Start
function checkBreakStart(field, errors, start, end) {
    return checkOverlapTimes(field, errors, start, end, ERROR.TIME_INVALID_BS);
}

//Time validation Break start, Work end
function checkBSWE(field, errors, start, end) {
    return checkOverlapTimes(field, errors, start, end, ERROR.TIME_INVALID_BSWE);
}

//Time validation Break End
function checkBreakEnd(field, errors, start, end) {
    return checkOverlapTimes(field, errors, start, end, ERROR.TIME_INVALID_BE);
}


class WorkHourConfigCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { disabled: false };

        if (history.location.state && history.location.state[FORM_CONTEXT]) {
            this.state = {
                values: history.location.state[FORM_CONTEXT],
                dropDownArrayCategory: [],
            };

            history.replace({
                pathname: history.location.pathname,
                state: {}
            });
        }
        else {
            this.state = {
                values: FORM_INITIAL_VALUES(),
                dropDownArrayCategory: [],
            };
        }
    }

    //  Reset WorkStart, WorkEnd, BreakStart, Breakend if WorkingDay is set to false
    resetWorkDaysByCategory = (values, Days) => {
        for (const day of Days) {
            const isWorkingDayField = `${IS_WORKING_DAY}${day}`;

            if (!values[isWorkingDayField]) {
                for (var Type in WORKING_HOUR_TYPE) {
                    const type = WORKING_HOUR_TYPE[Type];
                    const workingHoursField = `${type}${day}`;

                    values[workingHoursField] = '';
                }
            }
        }
        return values;
    }

    resetWorkingDays = (values) => {
        values = this.resetWorkDaysByCategory(values, DAYS.WEEKDAY);
        values = this.resetWorkDaysByCategory(values, DAYS.WEEKEND);

        return values;
    }

    // Fn: Massage form values to be submitted
    massageValues = (values, submitPlainForm) => {
        values = this.resetWorkingDays(values);

        submitPlainForm();
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(WORKING_HOUR_MANAGEMENT_URL, "");
                }
                toastSuccess("Working Hour has been added successfully.");
            }
            else {
                toastError(Message);
            }
        } else {
            toastError("Server could not be reached, please try again later")
        }
    }

    displayModalContent = (values, errors, onChangeField) => {
        return (
            <>
                <p>Set default working and break hours to populate <b>Working Hours for <u>Working Days</u></b>.
                Note that if there's existing Working Hours, it will be <u>overridden</u>.</p>
                <br />
                <DateInput
                    name={`${DEFAULT.WORK_START}`}
                    value={values[`${DEFAULT.WORK_START}`]}
                    placeholder="Default Work Start"
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min="1900-01-01 00:00"
                    max="2100-12-31 00:00"
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={ModalLabelSize}
                    inputSize={ModalInputSize}
                    label="Default Work Start"
                />
                <DateInput
                    name={`${DEFAULT.WORK_END}`}
                    value={values[`${DEFAULT.WORK_END}`]}
                    placeholder="Default Work End"
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min="1900-01-01 00:00"
                    max="2100-12-31 00:00"
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={ModalLabelSize}
                    inputSize={ModalInputSize}
                    label="Default Work End"
                    error={checkWorkEnd(`${DEFAULT.WORK_END}`, errors, values[`${DEFAULT.WORK_START}`], values[`${DEFAULT.WORK_END}`])}
                />
                <DateInput
                    name={`${DEFAULT.BREAK_START}`}
                    value={values[`${DEFAULT.BREAK_START}`]}
                    placeholder="Default Break Start"
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min="1900-01-01 00:00"
                    max="2100-12-31 00:00"
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={ModalLabelSize}
                    inputSize={ModalInputSize}
                    label="Default Break Start"
                    error={checkBreakStart(`${DEFAULT.BREAK_START}`, errors, values[`${DEFAULT.WORK_START}`], values[`${DEFAULT.BREAK_START}`]) || checkBSWE(`${DEFAULT.BREAK_START}`, errors, values[`${DEFAULT.BREAK_START}`], values[`${DEFAULT.WORK_END}`])}
                />
                <DateInput
                    name={`${DEFAULT.BREAK_END}`}
                    value={values[`${DEFAULT.BREAK_END}`]}
                    placeholder="Default Break End"
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min="1900-01-01 00:00"
                    max="2100-12-31 00:00"
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={ModalLabelSize}
                    inputSize={ModalInputSize}
                    label="Default Break End"
                    error={checkBreakEnd(`${DEFAULT.BREAK_START}`, errors, values[`${DEFAULT.BREAK_START}`], values[`${DEFAULT.BREAK_END}`])}
                />
            </>
        )
    }

    clearDefaultWorkingHours = (modal, values) => {
        values[`${DEFAULT.WORK_START}`] = '';
        values[`${DEFAULT.WORK_END}`] = '';
        values[`${DEFAULT.BREAK_START}`] = '';
        values[`${DEFAULT.BREAK_END}`] = '';

        modal.toggleModal(MODAL_NAMES.SET_WORKING_HOURS);
    }

    processDefaultWorkingHours = (modal, values, errors) => {
        if (!this.modalHasErrors(values, errors)) {
            Object.keys(ALL_DAYS).forEach((day) => {
                const dayValue = ALL_DAYS[day];
                const isWorkingDayField = `${IS_WORKING_DAY}${dayValue}`;

                const WorkStartField = `${WORKING_HOUR_TYPE.WORK_START}${dayValue}`;
                const WorkEndField = `${WORKING_HOUR_TYPE.WORK_END}${dayValue}`;
                const BreakStartField = `${WORKING_HOUR_TYPE.BREAK_START}${dayValue}`;
                const BreakEndField = `${WORKING_HOUR_TYPE.BREAK_END}${dayValue}`;

                if (values[isWorkingDayField]) {
                    values[WorkStartField] = values[`${DEFAULT.WORK_START}`];
                    values[WorkEndField] = values[`${DEFAULT.WORK_END}`];
                    values[BreakStartField] = values[`${DEFAULT.BREAK_START}`];
                    values[BreakEndField] = values[`${DEFAULT.BREAK_END}`];
                }
            });

            // Close modal
            modal.toggleModal(MODAL_NAMES.SET_WORKING_HOURS);
        }
    }

    modalHasErrors = (values, errors) => {
        const checkDefaultWorkEnd = checkWorkEnd(`${DEFAULT.WORK_END}`, errors, values[`${DEFAULT.WORK_START}`], values[`${DEFAULT.WORK_END}`])
        const checkDefaultBreakStart = checkBreakStart(`${DEFAULT.BREAK_START}`, errors, values[`${DEFAULT.WORK_START}`], values[`${DEFAULT.BREAK_START}`]) || checkBSWE(`${DEFAULT.BREAK_START}`, errors, values[`${DEFAULT.BREAK_START}`], values[`${DEFAULT.WORK_END}`]);
        const checkDefaultBreakEnd = checkBreakEnd(`${DEFAULT.BREAK_END}`, errors, values[`${DEFAULT.BREAK_START}`], values[`${DEFAULT.BREAK_END}`]);

        return (checkDefaultWorkEnd !== undefined || checkDefaultBreakStart !== undefined || checkDefaultBreakEnd !== undefined);
    }

    render() {
        const isMobile = window.innerWidth <= 992;
        const { modal } = this.props;

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="New Working Hour">
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={FORM_VALIDATIONS}
                        validateOnSubmit={true}
                        serverURL={ADD_WORKING_HOUR_URL}
                        nextURL={NEXT_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                        resetFormAfterSubmit={true}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <React.Fragment>

                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <FormGroup row>
                                            <Col className={`text-left ${isMobile ? 'm-3' : 'remove-padding'}`}>
                                                <Text
                                                    name="WorkingHourCategory"
                                                    value={values.WorkingHourCategory}
                                                    placeholder="Category"
                                                    onChange={onChange}
                                                    minLength={0}
                                                    maxLength={140}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    label="Category"
                                                    error={errors.WorkingHourCategory}
                                                    required
                                                />
                                            </Col>
                                            <Col className={`text-right ${isMobile ? 'set-working-hours' : ''}`}>
                                                <Button color="neutral" onClick={() => modal.toggleModal(MODAL_NAMES.SET_WORKING_HOURS)}>
                                                    Set Working Hours
                                                </Button>
                                            </Col>
                                        </FormGroup>

                                        <Table size="sm" className="WHC-Table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Working Day</th>
                                                    <th>Work Start</th>
                                                    <th>Work End</th>
                                                    <th>Break Start</th>
                                                    <th>Break End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(ALL_DAYS).map((day) => {
                                                        const dayValue = ALL_DAYS[day];

                                                        const isWorkingDayField = `${IS_WORKING_DAY}${dayValue}`;
                                                        const WorkStartField = `${WORKING_HOUR_TYPE.WORK_START}${dayValue}`;
                                                        const WorkEndField = `${WORKING_HOUR_TYPE.WORK_END}${dayValue}`;
                                                        const BreakStartField = `${WORKING_HOUR_TYPE.BREAK_START}${dayValue}`;
                                                        const BreakEndField = `${WORKING_HOUR_TYPE.BREAK_END}${dayValue}`;

                                                        return (
                                                            <tr key={day}>
                                                                <td>
                                                                    {day}
                                                                </td>
                                                                <td>
                                                                    <ToggleSwitchBoolean
                                                                        name={isWorkingDayField}
                                                                        value={values[isWorkingDayField]}
                                                                        onChangeField={onChangeField}
                                                                        inputSize={12}
                                                                        trueText="Work"
                                                                        falseText="No Work"
                                                                        defaultOn={true}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <DateInput
                                                                        name={WorkStartField}
                                                                        value={!values[isWorkingDayField] ? "" : values[WorkStartField]}
                                                                        key={isMobile}
                                                                        placeholder={isMobile ? "Work Start" : "Start"}
                                                                        onChangeField={onChangeField}
                                                                        time={true}
                                                                        date={false}
                                                                        min="1900-01-01 00:00"
                                                                        max="2100-12-31 00:00"
                                                                        format={DATE_DISPLAY_FORMAT.TIME}
                                                                        inputSize={8}
                                                                        label="Start"
                                                                        error={errors[WorkStartField]}
                                                                        required
                                                                        disabled={!values[isWorkingDayField] ? true : false}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <DateInput
                                                                        name={WorkEndField}
                                                                        value={!values[isWorkingDayField] ? "" : values[WorkEndField]}
                                                                        key={isMobile}
                                                                        placeholder={isMobile ? "Work End" : "End"}
                                                                        onChangeField={onChangeField}
                                                                        time={true}
                                                                        date={false}
                                                                        min="1900-01-01 00:00"
                                                                        max="2100-12-31 00:00"
                                                                        format={DATE_DISPLAY_FORMAT.TIME}
                                                                        inputSize={8}
                                                                        label="End"
                                                                        error={!values[isWorkingDayField] ? "" : checkWorkEnd(WorkEndField, errors, values[WorkStartField], values[WorkEndField]) === undefined ? '' : errors[WorkEndField]}
                                                                        disabled={!values[isWorkingDayField] ? true : false}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <DateInput
                                                                        name={BreakStartField}
                                                                        value={!values[isWorkingDayField] ? "" : values[BreakStartField]}
                                                                        key={isMobile}
                                                                        placeholder={isMobile ? "Break Start" : "Start"}
                                                                        onChangeField={onChangeField}
                                                                        time={true}
                                                                        date={false}
                                                                        min="1900-01-01 00:00"
                                                                        max="2100-12-31 00:00"
                                                                        format={DATE_DISPLAY_FORMAT.TIME}
                                                                        inputSize={8}
                                                                        label="Start"
                                                                        error={!values[isWorkingDayField] ? "" :
                                                                            (checkBreakStart(BreakStartField, errors, values[WorkStartField], values[BreakStartField]) === undefined ? '' : errors[BreakStartField]) ||
                                                                            (checkBSWE(BreakStartField, errors, values[BreakStartField], values[WorkEndField]) === undefined ? '' : errors[BreakStartField])}
                                                                        disabled={!values[isWorkingDayField] ? true : false}
                                                                    /></td>
                                                                <td>
                                                                    <DateInput
                                                                        name={BreakEndField}
                                                                        value={!values[isWorkingDayField] ? "" : values[BreakEndField]}
                                                                        key={isMobile}
                                                                        placeholder={isMobile ? "Break End" : "End"}
                                                                        onChangeField={onChangeField}
                                                                        time={true}
                                                                        date={false}
                                                                        min="1900-01-01 00:00"
                                                                        max="2100-12-31 00:00"
                                                                        format={DATE_DISPLAY_FORMAT.TIME}
                                                                        inputSize={8}
                                                                        label="End"
                                                                        error={!values[isWorkingDayField] ? "" : checkBreakEnd(BreakEndField, errors, values[BreakStartField], values[BreakEndField]) === undefined ? '' : errors[BreakEndField]}
                                                                        disabled={!values[isWorkingDayField] ? true : false}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(WORKING_HOUR_MANAGEMENT_URL)}>Back</Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>Submit</Button>
                                </PageFooter>

                                <ModalConfirm
                                    isOpen={(modal.modalState === MODAL_NAMES.SET_WORKING_HOURS)}
                                    contentHeader="Set Working Hours"
                                    contentBody={this.displayModalContent(values, errors, onChangeField)}
                                    confirmText="Confirm"
                                    confirmCallback={() => { this.processDefaultWorkingHours(modal, values, errors) }}
                                    confirmBtnDisabled={this.modalHasErrors(values, errors)}
                                    cancelText="Cancel"
                                    cancelCallback={() => this.clearDefaultWorkingHours(modal, values)}
                                />
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withModalWrapper(WorkHourConfigCreate);