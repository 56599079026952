import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { IS_DEMO, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, postParams } from '../../Shared/Actions';
import { Plaintext } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { validateRequest } from '../../Shared/SmartMe/Actions';
import { convertToFormData } from '../../Shared/Forms/FormUtils';
import { URL } from '../../Shared/SmartMe/Constants';
import AssignRolesToUser from '../../Shared/SmartMe/Components/AssignRolesToUser';
import { getRoles, mapUserRoles, userRolesMapper } from '../../Shared/SmartMe/Components/AssignRolesToUser/Utils';

const SERVER_URL = URL + 'User/UpdateUserRoles';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            deleteItemArray: [],
            isUpdatePage: props.isUpdatePage
        }
    }

    async componentDidMount() {
        const { loader } = this.props;

        loader.start();

        let userRolesResp = await fetchRequest(URL + "User/GetUserRoles/" + this.props.match.params.id, getParams());

        if (validateRequest([userRolesResp])) {
            let userRoles = userRolesResp.body.Data;

            //map user roles to grid values
            const rolesResponse = await getRoles(loader);
            let mappedUserRoles = userRolesMapper(userRoles.RoleToUser, rolesResponse.allRoles);

            this.setState({
                readOnly: {
                    id: this.props.match.params.id,
                    username: userRoles.Username,
                    email: userRoles.Email || ""
                },
                selectable: rolesResponse.selectable,
                allRoles: rolesResponse.allRoles,
                data: mappedUserRoles || [],
                isLoaded: true
            });
        }
        else {
            const { formAlert } = this.props;
            formAlert.showAlert(userRolesResp.body.messages);
        }
        loader.done();
    }

    submitForm = async () => {
        const { loader } = this.props;
        loader.start();

        const { data, deleteItemArray, readOnly } = this.state;
        const userRoles = mapUserRoles(data, deleteItemArray, readOnly.id);

        const response = await fetchRequest(SERVER_URL, postParams(convertToFormData(userRoles)), IS_DEMO);
        const { IsSuccess, Messages } = response.body;

        loader.done();

        // Return response when result is successful
        if (response.success && IsSuccess) {
            const currentURL = history.location.pathname
            history.push("/empty")
            history.push(currentURL)
            toastSuccess(Messages)
        }
        else {
            toastError(Messages)
        }
    }

    backBtn = () => {
        history.goBack()
    }

    render() {
        const { isLoaded, selectable, allRoles, data, deleteItemArray, isUpdatePage } = this.state;
        return (
            <>
                {isLoaded &&
                    <>
                        <Layout type={LAYOUT_TYPE.FORM} title="Update User Roles">
                            <Row className="body-content">
                                <Col>
                                    <Plaintext
                                        label="username"
                                        name="Username"
                                        value={this.state.readOnly.username}
                                    />
                                    <Plaintext
                                        label="email"
                                        name="Email"
                                        value={this.state.readOnly.email}
                                    />
                                    <AssignRolesToUser
                                        deleteItemArray={deleteItemArray}
                                        data={data}
                                        selectable={selectable}
                                        allRoles={allRoles}
                                        isUpdatePage={isUpdatePage}
                                    />
                                </Col>
                            </Row>
                        </Layout>
                        <PageFooter>
                            {isUpdatePage ?
                                <Button color="primary" type="button" onClick={this.submitForm}>Submit</Button> :
                                <Button color="primary" type="button" onClick={this.backBtn}>Back</Button>}
                        </PageFooter>
                    </>
                }
            </>
        );
    }
}

export default withLoader(withRouter(Form));


