import React from 'react';
import { Label, Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, ADDRESS, OPTIONAL_ADDRESS_TYPE, DEFAULT_HOME_COUNTRY, getAddressTypeOptions, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SMARTForm, Text, toastSuccess, toastError, Radio, SelectList, Plaintext, DateInput, withSMARTWrapper } from '../../Shared/Forms';
import { getCountries, getCountryCodes } from '../../Shared/SmartMe/Utils/FormUtils';
import { isValidForm, resetAddressDetails } from '../../Shared/SmartMe/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import PageFooter from '../../Shared/PageFooter';
import { ModalConfirm } from '../../Shared/Modal';
import * as ValidationUtils from '../../Shared/SmartMe/Utils/Validation/ValidationUtils';
import { navigateTo } from '../../Shared/Actions';
import { isEmptyValue } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const OrgTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const invalidMsg = ErrorMsgList.INVALID;
    const invalidEffectiveToDate =
        getTranslationValue("'Effective From' must be on or before 'Effective To' date",
            getStaticText, OrgTranslationKeys);

    return Yup.object().shape({
        AppId: Yup.string().required(requiredMsg),
        AbbreviatedName: Yup.string().required(requiredMsg),
        FullName: Yup.string().required(requiredMsg),
        Email: Yup.string().email(invalidMsg),
        EffectiveFromDate: Yup.string()
            .test('EffectiveFromDateRequired', requiredMsg,
                function () {
                    const EffectiveFromDate = this.resolve(Yup.ref("EffectiveFromDate"));
                    return !isEmptyValue(EffectiveFromDate);
                }),
        EffectiveToDate: Yup.string()
            .test('IsEffectiveToDateValid', invalidEffectiveToDate,
                function () {
                    const EffectiveFromDate = this.resolve(Yup.ref("EffectiveFromDate"));
                    const EffectiveToDate = this.resolve(Yup.ref("EffectiveToDate"));
                    const EffectiveToDateEmpty = isEmptyValue(EffectiveToDate);

                    return EffectiveToDateEmpty ||
                        (new Date(EffectiveFromDate) <= new Date(EffectiveToDate));
                }),
        OrganisationDetails: Yup.object().shape({
            ContactNumber: ValidationUtils.ContactNumberValidation(getStaticText),
            FaxNumber: ValidationUtils.FaxNumberValidation(getStaticText),
            Address: ValidationUtils.AddressValidation(getStaticText)
        })
    })
}

const INPUT_SIZE = 8;

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            server_url: props.server_url,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            prevState: props.prevState,
            countryCodesOptions: []
        };
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();

        this.setState({
            countriesOptions: await getCountries(loader),
            countryCodesOptions: await getCountryCodes(loader)
        }, () => {
            loader.done();
        });
    }

    render() {
        const { modal, getStaticText } = this.props;
        const { countriesOptions, countryCodesOptions } = this.state;

        const translation = {
            getStaticText: getStaticText,
            translationKeys: OrgTranslationKeys
        };

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                    <SMARTForm
                        formValues={this.state.values}
                        validationSchema={FORM_VALIDATIONS(getStaticText)}
                        validateOnSubmit={true}
                        serverURL={this.state.server_url}
                        isDummy={false}
                        submitCallback={({ response }) => {
                            if (response.success && response.body.IsSuccess) {
                                navigateTo('/Organisation'); // return back to grid
                                toastSuccess(response.body.Messages, null, translation);
                            }
                            else {
                                toastError(response.body.Messages, translation)
                            }
                        }
                        }
                    >
                        {({ values, errors, onChange, submitForm, onChangeField }) => (
                            <React.Fragment>
                                <Row className="body-content">
                                    <Col>
                                        {this.state.prevState.AppIds !== "" ?
                                            <SelectList
                                                name="AppId"
                                                value={values.AppId}
                                                options={this.state.prevState.AppIds}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                inputSize={INPUT_SIZE}
                                                error={errors.AppId}
                                                label="Application"
                                                translation={translation}
                                                required
                                            /> :
                                            <Plaintext
                                                label="Application"
                                                name="ApplicationId"
                                                value={values.AppName}
                                                translation={translation}
                                            />
                                        }
                                        <Text
                                            name="AbbreviatedName"
                                            value={values.AbbreviatedName}
                                            placeholder="Organisation (Abbreviated Name)"
                                            onChange={onChange}
                                            inputSize={INPUT_SIZE}
                                            minLength={0}
                                            maxLength={255}
                                            label="Organisation (Abbreviated Name)"
                                            translation={translation}
                                            error={errors.AbbreviatedName}
                                            required
                                        />
                                        <Text
                                            name="FullName"
                                            value={values.FullName}
                                            placeholder="Organisation (Full Name)"
                                            onChange={onChange}
                                            inputSize={INPUT_SIZE}
                                            minLength={0}
                                            maxLength={255}
                                            label="Organisation (Full Name)"
                                            translation={translation}
                                            error={errors.FullName}
                                            required
                                        />
                                        <Text
                                            name="Email"
                                            value={values.Email}
                                            placeholder="Contact Email"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={INPUT_SIZE}
                                            label="Contact Email"
                                            translation={translation}
                                            error={errors.Email}
                                        />
                                        <DateInput
                                            name="EffectiveFromDate"
                                            value={values.EffectiveFromDate}
                                            placeholder="Effective From"
                                            onChangeField={onChangeField}
                                            date={true}
                                            time={false}
                                            min={new Date()}
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Effective From"
                                            translation={translation}
                                            error={errors.EffectiveFromDate}
                                            clearFieldEnabled={false}
                                            disableKeyboardInput={true}
                                            dropUp={true}
                                            required
                                        />
                                        <DateInput
                                            name="EffectiveToDate"
                                            value={values.EffectiveToDate}
                                            placeholder="Effective To"
                                            onChangeField={onChangeField}
                                            date={true}
                                            time={false}
                                            min={new Date()}
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Effective To"
                                            translation={translation}
                                            error={errors.EffectiveToDate}
                                            clearFieldEnabled={true}
                                            disableKeyboardInput={true}
                                            dropUp={true}
                                        />
                                        <Row>
                                            <Label lg="3" className="label-default">
                                                {getTranslationValue("Contact Number", getStaticText, OrgTranslationKeys)}
                                            </Label>
                                            <Col lg="5">
                                                <SelectList
                                                    name="OrganisationDetails.ContactNumber.CountryCode"
                                                    value={values.OrganisationDetails.ContactNumber.CountryCode}
                                                    options={countryCodesOptions}
                                                    onChangeField={onChangeField}
                                                    placeholder="Country Code"
                                                    translation={translation}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    inputSize={FIELD_SIZE.MAX}
                                                    labelSize={FIELD_SIZE.NONE}
                                                    error={errors.OrganisationDetails && errors.OrganisationDetails.ContactNumber && errors.OrganisationDetails.ContactNumber.CountryCode}
                                                />
                                            </Col>
                                            <Col lg="3">
                                                <Text
                                                    name="OrganisationDetails.ContactNumber.Number"
                                                    placeholder="Number"
                                                    translation={translation}
                                                    value={values.OrganisationDetails.ContactNumber.Number}
                                                    onChange={onChange}
                                                    labelSize={FIELD_SIZE.NONE}
                                                    inputSize={FIELD_SIZE.MAX}
                                                    error={errors.OrganisationDetails && errors.OrganisationDetails.ContactNumber && errors.OrganisationDetails.ContactNumber.Number}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label lg="3" className="label-default">
                                                {getTranslationValue("Fax Number", getStaticText, OrgTranslationKeys)}
                                            </Label>
                                            <Col lg="5">
                                                <SelectList
                                                    name="OrganisationDetails.FaxNumber.CountryCode"
                                                    value={values.OrganisationDetails.FaxNumber.CountryCode}
                                                    options={countryCodesOptions}
                                                    onChangeField={onChangeField}
                                                    placeholder="Country Code"
                                                    translation={translation}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    inputSize={FIELD_SIZE.MAX}
                                                    labelSize={FIELD_SIZE.NONE}
                                                    error={errors.OrganisationDetails && errors.OrganisationDetails.FaxNumber && errors.OrganisationDetails.FaxNumber.CountryCode}
                                                />
                                            </Col>
                                            <Col lg="3">
                                                <Text
                                                    name="OrganisationDetails.FaxNumber.Number"
                                                    placeholder="Number"
                                                    translation={translation}
                                                    value={values.OrganisationDetails.FaxNumber.Number}
                                                    onChange={onChange}
                                                    labelSize={FIELD_SIZE.NONE}
                                                    inputSize={FIELD_SIZE.MAX}
                                                    error={errors.OrganisationDetails && errors.OrganisationDetails.FaxNumber && errors.OrganisationDetails.FaxNumber.Number}
                                                />
                                            </Col>
                                        </Row>
                                        <Radio
                                            name="OrganisationDetails.Address.AddressType"
                                            value={values.OrganisationDetails.Address.AddressType}
                                            options={getAddressTypeOptions(OPTIONAL_ADDRESS_TYPE)}
                                            onChange={(e) => {
                                                onChange(e);
                                                resetAddressDetails(values.OrganisationDetails.Address);
                                            }}
                                            labelSize={FIELD_SIZE.SMALL}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label="Address Type"
                                            translation={translation}
                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.AddressType}
                                            required
                                        />
                                        {values.OrganisationDetails.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                            <>
                                                <Text
                                                    name="OrganisationDetails.Address.PostalCode"
                                                    value={values.OrganisationDetails.Address.PostalCode}
                                                    onChange={onChange}
                                                    labelSize={FIELD_SIZE.SMALL}
                                                    inputSize={FIELD_SIZE.SMALL}
                                                    placeholder="ZIP / Postal Code"
                                                    label="ZIP / Postal Code"
                                                    translation={translation}
                                                    error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.PostalCode}
                                                    required
                                                />
                                                {values.OrganisationDetails.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                    <>
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine1.BlockNo"
                                                            value={values.OrganisationDetails.Address.AddressLine1.BlockNo}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.SMALL}
                                                            placeholder="Block / House No."
                                                            label="Block / House No."
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.AddressLine1 && errors.OrganisationDetails.Address.AddressLine1.BlockNo}
                                                            required
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine1.StreetName"
                                                            value={values.OrganisationDetails.Address.AddressLine1.StreetName}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                            placeholder="Street Name"
                                                            label="Street Name"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.AddressLine1 && errors.OrganisationDetails.Address.AddressLine1.StreetName}
                                                            required
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine2.LevelNo"
                                                            value={values.OrganisationDetails.Address.AddressLine2.LevelNo}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.XSMALL}
                                                            placeholder="Level"
                                                            label="Level"
                                                            translation={translation}
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine2.UnitNo"
                                                            value={values.OrganisationDetails.Address.AddressLine2.UnitNo}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.XSMALL}
                                                            placeholder="Unit No."
                                                            label="Unit No."
                                                            translation={translation}
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine2.BuildingName"
                                                            value={values.OrganisationDetails.Address.AddressLine2.BuildingName}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                            placeholder="Building Name"
                                                            label="Building Name"
                                                            translation={translation}
                                                        />
                                                    </>
                                                }
                                                {values.OrganisationDetails.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS && countriesOptions &&
                                                    <>
                                                        <SelectList
                                                            name="OrganisationDetails.Address.Country"
                                                            value={values.OrganisationDetails.Address.Country}
                                                            options={countriesOptions.filter((country) => {
                                                                return country.value !== DEFAULT_HOME_COUNTRY
                                                            })}
                                                            onChangeField={onChangeField}
                                                            placeholder="Country"
                                                            isMulti={false}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            label="Country"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.Country}
                                                            required
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.City"
                                                            value={values.OrganisationDetails.Address.City}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.SMALL}
                                                            placeholder="City"
                                                            label="City"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.City}
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.State"
                                                            value={values.OrganisationDetails.Address.State}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.SMALL}
                                                            placeholder="State / Province / Region"
                                                            label="State / Province / Region"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.State}
                                                            required
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine1.AddressLine1"
                                                            value={values.OrganisationDetails.Address.AddressLine1.AddressLine1}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            placeholder="Address Line 1"
                                                            // helpLabel="Address Line 1"
                                                            label="Address Line 1"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.AddressLine1 && errors.OrganisationDetails.Address.AddressLine1.AddressLine1}
                                                            required
                                                        />
                                                        <Text
                                                            name="OrganisationDetails.Address.AddressLine2.AddressLine2"
                                                            value={values.OrganisationDetails.Address.AddressLine2.AddressLine2}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            placeholder="Address Line 2"
                                                            // helpLabel="Address Line 2"
                                                            label="Address Line 2"
                                                            translation={translation}
                                                            error={errors.OrganisationDetails && errors.OrganisationDetails.Address && errors.OrganisationDetails.Address.AddressLine2 && errors.OrganisationDetails.Address.AddressLine2.AddressLine2}
                                                            required
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <PageFooter>
                                    <Button color="primary" type="button" onClick={() => isValidForm(FORM_VALIDATIONS(getStaticText), values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                </PageFooter>

                                <ModalConfirm
                                    isOpen={modal.modalState === "Submit"}
                                    contentHeader={this.state.contentHeader}
                                    contentBody={this.state.contentBody}
                                    confirmCallback={() => {
                                        modal.toggleModal("Submit");

                                        // Reset address details
                                        const Address = values.OrganisationDetails.Address;
                                        resetAddressDetails(Address);

                                        submitForm();
                                    }}
                                    cancelCallback={() => modal.toggleModal("Submit")}
                                    translation={translation}
                                    formatter={{ component: getTranslationValue('Organisation', getStaticText, OrgTranslationKeys) }}
                                />
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(Form);