import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import SubSection from '../../Section/SubSection';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { DataTable } from '../../Shared/DataTable';
import { withPageNotFound } from '../../Shared/Error';
import { Plaintext } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import AssignUserToUserGroup from '../../Shared/SmartMe/Components/AssignUserToUserGroup';
import { URL } from '../../Shared/SmartMe/Constants';
import history from '../../Shared/History';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE } from '../../Localization';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UGTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const IS_ORGANISATION_SPECIFIC = {
    YES: "Yes",
    NO: "No"
}

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(URL + "UserGroup/GetViewUserGroup/" + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;

                this.setState({
                    values: data,
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }
        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UGTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="View User Group">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col>
                                    <SubSection orientation="left" translation={translation} title="User Group Details">
                                        <Plaintext
                                            name="AppName"
                                            className="mb-1"
                                            label="Application"
                                            translation={translation}
                                            value={this.state.values.AppName}
                                            labelSize={LabelSize}
                                        />
                                        <Plaintext
                                            name="GroupName"
                                            className="mb-1"
                                            label="Group Name"
                                            translation={translation}
                                            value={this.state.values.GroupName}
                                            labelSize={LabelSize}
                                        />
                                        <Plaintext
                                            name="Description"
                                            className="mb-1"
                                            label="Description"
                                            translation={translation}
                                            value={this.state.values.Description}
                                            labelSize={LabelSize}
                                        />
                                        <Plaintext
                                            name="IsOrganisationSpecific"
                                            className="mb-1"
                                            label="Organisation Specific"
                                            translation={translation}
                                            translateValue={true}
                                            value={this.state.values.IsOrganisationSpecific}
                                            labelSize={LabelSize}
                                        />
                                        {this.state.values.IsOrganisationSpecific === IS_ORGANISATION_SPECIFIC.YES &&
                                            <Plaintext
                                                name="OrganisationName"
                                                className="mb-1"
                                                label="Organisation"
                                                translation={translation}
                                                translateValue={true}
                                                value={this.state.values.OrganisationName}
                                                labelSize={LabelSize}
                                            />
                                        }
                                        <Plaintext
                                            name="IsMeOrganisationAdmin"
                                            className="mb-1"
                                            label="Organisation Admin"
                                            translation={translation}
                                            translateValue={true}
                                            value={this.state.values.IsMeOrganisationAdmin}
                                            labelSize={LabelSize}
                                        />
                                    </SubSection>
                                    <SubSection orientation="left" translation={translation} title="Roles to User Group">
                                        <DataTable
                                            translation={translation}
                                            data={this.state.values.RolesToUserGroup}
                                            pageSize='5'
                                            minFilterChars='2'
                                            rowIdentifier='Id'
                                            noResultsMessage='No roles found'
                                            columns={
                                                {
                                                    RunningNumber: { title: 'No.', width: '3%' },
                                                    IsOrganisationSpecific: { title: 'Organisation Specific', width: '5%', DBkey: 'IsOrganisationSpecific', translateValue: true },
                                                    RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                                                    DisplayRoleName: { title: 'Display Role Name', width: '25%', DBkey: 'DisplayRoleName' },
                                                    Description: { title: 'Description', width: '15%', DBkey: 'Description' }
                                                }
                                            }
                                        />
                                    </SubSection>
                                    <SubSection orientation="left" translation={translation} title="Users to User Group">
                                        <AssignUserToUserGroup
                                            translation={translation}
                                            data={this.state.values.UsersToUserGroup}
                                            isUpdatePage={false}
                                        />
                                    </SubSection>
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default withPageNotFound(withLoader(withRouter(View)));