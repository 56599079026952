import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import _ from 'lodash';
import { withBaseControl } from './BaseControl';


class Checkbox extends React.Component {

    // Fn: Handle changes in checkbox group 
    handleCheckboxChange = (onChangeField, name, valuesArr, value, isChecked) => {

        // If checked, add item into array (non-unique),
        // Otherwise, remove item from array
        let itemsChecked = (isChecked) ?
            ([...valuesArr, value]) :
            (_.without(valuesArr, value));

        // Update changes in Formik handleChange event
        onChangeField(name, itemsChecked);
    };

    // Fn: Return whether checkbox has been checked on retrieval
    isChecked = (valuesArr, value) => {
        return (valuesArr && _.includes(valuesArr, value)) ? true : false;
    };
    
    checkboxLabel = () => {
        return (this.props.labelRequired ? "checkbox-label required": "checkbox-label")
    }

    render() {
        // De-structure props to be used
        const { name, options, onChangeField, value } = this.props;

        // For each option item
        return (options.map((option) => {
            var id = name+option.value
            id = id.replace(/\s/g, '');
            // Render an inline checkbox
            return (
                <FormGroup key={option.value} check inline>

                    <Label className="checkbox-group" check>
                        {/* Hide original Checkbox UI, but all react behavior still happens here */}
                        <Input
                            className="checkbox-control"
                            type="checkbox"
                            id={id}
                            name={name}
                            value={option.value}
                            onChange={(e) => this.handleCheckboxChange(onChangeField, name, value, option.value, e.target.checked)}
                            checked={this.isChecked(value, option.value)}
                        />

                        {/* Re-styled Checkbox UI for cross-browser compatibility */}
                        <Label for={id} className="checkbox-display"></Label>

                        {/* Checkbox Text */}
                        <Label for={id} className={this.checkboxLabel()}>{option.label}</Label>
                    </Label>
                </FormGroup>
            );
        }));
    }
}

// PropTypes: For prop validation
Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    options: PropTypes.array.isRequired,
    onChangeField: PropTypes.func.isRequired
};

// PropTypes: Defaulting value for optional props
Checkbox.defaultProps = {
    value: []
};


export default withBaseControl(Checkbox);

