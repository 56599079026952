export const ErrorTranslationKeys = [
    {
        "DefaultLanguageValue": "Minimum password length must NOT exceed Maximum password length.",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "MinPwdLengthExceedMaxPwdLength"
    },
    {
        "DefaultLanguageValue": "Minimum password length must be at least 3.",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "MinPwdLengthAtLeastThree"
    },
    {
        "DefaultLanguageValue": "Minimum password length must NOT be less than Total minimum character length.",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "MinPwdLengthLessThanTotalMinCharLength"
    },
    {
        "DefaultLanguageValue": "Maximum password length must NOT be more than Total maximum character length.",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "MaxPwdLengthMoreThanTotalMaxCharLength"
    },
    {
        "DefaultLanguageValue": "Min > Max",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "MinPwdLengthMoreThanMaxPwdLength"
    },
    {
        "DefaultLanguageValue": "Required",
        "Scope": "PasswordPolicy",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "RequiredMinMax"
    }
]