import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { selectListMapper }from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert } from '../../Shared//Forms';

const SERVER_URL = URL+'UserGroup/UpdateUserGroupUsers';

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    assignedValues = (data) =>{
        let values = [];
        if(data.length > 0){
            data.map(x => values.push(x.UserId));
        }
        return values;
    }

    async componentDidMount(){
        const { loader } = this.props;
        loader.start();
        let response = await fetchRequest(URL+"UserGroup/GetUserGroupUsers/" + this.props.match.params.id, getParams(), false);
        let selectionResponse = await fetchRequest(URL+"User/GetUsers", getParams(), false);

        
        if(response.success && response.body.IsSuccess && selectionResponse.success && selectionResponse.body.IsSuccess){
            let data = response.body.Data;
            let selection = selectionResponse.body.Data;

            const mappedSelection = selectListMapper(selection, "Id", "Username");
            let assigned = this.assignedValues(data.Users);

            this.setState({
                readOnlyValues:{
                    GroupName: data.GroupName,
                    Description: data.Description || "",
                    Selection: mappedSelection
                },
                values: {
                    UserGroupId : data.UserGroupId,
                    Assigned: assigned,
                },
                isLoaded: true 
            });
        }
        else{
            const { formAlert } = this.props;
            formAlert.showAlert("Something went wrong, please try again later");
        }
        loader.done();
    }
    
    render() {
        return(
            <React.Fragment>
                {this.state.isLoaded ? 
                (<Form values={this.state.values} server_url={SERVER_URL} readOnlyValues={this.state.readOnlyValues} title="Update User Group Users" contentHeader={"Update User Group Users Confirmation"} contentBody={"Confirm update of User Group Users?"}/>) 
                :null}
            </React.Fragment>
        )
    }
}
export default withFormAlert(withLoader(withRouter(Update)));