export const WizardTitleTranslationKeys = [
    {
        "DefaultLanguageValue": "User Group Details",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "UserGroupDetails"
    },
    {
        "DefaultLanguageValue": "Assign Roles to User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "AssignRolesToUserGroup"
    },
    {
        "DefaultLanguageValue": "Assign Users to User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "AssignUsersToUserGroup"
    },
    {
        "DefaultLanguageValue": "Roles to User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "RolesToUserGroup"
    },
    {
        "DefaultLanguageValue": "Users to User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "UsersToUserGroup"
    }
]