export const TooltipTranslationKeys = [
    {
        "DefaultLanguageValue": "View",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Tooltip",
        "TranslationKey": "View"
    },
    {
        "DefaultLanguageValue": "Update",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Tooltip",
        "TranslationKey": "Update"
    },
    {
        "DefaultLanguageValue": "Delete",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Tooltip",
        "TranslationKey": "Delete"
    },
    {
        "DefaultLanguageValue": "Send User Activation Email",
        "Scope": "SMARTMe",
        "Category": "Table",
        "SubCategory": "Tooltip",
        "TranslationKey": "SendUserActivationEmail"
    },
    {
        "DefaultLanguageValue": "Review User Request",
        "Scope": "SMARTMe",
        "Category": "Table",
        "SubCategory": "Tooltip",
        "TranslationKey": "ReviewUserRequest"
    }
]