import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { CreatableSelectList, DateInput, Paragraph, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { GET_APPCONFIG_URL, UPDATE_APPCONFIG_URL, APPCONFIG_LIST } from '../../App/AppSettings';

const FORM_CONTEXT = "Configuration";
const NEXT_URL = APPCONFIG_LIST;
const RETRIEVE_URL = GET_APPCONFIG_URL;
const SERVER_URL = UPDATE_APPCONFIG_URL;

const CURRENCY = "Currency";
const DATE = "Date";
const DECIMAL = "Decimal";
const EMAIL = "Email";
const NUMBER = "Number";

const FORM_INITIAL_VALUES = {
    AppConfigCategory: '',
    AppConfigName: '',
    AppConfigValueType: '',
    AppConfigValue: '',
    AppConfigDescription: '',
    AppConfigEffectiveStartDate: '',
    AppConfigEffectiveEndDate: new Date()
};

class AppConfigMgmtUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            valueTypeHelpLabel: ''
        };
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess } = response.body;
            if (IsSuccess) {
                // Redirect to Listing page
                navigateTo(NEXT_URL);

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_UPDATE, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.APPLICATION_CONFIGURATION);
            }
            else {
                toastError(response.body.Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    async componentDidMount() {

        const { state } = history.location;
        const { loader, pageNotFound } = this.props;

        if (state && state.Category && state.Name) {

            loader.start();
            const response = await fetchRequest(RETRIEVE_URL, getParams({ Category: state.Category, Name: state.Name }), false);
            loader.done();
            const { IsSuccess, Data, Messages } = response.body;
            if (response.success && IsSuccess) {

                this.changeValueTypeHelpText(Data.AppConfigValueType);

                this.setState({ 
                    values: Data, 
                    derivedMinDate: this.getDerivedMinDate(Data) });
            }
            else {
                // Error message to be loaded here
                loader.error(Messages);
            }
        }
        else {
            pageNotFound();
        }
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            AppConfigCategory: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigName: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigValueType: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            AppConfigValue: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigDescription: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigEffectiveStartDate: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID)),
            AppConfigEffectiveEndDate: Yup.string()
                .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID))
        })
    }
    
    // Get derived min. date based on whether current date / effective start-date is earlier
    getDerivedMinDate = (values) => {

        let effectiveDate = new Date(values.AppConfigEffectiveStartDate);
        let currDate = new Date();

        return (effectiveDate > currDate) ? currDate : effectiveDate;
    };

    // Fn: Get help text based on Value Type
    changeValueTypeHelpText = (valueType) => {

        // Initialize help label based on Value Type
        let valueTypeHelpLabel = '';

        // Switch case for value type
        switch (valueType) {
            case CURRENCY: 
                valueTypeHelpLabel = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CURRENCY, DEFAULT_TEXT.HELPTEXT_CURRENCY);
            break; 
            case DATE: 
                valueTypeHelpLabel = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DATE, DEFAULT_TEXT.HELPTEXT_DATE);
            break; 
            case DECIMAL: 
                valueTypeHelpLabel = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DECIMAL, DEFAULT_TEXT.HELPTEXT_DECIMAL);
            break; 
            case EMAIL: 
                valueTypeHelpLabel = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_EMAIL, DEFAULT_TEXT.HELPTEXT_EMAIL);
            break; 
            case NUMBER: 
                valueTypeHelpLabel = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_NUMBER, DEFAULT_TEXT.HELPTEXT_NUMBER);
            break; 
            default:
                valueTypeHelpLabel = '';
        }
        
        // Set Value Type help label
        this.setState({
            valueTypeHelpLabel: valueTypeHelpLabel
        });
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title=
                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_UPDATECONFIGURATION, DEFAULT_TEXT.UPDATE_CONFIGURATION)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={SERVER_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="AppConfigCategory"
                                            value={values.AppConfigCategory}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            error={errors.AppConfigCategory}
                                            readOnly
                                        />
                                        <Text
                                            name="AppConfigName"
                                            value={values.AppConfigName}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_NAME, DEFAULT_TEXT.NAME)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                            error={errors.AppConfigName}
                                            readOnly
                                        />
                                        <CreatableSelectList
                                            isClearable={true}
                                            name="AppConfigValueType"
                                            value={values.AppConfigValueType}
                                            minLength={0}
                                            maxLength={140}
                                            options={[
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_CURRENCY, "Currency"), value: "Currency" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DATE, "Date"), value: "Date" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DECIMAL, "Decimal"), value: "Decimal" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_EMAIL, "Email"), value: "Email" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_GENERAL, "General"), value: "General" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_NUMBER, "Number"), value: "Number" }]}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_VALUETYPE, DEFAULT_TEXT.HELPTEXT_VALUETYPE)}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUETYPE, DEFAULT_TEXT.PLACEHOLDER_VALUETYPE)}
                                            isMulti={false}
                                            onChangeField={(name, value) => { this.changeValueTypeHelpText(value); onChangeField(name, value);  }}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUETYPE, DEFAULT_TEXT.VALUE_TYPE)}
                                            error={errors.AppConfigValueType}
                                            required
                                        />
                                        <Paragraph
                                            name="AppConfigValue"
                                            value={values.AppConfigValue}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUE, DEFAULT_TEXT.VALUE)}
                                            onChange={onChange}
                                            rows={3}
                                            minLength={0}
                                            helpLabel={this.state.valueTypeHelpLabel}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUE, DEFAULT_TEXT.VALUE)}
                                            error={errors.AppConfigValue}
                                            required
                                        />
                                        <Paragraph
                                            name="AppConfigDescription"
                                            value={values.AppConfigDescription}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            onChange={onChange}
                                            rows={3}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.AppConfigDescription}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DESCRIPTION, DEFAULT_TEXT.HELPTEXT_DESCRIPTION)}
                                            required
                                        />
                                        <DateInput
                                            name="AppConfigEffectiveStartDate"
                                            value={values.AppConfigEffectiveStartDate}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min={this.state.derivedMinDate}
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                            error={errors.AppConfigEffectiveStartDate}
                                            dropUp
                                            required
                                        />
                                        <DateInput
                                            name="AppConfigEffectiveEndDate"
                                            value={values.AppConfigEffectiveEndDate}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min={this.state.derivedMinDate}
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                            error={errors.AppConfigEffectiveEndDate}
                                            dropUp
                                        />
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_SAVECHANGES, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(AppConfigMgmtUpdate);

