import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, CreatableSelectList, DateInput, Paragraph, SelectList, SMARTForm, Text } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { withLoader } from '../../Shared/Loading';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { ADD_APPCONFIG_URL, LIST_APPCONFIG_CATEGORY_URL, APPCONFIG_LIST } from '../../App/AppSettings';

const FORM_CONTEXT = "Application Configuration";
const NEXT_URL = APPCONFIG_LIST;
const SERVER_URL = ADD_APPCONFIG_URL;
const DROPDOWN_CATEGORY_URL = LIST_APPCONFIG_CATEGORY_URL;

const CURRENCY = "Currency";
const DATE = "Date";
const DECIMAL = "Decimal";
const EMAIL = "Email";
const NUMBER = "Number";

const FORM_INITIAL_VALUES = {
    AppConfigCategory: '',
    AppConfigName: '',
    AppConfigValueType: '',
    AppConfigValue: '',
    AppConfigDescription: '',
    AppConfigEffectiveStartDate: moment(new Date()).format(DATE_DISPLAY_FORMAT.DATE),
    AppConfigEffectiveEndDate: ''
};

class AppConfigMgmtCreate extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state && history.location.state[FORM_CONTEXT]) {
            this.state = {
                values: history.location.state[FORM_CONTEXT],
                dropDownArrayCategory: [],
                currValueType: '',
                valueTypeHelpLabel: ''
            };

            history.replace({
                pathname: history.location.pathname,
                state: {}
            });
        }
        else {
            this.state = {
                values: FORM_INITIAL_VALUES,
                dropDownArrayCategory: []
            };
        }
    }

    componentDidMount = async () => {
        this.props.loader.start();

        await this.dropdownList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");

        this.props.loader.done();
    }

    dropdownList = async (url, dropDownName) => {

        const response = await fetchRequest(url, getParams(), false);

        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element, 'value': element });
                });
                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            AppConfigCategory: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigName: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigValueType: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            AppConfigValue: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigDescription: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            AppConfigEffectiveStartDate: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID)),
            AppConfigEffectiveEndDate: Yup.string()
                .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID)),
        });
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess } = response.body;
            if (IsSuccess) {
                // Redirect to Listing page
                navigateTo(NEXT_URL);

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_CREATE, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.APPLICATION_CONFIGURATION);
            }
            else {
                toastError(response.body.Messages);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    getValueTypeHelpText = (valueType) => {
        let valueTypeHelpLabel = '';

         // Switch case for value type
         switch (valueType) {
            case CURRENCY:
                valueTypeHelpLabel = "Currency: $10.00 or 10.00";
                break;
            case DATE:
                valueTypeHelpLabel = "Date: 01-Aug-2020";
                break;
            case DECIMAL:
                valueTypeHelpLabel = "Decimal: 10.0 or 10";
                break;
            case EMAIL:
                valueTypeHelpLabel = "Email: abc@xyz.com";
                break;
            case NUMBER:
                valueTypeHelpLabel = "Number: 1";
                break;
            default:
                valueTypeHelpLabel = "";
        }
        return valueTypeHelpLabel;
    } 

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_NEWCONFIGURATION, DEFAULT_TEXT.NEW_CONFIGURATION)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={SERVER_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                        resetFormAfterSubmit={true}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <CreatableSelectList
                                            isClearable={true}
                                            name="AppConfigCategory"
                                            value={values.AppConfigCategory}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CATEGORY, DEFAULT_TEXT.HELPTEXT_CATEGORY)}
                                            required
                                            options={this.state.dropDownArrayCategory}
                                            onChangeField={onChangeField}
                                            isMulti={false}
                                            error={errors.AppConfigCategory}
                                        />
                                        <Text
                                            name="AppConfigName"
                                            value={values.AppConfigName}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_NAME, DEFAULT_TEXT.NAME)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                            error={errors.AppConfigName}
                                            required
                                        />
                                        <SelectList
                                            name="AppConfigValueType"
                                            value={values.AppConfigValueType}
                                            minLength={0}
                                            maxLength={140}
                                            options={[
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_CURRENCY, DEFAULT_TEXT.CURRENCY), value: "Currency" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DATE, DEFAULT_TEXT.DATE), value: "Date" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DECIMAL, DEFAULT_TEXT.DECIMAL), value: "Decimal" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_EMAIL, DEFAULT_TEXT.EMAIL), value: "Email" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_GENERAL, DEFAULT_TEXT.GENERAL), value: "General" },
                                                { label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_VALUE_NUMBER, DEFAULT_TEXT.NUMBER), value: "Number" }]}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_VALUETYPE, DEFAULT_TEXT.HELPTEXT_VALUETYPE)}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUETYPE, DEFAULT_TEXT.PLACEHOLDER_VALUETYPE)}
                                            isMulti={false}
                                            onChangeField={onChangeField}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUETYPE, DEFAULT_TEXT.VALUE_TYPE)}
                                            error={errors.AppConfigValueType}
                                            required
                                        />
                                        <Paragraph
                                            name="AppConfigValue"
                                            key={values.AppConfigValueType}
                                            value={values.AppConfigValue}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUE, DEFAULT_TEXT.VALUE)}
                                            onChange={onChange}
                                            rows={3}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUE, DEFAULT_TEXT.VALUE)}
                                            helpLabel={this.getValueTypeHelpText(values.AppConfigValueType)}
                                            error={errors.AppConfigValue}
                                            required
                                        />
                                        <Paragraph
                                            name="AppConfigDescription"
                                            value={values.AppConfigDescription}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            onChange={onChange}
                                            rows={3}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.AppConfigDescription}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DESCRIPTION, DEFAULT_TEXT.HELPTEXT_DESCRIPTION)}
                                            required
                                        />
                                        <DateInput
                                            name="AppConfigEffectiveStartDate"
                                            value={values.AppConfigEffectiveStartDate}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min={new Date()} // Get current date
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                            error={errors.AppConfigEffectiveStartDate}
                                            dropUp
                                            required
                                        />
                                        <DateInput
                                            name="AppConfigEffectiveEndDate"
                                            value={values.AppConfigEffectiveEndDate}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min={new Date()} // Get current date
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                            dropUp
                                        />
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(withLoader(AppConfigMgmtCreate));

