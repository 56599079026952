export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "List of User Groups",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfUserGroups"
    },
    {
        "DefaultLanguageValue": "New User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewUserGroup"
    },
    {
        "DefaultLanguageValue": "Update User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdateUserGroup"
    },
    {
        "DefaultLanguageValue": "View User Group",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ViewUserGroup"
    }
]