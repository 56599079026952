import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


class FormAlert extends React.Component {

    AlertMessage = (message) => {

        // If message is array
        if (_.isArray(message)) {  
            return <div className="form-alert-message-item">{message[message.length - 1]}</div>;
        }

        // Message is string/object in html, return as it is.
        else {
            return message;
        }
    };

    render() {
        return (
            <Row>
                <Col>
                    <Collapse isOpen={this.props.visible} className="form-alert-container">
                        <div className="form-alert-content">
                            <div className="form-alert-icon"><FontAwesomeIcon icon={this.props.icon} /></div>
                            <div className="form-alert-message">{this.AlertMessage(this.props.message)}</div>
                        </div>
                    </Collapse>
                </Col>
            </Row>
        );
    }
}

// PropTypes: For prop validation
FormAlert.propTypes = {
    icon: PropTypes.object,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    visible: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
FormAlert.defaultProps = {
    icon: faExclamationTriangle,
    message: "",
    visible: false
};

// HoC: To use FormAlert as a Wrapper
export const withFormAlert = (WrappedComponent) => {

    class FormAlertWrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                visible: false,
                message: ""
            };
        }

        // Fn: Displays form alert based on given response message
        showFormAlert = (message, component) => {
            if (component) {
                message = _.template(message)(component);
            }

            // Scroll to top of page
            window.scrollTo(0, 0);

            // Set Message into FormAlert & show FormAlert
            this.setState({
                visible: true,
                message
            });
        };

        // Fn: Displays form alert based on given response message
        hideFormAlert = () => {

            // Reset Message in FormAlert & hide FormAlert
            this.setState({
                visible: false,
                message: ""
            });
        };

        render() {
            const formAlertProps = {
                formAlert: {
                    showAlert: this.showFormAlert,
                    hideAlert: this.hideFormAlert,
                }
            };

            return (
                <React.Fragment>
                    <FormAlert
                        visible={this.state.visible}
                        message={this.state.message}
                    />

                    <WrappedComponent
                        {...this.props}
                        {...formAlertProps}
                    />
                </React.Fragment>
            );
        }
    }

    return FormAlertWrapper;
};

export default FormAlert;