import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, postParams } from '../../Shared/Actions';
import { toastError, toastSuccess, withSMARTWrapper } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';


const GRID_ENDPOINT = URL + "User/ListNonActiveUsers";
const VIEW_USER_URL_PAGE = "/User/ViewNonActiveUser";
const RESEND_ACTIVATION_EMAIL_URL = URL + "User/ResendAccountActivationEmail/";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleUpdate: false
        };
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;

        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_USER_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                <MobileCardActionButton
                    key={'Email' + Id}
                    onClick={() => this.SendActivationEmail(Id)}
                    icon={faMailBulk}
                    label={BUTTON_TITLE(getStaticText).SEND_USER_ACTIVATION_EMAIL}
                />
            </React.Fragment>
        )
    }

    //Fn: send activation email
    async SendActivationEmail(Id) {
        const { loader } = this.props;

        if (Id) {
            // Start loading
            loader.start();

            // Send request to backend to delete Row
            const response = await fetchRequest(RESEND_ACTIVATION_EMAIL_URL + Id, postParams(), false);

            //Done loading
            loader.done();

            const { IsSuccess, Messages } = response.body;

            // Based on response, show appropriate toaster

            if ((response.success && IsSuccess)) {
                toastSuccess("Email successfully sent.")
                this.setState({ toggleUpdate: true });
            }
            else {
                toastError(Messages);
            }
        }
    }


    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of Non-Active Users" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ServerSideDataTable
                                translation={translation}
                                toggleUpdate={this.state.toggleUpdate}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No users found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        Username: { title: 'Username', width: '10%', DBkey: 'Username' },
                                        Email: { title: 'Email', width: '10%', DBkey: 'Email' },
                                        FirstName: { title: 'First Name', width: '8%', DBkey: 'FirstName' },
                                        MiddleName: { title: 'Middle Name', width: '8%', DBkey: 'MiddleName' },
                                        LastName: { title: 'Last Name', width: '10%', DBkey: 'LastName' },
                                        UserType: { title: 'User Type', width: '10%', DBkey: 'UserType', translateValue: true },
                                        UserStatus: { title: 'Status', width: '10%', DBkey: 'UserStatus', translateValue: true },
                                        LastUpdatedDate: { title: 'Last Updated', width: '15%', DBkey: 'LastUpdatedDate' }
                                    }
                                }
                                renderActions={(rowData) => (

                                    <React.Fragment>
                                        <ActionButton key={'View' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).VIEW} color="forward" onClick={() => navigateTo(VIEW_USER_URL_PAGE, { ID: rowData.ID })}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                        {rowData.RowData.IsPendingActivation && <ActionButton key={'Email' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).SEND_USER_ACTIVATION_EMAIL} color="neutral" onClick={() => this.SendActivationEmail(rowData.ID)}><FontAwesomeIcon icon={faMailBulk} /></ActionButton>}
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);