import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Fade, UncontrolledDropdown } from 'reactstrap';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';
import { CHANGE_PASSWORD_URL, CHANGE_SECURITY_INFO_URL, UPDATE_LANGUAGE_URL, SET_REFERRER_URL, LOGIN_STATUS, LANGUAGE_APPLICATION_NAME } from '../App/AppSettings';
import { withSMARTWrapper } from '../Shared/Forms';
import { fetchRequest, postParams } from '../Shared/Actions';
import { convertToFormData } from '../Shared/Forms/FormUtils';
import { redirect } from '../Shared/SmartMe/Actions';
import { CLIENT_URL } from '../Shared/SmartMe/Constants';

// Translation Keys
import { NavbarTranslationKeys } from './NavbarTranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../Shared/Forms/FormUtils';

const App = LANGUAGE_APPLICATION_NAME;
const TranslationKeys = getTranslationKeys(App, NavbarTranslationKeys);

// Const: Title of Dropdown titles resided in NavHeader
const NAVHEADER_DROPDOWN_TITLE = {
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_SECURITY_INFO: 'Change Security Info',
  LANGUAGE: 'Language',
  LOGOUT: 'Log Out'
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    const { supportedLangResponse } = this.props.context;
    const langArray = supportedLangResponse ? supportedLangResponse.body.Data : [];

    this.state = {
      isActive: false,
      languages: langArray
    };
  }

  componentDidMount() {
    var logoutBtn = document.getElementById("logout");
    if (localStorage.getItem(LOGIN_STATUS) === "false" && logoutBtn !== null) {
      logoutBtn.click();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.context.supportedLangResponse !== this.props.context.supportedLangResponse) {
      const { supportedLangResponse } = this.props.context;
      this.setState({
        languages: supportedLangResponse.body.Data
      });
    }
  }

  // Fn: Toggle between active state
  toggleActiveState = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  toggleProfileDisplay = () => {
    if (this.props.profileDisplay) {
      return "d-block"
    }
    else {
      return "d-none"
    }
  }

  getTranslationText = (text) => {
    const { getStaticText } = this.props;
    return getTranslationValue(text, getStaticText, TranslationKeys);
  }

  renderProfileLanguages = (isAuthenticated) => {
    const { i18n } = this.props;
    const { changeLanguage } = this.props.context;
    const currLang = i18n.language;

    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret className="language-nav">
          {this.getTranslationText(NAVHEADER_DROPDOWN_TITLE.LANGUAGE)}
        </DropdownToggle>
        <DropdownMenu>
          {
            this.state.languages.map((lang, i) => {
              const language = lang.LanguageCodeValue;
              return (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    i18n.changeLanguage(language, () => {
                      changeLanguage(language);

                      if (isAuthenticated) {
                        const langParam = convertToFormData({ langCode: language });
                        fetchRequest(UPDATE_LANGUAGE_URL, postParams(langParam), false);
                      }
                    });
                  }}
                  active={currLang === language}
                >
                  <bdi>{`${lang.Description} (${language})`}</bdi>
                </DropdownItem>
              )
            })
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  setReferrerURL = () => {
    const referrerURLParam = convertToFormData({ referrerURL: CLIENT_URL });
    fetchRequest(SET_REFERRER_URL, postParams(referrerURLParam), false);
  }

  render() {
    return (
      <AuthConsumer>
        {({ isAuthenticated, profile, isSecurityQuestionsPresent, logout }) => (((!isAuthenticated) && (
          <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
            {this.renderProfileLanguages(false)}
          </Col>
        )) || ((profile && isAuthenticated) && (
          <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
            <Dropdown isOpen={this.state.isActive} toggle={this.toggleActiveState} className="profile-dropdown">
              <DropdownToggle tag="div" data-toggle="dropdown" data-boundary="viewport" aria-expanded="false">
                <div className="btn align-self-center" >
                  <i className="fa-2x align-middle"><FontAwesomeIcon icon={faUserCircle} /></i>
                  <span className="align-middle profile-text dropdown-toggle p-1">{profile.given_name}</span>
                </div>
              </DropdownToggle>
              <Fade in={this.state.isActive} timeout={100}>
                <DropdownMenu right className="navbar-header-dropdown">
                  <div onClick={(e) => { this.setReferrerURL(); e.preventDefault(); redirect(CHANGE_PASSWORD_URL) }}>
                    <DropdownItem>{this.getTranslationText(NAVHEADER_DROPDOWN_TITLE.CHANGE_PASSWORD)}</DropdownItem>
                  </div>
                  {isSecurityQuestionsPresent &&
                    <React.Fragment>
                      <DropdownItem divider />
                      <div onClick={(e) => { this.setReferrerURL(); e.preventDefault(); redirect(CHANGE_SECURITY_INFO_URL) }}>
                        <DropdownItem>{this.getTranslationText(NAVHEADER_DROPDOWN_TITLE.CHANGE_SECURITY_INFO)}</DropdownItem>
                      </div>
                    </React.Fragment>
                  }
                  <DropdownItem divider />
                  {this.renderProfileLanguages(true)}
                  <DropdownItem divider />
                  <DropdownItem>
                    <div onClick={logout}>
                      {this.getTranslationText(NAVHEADER_DROPDOWN_TITLE.LOGOUT)}
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Fade>
            </Dropdown>
          </Col>
        )))}
      </AuthConsumer>
    );
  }
}

export default withSMARTWrapper(Profile);