export const ModalTranslationKeys = [
    {
        "DefaultLanguageValue": "Save Password Policy Confirmation",
        "Scope": "PasswordPolicy",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "CfmSavePasswordPolicyHeader"
    },
    {
        "DefaultLanguageValue": "Are you sure to save the password policy? By doing so, the previous password policy will be discarded.",
        "Scope": "PasswordPolicy",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "CfmSavePasswordPolicyBody"
    }
]