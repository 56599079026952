import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';
import { withFormAlert } from '../../Shared//Forms';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'UserGroup/UpdateUserGroup';
const GET_USER_GROUP_URL = URL + "UserGroup/GetUserGroup/";

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            values: {
                Id: '',
                GroupName: '',
                Description: ''
            },
            AppIds: ''
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();
        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(GET_USER_GROUP_URL + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;
                data.ExistingRoles = data.Roles;
                data.Roles = new Map();
                this.setState({
                    values: data,
                    isLoaded: true
                });
            }
            else {
                //pageNotFound();
                const { formAlert } = this.props;
                formAlert.showAlert(response.body.messages || response.body.Messages);
            }
        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <>
                {this.state.isLoaded &&
                    <Form
                        values={this.state.values}
                        server_url={SERVER_URL}
                        title={"Update User Group"}
                        contentHeader={MODAL_TITLE(getStaticText).UPDATE.HEADER}
                        contentBody={"Confirm update of User Group with the following following roles?"}
                        prevState={this.state}
                        action="Update"
                        loader={this.props.loader}
                    />
                }
            </>
        )
    }
}
export default withPageNotFound(withFormAlert(withLoader(withRouter(Update))));