import { ADDRESS, ADDRESS_TYPE, OPTIONAL_ADDRESS_TYPE, getAddressTypeOptions as ADDRESS_TYPE_OPTIONS } from '../../../../App/AppSettings';
import { getTranslationOptions } from '../../../Forms/FormUtils';

export const getLabel = (defaultLabelValue, fieldName, validationConfig, getStaticText) => {
    const validation = validationConfig.Fields[fieldName];
    let label = "";
    if (validation !== undefined && getStaticText !== undefined) {
        label = getStaticText(validation.DisplayKey, defaultLabelValue);
    }
    return label === "" ? defaultLabelValue : label;
}

export const getPlaceholder = (defaultLabelValue, fieldName, validationConfig, getStaticText) => {
    return getLabel(defaultLabelValue, fieldName, validationConfig, getStaticText);
}

export const getFieldRequired = (defaultFieldRequired, fieldName, validationConfig) => {
    const validation = validationConfig.Fields[fieldName];
    const required = validation === undefined ? defaultFieldRequired : validation.Required;

    return required;
}

export const getMinValue = (defaultLength, fieldName, validationConfig) => {
    const validation = validationConfig.Fields[fieldName];
    const minLength = validation === undefined ? defaultLength : validation.MinimumValue;

    return minLength;
}

export const getMaxValue = (defaultLength, fieldName, validationConfig) => {
    const validation = validationConfig.Fields[fieldName];
    const maxLength = validation === undefined ? defaultLength : validation.MaximumValue;

    return maxLength;
}

export const getAddressTypeOptions = (defaultFieldRequired, fieldName, validationConfig, values, getStaticText, translations) => {
    const validation = validationConfig.Fields[fieldName];
    const isAddressRequired = validation === undefined ? defaultFieldRequired : validation.Required;

    const addressTypeOptions = isAddressRequired ? ADDRESS_TYPE : OPTIONAL_ADDRESS_TYPE;

    // default address type to local
    if (isAddressRequired && values.Profile.Address.AddressType === OPTIONAL_ADDRESS_TYPE.NONE) {
        values.Profile.Address.AddressType = ADDRESS.ADDRESS_TYPE.LOCAL;
    }

    let addressTypes = ADDRESS_TYPE_OPTIONS(addressTypeOptions);
    if (getStaticText && translations) {
        addressTypes = getTranslationOptions(addressTypes, getStaticText, translations);
    }
    return addressTypes;
}