export const ErrorTranslationKeys = [
    {
        "DefaultLanguageValue": "Draft has not been saved. Please try again.",
        "Scope": "DefaultStatic",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "UnsavedDraftApplication"
    },
    {
        "DefaultLanguageValue": "Application has not been processed successfully.",
        "Scope": "DefaultStatic",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "UnsuccessfulApplication"
    },
    {
        "DefaultLanguageValue": "Server has encountered an unexpected error. Please try again later.",
        "Scope": "SMARTMe",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "ServerUnreachable"
    },
    {
        "DefaultLanguageValue": "<%= label %> is required.",
        "Scope": "DefaultStatic",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "Required"
    },
    {
        "DefaultLanguageValue": "<%= label %> is invalid.",
        "Scope": "DefaultStatic",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "Invalid"
    },
    {
        "DefaultLanguageValue": "Effective End Date should not be earlier than Effective Start Date",
        "Scope": "DefaultStatic",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "EEDShouldNotBeEarlierThanESD"
    }
]