import { fetchRequest, getParams } from '../../../Actions';
import { toastError } from '../../../Forms';
import { URL } from '../../../SmartMe/Constants';
import { selectListMapper } from '../../../SmartMe/Actions';

const GET_GLOBAL_USER_GROUPS_BY_APP_ID =
    URL + 'UserGroup/GetGlobalUserGroupsByAppId?';

export const getUserGroupsByAppId = async (appId) => {
    let param = { appId: appId };
    let response = await fetchRequest(GET_GLOBAL_USER_GROUPS_BY_APP_ID, getParams(param), false);
    let userGroups = { userGroups: [], userGroupsData: [] };

    const { IsSuccess, Data, Messages } = response.body;

    if (response.success && IsSuccess) {
        return { userGroups: selectListMapper(Data, "Id", "GroupName"), userGroupsData: Data };
    }
    else {
        toastError(Messages);
    }

    return userGroups;
}