export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "List of Permissions",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfPermissions"
    },
    {
        "DefaultLanguageValue": "New Permission",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewPermission"
    },
    {
        "DefaultLanguageValue": "Update Permission",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdatePermission"
    },
    {
        "DefaultLanguageValue": "Permission Details",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "PermissionDetails"
    }
]