export const WizardTitleTranslationKeys = [
    {
        "DefaultLanguageValue": "User Information",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "UserInformation"
    },
    {
        "DefaultLanguageValue": "Assign Global User Groups",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "AssignGlobalUGs"
    },
    {
        "DefaultLanguageValue": "Assign User Groups",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "WizardTitle",
        "TranslationKey": "AssignUGs"
    }
]