import React from 'react';
import { withRouter } from 'react-router-dom';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert } from '../../Shared/Forms';
import Form from './Form';

const SERVER_URL = URL + 'User/UpdateUserRoles';

class Update extends React.Component {
    render() {
        return (
            <Form data={this.state} server_url={SERVER_URL} isUpdatePage={true} />
        )
    }
}
export default withFormAlert(withLoader(withRouter(Update)));