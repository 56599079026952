import React from 'react';
import _ from 'lodash';
import Alert from '../../Shared/SAlert/SAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getTranslationValue } from '../Forms/FormUtils';

export const toastInfo = (message) => {
    Alert.closeAll();
    Alert.info(info(message), {
        position: 'top',
        timeout: null
    });
}

export const toastSuccess = (message, component, translation) => {
	
    let toastOptions = {
        position: 'top',
        timeout: null
    };

    Alert.closeAll();

    if (translation) {
        const { getStaticText, translationKeys } = translation;
        message = getTranslationValue(message, getStaticText, translationKeys);
    }

    // Case: Component name given
    if (component) {
        // Fill in the name into template
        Alert.success(success(_.template(message)({ component })), toastOptions);
    }

    // Case: Component name not given
    else {
        Alert.success(success(message), toastOptions);
    }
}

export const toastError = (message, translation, component) => {
    if (translation) {
        const { getStaticText, translationKeys } = translation;
        message = getTranslationValue(message, getStaticText, translationKeys);
    }

    message = component === undefined ? message : _.template(message)(component);

    Alert.closeAll();
    Alert.error(error(message), {
        position: 'top',
        timeout: null
    });
}

const info = (message) => {
    return (
        <React.Fragment>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" className="toaster-icon" />
            {message}
        </React.Fragment>
    );
}

const success = (message) => {
    return (
        <React.Fragment>
            <FontAwesomeIcon icon={faCheck} size="lg" className="toaster-icon" />
            {message}
        </React.Fragment>
    );
}

const error = (message) => {
    return (
        <React.Fragment>
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="toaster-icon" />
            {message}
        </React.Fragment>
    );
}


class Toaster extends React.Component {

    render() {
        return <Alert stack={this.props.isStack} />;
    }
}


export default Toaster;