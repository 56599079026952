import React from 'react';
import _ from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import PageFooter from '../../Shared/PageFooter';
import { DataTable } from '../../Shared/DataTable';
import { URL } from '../../Shared/SmartMe/Constants';
import { LAYOUT_TYPE, IS_DEMO } from '../../App/AppSettings';
import Layout from '../../Layout';
import { Plaintext } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster';
import { withRouter } from 'react-router-dom';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { convertToFormData } from '../../Shared/Forms/FormUtils';
import { fetchRequest, postParams } from '../../Shared/Actions';
import { AssignRolesToUserModal } from '../../Shared/SmartMe/Modal';

const DELETE_MODAL = {
    hasModal: true,
    modalHeader: "Delete User Group Role",
    modalContent: "Are you sure you want to delete User Group Role?"
};

const SERVER_URL = URL + 'UserGroup/UpdateUserGroupRoles';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: props.data.roleToUserGroup || "",
            readOnly: props.data.readOnly,
            initialValues: { effectiveStartDate: "", effectiveEndDate: "", role: [] },
            deleteItemArray: "",
            isOpen: false,
            isUpdatePage: props.data.isUpdatePage,
        }
        this.submitForm = this.submitForm.bind(this)
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    submitForm = async() => {
        const { loader } = this.props;
        loader.start();

        let addArray = [];
        if (typeof (this.state.data) !== 'undefined') {
            this.state.data.map((x) => {
                if (!x["Existing"]) {
                    var obj = {
                        MeRoleId: x["Id"], EffectiveStartDate: x["EffectiveStartDate"], EffectiveEndDate: x["EffectiveEndDate"]
                    }
                    addArray.push(obj);
                }
                return null;
            })
        }

        let deleteArray = [];
        if (this.state.deleteItemArray.length > 0) {
            this.state.deleteItemArray.map(x => {
                deleteArray.push(x["MeRoleToUserGroupID"])
                return null;
            })
        }

        const obj = { AddItems: addArray, DeleteItems: deleteArray, Id: this.state.readOnly.id }
        const response = await fetchRequest(SERVER_URL, postParams(convertToFormData(obj)), IS_DEMO);
        const { IsSuccess, Messages } = response.body;

        loader.done();
        
        // Return response when result is successful
        if (response.success && IsSuccess) {
            const currentURL = history.location.pathname
            history.push("/empty")
            history.push(currentURL)
            toastSuccess(Messages)
        }
        else {
            toastError(Messages)
        }        
    }

    deleteItem = (rowIndex) => {
        var array = [...this.state.data];
        var item = array[rowIndex];

        if (item.Existing === true) {
            array.splice(rowIndex, 1);
            this.setState({
                deleteItemArray: [...this.state.deleteItemArray, item],
                data: array
            })
        }
        else {
            array.splice(rowIndex, 1);
            this.setState({ data: array })
        }
    }

    backBtn = () => {
        history.goBack()
    }

    addItem = (values) => {
        let displayRoleName = "";
        let id = "";
        let roleName = "";

        //map the values so that the grid can display them
        this.state.readOnly.allRoles.map(x => {
            if (values.role === x["Id"]) {
                displayRoleName = x["DisplayRoleName"]
                id = x["Id"]
                roleName = x["RoleName"]
            }
            return null;
        })

        var item = { Id: id, RoleName: roleName, DisplayRoleName: displayRoleName, MeRoleId: values.role[0], EffectiveStartDate: values.effectiveStartDate, EffectiveEndDate: values.effectiveEndDate, Existing: false }

        //update grid data
        this.setState({
            data: [...this.state.data, item],
            modal: !this.state.modal,
        });
    }

    getTableDefinition = () => {
        if (this.state.isUpdatePage) {
            return {
                RunningNumber: { title: 'No.', width: '5%' },
                Actions: { title: 'Actions', width: '5%' },
                RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                DisplayRoleName: { title: 'Display Role Name', width: '15%', DBkey: 'DisplayRoleName' },
                EffectiveStartDate: { title: 'Effective Start Date', width: '20%', DBkey: 'EffectiveStartDate' },
                EffectiveEndDate: { title: 'Effective End Date', width: '15%', DBkey: 'EffectiveEndDate' }
            }
        }
        else {
            return {
                RunningNumber: { title: 'No.', width: '5%' },
                RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                DisplayRoleName: { title: 'Display Role Name', width: '15%', DBkey: 'DisplayRoleName' },
                EffectiveStartDate: { title: 'Effective Start Date', width: '20%', DBkey: 'EffectiveStartDate' },
                EffectiveEndDate: { title: 'Effective End Date', width: '15%', DBkey: 'EffectiveEndDate' }
            }

        }
    }

    renderMobileCardActions = (data) => {
        //Set unique id for data
        if(data["Key"] === undefined) {
            data["Key"] = parseInt(_.uniqueId());
        }

        //Find row index in the array
        let array = this.state.data;
        let rowIndex = _.findIndex(array, {'Key': data["Key"]});

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'Delete' + rowIndex}
                    onClick={() => this.deleteItem(rowIndex)}
                    modal={DELETE_MODAL}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }


    render() {
        let tableDef = this.getTableDefinition();
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="Update User Group Roles">
                    <Row className="body-content">
                        <Col>
                            <Plaintext
                                label="Group Name"
                                name="GroupName"
                                value={this.state.readOnly.GroupName}
                            />

                            <Plaintext
                                label="Description"
                                name="Description"
                                value={this.state.readOnly.Description}
                            />

                            <Col md={12}>
                                {this.state.isUpdatePage ?
                                    <Button color="primary" className="float-right" type="button" onClick={this.toggle}>Add</Button> :
                                    null
                                }

                            </Col>
                            <br />
                            <br />

                            <Col md={12}>
                                <DataTable data={this.state.data}
                                    pageSize='5'
                                    minFilterChars='2'
                                    noResultsMessage='No user group roles found'
                                    columns={
                                        tableDef
                                    }
                                    rowIdentifier='MeRoleToUserGroupID'
                                    renderActions={(rowData) =>
                                        (
                                            <React.Fragment>
                                                {
                                                    this.state.isUpdatePage ?
                                                        <ActionButton key={'Delete' + rowData.RowNumber} tooltip="Delete" color="danger" onClick={() => this.deleteItem(rowData.RowNumber-1)} modal={DELETE_MODAL}><FontAwesomeIcon icon={faTrash} /></ActionButton>
                                                        : null
                                                }

                                            </React.Fragment>
                                        )
                                    }
                                    renderMobileCardActions={this.renderMobileCardActions}
                                />
                            </Col>
                        </Col>
                    </Row>

                </Layout>

                <AssignRolesToUserModal
                    toggle={this.toggle}
                    initialValues={this.state.initialValues}
                    selection={this.state.readOnly.selectable}
                    modalState={this.state.modal}
                    data={this.state.data}
                    addItem={this.addItem}
                    modalTitle="Add new role to group"
                />

                <PageFooter>
                    {this.state.isUpdatePage ?
                        <Button color="primary" type="button" onClick={this.submitForm}>Submit</Button> :
                        <Button color="primary" type="button" onClick={this.backBtn}>Back</Button>}
                </PageFooter>

            </React.Fragment>
        );
    }
}

export default withLoader(withRouter(Form));