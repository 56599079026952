const GET_AUTHORITY_CONFIG = (promptType) => {
    return (
        {
            "authentication_settings": {
                "authority": process.env.REACT_APP_AUTHORITY_URL,
                "client_id": process.env.REACT_APP_CLIENT_ID,
                "redirect_uri": process.env.REACT_APP_AUTHORITY_REDIRECT_URL,
                "response_type": process.env.REACT_APP_CLIENT_RESPONSE_TYPE,
                "scope": process.env.REACT_APP_CLIENT_SCOPE,
                "post_logout_redirect_uri": process.env.REACT_APP_AUTHORITY_POST_LOGOUT_REDIRECT_URL,
                "silent_redirect_uri": process.env.REACT_APP_AUTHORITY_RENEW_URL,
                "automaticSilentRenew": true,
                "revokeAccessTokenOnSignout": true,
                "prompt": promptType
            }
        }
    )
};


// Const: Authentication configuration
export const AUTHORITY_CONFIG = {
    "openid_client_configuration": GET_AUTHORITY_CONFIG(""),        // default
    "openid_client_prompt_configuration": GET_AUTHORITY_CONFIG("login")
};

//ENV urls
export const LOGO_URL = process.env.REACT_APP_LOGO_URL;

// Const: API to SMART Me URL
export const GET_USER_PERMISSION_URL = process.env.REACT_APP_SERVER_URL + 'Permission/PermissionGuids/';

// Constant: Active paths
export const PATH = {
    ORGANISATION: '/Organisation',
    PERMISSION: '/Permission',
    ROLE: '/Role',
    USERGROUP: '/UserGroup',
    USER: {
        MAIN: '/User',
        ACTIVE_USER: '/User/ActiveUsers',
        NON_ACTIVE_USER: '/User/NonActiveUsers'
    },
    USERAPPROVAL: {
        MAIN: '/UserApproval',
        PENDING_USER_REQUESTS: '/UserApproval/PendingUserRequests'
    },
    POLICY: {
        MAIN: '/Policy',
        PASSWORD_POLICY: '/Policy/PasswordPolicy'
    },
    WEBHOOK: '/Webhook',
    SYSTEM_CONFIG: {
        MAIN: '/SystemConfig',
        NOTIFICATION: '/SystemConfig/NotificationTemplate',
        LANGUAGE: '/SystemConfig/language',
        FAQ: '/SystemConfig/faq',
        CODE: {
            MAIN: '/SystemConfig/Codes'
        },
        PUBLICHOLIDAY: {
            MAIN: '/SystemConfig/PublicHoliday'
        },
        WORKINGHOUR: {
            MAIN: '/SystemConfig/WorkingHour'
        }
    }
};

// Constant: Path to permissions
const USER_PERMISSION = ['c2ec5ce2-3e93-4377-9bde-606146929a82'];
const USERAPPROVAL_PERMISSION = ['b3c25360-161a-4f73-9498-e1d8eac5badd'];
const POLICY_PERMISSION = ['d6843942-54da-4510-bf57-a6241f45666c'];

export const PATH_TO_PERMISSIONS = {
    [PATH.ORGANISATION]: ['fa4946da-82d2-4e7c-9755-ea6600432097'],
    [PATH.PERMISSION]: ['c6459fa8-4439-4a16-afc5-3ab3a09d9673'],
    [PATH.ROLE]: ['4569cbb4-4783-46cc-8f37-c374d270b3a2'],
    [PATH.USERGROUP]: ['a3489e0c-54b6-410c-80da-001814aa03c4'],
    [PATH.USER.MAIN]: USER_PERMISSION,
    [PATH.USER.ACTIVE_USER]: USER_PERMISSION,
    [PATH.USER.NON_ACTIVE_USER]: USER_PERMISSION,
    [PATH.USERAPPROVAL.MAIN]: USERAPPROVAL_PERMISSION,
    [PATH.USERAPPROVAL.PENDING_USER_REQUESTS]: USERAPPROVAL_PERMISSION,
    [PATH.POLICY.MAIN]: POLICY_PERMISSION,
    [PATH.POLICY.PASSWORD_POLICY]: POLICY_PERMISSION,
    [PATH.WEBHOOK]: ['c4ccc1a7-1d9b-49bb-b15f-aa05959f2c05']
}

// Set IS_DEMO
export const IS_DEMO = false;

// SERVER_URL
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;
export const CHANGE_PASSWORD_URL = process.env.REACT_APP_AUTHORITY_CLIENT_URL + "Account/ChangePassword";
export const CHANGE_SECURITY_INFO_URL = process.env.REACT_APP_AUTHORITY_CLIENT_URL + "Account/ChangeSecurityInfo";
export const GET_COUNTRIES_URL = process.env.REACT_APP_SERVER_URL + "Code/GetCountries";
export const GET_SALUTATIONS_URL = process.env.REACT_APP_SERVER_URL + "Code/GetSalutations";
export const GET_ID_TYPES_URL = process.env.REACT_APP_SERVER_URL + "Code/GetIdTypes";
export const GET_COUNTRY_CODES_URL = process.env.REACT_APP_SERVER_URL + "Code/GetCountryCodes";
export const GET_FILE_URL = process.env.REACT_APP_SERVER_URL + "SupportingDocuments/File?";

const URL = process.env.REACT_APP_AUTHORITY_URL;
export const LANGUAGE_APPLICATION_NAME = 'DXP_Auth_UI'
export const UPDATE_LANGUAGE_URL = `${URL}/Account/UpdateUserLanguageCode`;
export const GET_LANGUAGE_URL =  `${URL}/Account/GetUserLanguageCode`;
export const SET_REFERRER_URL = `${URL}/Account/SetReferrerURL`;

export const LOGIN_STATUS = "login_status." + process.env.REACT_APP_CLIENT_ID;

// SYSTEM CONFIG
//codetable
export const CODETABLE_DETAILS_JSON = '/data/codetable_details.json';
export const CODETABLE_UPDATE_JSON = '/data/codetable_update_response.json';
export const CODETABLE_CATEGORY_JSON = '/data/codetable_category_list.json';
export const CODETABLE_LIST_JSON = '/data/codetable_list.json';
export const CODETABLE_SEARCH_JSON = '/data/codetable_search_list.json';
export const CODETABLE_STATUS_JSON = '/data/codetable_status_list.json'
export const CODETABLE_CREATE_JSON = '/data/codetable_create_response.json';

//AppConfigMgmt
export const APPCONFIG_DETAILS_JSON = '/data/appconfig_details.json';
export const APPCONFIG_UPDATE_JSON = '/data/appconfig_update_response.json';
export const APPCONFIG_LIST_JSON = '/data/appconfig_list.json';
export const APPCONFIG_SEARCH_JSON = '/data/appconfig_search_list.json';
export const APPCONFIG_CREATE_JSON = '/data/appconfig_create_response.json';
export const APPCONFIG_CATEGORY_JSON = '/data/appconfig_category_list.json';

//PublicHolidayConfig
export const PUBLICHOLIDAY_DETAILS_JSON = '/data/publicholiday_details.json';
export const PUBLICHOLIDAY_UPDATE_JSON = '/data/publicholiday_update_response.json';
export const PUBLICHOLIDAY_LIST_JSON = '/data/publicholiday_list.json';
export const PUBLICHOLIDAY_SEARCH_JSON = '/data/publicholiday_search_list.json';
export const PUBLICHOLIDAY_CREATE_JSON = '/data/publicholiday_create_response.json';
export const PUBLICHOLIDAY_CATEGORY_JSON = '/data/publicholiday_category_list.json';

//WorkingHourConfig
export const WORKINGHOUR_DETAILS_JSON = '/data/workinghour_details.json';
export const WORKINGHOUR_UPDATE_JSON = '/data/workinghour_update_response.json';
export const WORKINGHOUR_LIST_JSON = '/data/workinghour_list.json';
export const WORKINGHOUREXCEP_LIST_JSON = '/data/workinghourexcep_list.json';
export const WORKINGHOUR_SEARCH_JSON = '/data/workinghour_search_list.json';
export const WORKINGHOUR_CREATE_JSON = '/data/workinghour_create_response.json';
export const WORKINGHOUR_CATEGORY_JSON = '/data/workinghour_category_list.json';
export const WORKINGHOUREXCEP_CREATE_JSON = '/data/workinghourex_create_response.json';
export const WORKINGHOUR_DELETE_JSON = '/data/workinghour_delete.json';

//OnlineHelpMgmt
export const ONLINEHELP_LIST_JSON = '/data/onlinehelp_list.json'
export const ONLINEHELP_LIST_BIRTH_JSON = '/data/onlinehelp_list_birth.json'
export const ONLINEHELP_LIST_DEATH_JSON = '/data/onlinehelp_list_death.json'
export const ONLINEHELP_LIST_MARRIAGE_JSON = '/data/onlinehelp_list_marriage.json'
export const ONLINEHELP_CREATE_JSON = '/data/onlinehelp_create.json'
export const ONLINEHELP_UPDATE_JSON = '/data/onlinehelp_update_response.json'
export const ONLINEHELP_DELETE_URL = '/data/onlinehelp_delete.json'

//App Name
export const IS_LOCALE_ENABLED = true;
export const GET_TRANSLATION_URL = `${SERVER_URL}dxpLocale/api/GetTranslation?language={{lng}}`;
export const GET_SUPPORTED_LANGUAGE_URL = `${SERVER_URL}dxpLocale/api/GetSupportedLanguage`;

//DXP Language Translation related const
export const DEFAULT_LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE = '0001-01-01';
export const LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE_KEY = "LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE"
export const LANGUAGE_PACK_CONTENT_KEY = "LANGUAGE_PACK_CONTENT"


//LanguageList
export const LANGUAGE_TRANSLATION_KEY_LIST_JSON = '/data/language_translation_key_list.json'
export const LANGUAGE_APPLICATION_JSON = '/data/language_application_list.json'
export const LANGUAGE_SCOPE_JSON = '/data/language_scope_list.json'
export const LANGUAGE_CATEGORY_JSON = '/data/language_category_list.json'
export const LANGUAGE_SUB_CATEGORY_JSON = '/data/language_subcategory_list.json'
export const LANGUAGE_SORT_BY_JSON = '/data/language_sort_by_list.json'
export const LANGUAGE_JSON = '/data/language_list.json'
export const LANGUAGE_PUBLISH_JSON = '/data/language_publish.json';
export const LANGUAGE_KEY_UPDATE_JSON = '/data/language_key_update.json';
export const LANGUAGE_APP_LANGUAGES_JSON = '/data/language_application_language_list.json'
export const LANGUAGE_SETTINGS_JSON = '/data/language_settings.json'
export const LANGUAGE_CODE_JSON = '/data/language_code_table.json'

//NotificationTemplate
export const GET_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/notification_template_details.json' : `${SERVER_URL}NotificationTemplate/Template/`;
export const UPDATE_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/codetable_update_response.json' : `${SERVER_URL}NotificationTemplate/Template/Edit?`;
export const LIST_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/codetable_list.json' : `${SERVER_URL}NotificationTemplate/Template/List?`;
export const ADD_NOTIFICATION_TEMPLATE_URL = `${SERVER_URL}NotificationTemplate/Template/New?`;
export const LIST_NOTIFICATION_TEMPLATE_CATEGORY_URL = `${SERVER_URL}NotificationTemplate/Category/List?`;
export const LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL = `${SERVER_URL}NotificationTemplate/PlaceHolder/List?`;
export const DELETE_NOTIFICATION_TEMPLATE_URL = `${SERVER_URL}NotificationTemplate/Template/`;

// Page URLs
export const ENQUIRY_URL = '/enquiry';
export const INBOX_URL = '/inbox';
export const LOGIN_REDIRECT_URL = '/dashboard'; // this page needs to be a authentication required page

export const SCOPE_MANAGEMENT_URL = '/SystemConfig/language/scopeManagement';
export const ADD_SCOPE_SYSTEM_URL = '/SystemConfig/language/addScope';
export const VIEW_SCOPE_SYSTEM_URL = '/SystemConfig/language/viewScope';
export const EDIT_SCOPE_SYSTEM_URL = '/SystemConfig/language/editScope';
export const CATEGORY_MANAGEMENT_URL = '/SystemConfig/language/categoryManagement';
export const EDIT_CATEGORY_SYSTEM_URL = '/SystemConfig/language/editCategory';
export const VIEW_CATEGORY_SYSTEM_URL = '/SystemConfig/language/viewCategory';
export const ADD_CATEGORY_SYSTEM_URL = '/SystemConfig/language/addCategory';
export const SUBCATEGORY_MANAGEMENT_URL = '/SystemConfig/language/subcategoryManagement';
export const EDIT_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/editSubCategory';
export const VIEW_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/viewSubCategory';
export const ADD_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/addSubCategory';
export const LANGUAGE_REGISTRATION_EDIT = '/SystemConfig/language/editApplicationLanguages';
export const LANGUAGE_REGISTRATION_LIST = '/SystemConfig/language/languageRegistration';
export const TRANSLATION_MANAGEMENT_UPLOAD_FILE = '/SystemConfig/language/uploadFile/';
export const TRANSLATION_MANAGEMENT_ADD_ENTRY = '/SystemConfig/language/addEntry/';
export const TRANSLATION_MANAGEMENT_UPDATE_ENTRY = '/SystemConfig/language/editEntry';
export const TRANSLATION_MANAGEMENT_LIST = '/SystemConfig/language/list';

// FAQ urls
export const FAQ_MANAGEMENT_URL = '/SystemConfig/faq/faqManagement';
export const ADD_FAQ_SYSTEM_URL = '/SystemConfig/faq/addFaq';
export const EDIT_FAQ_SYSTEM_URL = '/SystemConfig/faq/editFaq';
export const VIEW_FAQ_SETTINGS_SYSTEM_URL = '/SystemConfig/faq/viewFaqSettings';
export const VIEW_FAQ_SYSTEM_URL = '/SystemConfig/faq/viewFaq';
export const VIEW_PUBLISHED_FAQ_SYSTEM_URL = '/SystemConfig/faq/viewPublishedFaq';

// Application Configurations urls
export const APPCONFIG_LIST = '/SystemConfig/ApplicationConfig';
export const APPCONFIG_UPDATE = '/SystemConfig/ApplicationConfig/Update';
export const APPCONFIG_CREATE = '/SystemConfig/ApplicationConfig/Create';
export const APPCONFIG_VIEW = '/SystemConfig/ApplicationConfig/View';

//notification template urls
export const NOTIFICATION_TEMPLATE_EMAIL_LIST = '/SystemConfig/NotificationTemplate/EmailList';
export const NOTIFICATION_TEMPLATE_EMAIL_UPDATE = '/SystemConfig/NotificationTemplate/EmailUpdate';
export const NOTIFICATION_TEMPLATE_EMAIL_CREATE = '/SystemConfig/NotificationTemplate/EmailCreate';

//codes url
export const CODES_LIST = '/SystemConfig/Codes';
export const CODES_UPDATE = '/SystemConfig/Codes/Update';
export const CODES_CREATE = '/SystemConfig/Codes/Create';
export const CODES_VIEW = '/SystemConfig/Codes/View';

export const EXCEL_UPDATE = '/SystemConfig/language/excelUpdate';
export const EXCEL_GET_FILE_URL = `${SERVER_URL}Export?`;

export const GET_SCOPE_LIST_URL = `${SERVER_URL}Locale/Scope/List?`;
export const ADD_SCOPE_URL = `${SERVER_URL}Locale/Scope/New/`;
export const EDIT_SCOPE_URL = `${SERVER_URL}Locale/Scope/Edit/`;
export const GET_CATEGORY_LIST_URL = `${SERVER_URL}Locale/Category/List/`;
export const ADD_CATEGORY_URL = `${SERVER_URL}Locale/Category/New/`;
export const EDIT_CATEGORY_URL = `${SERVER_URL}Locale/Category/Edit/`;
export const GET_SUBCATEGORY_LIST_URL = `${SERVER_URL}Locale/SubCategory/List/`;
export const ADD_SUBCATEGORY_URL = `${SERVER_URL}Locale/SubCategory/New/`;
export const EDIT_SUBCATEGORY_URL = `${SERVER_URL}Locale/SubCategory/Edit/`;

export const APP_REGISTERED_LANGUAGE_URL = `${SERVER_URL}Locale/RegisteredLanguage?`;
export const APPLICATION_LIST_URL = `${SERVER_URL}Application/Application/List`;
export const TRANSLATION_LIST_MANAGEMENT_URL = `${SERVER_URL}Locale/Translation/Manage/List?`;
export const TRANSLATION_MANAGEMENT_URL = `${SERVER_URL}Locale/Translation/Manage?`;
export const TRANSLATION_MANAGEMENT_ADD_URL = `${SERVER_URL}Locale/Translation/Manage/New?`;
export const TRANSLATION_MANAGEMENT_EDIT_URL = `${SERVER_URL}Locale/Translation/Manage/Edit/`;
export const TRANSLATION_MANAGEMENT_PUBLISH_URL = `${SERVER_URL}Locale/Translation/Manage/Publish?`;
export const TRANSLATION_MANAGEMENT_PUBLISH_BY_SCOPE_URL = `${SERVER_URL}Locale/Translation/Manage/PublishByScope?`;
export const TRANSLATION_MANAGEMENT_DELETE_URL = `${SERVER_URL}Locale/Translation/Manage/Delete?`;
export const TRANSLATION_MANAGEMENT_REVERT_URL = `${SERVER_URL}Locale/Translation/Manage/Revert?`;
export const LANGUAGE_REGISTRATION_EDIT_URL = `${SERVER_URL}Locale/LanguageRegistration/Edit`;
export const APP_LANGUAGE_LIST_URL = `${SERVER_URL}Locale/LanguageRegistration/List`;
export const LANGUAGE_CODE_LIST_URL = `${SERVER_URL}Locale/LanguageCode/List`;
export const IMPORT_TRANSLATION_URL = `${SERVER_URL}Locale/Import`;
export const EXPORT_TRANSLATION_URL = `${SERVER_URL}Locale/Export?`;
export const GET_SCOPE_LIST_BY_APP_NAME = `${SERVER_URL}Locale/Scope/ListByName?`;

// FAQ 

export const GET_FAQ_URL = `${SERVER_URL}faq/faq?`;
export const GET_FAQ_TOPIC_URL = `${SERVER_URL}faq/faqTopic?`;
export const GET_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/faqSubTopic?`;


export const GET_FAQ_LIST_URL = `${SERVER_URL}faq/List`;
export const DELETE_FAQ_URL = `${SERVER_URL}faq/Delete?`;
export const VIEW_FAQ_URL = `${SERVER_URL}faq/View?`;
export const CREATE_DRAFT_FAQ_URL = `${SERVER_URL}faq/Draft/New?`;
export const CODETABLE_LANGUAGE_LIST_URL = `${SERVER_URL}faq/LanguageCodeList?`;
export const ADD_FAQ_URL = `${SERVER_URL}faq/New/`;
export const PUBLISH_FAQ_URL = `${SERVER_URL}faq/Publish?`;
export const PUBLISH_FAQ_VALIDATION_URL = `${SERVER_URL}faq/PublishFaqValidation?`;
export const EDIT_FAQ_URL = `${SERVER_URL}faq/Edit/`;
export const EDIT_FAQ_SETTINGS_URL = `${SERVER_URL}faq/Settings/Edit/`;
export const SUPPORTED_LANGUAGE_LIST_URL = `${SERVER_URL}faq/SupportedLanguage?`;
export const GET_FAQ_TREE_URL = `${SERVER_URL}faq/Tree?`;
export const ADD_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/New/`;
export const ADD_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/New/`;
export const ADD_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/New/`;
export const GET_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset?`;

export const PREVIEW_FAQ_URL = `${SERVER_URL}faq/preview?`;

export const GET_FAQ_QUESTION_SET_LIST_URL = `${SERVER_URL}faq/questionset/List?`;
export const DELETE_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/Delete?`;
export const DELETE_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/Delete?`;
export const DELETE_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/Delete?`;
export const SORT_FAQ_RANK_URL = `${SERVER_URL}faq/Sort?`;
export const SEARCH_FAQ_URL = `${SERVER_URL}faq/Search?`;

export const EDIT_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/edit?`;
export const EDIT_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/edit?`;
export const EDIT_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/edit?`;


// Public Holiday Service
export const GET_PUBLIC_HOLIDAY_LIST_URL = `${SERVER_URL}PublicHoliday/List`;
export const GET_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday?`;
export const ADD_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/New`;
export const DELETE_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/Delete?`;
export const UPDATE_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/Update`;

export const PUBLIC_HOLIDAY_MANAGEMENT_URL = '/SystemConfig/PublicHoliday';
export const PUBLIC_HOLIDAY_CREATE_URL = '/SystemConfig/PublicHoliday/Create';
export const PUBLIC_HOLIDAY_UPDATE_URL = '/SystemConfig/PublicHoliday/Update';
export const PUBLIC_HOLIDAY_VIEW_URL = '/SystemConfig/PublicHoliday/View';

// Working Hour
export const GET_WORKING_HOUR_CATEGORY_LIST_URL = `${SERVER_URL}WorkingHour/Category/List`;
export const GET_WORKING_HOUR_LIST_BY_CATEGORY_URL = `${SERVER_URL}WorkingHour/List?`;
export const GET_EXCEPTION_DATE_LIST_BY_CATEGORY_URL = `${SERVER_URL}WorkingHour/ExceptionDate/List?`;
export const GET_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour?`;
export const ADD_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour/New`;
export const ADD_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/New`;
export const UPDATE_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour/Update`;
export const UPDATE_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/Update`;
export const DELETE_WORKING_HOUR_CATEGORY_URL = `${SERVER_URL}WorkingHour/Category/Delete?`;
export const DELETE_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/Delete?`;

export const WORKING_HOUR_UPDATE_URL = '/SystemConfig/WorkingHour/Update';
export const WORKING_HOUR_MANAGEMENT_URL = '/SystemConfig/WorkingHour';
export const EXCEPTION_DATE_UPDATE_URL = '/SystemConfig/WorkingHour/UpdateException';

// SMS Template
export const NOTIFICATION_TEMPLATE_SMS_LIST = '/SystemConfig/NotificationTemplate/SMSList';
export const NOTIFICATION_TEMPLATE_SMS_UPDATE = '/SystemConfig/NotificationTemplate/SMSUpdate';
export const NOTIFICATION_TEMPLATE_SMS_CREATE = '/SystemConfig/NotificationTemplate/SMSCreate';
export const NOTIFICATION_TEMPLATE_SMS_VIEW = '/SystemConfig/NotificationTemplate/SMSView';


// Application Configuration commands
export const GET_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config?`;
export const LIST_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/List?`;
export const ADD_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/New`;
export const UPDATE_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/Update`;
export const LIST_APPCONFIG_CATEGORY_URL = `${SERVER_URL}ApplicationConfiguration/Category/List?`;
export const DELETE_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/Delete?`;

//Code Service
export const CODETABLE_ADD_URL = `${SERVER_URL}Code/New/`;
export const CODETABLE_EDIT_URL = `${SERVER_URL}Code/Edit/`;
export const CODETABLE_CATEGORY_LIST_URL = `${SERVER_URL}Code/Category/List`;
export const CODETABLE_LIST_URL = `${SERVER_URL}Code/List?`;
export const CODETABLE_DELETE_URL = `${SERVER_URL}Code/Delete?`;

// Common Services
export const CODETABLE_URL = `${SERVER_URL}common/codetables`;
export const APPCONFIG_URL = `${SERVER_URL}common/applicationconfigs`;
export const NOTIFICATIONTEMPLATE_URL = `${SERVER_URL}common/notificationtemplates`;
export const WORKINGHOUR_URL = `${SERVER_URL}common/workinghours`;
export const ONLINEHELP_URL = `${SERVER_URL}common/onlinehelps`;

// Const: Default timeout for all loader implementations
export const TIMEOUT_LOADER = 60000;

// Const: Display format for date
export const DATE_DISPLAY_FORMAT = {
    DATE_TIME: "DD-MMM-YYYY HH:mm A",
    TIME: "HH:mm A",
    TIME_12HOUR: "hh:mm A",
    DATE: "DD-MMM-YYYY",
    DATE_SHORT: "DD-MMM",
    DAY_OF_WEEK: "DDD"
}

// Const: Default period ago for Enquiry page
export const DEFAULT_ENQUIRY_MONTHS_AGO = 1;

// Const: Status Badge Color configuration
export const STATUS_BADGE_COLOR = {
    /* green */ success: ["Application Accepted", "Completed", "Active"],
    /* amber */ warning: ["Pending Approval Officer", "Pending Verification Officer", "Pending Review Officer", "Pending User Action", "Processing"],
    /* red   */ danger: ["Application Rejected", "Rejected"],
    /* grey  */ normal: ["Draft"]
}

// Const: Regular Expressions for Validation
export const REGEX = {
    EMAIL: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    TELEPHONE: /^(\+)([\d ]{12})+$/,
    NUMBER: /^[1-9]\d*(\.\d+)?$/,
    DATE: /^(([0-9])|([0-2][0-9])|([3][0-1]))-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/
};


// Const: Regular Expressions for Masked inputs
// Format: Array of Regex for each character
export const MASK = {
    TELEPHONE: ['+', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
};


// Const: Actions in React-Grid / Tables
export const ROW_ACTIONS = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    DELETE: "DELETE"
}

export const FORM_LAYOUT_TYPES = {
    FULL_PAGE: 1,
    FLOATING_WINDOW: 2
}

// Const: Layout Type for each page
export const LAYOUT_TYPE = {
    NAVBAR_DESKTOP: 1,      // 1 = vertical, 2 = horizontal
    NAVBAR_MOBILE: 1,       // 1 = hamburger, 2 = bottom buttons
    FORM: 2,                // 1 = full page, 2 = floating window
    FORM_CONTENT: 1         // 1 = tab, 2 = accordion, 3 = wizard
}

export const THEME = "blue"; // red blue green


// Const: Address Details
export const ADDRESS_TYPE = {
    LOCAL: 'Local',
    OVERSEAS: 'Overseas'
}

export const OPTIONAL_ADDRESS_TYPE = {
    NONE: 'None',
    LOCAL: 'Local',
    OVERSEAS: 'Overseas'
}

const ADDRESS_FIELDS = {
    LINE1: {
        AddressLine1: '',
        BlockNo: '',
        StreetName: ''
    },
    LINE2: {
        AddressLine2: '',
        LevelNo: '',
        UnitNo: '',
        BuildingName: ''
    }
}

export const DEFAULT_ADDRESS_TYPE = ADDRESS_TYPE.LOCAL;

export const ADDRESS = {
    ADDRESS_TYPE: ADDRESS_TYPE,
    ADDRESS_FIELDS: ADDRESS_FIELDS
}

export const getAddressTypeOptions = (addressTypeOptions) => {
    const addressTypes = addressTypeOptions === undefined ? ADDRESS.ADDRESS_TYPE : addressTypeOptions;
    const addressTypesOptions = [];

    Object.keys(addressTypes).forEach(function (key) {
        addressTypesOptions.push({
            value: addressTypes[key],
            label: addressTypes[key]
        })
    });

    return addressTypesOptions;
}

export const DEFAULT_HOME_COUNTRY = 'Singapore';
export const DEFAULT_HOME_COUNTRY_ID_TYPE = 'NRIC';
export const DEFAULT_OVERSEAS_COUNTRY_ID_TYPE = 'Passport';

export const USER_TYPE = {
    INTERNAL: 'Internal',
    EXTERNAL: 'External'
}