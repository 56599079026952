import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { Plaintext } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE } from '../../Localization';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const PermissionTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {

            let response = await fetchRequest(URL + "Permission/GetViewPermission/" + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;
                this.setState({
                    values: {
                        AppName: data.AppName,
                        PermissionName: data.PermissionName,
                        PermissionGuid: data.PermissionGuid,
                        PermissionType: data.PermissionType,
                        Description: data.Description || "-",
                        IsMonitored: data.IsMonitored,
                        FeatureName: data.FeatureName || "-"
                    },
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }
        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: PermissionTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Permission Details">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col className="smart-me-content">
                                    <Plaintext
                                        label="Application"
                                        translation={translation}
                                        name="AppName"
                                        className="mb-1"
                                        value={this.state.values.AppName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Feature"
                                        translation={translation}
                                        translateValue={true}
                                        name="Feature"
                                        className="mb-1"
                                        value={this.state.values.FeatureName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Permission Name"
                                        translation={translation}
                                        name="PermissionName"
                                        className="mb-1"
                                        value={this.state.values.PermissionName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Permission Guid"
                                        translation={translation}
                                        name="PermissionGuid"
                                        className="mb-1"
                                        value={this.state.values.PermissionGuid}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Permission Type"
                                        translation={translation}
                                        translateValue={true}
                                        name="PermissionType"
                                        className="mb-1"
                                        value={this.state.values.PermissionType}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Monitor Permission"
                                        translation={translation}
                                        translateValue={true}
                                        name="IsMonitored"
                                        className="mb-1"
                                        value={this.state.values.IsMonitored}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Description"
                                        translation={translation}
                                        name="Description"
                                        className="mb-1"
                                        value={this.state.values.Description}
                                        labelSize={LabelSize}
                                    />
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default withPageNotFound(withLoader(withRouter(View)));