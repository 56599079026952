export const SMARTMeTranslationKeys = [
    {
        "DefaultLanguageValue": "Application",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "Application"
    },
    {
        "DefaultLanguageValue": "Group Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "GroupName"
    },
    {
        "DefaultLanguageValue": "Description",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "Description"
    },
    {
        "DefaultLanguageValue": "Effective From",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "EffectiveFrom"
    },
    {
        "DefaultLanguageValue": "Effective To",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "EffectiveTo"
    },
    {
        "DefaultLanguageValue": "Effective Start Date",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "EffectiveStartDate"
    },
    {
        "DefaultLanguageValue": "Effective End Date",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "EffectiveEndDate"
    },
    {
        "DefaultLanguageValue": "User Group",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "UserGroup"
    },
    {
        "DefaultLanguageValue": "Global",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "Global"
    },
    {
        "DefaultLanguageValue": "Global User Group",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "GlobalUserGroup"
    },
    {
        "DefaultLanguageValue": "Successfully completed action",
        "Scope": "DefaultStatic",
        "Category": "Success",
        "SubCategory": "Title",
        "TranslationKey": "Success"
    },
    {
        "DefaultLanguageValue": "Yes",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "Yes"
    },
    {
        "DefaultLanguageValue": "No",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Field",
        "TranslationKey": "No"
    },
    {
        "DefaultLanguageValue": "Success",
        "Scope": "SMARTMe",
        "Category": "Success",
        "SubCategory": "Title",
        "TranslationKey": "Success"
    },
    {
        "DefaultLanguageValue": "<%= IdNumber %> (Issuing Country: <%= IdCountry %>)",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IdentificationDetails",
        "TranslationKey": "IdentificationDetails"
    }
]