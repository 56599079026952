import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { WORKINGHOUR_URL, GET_WORKING_HOUR_CATEGORY_LIST_URL, GET_WORKING_HOUR_LIST_BY_CATEGORY_URL, GET_EXCEPTION_DATE_LIST_BY_CATEGORY_URL,
     WORKING_HOUR_UPDATE_URL, EXCEPTION_DATE_UPDATE_URL, DELETE_WORKING_HOUR_CATEGORY_URL,
     DELETE_EXCEPTION_DATE_URL, WORKING_HOUR_MANAGEMENT_URL } from '../../App/AppSettings';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { postParams, fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { SelectList, SMARTForm, toastError, withSMARTWrapper } from '../../Shared/Forms';
import { toastSuccess } from '../../Shared/Forms/Toaster';
import { ModalConfirm } from '../../Shared/Modal';
import ReactHtmlParser from 'react-html-parser';
import Title from '../../Title';

const FORM_CONTEXT = "WorkingHourConfig";

const DELETE_CATEGORY_MODAL = {
    hasModal: true,
    name: "Delete Category",
    modalHeader: "Delete Category?",
    modalContent: "<div>Are you sure you want to delete this Working Hour category? <br />Please be reminded that changes cannot be undone.</div>",
    deleteUrl: WORKINGHOUR_URL+"/category/"
};

const DELETE_EXCEPTION_MODAL = {
    hasModal: true,
    name: "Delete Exception",
    modalHeader: "Delete Exception Date?",
    modalContent: "<div>Are you sure you want to delete this working hour exception? <br />Please be reminded that changes cannot be undone.</div>",
    deleteUrl: WORKINGHOUR_URL+"/delete/"
};

const SECTION_NAMES = {
    WORKING_HOUR: `Working Hour`,
    EXCEPTION: `Exception`,
};

const FORM_INITIAL_VALUES = {
    WorkingHourCategory: '',
};

const dropDownArrayCategory = 'dropDownArrayCategory';

class WorkHourConfigList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            category: '',
            data: [],
            data_excep: [],
            collapse: false,
            [dropDownArrayCategory]: [],
            modalState: undefined,
        };
    }

    componentDidMount = async () => {
        //Get dropdown list values from backend, must put after setstate else async will cause page to be unable to load
        this.props.loader.start();
        await this.dropdownList(dropDownArrayCategory);
        this.props.loader.done();
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    dropdownList = async (dropDownName) => {
        const response = await fetchRequest(GET_WORKING_HOUR_CATEGORY_LIST_URL, getParams(), false);
        const { Data, IsSuccess } = response.body;

        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element.Label, 'value': element.Value });
                });

                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError("Unable to reach the server, please try again later.");
            return null;
        }
        
        return dropDownArray;
    }

    deleteCategory = async (category) => {
        const { loader } = this.props;
        loader.start();

        const params = {
            Category: this.state.modalID
        }

        const response = await fetchRequest(DELETE_WORKING_HOUR_CATEGORY_URL, postParams('', params));
        const { IsSuccess, Message, RedirectURL } = response.body;

        if (IsSuccess) {
            if (RedirectURL) {
                navigateTo(RedirectURL, "");
            } else {
                loader.done();
                navigateTo(WORKING_HOUR_MANAGEMENT_URL, "");
                toastSuccess("Category has been deleted successfully.");
            }
    
        }
        else {
            // Error message to be loaded here
            loader.error(Message);
        }
    }

    deleteException = async (category) => {
        const { loader } = this.props;
        loader.start();

        const params = {
            Id: this.state.modalID
        }

        // Get results of server-side form posting
        const response = await fetchRequest(DELETE_EXCEPTION_DATE_URL, postParams('', params));
        const { IsSuccess, Messages } = response.body;

        if (IsSuccess) {
            // Reload Grid Data after deleting form
            const response_excep = await fetchRequest(GET_EXCEPTION_DATE_LIST_BY_CATEGORY_URL, getParams({ Category: category.toString() }), false);
        
            if (response_excep.success && response_excep.body.IsSuccess){

                const Data_Excep = response_excep.body.Data;
                //Returns a dash to fill null fields
                Object.keys(Data_Excep).forEach(function (i) {
        
                    Object.keys(Data_Excep[i]).forEach(function (x) {
                        if (Data_Excep[i][x] === "") {
                            return Data_Excep[i][x] = "-"
                        }
                    })
        
                });
        
                //set state
                await this.setState({
                    success: true,
                    data_excep: Data_Excep
                });
                toastSuccess("Exception Date has been deleted succesfully.");

                // End loading
                loader.done();
            }
        }
        else {
            // Error message to be loaded here
            loader.error(Messages);
        }
    }

    enquire = async (values) => {       
        this.state.filteredArray = [];
        this.state.success = false;
        const { loader } = this.props;

        loader.start();

        const response = await fetchRequest(GET_WORKING_HOUR_LIST_BY_CATEGORY_URL, getParams({ Category: values.WorkingHourCategory.toString() }), false);
        const response_excep = await fetchRequest(GET_EXCEPTION_DATE_LIST_BY_CATEGORY_URL, getParams({ Category: values.WorkingHourCategory.toString() }), false);
        
        if (response.success && response.body.IsSuccess && response_excep.success && response_excep.body.IsSuccess){
            const Data = response.body.Data;
            const Data_Excep = response_excep.body.Data;
    
            //Returns a dash to fill null fields
            Object.keys(Data).forEach(function (i) {

                Object.keys(Data[i]).forEach(function (x) {
                    if (Data[i][x] === "" || Data[i][x] === null) {
                        return Data[i][x] = "-"
                    }
                })
            });
            
            
            if (Data_Excep.length > 0) {
                //Returns a dash to fill null fields
                Object.keys(Data_Excep).forEach(function (i) {
                    
                    Object.keys(Data_Excep[i]).forEach(function (x) {
                        if (Data_Excep[i][x] === "" || Data_Excep[i][x] === null) {
                            return Data_Excep[i][x] = "-"
                        }
                    })

                });
            }
         
            //set state
            await this.setState({
                values: values,
                success: true,
                data: Data,
                category: values.WorkingHourCategory.toString(),
                data_excep: Data_Excep
            });
        }
        else{
            toastError("Something went wrong, please try again later.")
        }
        loader.done();
    }

    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(`/SystemConfig/WorkingHour/Update`, { ID: RowData.Id })}
                    icon={faEdit}
                    label='Update'
                />
            </React.Fragment>
        )
    }

    renderMobileCardActionsExcep = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(`/SystemConfig/WorkingHour/Update`, { ID: RowData.Id })}
                    icon={faEdit}
                    label='Update'
                />
                <MobileCardActionButton
                    onClick={() => this.deleteException(RowData.Id)}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }

    renderLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <div className="workhour-config-list">
                        <DataTable
                            pageSize="7"
                            minFilterChars='2'
                            data={this.state.data}
                            noResultsMessage='No Working Hour'
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: 'No.', width: '5%' },
                                    Actions: { title: 'Actions', width: '10%', DBkey: 'Action' },
                                    StatusBooleanBadge: { title: 'Working Day', width: '15%', DBkey: 'IsWorkingDay', trueText: "Yes", falseText: "No" },
                                    Enum: { title: 'Day of Week', width: '20%', DBkey: 'DayOfWeek', enumArray: ["-", "Monday","Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] },
                                    WorkStart: { title: 'Work Start', width: '12.5%', DBkey: 'WorkStart' },
                                    WorkEnd: { title: 'Work End', width: '12.5%', DBkey: 'WorkEnd' },
                                    BreakStart: { title: 'Break Start', width: '12.5%', DBkey: 'BreakStart' },
                                    BreakEnd: { title: 'Break End', width: '12.5%', DBkey: 'BreakEnd' },
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton 
                                    tooltip="Edit" 
                                    color="neutral" 
                                    onClick={() => navigateTo(WORKING_HOUR_UPDATE_URL, 
                                    { 
                                        ID: RowData.Id
                                    })}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </ActionButton>
                                </React.Fragment>
                            )}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    renderExceptionLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <div className="workhour-config-list">
                        <DataTable
                            pageSize="7"
                            minFilterChars='2'
                            data={this.state.data_excep}
                            noResultsMessage='No Working Hour Exception'
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: 'No.', width: '5%' },
                                    Actions: { title: 'Actions', width: '10%', DBkey: 'Action' },
                                    StatusBooleanBadge: { title: 'Working Day', width: '10%', DBkey: 'IsWorkingDay', trueText: "Yes", falseText: "No" },
                                    ExceptionDate: { title: 'Exception Date', width: '25%', DBkey: 'ExceptionDateString' },
                                    Enum: { title: 'Day of Week', width: '10%', DBkey: 'DayOfWeek', enumArray: ["-", "Monday","Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] },
                                    WorkStart: { title: 'Work Start', width: '10%', DBkey: 'WorkStart' },
                                    WorkEnd: { title: 'Work End', width: '10%', DBkey: 'WorkEnd' },
                                    BreakStart: { title: 'Break Start', width: '10%', DBkey: 'BreakStart' },
                                    BreakEnd: { title: 'Break End', width: '10%', DBkey: 'BreakEnd' },
                                }
                            }
                            renderActions={({RowData}) => (
                                <React.Fragment>
                                    <ActionButton tooltip="Edit" color="neutral" onClick={() => navigateTo(EXCEPTION_DATE_UPDATE_URL, { ID : RowData.Id })}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                    <ActionButton tooltip="Delete" color="hazard" onClick={() => this.toggleModal(DELETE_EXCEPTION_MODAL.name, RowData.Id)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                                </React.Fragment>
                            )}
                            renderMobileCardActions={this.renderMobileCardActionsExcep}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }


    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue="Working Hour Management" />
                    <div className="grid-container">
                        <Row>
                            <Col>
                                <SMARTForm
                                    formContext={FORM_CONTEXT}
                                    formValues={this.state.values}
                                    validateOnSubmit={true}
                                    isDummy={false}
                                    defaultSection={SECTION_NAMES.WORKING_HOUR}
                                    sectionNames={SECTION_NAMES}
                                >
                                    {({ values, errors, onChangeField, sectionState, toggleSection }) => (
                                        <React.Fragment>
                                            <div className="workhour-config-list-row">
                                                <Row className="wh-cat-row">
                                                    <span className="wh-cat-col">
                                                        <SelectList
                                                            className="wh-cat-col-list"
                                                            name="WorkingHourCategory"
                                                            value={values.WorkingHourCategory}
                                                            options={this.state.dropDownArrayCategory}
                                                            onChangeField={onChangeField}
                                                            placeholder="Please select a category"
                                                            isMulti={false}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            label="Category"
                                                            error={errors.WorkingHourCategory}
                                                            isDisabled={this.state.dropDownArrayCategory.length === 0 ? true : false}
                                                            onSelectListChange={this.enquire}
                                                        />
                                                        <ActionButton className='workinghour-btn' onClick={() => navigateTo('/SystemConfig/WorkingHour/Create')}>Add Category</ActionButton>
                                                        <ActionButton 
                                                            className='workinghour-btn' 
                                                            disabled={values.WorkingHourCategory==="" ? true : false} 
                                                            onClick={() => this.toggleModal(DELETE_CATEGORY_MODAL.name, values.WorkingHourCategory)}>
                                                            Delete Category
                                                        </ActionButton>
                                                    </span>
                                                </Row>
                                                {this.state.success && 
                                                <Row>
                                                    <Col className="body-content">
                                                        <SectionWrapper
                                                            type={1}
                                                            tabs={SECTION_NAMES}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            toggleFlex={true}
                                                        >
                                                            <Section
                                                                type={1}
                                                                sectionState={sectionState}
                                                                sectionName={SECTION_NAMES.WORKING_HOUR}
                                                                toggleSection={toggleSection}
                                                            >
                                                                {this.renderLayout()}
                                                                <ModalConfirm
                                                                    isOpen={(this.state.modalState === DELETE_CATEGORY_MODAL.name)}
                                                                    contentHeader={DELETE_CATEGORY_MODAL.modalHeader}
                                                                    contentBody={ReactHtmlParser(DELETE_CATEGORY_MODAL.modalContent)}
                                                                    confirmText="Confirm"
                                                                    confirmCallback={() => { this.toggleModal(DELETE_CATEGORY_MODAL.name); this.deleteCategory(values.WorkingHourCategory); }}
                                                                    cancelText="Cancel"
                                                                    cancelCallback={() => this.toggleModal(DELETE_CATEGORY_MODAL.name)}
                                                                />
                                                                <ModalConfirm
                                                                    isOpen={(this.state.modalState === DELETE_EXCEPTION_MODAL.name)}
                                                                    contentHeader={DELETE_EXCEPTION_MODAL.modalHeader}
                                                                    contentBody={ReactHtmlParser(DELETE_EXCEPTION_MODAL.modalContent)}
                                                                    confirmText="Confirm"
                                                                    confirmCallback={() => { this.toggleModal(DELETE_EXCEPTION_MODAL.name); this.deleteException(values.WorkingHourCategory); }}
                                                                    cancelText="Cancel"
                                                                    cancelCallback={() => this.toggleModal(DELETE_EXCEPTION_MODAL.name)}
                                                                />
                                                            </Section>
                                                            <Section
                                                                type={1}
                                                                sectionState={sectionState}
                                                                sectionName={SECTION_NAMES.EXCEPTION}
                                                                toggleSection={toggleSection}
                                                            >
                                                                <ActionButton className='workinghourexcep-btn' onClick={() => navigateTo('/SystemConfig/WorkingHour/CreateException', { Category: this.state.category })}>Add Exception Date</ActionButton>
                                                                {this.renderExceptionLayout()}
                                                            </Section>
                                                        </SectionWrapper>
                                                    </Col>
                                                </Row>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )}
                                </SMARTForm>
                            </Col>
                        </Row>
                    </div>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(WorkHourConfigList);

