export const CountryTranslationKeys = [
    {
        "DefaultLanguageValue": "Afghanistan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AF"
    },
    {
        "DefaultLanguageValue": "Åland Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AX"
    },
    {
        "DefaultLanguageValue": "Albania",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AL"
    },
    {
        "DefaultLanguageValue": "Algeria",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DZ"
    },
    {
        "DefaultLanguageValue": "American Samoa",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AS"
    },
    {
        "DefaultLanguageValue": "Andorra",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AD"
    },
    {
        "DefaultLanguageValue": "Angola",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AO"
    },
    {
        "DefaultLanguageValue": "Anguilla",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AI"
    },
    {
        "DefaultLanguageValue": "Antarctica",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AQ"
    },
    {
        "DefaultLanguageValue": "Antigua and Barbuda",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AG"
    },
    {
        "DefaultLanguageValue": "Argentina",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AR"
    },
    {
        "DefaultLanguageValue": "Armenia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AM"
    },
    {
        "DefaultLanguageValue": "Aruba",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AW"
    },
    {
        "DefaultLanguageValue": "Australia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AU"
    },
    {
        "DefaultLanguageValue": "Austria",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AT"
    },
    {
        "DefaultLanguageValue": "Azerbaijan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AZ"
    },
    {
        "DefaultLanguageValue": "Bahamas",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BS"
    },
    {
        "DefaultLanguageValue": "Bahrain",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BH"
    },
    {
        "DefaultLanguageValue": "Bangladesh",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BD"
    },
    {
        "DefaultLanguageValue": "Barbados",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BB"
    },
    {
        "DefaultLanguageValue": "Belarus",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BY"
    },
    {
        "DefaultLanguageValue": "Belgium",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BE"
    },
    {
        "DefaultLanguageValue": "Belize",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BZ"
    },
    {
        "DefaultLanguageValue": "Benin",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BJ"
    },
    {
        "DefaultLanguageValue": "Bermuda",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BM"
    },
    {
        "DefaultLanguageValue": "Bhutan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BT"
    },
    {
        "DefaultLanguageValue": "Bolivia, Plurinational State of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BO"
    },
    {
        "DefaultLanguageValue": "Bonaire, Sint Eustatius and Saba",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BQ"
    },
    {
        "DefaultLanguageValue": "Bosnia and Herzegovina",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BA"
    },
    {
        "DefaultLanguageValue": "Botswana",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BW"
    },
    {
        "DefaultLanguageValue": "Bouvet Island",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BV"
    },
    {
        "DefaultLanguageValue": "Brazil",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BR"
    },
    {
        "DefaultLanguageValue": "British Indian Ocean Territory",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IO"
    },
    {
        "DefaultLanguageValue": "Brunei Darussalam",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BN"
    },
    {
        "DefaultLanguageValue": "Bulgaria",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BG"
    },
    {
        "DefaultLanguageValue": "Burkina Faso",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BF"
    },
    {
        "DefaultLanguageValue": "Burundi",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BI"
    },
    {
        "DefaultLanguageValue": "Cambodia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KH"
    },
    {
        "DefaultLanguageValue": "Cameroon",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CM"
    },
    {
        "DefaultLanguageValue": "Canada",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CA"
    },
    {
        "DefaultLanguageValue": "Cape Verde",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CV"
    },
    {
        "DefaultLanguageValue": "Cayman Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KY"
    },
    {
        "DefaultLanguageValue": "Central African Republic",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CF"
    },
    {
        "DefaultLanguageValue": "Chad",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TD"
    },
    {
        "DefaultLanguageValue": "Chile",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CL"
    },
    {
        "DefaultLanguageValue": "China",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CN"
    },
    {
        "DefaultLanguageValue": "Christmas Island",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CX"
    },
    {
        "DefaultLanguageValue": "Cocos (Keeling) Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CC"
    },
    {
        "DefaultLanguageValue": "Colombia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CO"
    },
    {
        "DefaultLanguageValue": "Comoros",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KM"
    },
    {
        "DefaultLanguageValue": "Congo",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CG"
    },
    {
        "DefaultLanguageValue": "Congo, the Democratic Republic of the",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CD"
    },
    {
        "DefaultLanguageValue": "Cook Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CK"
    },
    {
        "DefaultLanguageValue": "Costa Rica",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CR"
    },
    {
        "DefaultLanguageValue": "Côte d'Ivoire",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CI"
    },
    {
        "DefaultLanguageValue": "Croatia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HR"
    },
    {
        "DefaultLanguageValue": "Cuba",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CU"
    },
    {
        "DefaultLanguageValue": "Curaçao",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CW"
    },
    {
        "DefaultLanguageValue": "Cyprus",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CY"
    },
    {
        "DefaultLanguageValue": "Czech Republic",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CZ"
    },
    {
        "DefaultLanguageValue": "Denmark",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DK"
    },
    {
        "DefaultLanguageValue": "Djibouti",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DJ"
    },
    {
        "DefaultLanguageValue": "Dominica",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DM"
    },
    {
        "DefaultLanguageValue": "Dominican Republic",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DO"
    },
    {
        "DefaultLanguageValue": "Ecuador",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "EC"
    },
    {
        "DefaultLanguageValue": "Egypt",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "EG"
    },
    {
        "DefaultLanguageValue": "El Salvador",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SV"
    },
    {
        "DefaultLanguageValue": "Equatorial Guinea",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GQ"
    },
    {
        "DefaultLanguageValue": "Eritrea",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ER"
    },
    {
        "DefaultLanguageValue": "Estonia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "EE"
    },
    {
        "DefaultLanguageValue": "Ethiopia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ET"
    },
    {
        "DefaultLanguageValue": "Falkland Islands (Malvinas)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FK"
    },
    {
        "DefaultLanguageValue": "Faroe Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FO"
    },
    {
        "DefaultLanguageValue": "Fiji",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FJ"
    },
    {
        "DefaultLanguageValue": "Finland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FI"
    },
    {
        "DefaultLanguageValue": "France",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FR"
    },
    {
        "DefaultLanguageValue": "French Guiana",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GF"
    },
    {
        "DefaultLanguageValue": "French Polynesia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PF"
    },
    {
        "DefaultLanguageValue": "French Southern Territories",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TF"
    },
    {
        "DefaultLanguageValue": "Gabon",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GA"
    },
    {
        "DefaultLanguageValue": "Gambia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GM"
    },
    {
        "DefaultLanguageValue": "Georgia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GE"
    },
    {
        "DefaultLanguageValue": "Germany",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "DE"
    },
    {
        "DefaultLanguageValue": "Ghana",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GH"
    },
    {
        "DefaultLanguageValue": "Gibraltar",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GI"
    },
    {
        "DefaultLanguageValue": "Greece",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GR"
    },
    {
        "DefaultLanguageValue": "Greenland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GL"
    },
    {
        "DefaultLanguageValue": "Grenada",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GD"
    },
    {
        "DefaultLanguageValue": "Guadeloupe",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GP"
    },
    {
        "DefaultLanguageValue": "Guam",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GU"
    },
    {
        "DefaultLanguageValue": "Guatemala",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GT"
    },
    {
        "DefaultLanguageValue": "Guernsey",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GG"
    },
    {
        "DefaultLanguageValue": "Guinea",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GN"
    },
    {
        "DefaultLanguageValue": "Guinea-Bissau",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GW"
    },
    {
        "DefaultLanguageValue": "Guyana",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GY"
    },
    {
        "DefaultLanguageValue": "Haiti",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HT"
    },
    {
        "DefaultLanguageValue": "Heard Island and McDonald Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HM"
    },
    {
        "DefaultLanguageValue": "Holy See (Vatican City State)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VA"
    },
    {
        "DefaultLanguageValue": "Honduras",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HN"
    },
    {
        "DefaultLanguageValue": "Hong Kong",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HK"
    },
    {
        "DefaultLanguageValue": "Hungary",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "HU"
    },
    {
        "DefaultLanguageValue": "Iceland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IS"
    },
    {
        "DefaultLanguageValue": "India",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IN"
    },
    {
        "DefaultLanguageValue": "Indonesia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ID"
    },
    {
        "DefaultLanguageValue": "Iran, Islamic Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IR"
    },
    {
        "DefaultLanguageValue": "Iraq",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IQ"
    },
    {
        "DefaultLanguageValue": "Ireland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IE"
    },
    {
        "DefaultLanguageValue": "Isle of Man",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IM"
    },
    {
        "DefaultLanguageValue": "Israel",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IL"
    },
    {
        "DefaultLanguageValue": "Italy",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "IT"
    },
    {
        "DefaultLanguageValue": "Jamaica",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "JM"
    },
    {
        "DefaultLanguageValue": "Japan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "JP"
    },
    {
        "DefaultLanguageValue": "Jersey",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "JE"
    },
    {
        "DefaultLanguageValue": "Jordan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "JO"
    },
    {
        "DefaultLanguageValue": "Kazakhstan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KZ"
    },
    {
        "DefaultLanguageValue": "Kenya",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KE"
    },
    {
        "DefaultLanguageValue": "Kiribati",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KI"
    },
    {
        "DefaultLanguageValue": "Korea, Democratic People's Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KP"
    },
    {
        "DefaultLanguageValue": "Korea, Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KR"
    },
    {
        "DefaultLanguageValue": "Kuwait",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KW"
    },
    {
        "DefaultLanguageValue": "Kyrgyzstan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KG"
    },
    {
        "DefaultLanguageValue": "Lao People's Democratic Republic",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LA"
    },
    {
        "DefaultLanguageValue": "Latvia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LV"
    },
    {
        "DefaultLanguageValue": "Lebanon",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LB"
    },
    {
        "DefaultLanguageValue": "Lesotho",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LS"
    },
    {
        "DefaultLanguageValue": "Liberia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LR"
    },
    {
        "DefaultLanguageValue": "Libya",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LY"
    },
    {
        "DefaultLanguageValue": "Liechtenstein",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LI"
    },
    {
        "DefaultLanguageValue": "Lithuania",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LT"
    },
    {
        "DefaultLanguageValue": "Luxembourg",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LU"
    },
    {
        "DefaultLanguageValue": "Macao",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MO"
    },
    {
        "DefaultLanguageValue": "Macedonia, the Former Yugoslav Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MK"
    },
    {
        "DefaultLanguageValue": "Madagascar",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MG"
    },
    {
        "DefaultLanguageValue": "Malawi",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MW"
    },
    {
        "DefaultLanguageValue": "Malaysia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MY"
    },
    {
        "DefaultLanguageValue": "Maldives",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MV"
    },
    {
        "DefaultLanguageValue": "Mali",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ML"
    },
    {
        "DefaultLanguageValue": "Malta",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MT"
    },
    {
        "DefaultLanguageValue": "Marshall Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MH"
    },
    {
        "DefaultLanguageValue": "Martinique",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MQ"
    },
    {
        "DefaultLanguageValue": "Mauritania",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MR"
    },
    {
        "DefaultLanguageValue": "Mauritius",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MU"
    },
    {
        "DefaultLanguageValue": "Mayotte",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "YT"
    },
    {
        "DefaultLanguageValue": "Mexico",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MX"
    },
    {
        "DefaultLanguageValue": "Micronesia, Federated States of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "FM"
    },
    {
        "DefaultLanguageValue": "Moldova, Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MD"
    },
    {
        "DefaultLanguageValue": "Monaco",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MC"
    },
    {
        "DefaultLanguageValue": "Mongolia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MN"
    },
    {
        "DefaultLanguageValue": "Montenegro",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ME"
    },
    {
        "DefaultLanguageValue": "Montserrat",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MS"
    },
    {
        "DefaultLanguageValue": "Morocco",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MA"
    },
    {
        "DefaultLanguageValue": "Mozambique",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MZ"
    },
    {
        "DefaultLanguageValue": "Myanmar",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MM"
    },
    {
        "DefaultLanguageValue": "Namibia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NA"
    },
    {
        "DefaultLanguageValue": "Nauru",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NR"
    },
    {
        "DefaultLanguageValue": "Nepal",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NP"
    },
    {
        "DefaultLanguageValue": "Netherlands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NL"
    },
    {
        "DefaultLanguageValue": "New Caledonia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NC"
    },
    {
        "DefaultLanguageValue": "New Zealand",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NZ"
    },
    {
        "DefaultLanguageValue": "Nicaragua",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NI"
    },
    {
        "DefaultLanguageValue": "Niger",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NE"
    },
    {
        "DefaultLanguageValue": "Nigeria",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NG"
    },
    {
        "DefaultLanguageValue": "Niue",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NU"
    },
    {
        "DefaultLanguageValue": "Norfolk Island",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NF"
    },
    {
        "DefaultLanguageValue": "Northern Mariana Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MP"
    },
    {
        "DefaultLanguageValue": "Norway",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "NO"
    },
    {
        "DefaultLanguageValue": "Oman",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "OM"
    },
    {
        "DefaultLanguageValue": "Pakistan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PK"
    },
    {
        "DefaultLanguageValue": "Palau",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PW"
    },
    {
        "DefaultLanguageValue": "Palestine, State of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PS"
    },
    {
        "DefaultLanguageValue": "Panama",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PA"
    },
    {
        "DefaultLanguageValue": "Papua New Guinea",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PG"
    },
    {
        "DefaultLanguageValue": "Paraguay",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PY"
    },
    {
        "DefaultLanguageValue": "Peru",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PE"
    },
    {
        "DefaultLanguageValue": "Philippines",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PH"
    },
    {
        "DefaultLanguageValue": "Pitcairn",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PN"
    },
    {
        "DefaultLanguageValue": "Poland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PL"
    },
    {
        "DefaultLanguageValue": "Portugal",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PT"
    },
    {
        "DefaultLanguageValue": "Puerto Rico",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PR"
    },
    {
        "DefaultLanguageValue": "Qatar",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "QA"
    },
    {
        "DefaultLanguageValue": "Réunion",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "RE"
    },
    {
        "DefaultLanguageValue": "Romania",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "RO"
    },
    {
        "DefaultLanguageValue": "Russian Federation",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "RU"
    },
    {
        "DefaultLanguageValue": "Rwanda",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "RW"
    },
    {
        "DefaultLanguageValue": "Saint Barthélemy",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "BL"
    },
    {
        "DefaultLanguageValue": "Saint Helena, Ascension and Tristan da Cunha",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SH"
    },
    {
        "DefaultLanguageValue": "Saint Kitts and Nevis",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "KN"
    },
    {
        "DefaultLanguageValue": "Saint Lucia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LC"
    },
    {
        "DefaultLanguageValue": "Saint Martin (French part)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "MF"
    },
    {
        "DefaultLanguageValue": "Saint Pierre and Miquelon",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "PM"
    },
    {
        "DefaultLanguageValue": "Saint Vincent and the Grenadines",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VC"
    },
    {
        "DefaultLanguageValue": "Samoa",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "WS"
    },
    {
        "DefaultLanguageValue": "San Marino",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SM"
    },
    {
        "DefaultLanguageValue": "Sao Tome and Principe",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ST"
    },
    {
        "DefaultLanguageValue": "Saudi Arabia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SA"
    },
    {
        "DefaultLanguageValue": "Senegal",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SN"
    },
    {
        "DefaultLanguageValue": "Serbia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "RS"
    },
    {
        "DefaultLanguageValue": "Seychelles",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SC"
    },
    {
        "DefaultLanguageValue": "Sierra Leone",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SL"
    },
    {
        "DefaultLanguageValue": "Singapore",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SG"
    },
    {
        "DefaultLanguageValue": "Sint Maarten (Dutch part)",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SX"
    },
    {
        "DefaultLanguageValue": "Slovakia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SK"
    },
    {
        "DefaultLanguageValue": "Slovenia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SI"
    },
    {
        "DefaultLanguageValue": "Solomon Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SB"
    },
    {
        "DefaultLanguageValue": "Somalia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SO"
    },
    {
        "DefaultLanguageValue": "South Africa",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ZA"
    },
    {
        "DefaultLanguageValue": "South Georgia and the South Sandwich Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GS"
    },
    {
        "DefaultLanguageValue": "South Sudan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SS"
    },
    {
        "DefaultLanguageValue": "Spain",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ES"
    },
    {
        "DefaultLanguageValue": "Sri Lanka",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "LK"
    },
    {
        "DefaultLanguageValue": "Sudan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SD"
    },
    {
        "DefaultLanguageValue": "Suriname",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SR"
    },
    {
        "DefaultLanguageValue": "Svalbard and Jan Mayen",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SJ"
    },
    {
        "DefaultLanguageValue": "Swaziland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SZ"
    },
    {
        "DefaultLanguageValue": "Sweden",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SE"
    },
    {
        "DefaultLanguageValue": "Switzerland",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "CH"
    },
    {
        "DefaultLanguageValue": "Syrian Arab Republic",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "SY"
    },
    {
        "DefaultLanguageValue": "Taiwan, Province of China",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TW"
    },
    {
        "DefaultLanguageValue": "Tajikistan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TJ"
    },
    {
        "DefaultLanguageValue": "Tanzania, United Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TZ"
    },
    {
        "DefaultLanguageValue": "Thailand",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TH"
    },
    {
        "DefaultLanguageValue": "Timor-Leste",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TL"
    },
    {
        "DefaultLanguageValue": "Togo",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TG"
    },
    {
        "DefaultLanguageValue": "Tokelau",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TK"
    },
    {
        "DefaultLanguageValue": "Tonga",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TO"
    },
    {
        "DefaultLanguageValue": "Trinidad and Tobago",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TT"
    },
    {
        "DefaultLanguageValue": "Tunisia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TN"
    },
    {
        "DefaultLanguageValue": "Turkey",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TR"
    },
    {
        "DefaultLanguageValue": "Turkmenistan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TM"
    },
    {
        "DefaultLanguageValue": "Turks and Caicos Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TC"
    },
    {
        "DefaultLanguageValue": "Tuvalu",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "TV"
    },
    {
        "DefaultLanguageValue": "Uganda",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "UG"
    },
    {
        "DefaultLanguageValue": "Ukraine",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "UA"
    },
    {
        "DefaultLanguageValue": "United Arab Emirates",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "AE"
    },
    {
        "DefaultLanguageValue": "United Kingdom",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "GB"
    },
    {
        "DefaultLanguageValue": "United States",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "US"
    },
    {
        "DefaultLanguageValue": "United States Minor Outlying Islands",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "UM"
    },
    {
        "DefaultLanguageValue": "Uruguay",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "UY"
    },
    {
        "DefaultLanguageValue": "Uzbekistan",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "UZ"
    },
    {
        "DefaultLanguageValue": "Vanuatu",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VU"
    },
    {
        "DefaultLanguageValue": "Venezuela, Bolivarian Republic of",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VE"
    },
    {
        "DefaultLanguageValue": "Viet Nam",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VN"
    },
    {
        "DefaultLanguageValue": "Virgin Islands, British",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VG"
    },
    {
        "DefaultLanguageValue": "Virgin Islands, U.S.",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "VI"
    },
    {
        "DefaultLanguageValue": "Wallis and Futuna",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "WF"
    },
    {
        "DefaultLanguageValue": "Western Sahara",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "EH"
    },
    {
        "DefaultLanguageValue": "Yemen",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "YE"
    },
    {
        "DefaultLanguageValue": "Zambia",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ZM"
    },
    {
        "DefaultLanguageValue": "Zimbabwe",
        "Scope": "CodeStatic",
        "Category": "Form",
        "SubCategory": "Country",
        "TranslationKey": "ZW"
    }
]