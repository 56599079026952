export const TableTranslationKeys = [
    {
        "DefaultLanguageValue": "No global user groups found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoGlobalUGsFound"
    },
    {
        "DefaultLanguageValue": "No user groups found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoUGsFound"
    },
    {
        "DefaultLanguageValue": "No users found",
        "Scope": "MeUser",
        "Category": "Table",
        "SubCategory": "NoRecord",
        "TranslationKey": "NoUsersFound"
    }
]