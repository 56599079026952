import { faEdit, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as Yup from 'yup';
import { Col, Label, Row } from 'reactstrap';
import {
    APPLICATION_LIST_URL, APP_REGISTERED_LANGUAGE_URL, GET_CATEGORY_LIST_URL, GET_SCOPE_LIST_URL, GET_SUBCATEGORY_LIST_URL, LAYOUT_TYPE, TRANSLATION_LIST_MANAGEMENT_URL,
    TRANSLATION_MANAGEMENT_ADD_ENTRY, TRANSLATION_MANAGEMENT_DELETE_URL, TRANSLATION_MANAGEMENT_PUBLISH_URL,
    TRANSLATION_MANAGEMENT_REVERT_URL, TRANSLATION_MANAGEMENT_UPDATE_ENTRY
} from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo, postParams } from '../../Shared/Actions';
import { FIELD_SIZE, FILTER, SORT_COLUMN, SORT_DIR } from '../../Shared/Constants';
import { ActionButton, ServerSideDataTableV2 } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { SelectList, SMARTForm, toastError, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastSuccess } from '../../Shared/Forms/Toaster';
import history from '../../Shared/History';
import { ModalPublishTranslation, ModalConfirm } from '../../Shared/Modal';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import './LanguageList.css';

const PUBLISHED_VALUE = "Published";
const DRAFTED_VALUE = "Drafted";
const DELETED_VALUE = "Deleted";

const PUBLISH_MODAL = {
    hasModal: true,
    name: "Publish"
};

const HARD_DELETE_MODAL = {
    hasModal: true,
    name: "HardDelete"
};
const SOFT_DELETE_MODAL = {
    hasModal: true,
    name: "SoftDelete"
};

const REVERT_MODAL = {
    hasModal: true,
    name: "RevertAllLanguage"
};

const FORM_CONTEXT = "TranslationView";
const RETRIEVE_URL = TRANSLATION_LIST_MANAGEMENT_URL;
const DROPDOWN_APPLICATION_URL = APPLICATION_LIST_URL;
const DROPDOWN_LANGUAGE_URL = APP_REGISTERED_LANGUAGE_URL;
const DROPDOWN_SCOPE_URL = GET_SCOPE_LIST_URL;
const DROPDOWN_CATEGORY_URL = GET_CATEGORY_LIST_URL;
const DROPDOWN_SUBCATEGORY_URL = GET_SUBCATEGORY_LIST_URL;
const DELETE_URL = TRANSLATION_MANAGEMENT_DELETE_URL;
const REVERT_URL = TRANSLATION_MANAGEMENT_REVERT_URL;

const dropDownArrayApplication = 'dropDownArrayApplication';
const dropDownArrayLanguage = 'dropDownArrayLanguage';
const dropDownArrayScope = 'dropDownArrayScope';
const dropDownArrayFilteredScope = 'dropDownArrayFilteredScope';
const dropDownArrayCategory = 'dropDownArrayCategory';
const dropDownArraySubCategory = 'dropDownArraySubCategory';
const dropDownArraySortBy = 'dropDownArraySortBy';
const dropDownArrayScopeSelection = 'dropDownArrayScopeSelection';
const dropDownArrayCategorySelection = 'dropDownArrayCategorySelection';
const dropDownArraySubCategorySelection = 'dropDownArraySubCategorySelection';
const APPLICATION = 'LanguageApplication';
const LANGUAGE = 'Language';

class LanguageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            data: [],
            collapse: false,
            count: 0,
            [dropDownArrayApplication]: [],
            [dropDownArrayLanguage]: [],
            [dropDownArrayScope]: [],
            [dropDownArrayFilteredScope]: [],
            [dropDownArrayCategory]: [],
            [dropDownArraySubCategory]: [],
            [dropDownArraySortBy]: [
                { value: "Key (asc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCKEY, DEFAULT_TEXT.ASC_KEY), key: "Key", order: "asc" },
                { value: "Key (desc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCKEY, DEFAULT_TEXT.DESC_KEY), key: "Key", order: "desc" },
                { value: "UpdatedDate (asc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCUPDATEDDATE, DEFAULT_TEXT.ASC_UPDATEDDATE), key: "UpdatedDate", order: "asc" },
                { value: "UpdatedDate (desc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCUPDATEDDATE, DEFAULT_TEXT.DESC_UPDATEDDATE), key: "UpdatedDate", order: "desc" }
            ],
            [dropDownArrayScopeSelection]: [],
            [dropDownArrayCategorySelection]: [],
            [dropDownArraySubCategorySelection]: [],
            modalState: undefined,
            isFirstLoad: true,
            LanguageSortBy: '',
            SortBy: {},
            filterValues: {
                LanguageScope: '',
                LanguageCategory: '',
                LanguageSubCategory: '',
                Status: '',
                SearchFilter: ''
            },
            Language: '',
            LanguageApplication: '',
            toggleUpdate: false,
            values: {
                "IsPublishByScope": true,
                "Scope": []
            },
            isScopeEmpty: false,
            isFilteredScopeLoaded: false
        };
    }

    toggleModal = (modalName, ID) => {
        if (modalName === PUBLISH_MODAL.name) {
            this.dropDownScopeList(DROPDOWN_SCOPE_URL, dropDownArrayFilteredScope, true);
            this.setState({
                isFilteredScopeLoaded: true
            });
        }

        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            IsPublishByScope: Yup.boolean(),
            Scope: Yup.array()
                .when('IsPublishByScope', { is: true, then: Yup.array().required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)) })
        });
    }

    publishTranslation = async (submitForm, values) => {
        const { LanguageApplication, Language } = this.state;

        values.ApplicationId = LanguageApplication;
        values.LanguageCode = Language;
        values.scopeList = values.IsPublishByScope ? values.Scope : "";

        // Only close modal if form is valid and submitted
        if ((values.IsPublishByScope && values.scopeList.length !== 0) ||
            !values.IsPublishByScope) {
            this.toggleModal(PUBLISH_MODAL.name);
        }
        submitForm();
    }

    submitCallback = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message, toggleUpdate, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                // Reload Grid Data after publishing form
                await this.enquire();
                //update translation table
                this.setState({
                    toggleUpdate: !toggleUpdate
                });

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_PUBLISHTRANS, SUCCESS.PUBLISH_SUCCESS), DEFAULT_TEXT.TRANSLATION);
            }
            else {
                // Error message to be loaded here
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    deleteForm = async () => {
        const { loader } = this.props;
        // Start loading
        loader.start();
        // Get results of server-side form posting
        const { LanguageApplication, modalID, toggleUpdate } = this.state;
        const params = {
            localeTranslationId: modalID,
            applicationId: LanguageApplication
        }
        const response = await fetchRequest(DELETE_URL, postParams('', params));
        loader.done();
        const { IsSuccess, Message, MessageLanguageKey } = response.body;
        if (response.success) {
            if (IsSuccess) {
                // Reload Grid Data after deleting form
                await this.enquire();
                //update translation table
                this.setState({
                    toggleUpdate: !toggleUpdate
                });
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_DELETETRANS, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.TRANSLATION);
            }
            else {
                // Error message to be loaded here
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    revertForm = async () => {
        const { loader } = this.props;
        // Start loading
        loader.start();
        // Get results of server-side form posting
        const { LanguageApplication, modalID, toggleUpdate } = this.state;
        const params = {
            localeTranslationId: modalID,
            applicationId: LanguageApplication
        }
        const response = await fetchRequest(REVERT_URL, postParams('', params));
        loader.done();
        const { IsSuccess, Message, MessageLanguageKey } = response.body;
        if (response.success) {
            if (IsSuccess) {
                // Reload Grid Data after reverting form
                await this.enquire();
                //update translation table
                this.setState({
                    toggleUpdate: !toggleUpdate
                });
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_REVERTTRANS, SUCCESS.REVERT_SUCCESS), DEFAULT_TEXT.TRANSLATION);
            }
            else {
                // Error message to be loaded here
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    componentDidMount = async () => {
        const { loader } = this.props;
        loader.start();
        const historyValues = history.location.state;
        await this.dropdownList(DROPDOWN_APPLICATION_URL, dropDownArrayApplication);
        //assign dropdownlist for filtering and selecting drop down list
        await this.dropDownScopeList(DROPDOWN_SCOPE_URL, dropDownArrayScope, false);
        await this.dropdownListFilters(DROPDOWN_CATEGORY_URL, dropDownArrayCategory, dropDownArrayCategorySelection);
        await this.dropdownListFilters(DROPDOWN_SUBCATEGORY_URL, dropDownArraySubCategory, dropDownArraySubCategorySelection);
        loader.done();

        let LanguageApplication = '', Language = '';
        let filterValues = this.state.filterValues;
        if (this.state.dropDownArrayApplication.length > 0) {
            if (historyValues && historyValues.chosenLanguageApplication) {
                LanguageApplication = historyValues.chosenLanguageApplication;
                await this.dropdownListLanguage(DROPDOWN_LANGUAGE_URL, dropDownArrayLanguage, LanguageApplication);
                if (this.state.dropDownArrayLanguage.length > 0) {
                    //auto selects historyValues.chosenLanguage
                    Language = historyValues.chosenLanguage;
                }
                // for setting filtervalues based on history
                filterValues = historyValues.chosenFilterValues;
            }
            else {
                //auto selects first item in dropdown application
                LanguageApplication = this.state.dropDownArrayApplication[0].value;
                //await this.dropdownListLanguage(DROPDOWN_LANGUAGE_URL, dropDownArrayLanguage, FORM_NEW_VALUES.LanguageApplication);
                await this.dropdownListLanguage(DROPDOWN_LANGUAGE_URL, dropDownArrayLanguage, LanguageApplication);
                if (this.state.dropDownArrayLanguage.length > 0) {
                    //auto selects first item in dropdown language
                    Language = this.state.dropDownArrayLanguage[0].value;
                }
            }
        }

        this.setState({
            LanguageApplication,
            Language,
            filterValues,
            success: true
        });

    }

    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    findArrayElementByValue = (array, value) => {
        return array.find((element) => {
            return element.value === value;
        })
    }

    //assign dropdown list 
    dropdownList = async (url, dropDownName) => {
        let fullurl = url;
        const response = await fetchRequest(fullurl, getParams());
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element.Name, 'value': String(element.Id) });
                });

                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    //assign dropdown list for filtering
    dropdownListFilters = async (url, dropDownFilter, dropDownSelection) => {
        let fullurl = url;

        const response = await fetchRequest(fullurl, getParams());
        const { Data, IsSuccess, Message, MessageLanguageKey } = response.body;
        let dropDownFilterArray = [], dropDownSelectionArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownFilterArray.push({ 'label': element.Name, 'value': element.Name });
                    dropDownSelectionArray.push({ 'label': element.Name, 'value': String(element.Id) });
                });
                this.setState({
                    [dropDownFilter]: dropDownFilterArray,
                    [dropDownSelection]: dropDownSelectionArray
                });
            } else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownFilterArray;
    }

    // Get dropdown list for scope
    dropDownScopeList = async (url, dropDownFilter, isScopeListFiltered) => {
        let fullurl = url;
        let params = {
            isScopeListFiltered: isScopeListFiltered,
            ApplicationId: this.state.LanguageApplication
        };

        const response = await fetchRequest(fullurl, getParams(params));

        const { Data, IsSuccess, Message, MessageLanguageKey } = response.body;
        let dropDownFilterArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownFilterArray.push({ 'label': element.Name, 'value': element.Name });
                });
                this.setState({
                    [dropDownFilter]: dropDownFilterArray
                });
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    //assign dropdown list for language 
    dropdownListLanguage = async (url, dropDownName, applicationValue) => {
        let fullurl = url;
        const params = {
            applicationId: applicationValue,
            isActiveOnly: true
        }
        const response = await fetchRequest(fullurl, getParams(params));
        const { Data, IsSuccess, MessageLanguageKey, Message } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element.LanguageCodeTitle + " (" + element.LanguageCodeValue + ")", 'value': element.LanguageCodeValue });
                });

                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    //refresh translation list
    enquire = async (name, value) => {
        let selectedLanguageLabel = "";
        const { loader } = this.props;
        let params = {
            applicationId: this.state.LanguageApplication,
            languageCode: this.state.Language
        }
        //Update param type accordingly
        if (name === APPLICATION) {
            params.applicationId = value;
            if (!this.state.isFirstLoad) {
                if (!(params.applicationId === this.state.LanguageApplication && params.languageCode === this.state.Language)) {
                    //if is not firstload, and it does not match, update dropdown list
                    loader.start();
                    //first load of dropdownlistlanguage is loaded in componentdidmount
                    await this.dropdownListLanguage(DROPDOWN_LANGUAGE_URL, dropDownArrayLanguage, value);
                    loader.done();
                }
                if (this.state.dropDownArrayLanguage.length > 0) {
                    //if params.applicationId does not exists in dropdownarraylanguage, choose first item in language dropdown
                    if (this.findArrayElementByValue(this.state.dropDownArrayLanguage, params.languageCode) === undefined) {
                        //auto selects first item in dropdown language
                        params.languageCode = this.state.dropDownArrayLanguage[0].value;
                    }
                    if (params.applicationId === this.state.LanguageApplication && params.languageCode === this.state.Language) {
                        //if is not firstload, and it matches, nothing new to update in datatable
                        return;
                    }
                }
                else {
                    params.languageCode = "";
                }
            }
        } else if (name === LANGUAGE) {
            if (value === params.languageCode) {
                //if matches, nothing new to update in datatable
                return;
            }
            params.languageCode = value;
        }
        //Retrieve selected language label to be displayed in card
        if (this.state.dropDownArrayLanguage.length > 0) {
            selectedLanguageLabel = this.findArrayElementByValue(this.state.dropDownArrayLanguage, params.languageCode).label;
        }
        this.setState({
            isFirstLoad: false,
            LanguageApplication: params.applicationId,
            Language: params.languageCode,
            selectedLanguageLabel: selectedLanguageLabel
        });
        //render layout after filtering
        this.renderLayout(this.state);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    getCustomComponents = () => {
        let components = getCustomComponents();
        components.Filter = this.Filter;
        return components;
    }

    onFilterChange = (filterName, value, setFilter) => {
        let filterValues = this.state.filterValues;
        filterValues[filterName] = value;
        this.setState({
            filterValues
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        let filterFields = this.getFilterFields();
        setFilter(filterFields);
    }

    getFilterFields = () => {
        const { SortBy } = this.state;
        const { SearchFilter, LanguageScope, LanguageCategory, LanguageSubCategory, Status } = this.state.filterValues;

        let filterFields = {
            [FILTER]: SearchFilter,
            'LanguageScopeFilter': LanguageScope,
            'LanguageCategoryFilter': LanguageCategory,
            'LanguageSubCategoryFilter': LanguageSubCategory,
            'StatusFilter': Status,
        };
        if (SortBy) {
            filterFields = Object.assign(filterFields, SortBy);
        }
        return filterFields;
    }
    getSortBy = (selectedOption, setFilter) => {
        let SORT_BY = [
            { value: "Key (asc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCKEY, DEFAULT_TEXT.ASC_KEY), key: "Key", order: "asc" },
            { value: "Key (desc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCKEY, DEFAULT_TEXT.DESC_KEY), key: "Key", order: "desc" },
            { value: "UpdatedDate (asc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCUPDATEDDATE, DEFAULT_TEXT.ASC_UPDATEDDATE), key: "UpdatedDate", order: "asc" },
            { value: "UpdatedDate (desc)", label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCUPDATEDDATE, DEFAULT_TEXT.DESC_UPDATEDDATE), key: "UpdatedDate", order: "desc" }
        ];
        let sortBy = {};
        let keyToSort = SORT_BY.find(o => o.value === selectedOption);
        sortBy[SORT_COLUMN] = keyToSort.key;
        sortBy[SORT_DIR] = keyToSort.order;
        this.changeSortBy(sortBy, selectedOption, setFilter);
    }

    changeSortBy = (sortBy, selectedOption, setFilter) => {
        this.setState({
            SortBy: sortBy,
            LanguageSortBy: selectedOption
        }, () => this.filter(setFilter))
    }

    Filter = (props) => {
        const { dropDownArrayScope, dropDownArrayCategory, dropDownArraySubCategory, LanguageSortBy, dropDownArraySortBy, filterValues } = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col xs="6" sm="4">
                        <SelectList
                            name="LanguageScope"
                            value={filterValues.LanguageScope}
                            options={dropDownArrayScope}
                            onChangeField={(name, selectedOption) => this.onFilterChange(name, selectedOption, props.setFilter)}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSCOPE, DEFAULT_TEXT.FILTER_BY_SCOPE)}
                            isMulti={false}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            isClearable
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE, DEFAULT_TEXT.SCOPE)}
                        />
                    </Col>
                    <Col xs="6" sm="4">
                        <SelectList
                            name="LanguageCategory"
                            value={filterValues.LanguageCategory}
                            options={dropDownArrayCategory}
                            onChangeField={(name, selectedOption) => this.onFilterChange(name, selectedOption, props.setFilter)}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYCAT, DEFAULT_TEXT.FILTER_BY_CATEGORY)}
                            isMulti={false}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            isClearable
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                        />
                    </Col>
                    <Col xs="6" sm="4">
                        <SelectList
                            name="LanguageSubCategory"
                            value={filterValues.LanguageSubCategory}
                            options={dropDownArraySubCategory}
                            onChangeField={(name, selectedOption) => this.onFilterChange(name, selectedOption, props.setFilter)}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSUBCAT, DEFAULT_TEXT.FILTER_BY_SUBCATEGORY)}
                            isMulti={false}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            isClearable
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SUBCATEGORY, DEFAULT_TEXT.SUB_CATEGORY)}
                        />
                    </Col>

                    <Col xs="6" sm="4">
                        <SelectList
                            name="Status"
                            value={filterValues.Status}
                            options={[
                                { value: PUBLISHED_VALUE, label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_PUBLISHED, DEFAULT_TEXT.PUBLISHED) },
                                { value: DRAFTED_VALUE, label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DRAFTED, DEFAULT_TEXT.DRAFTED) },
                                { value: DELETED_VALUE, label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DELETED, DEFAULT_TEXT.DELETED) }]}
                            onChangeField={(name, selectedOption) => this.onFilterChange(name, selectedOption, props.setFilter)}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYPUBLISHED, DEFAULT_TEXT.FILTER_BY_PUBLISHED)}
                            isMulti={false}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            isClearable
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHED, DEFAULT_TEXT.PUBLISHED)}
                        />
                    </Col>
                    <Col xs="6" sm="4">
                        <SelectList
                            name="LanguageSortBy"
                            value={LanguageSortBy}
                            options={dropDownArraySortBy}
                            onChangeField={(name, selectedOption) => {
                                this.getSortBy(selectedOption, props.setFilter);
                            }}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SORTBY, DEFAULT_TEXT.SORT_BY)}
                            isMulti={false}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SORTBY, DEFAULT_TEXT.SORT_BY)}
                        />
                    </Col>
                    <Col xs="6" sm="4">
                        <Row>
                            <Label className=" label-default col-form-label" lg="3">
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SEARCH, DEFAULT_TEXT.SEARCH)}
                            </Label>
                            <Col lg="9">
                                <input
                                    type="text"
                                    name="filter"
                                    defaultValue={filterValues.SearchFilter}
                                    className="form-control"
                                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SEARCH, DEFAULT_TEXT.SEARCH)}
                                    onChange={e => this.onFilterChange("SearchFilter", e.target.value, props.setFilter)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    //render header filters: language
    renderHeaderFilter = (errors, dropDownArrayLanguage) => {
        return (
            <React.Fragment>
                <div>
                    <SelectList
                        name="Language"
                        value={this.state.Language}
                        options={dropDownArrayLanguage}
                        onChangeField={(name, value) => { this.enquire(name, value) }}
                        placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_A_LANGUAGE)}
                        isMulti={false}
                        labelSize={FIELD_SIZE.SMALL}
                        inputSize={FIELD_SIZE.XLARGE}
                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                        error={errors.Language}
                    />
                </div>
            </React.Fragment>
        );
    }

    //render header buttons: upload file, create entry, publish
    renderHeaderButtons = (dropDownOptions) => {
        const { LanguageApplication, Language, filterValues } = this.state;

        if (this.state.success) {
            return (
                <React.Fragment>
                    <div className='language-header-btn remove-padding'>
                        <ActionButton color="neutral" className='language-btn' disabled={LanguageApplication === "" || Language === "" ? true : null}
                            onClick={() => navigateTo(TRANSLATION_MANAGEMENT_ADD_ENTRY,
                                {
                                    dropDownOptions: dropDownOptions, applicationId: LanguageApplication,
                                    applicationLabel: this.findArrayElementByValue(this.state.dropDownArrayApplication, LanguageApplication).label,
                                    chosenLanguage: Language, chosenFilterValues: filterValues
                                })}>
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWENTRY, DEFAULT_TEXT.NEW_ENTRY)}
                        </ActionButton>

                        <ActionButton
                            color="neutral"
                            className='language-btn'
                            disabled={LanguageApplication === "" || Language === "" ? true : null}
                            onClick={() => this.toggleModal(PUBLISH_MODAL.name)}
                        >
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH, DEFAULT_TEXT.PUBLISH)}
                        </ActionButton>

                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    customizeData = (Data) => {
        //Setting status in data
        if (Data.IsDeleted === true) {
            Data.Status = DELETED_VALUE;
        }
        else if (Data.IsPublished === false) {
            Data.Status = DRAFTED_VALUE;
        }
        else {
            Data.Status = PUBLISHED_VALUE;
        }
        //Setting full key in data
        Data.FullKey = Data.Namespace + "." + Data.Key;
        //Setting current language 
        Data.LanguageLabel = this.state.selectedLanguageLabel;
        return Data;
    }

    //render translations
    renderLayout = () => {
        const { LanguageApplication, Language, filterValues, toggleUpdate } = this.state;

        if (this.state.success) {
            return (
                <ServerSideDataTableV2
                    url={RETRIEVE_URL}
                    toggleUpdate={toggleUpdate}
                    pageSize="10"
                    minFilterChars='2'
                    isLanguageCard
                    customizeData={this.customizeData}
                    components={this.getCustomComponents()}
                    isCustomTable={true}
                    isCustomFilter={true}
                    setInitialFilterText={this.getFilterFields}
                    extraGetParams={{
                        'ApplicationId': LanguageApplication,
                        'LanguageCode': Language
                    }}
                    noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSNORECORD, DEFAULT_TEXT.TRANS_NO_RECORD)}
                    columns={
                        {
                            Status: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_STATUS, DEFAULT_TEXT.STATUS), width: '25%', DBkey: 'Status' },
                            Key: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_KEY, DEFAULT_TEXT.KEY), width: '12%', DBkey: 'Key' },
                            FullKey: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_FULLKEY, DEFAULT_TEXT.FULL_KEY), width: '25%', DBkey: 'FullKey' },
                            DefaultValue: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULTVALUE, DEFAULT_TEXT.DEFAULT_VALUE), DBkey: 'DefaultValue' },
                            Value: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_VALUE, DEFAULT_TEXT.VALUE), width: '15%', DBkey: 'Value' },
                            UpdatedBy: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDBY, DEFAULT_TEXT.UPDATED_BY), width: '25%', DBkey: 'UpdatedBy' },
                            UpdatedDate: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDDATE, DEFAULT_TEXT.UPDATED_DATE), width: '25%', DBkey: 'UpdatedDateFormatted', isDate: true },
                            LanguageScope: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_SCOPE, DEFAULT_TEXT.SCOPE), DBkey: 'LanguageScope' },
                            LanguageCategory: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_CATEGORY, DEFAULT_TEXT.CATEGORY), DBkey: 'LanguageCategory' },
                            LanguageSubCategory: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_SUBCATEGORY, DEFAULT_TEXT.SUB_CATEGORY), DBkey: 'LanguageSubCategory' },
                        }
                    }
                    renderActions={(data) => (
                        <React.Fragment>
                            {data.IsEditAction &&
                                <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)} color="neutral" onClick={() => navigateTo(TRANSLATION_MANAGEMENT_UPDATE_ENTRY,
                                    { keyData: data, chosenLanguageApplication: LanguageApplication, chosenLanguage: Language, chosenFilterValues: filterValues })}>
                                    <FontAwesomeIcon icon={faEdit} /></ActionButton>
                            }

                            {data.IsHardDeleteAction &&
                                <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_DELETE, DEFAULT_TEXT.DELETE)} color="danger" onClick={() => this.toggleModal(HARD_DELETE_MODAL.name, data.Id)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                            }
                            {data.IsSoftDeleteAction &&
                                <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_DELETE, DEFAULT_TEXT.DELETE)} color="danger" onClick={() => this.toggleModal(SOFT_DELETE_MODAL.name, data.Id)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                            }
                            {data.IsRevertAction &&
                                <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REVERT, DEFAULT_TEXT.REVERT)} color="warning" onClick={() => this.toggleModal(REVERT_MODAL.name, data.Id)}><FontAwesomeIcon fixedWidth icon={faUndo} /></ActionButton>
                            }
                        </React.Fragment>
                    )}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const { dropDownArrayApplication, dropDownArrayLanguage, dropDownArrayScopeSelection, dropDownArrayCategorySelection, dropDownArraySubCategorySelection, LanguageApplication } = this.state;

        const dropDownOptions = {
            languages: dropDownArrayLanguage,
            scopes: dropDownArrayScopeSelection,
            categories: dropDownArrayCategorySelection,
            subcategories: dropDownArraySubCategorySelection
        }
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FULL_PAGE} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_TRANSMANAGEMENT, DEFAULT_TEXT.TRANSLATION_MANAGEMENT)}>
                    <div className="grid-container ">
                        <Row className="body-content">
                            <Col>
                                <SMARTForm
                                    formContext={FORM_CONTEXT}
                                    formValues={this.state.values}
                                    validationSchema={this.getFormValidations()}
                                    validateOnSubmit={true}
                                    serverURL={TRANSLATION_MANAGEMENT_PUBLISH_URL}
                                    submitCallback={this.submitCallback}
                                    resetFormAfterSubmit={true}
                                >
                                    {({ errors, onChangeField, values, submitForm, isFormValid }) => (
                                        <React.Fragment>
                                            <div>
                                                <Row className="language-header-content">
                                                    <Col xs="12" sm="6">
                                                        <SelectList
                                                            name="LanguageApplication"
                                                            value={LanguageApplication}
                                                            options={dropDownArrayApplication}
                                                            onChangeField={(name, value) => { this.enquire(name, value) }}
                                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_APPLICATION, DEFAULT_TEXT.SELECT_APP)}
                                                            isMulti={false}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.XLARGE}
                                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                                            error={errors.LanguageApplication}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        {LanguageApplication !== "" &&
                                                            this.renderHeaderFilter(errors, dropDownArrayLanguage)
                                                        }
                                                    </Col>
                                                    <Col xs="12" className="text-right">
                                                        {this.renderHeaderButtons(dropDownOptions)}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {this.state.success &&
                                                        <Col className="body-content language-container">
                                                            {this.renderLayout()}
                                                        </Col>}
                                                    <ModalConfirm
                                                        isOpen={(this.state.modalState === HARD_DELETE_MODAL.name)}
                                                        contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TITLE_DELETETRANSKEY, DEFAULT_TEXT.DELETE_TRANSKEY_MODAL_TITLE)}
                                                        contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_HARDDELETETRANSKEY, DEFAULT_TEXT.HARD_DELETE_TRANSKEY_MODAL_TEXT, true)}
                                                        confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                                        confirmCallback={() => { this.toggleModal(HARD_DELETE_MODAL.name); this.deleteForm(); }}
                                                        cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                                        cancelCallback={() => this.toggleModal(HARD_DELETE_MODAL.name)}
                                                    />
                                                    <ModalConfirm
                                                        isOpen={(this.state.modalState === SOFT_DELETE_MODAL.name)}
                                                        contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TITLE_DELETETRANSKEY, DEFAULT_TEXT.DELETE_TRANSKEY_MODAL_TITLE)}
                                                        contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_SOFTDELETETRANSKEY, DEFAULT_TEXT.SOFT_DELETE_TRANSKEY_MODAL_TEXT, true)}
                                                        confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                                        confirmCallback={() => { this.toggleModal(SOFT_DELETE_MODAL.name); this.deleteForm(); }}
                                                        cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                                        cancelCallback={() => this.toggleModal(SOFT_DELETE_MODAL.name)}
                                                    />
                                                    <ModalConfirm
                                                        isOpen={(this.state.modalState === REVERT_MODAL.name)}
                                                        contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TITLE_REVERTTRANSKEY, DEFAULT_TEXT.REVERT_TRANSKEY_MODAL_TITLE)}
                                                        contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_REVERTTRANSKEY, DEFAULT_TEXT.REVERT_TRANSKEY_MODAL_TEXT, true)}
                                                        confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                                        confirmCallback={() => { this.toggleModal(REVERT_MODAL.name); this.revertForm(); }}
                                                        cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                                        cancelCallback={() => this.toggleModal(REVERT_MODAL.name)}
                                                    />
                                                    <ModalPublishTranslation
                                                        isOpen={(this.state.modalState === PUBLISH_MODAL.name)}
                                                        selectListName={"Scope"}
                                                        toggleLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHBYSCOPE, DEFAULT_TEXT.PUBLISH_BY_SCOPE)}
                                                        toggleName={"IsPublishByScope"}
                                                        toggleValue={values.IsPublishByScope}
                                                        toggleModal={this.toggleModal}
                                                        toggleTrueText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_YES, DEFAULT_TEXT.YES)}
                                                        toggleFalseText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_NO, DEFAULT_TEXT.NO)}
                                                        selectListLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE, DEFAULT_TEXT.SCOPE)}
                                                        selectListPlaceholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SCOPE, DEFAULT_TEXT.SELECT_SCOPE)}
                                                        selectListvalue={values.Scope}
                                                        options={this.state.dropDownArrayFilteredScope}
                                                        isScopeLoaded={this.state.isFilteredScopeLoaded}
                                                        onChangeField={onChangeField}
                                                        errorSelect={errors.Scope}
                                                        contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TITLE_PUBLISHTRANSKEY, DEFAULT_TEXT.PUBLISH_TRANSKEY_MODAL_TITLE)}
                                                        contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHTRANSKEY, DEFAULT_TEXT.PUBLISH_TRANSKEY_MODAL_TEXT, true)}
                                                        confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH, DEFAULT_TEXT.PUBLISH)}
                                                        confirmCallback={() => { this.publishTranslation(submitForm, values); }}
                                                        cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                                    />
                                                </Row>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </SMARTForm>
                            </Col>
                        </Row>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(withModalWrapper(LanguageList));

