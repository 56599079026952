import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, getParams, postParams } from '../../Shared/Actions';
import { toastError, toastSuccess, withSMARTWrapper } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import './WebHook.css';
import { withLoader } from '../../Shared/Loading';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';


const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

const GRID_ENDPOINT = URL + "WebHook/ListWebHooks";
const DELETE_URL_ENDPOINT = URL + 'WebHook/DeleteWebHook/';

const VIEW_WEBHOOK_URL_PAGE = "WebHook/View/";
const UPDATE_WEBHOOK_URL_PAGE = "WebHook/Update/"
const NEW_WEBHOOK_URL_PAGE = "/WebHook/New";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const WebhookTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleUpdate: false
        };
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        let appAdminResponse = await fetchRequest(URL + "App/GetIsUserAppAdmin", getParams(), false);

        if (appAdminResponse.success && appAdminResponse.body.IsSuccess) {
            let isAppAdmin = appAdminResponse.body.Data;

            this.setState({
                isAppAdmin: isAppAdmin,
            });
        }
        else {
            toastError(appAdminResponse.body.messages || appAdminResponse.body.Messages);
        }
        loader.done();
    }

    //Fn: Delete Row via Id
    async deleteRow(Id) {
        const { loader, getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: WebhookTranslationKeys
        };

        if (Id) {
            // Start loading
            loader.start();

            // Send request to backend to delete Row
            const response = await fetchRequest(DELETE_URL_ENDPOINT + Id, postParams(), false);

            // Done loading
            loader.done();

            const { IsSuccess, Messages } = response.body;

            // Based on response, show appropriate toaster
            (response.success && IsSuccess) ?
                toastSuccess(Messages, null, translation) :
                toastError(Messages, translation);

            if (IsSuccess) {
                this.setState({ toggleUpdate: !this.state.toggleUpdate });
            }
        }
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;
        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_WEBHOOK_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                <MobileCardActionButton
                    key={'Update' + Id}
                    onClick={() => navigateTo(UPDATE_WEBHOOK_URL_PAGE, ID)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
                <MobileCardActionButton
                    key={'Delete' + Id}
                    onClick={() => this.deleteRow(Id)}
                    modal={DELETE_MODAL(getStaticText)}
                    icon={faTrash}
                    label={BUTTON_TITLE(getStaticText).DELETE}
                    formatter={{ component: getTranslationValue('Webhook', getStaticText, WebhookTranslationKeys) }}
                />
            </React.Fragment>
        )
    }

    setContent = (rowData) => {
        return <div className="div-link" onClick={() => navigateTo(VIEW_WEBHOOK_URL_PAGE, { ID: rowData.Id })}>{rowData.NotifyUrl}</div>
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: WebhookTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of Webhooks" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            {this.state.isAppAdmin &&
                                <ActionButton className='add-button' color="neutral" onClick={() => navigateTo(NEW_WEBHOOK_URL_PAGE)}>
                                    <FontAwesomeIcon icon={faPlus} /></ActionButton>
                            }
                            <ServerSideDataTable
                                translation={translation}
                                toggleUpdate={this.state.toggleUpdate}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No webhooks found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '4%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        AppName: { title: 'Application', width: '15%', DBkey: 'AppName' },
                                        DataType: { title: 'Data Type', width: '10%', DBkey: 'DataType', translateValue: true },
                                        NotifyUrl: { title: 'Notify URL', width: '15%', DBkey: 'NotifyUrl', setContent: this.setContent },
                                        Description: { title: 'Description', width: '20%', DBkey: 'Description' },
                                        LastUpdatedDate: { title: 'Last Updated', width: '8%', DBkey: 'LastUpdatedDate' },
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        {rowData.RowData.IsEditable &&
                                            <ActionButton key={'Update' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                                onClick={() => navigateTo(UPDATE_WEBHOOK_URL_PAGE, { ID: rowData.ID })}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionButton>
                                        }
                                        {rowData.RowData.IsDeletable &&
                                            <ActionButton key={'Delete' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                                onClick={() => this.deleteRow(rowData.ID)} modal={DELETE_MODAL(getStaticText)}
                                                formatter={{ component: getTranslationValue('Webhook', getStaticText, WebhookTranslationKeys) }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </ActionButton>
                                        }
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withLoader(withSMARTWrapper(Grid));