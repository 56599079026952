import history from '../../History';
import { CLIENT_URL, REDIRECT_URI } from '../../../Shared/SmartMe/Constants';
import { ADDRESS } from '../../../App/AppSettings';

export const toFormData = (object, form, namespace) => {
  const formData = form || new FormData();
  for (let property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
      toFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
}

export const processGenders = (response) => {
  var genders = [];
  response.map(x => {
    var obj = { label: x.CodeTitle, value: x.CodeValue }
    genders.push(obj);
    return obj;
  })
  return genders;
}

export const createLabelAndValueArray = (response) => {
  var values = [];
  response.map(x => {
    var obj = { label: x.CodeTitle, value: x.CodeValue }
    values.push(obj);
    return obj;
  })
  return values;
}

export const serverTableNavigateTo = (nextURL, values) => {
  // Ensure scroll to top of page on navigation
  window.scrollTo(0, 0);

  // Perform navigation
  history.push({
    pathname: nextURL + values.ID
  })
};

export const serverTableInLineNavigateTo = (nextURL, values) => {
  // Ensure scroll to top of page on navigation
  window.scrollTo(0, 0);

  // Perform navigation
  history.push({
    pathname: nextURL + values.Id
  })
};

export const selectListMapper = (itemList, val, lab) => {
  let list = [];
  itemList.map(x => {
    var obj = { value: x[val], label: x[lab] };
    return list.push(obj);
  })
  return list;
}

export const resetAddressDetails = (Address) => {
  let AddressLine1 = Address.AddressLine1;
  let AddressLine2 = Address.AddressLine2;

  if (Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL) {
    Address.Country = Address.City = Address.State = '';
    AddressLine1.AddressLine1 = AddressLine2.AddressLine2 = '';
  }
  else if (Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS) {
    AddressLine1.BlockNo = AddressLine1.StreetName = '';
    AddressLine2.LevelNo = AddressLine2.UnitNo = AddressLine2.BuildingName = '';
  }
  else {
    // Address Type === NONE : Clear all values
    const newAddress = Object.assign
      ({ "AddressType": Address.AddressType },
        { "AddressLine1": ADDRESS.ADDRESS_FIELDS.LINE1 },
        { "AddressLine2": ADDRESS.ADDRESS_FIELDS.LINE2 });

    Address = newAddress;
  }
}

export const dateMapper = (value) => {
  if (value !== "") {
    var fullDate = value.toString().split(" ", 1);
    var dateVal = fullDate.toString().split("/");

    var day = "";
    var mth = "";

    //day
    if (dateVal[1] < 2) {
      day = 0 + "" + dateVal[1];
    } else {
      day = dateVal[1];
    }

    //mths
    var monthEnum = Object.freeze({
      "Jan": 1, "Feb": 2, "Mar": 3, "Apr": 4, "May": 5, "Jun": 6, "Jul": 7, "Aug": 8, "Sept": 9, "Oct": 10,
      "Nov": 11, "Dec": 12,
    })

    const objKeys = Object.keys(monthEnum);
    const mthVal = parseInt(dateVal[0], 10) - 1;
    mth = objKeys[mthVal];

    return day + "-" + mth + "-" + dateVal[2];
  }
  else {
    return "";
  }
}

export const parseIsMonitoredType = (value) => {
  if (value === "No") {
    return "0";
  }
  if (value === "Yes") {
    return "1";
  }
}

export const isValidForm = (validationSchema, values) => {
  return validationSchema.isValidSync(values);
}

export const getValue = (value) => {
  return (value === null || value === '' || value === undefined) ? 0 : parseInt(value, 10);
}

export const getString = (value) => {
  return (value === null || value === '') ? '' : value;
}

export const validateRequest = (value) => {
  if (Array.isArray(value)) {
    var result = false;
    value.map(x => {
      (x.success && x.body.IsSuccess) ? result = true : result = false;
      return result;
    })
    return result;
  }
  else {
    (value.success && value.body.IsSuccess) ? result = true : result = false;
    return result;
  }
}

export const redirect = (path) => {
  document.cookie = REDIRECT_URI + "=" + CLIENT_URL;
  window.location = path;
}

export const setCookie = (name, value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ",path='/',expires=" + d.toGMTString();
}

export const deleteCookie = (name) => {
  setCookie(name, '', -1);
}