import React from 'react'
import { Route } from 'react-router-dom'
import { AuthConsumer } from './AuthContext'
import Spinner from '../Loading/Spinner';
import ErrorPage from '../Error/ErrorPage'
import AuthHelper from './AuthHelper';
import { PATH_TO_PERMISSIONS } from '../../App/AppSettings';

const ProtectedRoute = ({ component: Component, path: pagePath, ...rest }) => {
  let pagePermissions = PATH_TO_PERMISSIONS[pagePath];
  pagePermissions = pagePermissions === undefined ? [] : pagePermissions;

  return (
    <AuthConsumer>
      {({ isAuthenticated, login, permissions }) =>
        <Route
          render={props =>
            isAuthenticated ?
              typeof pagePermissions === undefined ||
                pagePermissions.length === 0 ||
                AuthHelper.intersectPermissions(pagePermissions, permissions) ?
                <Component {...props} /> :
                <ErrorPage
                  errorHeader="404"
                  errorContent="This page is unavailable. Please contact your site administrator."
                /> :
              (
                <React.Fragment>
                  <Spinner visible={true} />
                  {login(props.location.pathname)}
                </React.Fragment>
              )

          }
          {...rest}
        />
      }
    </AuthConsumer>
  )
}


export default ProtectedRoute
