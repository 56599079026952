export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Data Type",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "DataType"
    },
    {
        "DefaultLanguageValue": "Notify URL",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "NotifyURL"
    },
    {
        "DefaultLanguageValue": "Add Organisation",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "DataType",
        "TranslationKey": "AddOrganisation"
    }
]