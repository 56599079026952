export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "List of Webhooks",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfWebHooks"
    },
    {
        "DefaultLanguageValue": "New Webhook",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewWebHook"
    },
    {
        "DefaultLanguageValue": "Update Webhook",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdateWebHook"
    },
    {
        "DefaultLanguageValue": "Webhook Details",
        "Scope": "MeWebHook",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "WebhookDetails"
    }
]