import { Formik } from 'formik';
import React from 'react';
import _ from 'lodash';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT } from '../../../App/AppSettings';
import Spinner from '../../../Shared/Loading/Spinner';
import { FIELD_SIZE } from '../../Constants';
import { DateInput, DropdownList, SelectList } from '../../Forms';
import { getTranslationValue, isDateOverlapped } from '../../Forms/FormUtils';
import { getUserGroupsByAppIdAndOrganisationId } from '../Components/AssignUserGroupsToUser/Utils';
import { BUTTON_TITLE, ERROR_MSG } from '../../../Localization';


const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const effectiveEndDateValidation = ErrorMsgList.EED_BEFORE_ESD;

    return Yup.object().shape({
        OrganisationId: Yup.string().required(requiredMsg),
        UserGroupId: Yup.string().required(requiredMsg),
        EffectiveStartDate: Yup.date().required(requiredMsg),
        EffectiveEndDate: Yup.date().when('EffectiveStartDate', {
            is: val => val !== null,
            then: Yup.date().min(Yup.ref('EffectiveStartDate'), effectiveEndDateValidation),
        })
    });
};

const FORM_INITIAL_VALUES = {
    OrganisationId: '',
    EffectiveStartDate: '',
    EffectiveEndDate: ''
}

class AssignUserGroupsToUserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            organisationList: props.organisationList,
            organisationIds: props.organisationSelectedOptions,
            values: FORM_INITIAL_VALUES,
            userGroups: [],
            isUserGroupsLoaded: false
        }
    }

    getUserGroups = async (organisationId) => {
        this.setState({
            ...this.state,
            isUserGroupsLoaded: false
        });

        const { translation } = this.props;
        const { organisationList } = this.state;
        const appId = organisationList.find(a => a.Id === organisationId).MeAppId;
        const data = await getUserGroupsByAppIdAndOrganisationId(appId, organisationId, translation);

        this.setState({
            ...this.state,
            isUserGroupsLoaded: true,
            userGroupsData: data.userGroupsData,
            userGroups: data.userGroups,
            toggleUserGroups: true,
            values: {
                ...this.state.values,
                OrganisationId: organisationId
            }
        });
    }

    clearField = (name, values, setFieldValue) => {
        values[name] = '';
        setFieldValue(name, '');
    }

    setFields = (values) => {
        const { toggleUserGroups, organisationList } = this.state;
        const { editItem, translation } = this.props;
        const { userGroupsData } = (editItem === undefined || toggleUserGroups) ? this.state : editItem.userGroupsList;
        const { EffectiveEndDate, OrganisationId } = values;

        const data = userGroupsData.find(data => data.Id === values.UserGroupId);

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;
        
        const organisationName = getTranslationValue(data.OrganisationName, getStaticText, translationKeys);
        const groupName = `${data.GroupName} (${organisationName})`;

        values.Id = editItem !== undefined ? editItem.data.Id : 0;
        values.AppId = data.AppId;
        values.OrganisationName = organisationList.find(a => a.Id === OrganisationId).FullName;
        values.GroupName = groupName;
        values.Description = data.Description;
        values.StrEffectiveEndDate = EffectiveEndDate === "" ? "-" : EffectiveEndDate;
    }

    // Fn: Do not display errors if user wants validation to be seen after submitForm() is called
    displayErrors = (validateOnSubmit, submitCount, errors) => {
        return (validateOnSubmit && submitCount < 1) ? '' : errors;
    };

    resetForm = () => {
        this.setState({
            ...this.state,
            userGroups: [],
            values: FORM_INITIAL_VALUES,
            isUserGroupsLoaded: false,
            toggleUserGroups: false
        })

        this.props.clearEditItem();
    }

    getInitialValues = () => {
        const { editItem } = this.props;
        if (editItem) {
            const item = Object.assign({}, editItem.data);
            return item;
        }
        return FORM_INITIAL_VALUES;
    }

    render() {
        const { toggleUserGroups } = this.state;
        const { data, addItem, editItem, saveEditItem, translation } = this.props;
        const { userGroups } = (editItem === undefined || toggleUserGroups) ? this.state : editItem.userGroupsList;
        const isUserGroupsLoaded = (editItem === undefined || toggleUserGroups) ? this.state.isUserGroupsLoaded : true;
        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        return (
            <Modal key={this.props.modalState} isOpen={this.props.modalState} toggle={() => { this.resetForm(); this.props.toggle(); }} className={this.props.className}>
                <ModalHeader toggle={() => { this.resetForm(); this.props.toggle(); }}>{this.props.modalTitle}</ModalHeader>
                <Formik
                    initialValues={this.getInitialValues()}
                    validateOnBlur={false}
                    validateOnChange={true}
                    validate={values => {
                        let errors = {};

                        const { EffectiveStartDate, EffectiveEndDate } = values;
                        if (EffectiveStartDate) {
                            var inputStart = new Date(EffectiveStartDate);
                            var inputEnd = EffectiveEndDate === '' ? null : new Date(EffectiveEndDate);

                            for (var i = 0; i < data.length; i++) {
                                var item = data[i];
                                var checkDuplicate = false;

                                const isSameUserGroup =
                                    (item.OrganisationId === values.OrganisationId &&
                                        item.UserGroupId === values.UserGroupId);

                                if (editItem) {
                                    // can edit own row; check duplicate of other rows
                                    const isDifferentRow = editItem.rowIndex !== i;
                                    checkDuplicate = isDifferentRow && isSameUserGroup;
                                } else {
                                    checkDuplicate = isSameUserGroup;
                                }

                                if (checkDuplicate) {
                                    var strDataStart = item.EffectiveStartDate;
                                    var dataStart = new Date(strDataStart);
                                    var strDataEnd = item.EffectiveEndDate;
                                    var dataEnd = strDataEnd === '' ? null : new Date(strDataEnd);

                                    if (isDateOverlapped(dataStart, inputStart, dataEnd, inputEnd)) {
                                        
                                        // Date validation part 1
                                        let dateValidationMsgPart1 = getTranslationValue("Overlaps existing User Group:  <%= group %>.", getStaticText, translationKeys);
                                        dateValidationMsgPart1 = _.template(dateValidationMsgPart1)({ group: item.GroupName.trim() });

                                        errors.DateValidationMsgPart1 = dateValidationMsgPart1;

                                        // Date validation part 2
                                        const effectiveFromDateMsg = "Effective from <%= ESD %>";
                                        const effectiveToDateMsg = strDataEnd === '' ? "." : " to <%= EED %>.";

                                        let dateValidationMsgPart2 = effectiveFromDateMsg + effectiveToDateMsg;
                                        dateValidationMsgPart2 = getTranslationValue(dateValidationMsgPart2, getStaticText, translationKeys);
                                        dateValidationMsgPart2 = _.template(dateValidationMsgPart2)({
                                            ESD: strDataStart, EED: strDataEnd
                                        });

                                        errors.DateValidationMsgPart2 = dateValidationMsgPart2;

                                        break;
                                    }
                                }
                            }
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        this.setFields(values);
                        if (editItem) {
                            saveEditItem(values, editItem.rowIndex);
                        } else {
                            addItem(values);
                        }
                        this.resetForm();
                    }}
                    validationSchema={FORM_VALIDATIONS(getStaticText)}
                >
                    {(props) => {
                        const { values, errors, submitCount, handleSubmit, setFieldValue } = props;
                        const formErrors = this.displayErrors(true, submitCount, errors);

                        return (
                            <Form>
                                <ModalBody>
                                    <Spinner visible={!isUserGroupsLoaded && values.OrganisationId !== ''} />

                                    {errors.DateValidationMsgPart1 && errors.DateValidationMsgPart2 &&
                                        <>
                                            <div className="alert alert-danger" role="alert">
                                                {errors.DateValidationMsgPart1}<br />
                                                {errors.DateValidationMsgPart2}
                                            </div>
                                        </>
                                    }
                                    <SelectList
                                        name="OrganisationId"
                                        value={values.OrganisationId}
                                        options={this.state.organisationIds}
                                        onChangeField={(name, value) => {
                                            setFieldValue(name, value);
                                            if (values.OrganisationId !== value) { // get latest agency info once
                                                this.getUserGroups(value);
                                            }
                                            this.clearField('UserGroupId', values, setFieldValue);
                                        }}
                                        isMulti={false}
                                        error={formErrors.OrganisationId}
                                        inputSize={FIELD_SIZE.NONE}
                                        label="Organisation"
                                        translation={translation}
                                        required
                                    />
                                    {isUserGroupsLoaded &&
                                        <DropdownList
                                            key={values.UserGroupId}
                                            name="UserGroupId"
                                            value={values.UserGroupId}
                                            options={userGroups}
                                            onChangeField={setFieldValue}
                                            error={formErrors.UserGroupId}
                                            inputSize={FIELD_SIZE.NONE}
                                            placeholder="User Group"
                                            label="User Group"
                                            translation={translation}
                                            required
                                        />
                                    }
                                    <DateInput
                                        name="EffectiveStartDate"
                                        value={values.EffectiveStartDate}
                                        placeholder="Effective Start Date"
                                        onChangeField={setFieldValue}
                                        time={false}
                                        date={true}
                                        min={new Date()}
                                        format={DATE_DISPLAY_FORMAT.DATE}
                                        inputSize={FIELD_SIZE.NONE}
                                        label="Effective Start Date"
                                        translation={translation}
                                        error={formErrors.EffectiveStartDate}
                                        required
                                    />
                                    <DateInput
                                        name="EffectiveEndDate"
                                        value={values.EffectiveEndDate}
                                        placeholder="Effective End Date"
                                        onChangeField={setFieldValue}
                                        time={false}
                                        date={true}
                                        format={DATE_DISPLAY_FORMAT.DATE}
                                        inputSize={FIELD_SIZE.NONE}
                                        label="Effective End Date"
                                        translation={translation}
                                        error={formErrors.EffectiveEndDate}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="button" onClick={handleSubmit}>{BUTTON_TITLE(getStaticText).SAVE}</Button>
                                    <Button color="secondary" onClick={() => { this.resetForm(); this.props.toggle(); }}>{BUTTON_TITLE(getStaticText).CANCEL}</Button>
                                </ModalFooter>
                            </Form>

                        );
                    }}
                </Formik>
            </Modal>
        )
    }
}

export default AssignUserGroupsToUserModal;