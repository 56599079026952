import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, postParams } from '../../Shared/Actions';
import { toastError, toastSuccess, withSMARTWrapper } from '../../Shared//Forms';
import { URL, USER_CHECK_TYPE } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';

const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

const GRID_ENDPOINT = URL + "User/ListUsers";
const DELETE_URL = URL + 'User/DeleteUser/';
const VIEW_USER_URL_PAGE = '/User/View';
const UPDATE_USER_URL_PAGE = '/User/Update';
const NEW_USER_URL_PAGE = '/User/Check';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleUpdate: false
        };
    }

    //Fn: Delete Row via Id
    async deleteRow(Id) {
        const { loader, getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        if (Id) {
            // Start loading
            loader.start();

            // Send request to backend to delete Row
            const response = await fetchRequest(DELETE_URL + Id, postParams(), false);

            // Done loading
            loader.done();

            const { IsSuccess, Messages } = response.body;

            // Based on response, show appropriate toaster
            (response.success && IsSuccess) ?
                toastSuccess(Messages, null, translation) :
                toastError(Messages, translation);

            if (IsSuccess) {
                this.setState({ toggleUpdate: !this.state.toggleUpdate });
            }
        }
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;

        let Id = rowData.Id;
        let ID = { ID: Id };
        let CheckType = { ID: Id, UserCheckType: USER_CHECK_TYPE.UPDATE_USER };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_USER_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                <MobileCardActionButton
                    key={'Update' + Id}
                    onClick={() => navigateTo(UPDATE_USER_URL_PAGE, CheckType)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
                <MobileCardActionButton
                    key={'Delete' + Id}
                    onClick={() => this.deleteRow(Id)}
                    modal={DELETE_MODAL(getStaticText)}
                    icon={faTrash}
                    label={BUTTON_TITLE(getStaticText).DELETE}
                    formatter={{ component: getTranslationValue('User', getStaticText, UserTranslationKeys) }}
                />
            </React.Fragment>
        )
    }

    setContent = (rowData) => {
        return <div className="div-link" onClick={() => navigateTo(VIEW_USER_URL_PAGE, { ID: rowData.Id })}>{rowData.Username}</div>
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of Users" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ActionButton className='add-button' color="neutral" onClick={() => navigateTo(NEW_USER_URL_PAGE)}><FontAwesomeIcon icon={faPlus} /></ActionButton>
                            <ServerSideDataTable
                                translation={translation}
                                toggleUpdate={this.state.toggleUpdate}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No users found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        Username: { title: 'Username', width: '8%', DBkey: 'Username', setContent: this.setContent },
                                        Organisation: { title: 'Organisation', width: '12%', DBkey: 'Organisation', translateValue: true },
                                        // Email: { title: 'Email', width: '10%', DBkey: 'Email' },
                                        FirstName: { title: 'First Name', width: '8%', DBkey: 'FirstName' },
                                        MiddleName: { title: 'Middle Name', width: '5%', DBkey: 'MiddleName' },
                                        LastName: { title: 'Last Name', width: '8%', DBkey: 'LastName' },
                                        UserType: { title: 'User Type', width: '6%', DBkey: 'UserType', translateValue: true },
                                        LastUpdatedDate: { title: 'Last Updated', width: '8%', DBkey: 'LastUpdatedDate' }
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        {rowData.RowData.IsEditable &&
                                            <ActionButton key={'Update' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                                onClick={() => navigateTo(UPDATE_USER_URL_PAGE, { ID: rowData.ID, UserCheckType: USER_CHECK_TYPE.UPDATE_USER })}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionButton>
                                        }
                                        {/* <ActionButton key={'View' + rowData.ID} tooltip="View User" color="forward" onClick={() => serverTableNavigateTo(VIEW_USER_URL_PAGE, rowData)}><FontAwesomeIcon icon={faEye} /></ActionButton> */}
                                        {rowData.RowData.IsDeletable &&
                                            <ActionButton key={'Delete' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                                onClick={() => this.deleteRow(rowData.ID)} modal={DELETE_MODAL(getStaticText)}
                                                formatter={{ component: getTranslationValue('User', getStaticText, UserTranslationKeys) }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </ActionButton>
                                        }
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);