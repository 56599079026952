
import React from 'react';
import { fetchRequest, getParams } from '../../../Actions';
import { toastError } from '../../../Forms';
import { getTranslationValue } from '../../../Forms/FormUtils';
import { URL } from '../../../SmartMe/Constants';

const GET_ORGANISATION_USER_GROUPS_BY_APP_ID_AND_ORGANISATION_ID_URL =
    URL + 'UserGroup/GetUserGroupsByAppIdAndOrganisationId?';

const dropListMapper = (dataList, translation) => {
    let options = [];

    const getStaticText = translation && translation.getStaticText;
    const translationKeys = translation && translation.translationKeys;

    dataList.forEach((data, index) => {
        const organisationName = getTranslationValue(data.OrganisationName, getStaticText, translationKeys);
        const groupDetails = `${data.GroupName} (${organisationName})`;

        const option =
        {
            key: index,
            text: groupDetails,
            value: data.Id,
            content: (
                <div>
                    <b>{data.GroupName}</b>
                    <div>{organisationName}</div>
                </div>
            )
        };
        options.push(option);
    })
    return options;
}

export const getUserGroupsByAppIdAndOrganisationId = async (appId, organisationId, translation) => {
    let param = { appId: appId, organisationId: organisationId };
    let response = await fetchRequest(GET_ORGANISATION_USER_GROUPS_BY_APP_ID_AND_ORGANISATION_ID_URL, getParams(param), false);
    let userGroups = { userGroups: [], userGroupsData: [] };

    const { IsSuccess, Data, Messages } = response.body;

    if (response.success && IsSuccess) {
        return { userGroups: dropListMapper(Data, translation), userGroupsData: Data };
    }
    else {
        toastError(Messages);
    }

    return userGroups;
}

export const getUserGroups = (userGroupList, translation) => {
    const getStaticText = translation && translation.getStaticText;
    const translationKeys = translation && translation.translationKeys;

    userGroupList.forEach((data) => {
        if (!data.translated) {
            const organisationName = getTranslationValue(data.OrganisationGroupName, getStaticText, translationKeys);
            data.GroupName = `${data.GroupName} (${organisationName})`;
            data.translated = true;
        }
    });
    return userGroupList;
}