import { default as React } from 'react';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { LAYOUT_TYPE } from '../../App/AppSettings';
import { ERROR } from '../../Localization';
import { CONTENT_TYPE, formParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { Paragraph, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import { NavigationPrompt } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { ONLINEHELP_URL } from '../../App/AppSettings';
import { Tree } from '../../Shared/Tree';
import Title from '../../Title';


const SERVER_URL = ONLINEHELP_URL;

const FORM_CONTEXT = "Online Help"

const FORM_INITIAL_VALUES = {
    OnlineHelpName: '',
    OnlineHelpDescription: '',
    OnlineHelpTree: '',
};

const FORM_VALIDATIONS = Yup.object().shape({
    OnlineHelpName: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),
    OnlineHelpDescription: Yup.string().required(ERROR.REQUIRED),
    OnlineHelpTree: Yup.string()
});

class OnlineHelpMgmtTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            documentReady: false
        }
    }



    async componentDidMount() {

        // const { Data, IsSuccess } = response.body;
        // if (IsSuccess){
        this.setState({
            // values: Data,
            documentReady: true
        })
        // }
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess } = response.body;
            if (IsSuccess) {
                toastSuccess("Online Help Tree successfully created");
            }
            else {
                toastError("Online Help Tree was not created");
            }
        } else {
            toastError("Server could not be reached, please try again later")
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className={"first body-title body-title-text"} titleValue={"Online Help"}></Title>
                <div className="onlinehelp-container">
                    {this.state.documentReady &&
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            formParams={formParams("POST", CONTENT_TYPE.JSON)}
                            serverURL={SERVER_URL}
                            isDummy={false}
                            submitCallback={this.submitCallback}
                            validationSchema={FORM_VALIDATIONS}
                            validateOnSubmit={true}
                        >
                            {({ values, onChange, onChangeField, submitPlainForm, errors, dirty }) => (
                                <React.Fragment>
                                    <Text
                                        name="OnlineHelpName"
                                        value={values.OnlineHelpName}
                                        placeholder="Online Help Name"
                                        onChange={onChange}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize={10}
                                        labelSize={FIELD_SIZE.XSMALL}
                                        label="Online Help Name"
                                        error={errors.OnlineHelpName}
                                        required
                                    />
                                    <Paragraph
                                        name="OnlineHelpDescription"
                                        value={values.OnlineHelpDescription}
                                        placeholder="Description"
                                        onChange={onChange}
                                        rows={3}
                                        minLength={0}
                                        inputSize={10}
                                        labelSize={FIELD_SIZE.XSMALL}
                                        label="Description"
                                        error={errors.OnlineHelpDescription}
                                        required
                                    />
                                    <Tree
                                        treeHeader="Help Tree"
                                        renderActions={true}
                                        error={errors.OnlineHelpTree}
                                        name="OnlineHelpTree"
                                        value={values.OnlineHelpTree}
                                        onChangeField={onChangeField}
                                    />

                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>Back</Button>
                                        <Button color="forward" size="sm" type="button" onClick={() => { submitPlainForm(); this.setState({ values: values }) }}>Save</Button>
                                    </PageFooter>
                                    <NavigationPrompt
                                        when={dirty}
                                        contentHeader={"Form not saved"}
                                        contentBody={"The form has not been saved, are you sure you want to leave this page?"}
                                        confirmText={"Leave"}
                                        cancelText={"Stay"}
                                    />
                                </React.Fragment>
                            )}
                        </SMARTForm>}
                </div>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(OnlineHelpMgmtTree);

