import React from 'react';
import _ from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE, ADDRESS, DEFAULT_ADDRESS_TYPE, OPTIONAL_ADDRESS_TYPE, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { URL } from '../../Shared/SmartMe/Constants';
import * as Yup from 'yup';
import history from '../../Shared/History';
import { SMARTForm, Plaintext, Radio, Paragraph, withSMARTWrapper, toastSuccess, toastError, FileUpload } from '../../Shared/Forms';
import { isValidForm } from '../../Shared/SmartMe/Actions';
import { ModalConfirm } from '../../Shared/Modal';
import SubSection from '../../Section/SubSection';
import { constructNumber } from '../../Shared/SmartMe/Utils/FormUtils'
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';
import './UserApproval.css';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const GET_USER_REQUEST_URL =
    URL + 'UserActivationRequest/GetUserActivationRequestByActivationKey/';

const SERVER_URL = URL + 'User/UserApproval';
const GET_FILE_URL = URL + 'MeDocument/File?';

const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

const APPROVE = "Approve";
const REJECT = "Reject";

const APPROVAL_STATUS_TYPES = [
    { label: "Approve", value: APPROVE },
    { label: "Reject", value: REJECT }
]

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;

    return Yup.object().shape({
        Status: Yup.string().required(requiredMsg),
        Remarks: Yup.string().when('Status', {
            is: REJECT,
            then: Yup.string().required(requiredMsg),
        })
    });
}

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UATranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Approval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            values: {

                MobileNumber: {
                    CountryCode: "",
                    Number: ""
                },
                OfficeNumber: {
                    CountryCode: "",
                    Number: ""
                },
                HomeNumber: {
                    CountryCode: "",
                    Number: ""
                },
                FaxNumber: {
                    CountryCode: "",
                    Number: ""
                },
                Address: {
                    AddressType: DEFAULT_ADDRESS_TYPE,
                    PostalCode: '',
                    Country: '', // Only applicable to Address Type: Overseas
                    City: '',
                    State: '',
                    AddressLine1: ADDRESS.ADDRESS_FIELDS.LINE1,
                    AddressLine2: ADDRESS.ADDRESS_FIELDS.LINE2
                }
                //  IdNumbers: JSON.parse(data.Profile.IdNumbers),

            },
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;

        loader.start();

        let response = await fetchRequest(
            GET_USER_REQUEST_URL + this.props.match.params.activationkey, getParams(), false);

        if (response.success && response.body.IsSuccess) {
            let data = response.body.Data;
            this.setState({
                formValues: {
                    ActivationKey: this.props.match.params.activationkey,
                    Status: '',
                    Remarks: ''
                },
                values: {
                    IdentificationNo: data.Profile.IdentificationNo,
                    FirstName: data.Profile.FirstName,
                    MiddleName: data.Profile.MiddleName || "-",
                    LastName: data.Profile.LastName,
                    Gender: data.Profile.Gender,
                    DateOfBirth: data.Profile.DateOfBirth,
                    Username: data.Username,
                    Email: data.Email,
                    FileUploadFiles: data.SingleFileUploadFiles,
                    Id: data.Id,
                    Salutation: data.Profile.Salutation,
                    Nationality: data.Profile.Nationality,
                    AltEmailAddress: data.Profile.AltEmailAddress,
                    PrimaryContact: data.Profile.PrimaryContact,
                    MobileNumber: data.Profile.MobileNumber,
                    OfficeNumber: data.Profile.OfficeNumber,
                    HomeNumber: data.Profile.HomeNumber,
                    FaxNumber: data.Profile.FaxNumber,
                    Address: data.Profile.Address,
                    IdNumbers: JSON.parse(data.Profile.IdNumbers),

                },
                isLoaded: true
            });
        }
        else {
            pageNotFound();
        }
        loader.done();
    }

    getTranslationText = (text, formatter) => {
		const { getStaticText } = this.props;

		const translatedText = getTranslationValue(text, getStaticText, UATranslationKeys);
		return formatter === undefined ? translatedText : _.template(translatedText)(formatter);
	}

    render() {
        const { modal, getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UATranslationKeys
        };

        const { MobileNumber, OfficeNumber, FaxNumber, HomeNumber } = this.state.values;

        let mobileNumberCountryCode = MobileNumber === null ? null : MobileNumber.CountryCode;
        let mobileNumberNumber = MobileNumber === null ? null : MobileNumber.Number;
        let officeNumberCountryCode = OfficeNumber === null ? null : OfficeNumber.CountryCode;
        let officeNumberNumber = OfficeNumber === null ? null : OfficeNumber.Number;
        let faxNumberCountryCode = FaxNumber === null ? null : FaxNumber.CountryCode;
        let faxNumberNumber = FaxNumber === null ? null : FaxNumber.Number;
        let homeNumberCountryCode = HomeNumber === null ? null : HomeNumber.CountryCode;
        let homeNumberNumber = HomeNumber === null ? null : HomeNumber.Number;
        let altEmailAddress = this.state.values.AltEmailAddress;
        let idArray = [];

        if (this.state !== null && this.state.values.IdNumbers) {
            this.state.values.IdNumbers.forEach((idNumber, idx) => {
                const country = getTranslationValue(idNumber.IdCountry, getStaticText, UATranslationKeys);
				const idValue = this.getTranslationText("<%= IdNumber %> (Issuing Country: <%= IdCountry %>)",
					{ IdNumber: idNumber.IdNumber, IdCountry: country });

                idArray.push(
                    <Plaintext
                        label={idNumber.IdType}
                        name="Profile.IdNumber"
                        value={idValue}
                        labelSize={LABEL_SIZE}
                        translation={translation}
                        key={idx}
                    />
                )
            })
        }


        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="User Approval">
                        <SMARTForm
                            formValues={this.state.formValues}
                            validationSchema={FORM_VALIDATIONS(getStaticText)}
                            validateOnSubmit={true}
                            serverURL={SERVER_URL}
                            submitCallback={({ response }) => {
                                const { IsSuccess, Messages } = response.body;
                                if (response.success && IsSuccess) {
                                    history.push("/UserApproval/PendingUserRequests");
                                    toastSuccess(Messages, null, translation);
                                }
                                else {
                                    toastError(ERROR_MSG(getStaticText).SERVER_ERROR);
                                }
                            }}
                        >
                            {({ values, errors, onChange, submitForm }) => (
                                <React.Fragment>
                                    <Row className="body-content">
                                        <Col>
                                            <SubSection orientation="left" translation={translation} title="Personal Details">
                                                <Plaintext
                                                    label="Username"
                                                    name="Username"
                                                    value={this.state.values.Username}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                />
                                                <Plaintext
                                                    label="Gender"
                                                    name="Gender"
                                                    value={this.state.values.Gender}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                    translateValue={true}
                                                />
                                                <Plaintext
                                                    label="Date Of Birth"
                                                    name="DateOfBirth"
                                                    value={this.state.values.DateOfBirth}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                />
                                                <Plaintext
                                                    label="Nationality"
                                                    name="Nationality"
                                                    value={this.state.values.Nationality}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                    translateValue={true}
                                                />
                                                {idArray}
                                            </SubSection>

                                            <SubSection orientation="left" translation={translation} title="Contact Info">
                                                <Plaintext
                                                    label="Primary Email Address"
                                                    name="Email"
                                                    value={this.state.values.Email}
                                                    translation={translation}
                                                />
                                                {altEmailAddress !== null &&
                                                    <Plaintext
                                                        label="Alternate Email Address"
                                                        name="AltEmailAddress"
                                                        value={altEmailAddress}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                }
                                                <Plaintext
                                                    label="Primary Contact"
                                                    name="Primary Contact"
                                                    value={`${this.state.values.PrimaryContact} Number`}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                    translateValue={true}
                                                />
                                                <Plaintext
                                                    label="Mobile Number"
                                                    name="Mobile Number"
                                                    value={constructNumber(mobileNumberCountryCode, mobileNumberNumber)}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                />
                                                {officeNumberCountryCode !== null && officeNumberNumber !== null &&
                                                    <Plaintext
                                                        label="Office Number"
                                                        name="Office Number"
                                                        value={constructNumber(officeNumberCountryCode, officeNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                }
                                                {homeNumberCountryCode !== null && homeNumberNumber !== null &&
                                                    <Plaintext
                                                        label="Home Number"
                                                        name="Home Number"
                                                        value={constructNumber(homeNumberCountryCode, homeNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                }
                                                {faxNumberCountryCode !== null && faxNumberNumber !== null &&
                                                    <Plaintext
                                                        label="Fax Number"
                                                        name="Fax Number"
                                                        value={constructNumber(faxNumberCountryCode, faxNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                }
                                            </SubSection>

                                            <SubSection orientation="left" translation={translation} title="Address Details">
                                                <Plaintext
                                                    label="Address Type"
                                                    name="Profile.Address.AddressType"
                                                    value={this.state.values.Address.AddressType}
                                                    labelSize={LABEL_SIZE}
                                                    translation={translation}
                                                    translateValue={true}
                                                />
                                                {this.state.values.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                                    <>
                                                        <Plaintext
                                                            label="ZIP / Postal Code"
                                                            name="Profile.Address.PostalCode"
                                                            value={this.state.values.Address.PostalCode}
                                                            labelSize={LABEL_SIZE}
                                                            translation={translation}
                                                        />
                                                        {this.state.values.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                            <>
                                                                <Plaintext
                                                                    label="Block / House No."
                                                                    name="Profile.Address.AddressLine1.BlockNo"
                                                                    value={this.state.values.Address.AddressLine1.BlockNo}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                />
                                                                <Plaintext
                                                                    label="Street Name"
                                                                    name="Profile.Address.AddressLine1.StreetName"
                                                                    value={this.state.values.Address.AddressLine1.StreetName}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                />
                                                                {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.LevelNo !== null &&
                                                                    <Plaintext
                                                                        label="Level"
                                                                        name="Profile.Address.AddressLine2.LevelNo"
                                                                        value={this.state.values.Address.AddressLine2.LevelNo}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                }
                                                                {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.UnitNo !== null &&
                                                                    <Plaintext
                                                                        label="Unit No."
                                                                        name="Profile.Address.AddressLine2.UnitNo"
                                                                        value={this.state.values.Address.AddressLine2.UnitNo}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                }
                                                                {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.BuildingName !== null &&
                                                                    <Plaintext
                                                                        label="Building Name"
                                                                        name="Profile.Address.AddressLine2.BuildingName"
                                                                        value={this.state.values.Address.AddressLine2.BuildingName}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                        {this.state.values.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                                            <>
                                                                <Plaintext
                                                                    label="Country"
                                                                    name="Profile.Address.Country"
                                                                    value={this.state.values.Address.Country}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                    translateValue={true}
                                                                />
                                                                {this.state.values.Address.City !== null &&
                                                                    <Plaintext
                                                                        label="City"
                                                                        name="Profile.Address.City"
                                                                        value={this.state.values.Address.City}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                }
                                                                <Plaintext
                                                                    label="State / Province / Region"
                                                                    name="Profile.Address.State"
                                                                    value={this.state.values.Address.State}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                />
                                                                <Plaintext
                                                                    label="Address Line 1"
                                                                    name="Profile.Address.AddressLine1.AddressLine1"
                                                                    value={this.state.values.Address.AddressLine1.AddressLine1}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                />
                                                                <Plaintext
                                                                    label="Address Line 2"
                                                                    name="Profile.Address.AddressLine2.AddressLine2"
                                                                    value={this.state.values.Address.AddressLine2.AddressLine2}
                                                                    labelSize={LABEL_SIZE}
                                                                    translation={translation}
                                                                />
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </SubSection>

                                            <SubSection orientation="left" translation={translation} title="Supporting Documents">
                                                <FileUpload
                                                    name="PhotoIdentification"
                                                    fileList={this.state.values.FileUploadFiles}
                                                    placeholder="Photo Identification"
                                                    value={this.state.values.PhotoIdentification}
                                                    label="Photo Identification"
                                                    translation={translation}
                                                    getFileURL={GET_FILE_URL}
                                                    readonly
                                                />
                                            </SubSection>
                                            <SubSection orientation="left" translation={translation} title="Approval">
                                                <Radio
                                                    name="Status"
                                                    value={values.Status}
                                                    options={APPROVAL_STATUS_TYPES}
                                                    onChange={onChange}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                    label="Status"
                                                    translation={translation}
                                                    error={errors.Status}
                                                    required
                                                />
                                                {/* {values.Status === APPROVE &&
                                                    <Paragraph
                                                        charLeftText={false}
                                                        name="Remarks"
                                                        value={values.Remarks}
                                                        placeholder="Remarks (If any)"
                                                        onChange={onChange}
                                                        inputSize={7}
                                                        label="Remarks"
                                                        error={errors.Remarks}
                                                    />
                                                } */}
                                                {values.Status === REJECT &&
                                                    <Paragraph
                                                        charLeftText={false}
                                                        name="Remarks"
                                                        value={values.Remarks}
                                                        placeholder="Rejection Reason"
                                                        onChange={onChange}
                                                        inputSize={8}
                                                        label="Rejection Reason"
                                                        translation={translation}
                                                        error={errors.Remarks}
                                                        required
                                                    />
                                                }
                                            </SubSection>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <PageFooter>
                                            <Button color="primary" type="button" onClick={() => navigateTo('/UserApproval/AmendUserRequest/' + values.ActivationKey)}>{BUTTON_TITLE(getStaticText).AMEND}</Button>
                                            <Button color="forward" type="button" onClick={() => isValidForm(FORM_VALIDATIONS(getStaticText), values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                            <ModalConfirm
                                                translation={translation}
                                                isOpen={modal.modalState === "Submit"}
                                                contentHeader="User Approval Confirmation"
                                                contentBody={values.Status === APPROVE ? "Are you sure you want to approve this user?" : "Are you sure you want to reject this user?"}
                                                confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                                cancelCallback={() => modal.toggleModal("Submit")}
                                            />
                                        </PageFooter>
                                    </Row>
                                </React.Fragment>
                            )}
                        </SMARTForm>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default withSMARTWrapper(withRouter(Approval));