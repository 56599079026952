export const ModalTranslationKeys = [
    {
        "DefaultLanguageValue": "Create <%= component %> Confirmation",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "CreateHeader"
    },
    {
        "DefaultLanguageValue": "Confirm creation of <%= component %>?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "CreateBody"
    },
    {
        "DefaultLanguageValue": "Update <%= component %> Confirmation",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "UpdateHeader"
    },
    {
        "DefaultLanguageValue": "Confirm update of <%= component %>?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "UpdateBody"
    },
    {
        "DefaultLanguageValue": "Delete <%= component %>",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "DeleteHeader"
    },
    {
        "DefaultLanguageValue": "Are you sure you want to delete <%= component %>?",
        "Scope": "SMARTMe",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "DeleteBody"
    }
]