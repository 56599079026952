import React from 'react';
import { Row, Col } from 'reactstrap';
import { getTranslationValue } from '../Shared/Forms/FormUtils';

class Title extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      className: this.props.className
    };
  }

  render() {
    const { titleValue, translation } = this.props;

    const getStaticText = translation && translation.getStaticText;
    const translationKeys = translation && translation.translationKeys;

    const titleTextValue = getStaticText && translationKeys ? getTranslationValue(titleValue, getStaticText, translationKeys) : titleValue;

    return (
      <React.Fragment>
        <Row className={this.state.className}>
          <Col>
            {(this.props.titleValue &&
              <Row>
                <Col className="title-text">
                  {titleTextValue}
                </Col>
              </Row>)}
            {this.props.children}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Title;