import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ERROR } from '../../Localization';
import FileUploadSectionViewer from './FileUploadSectionViewer';
import { ErrorLabel } from '../Forms';
import { ModalFileUpload } from '../Modal';


class FileUploadSectionAdder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalToggle: false,
            fileLimitError: ''
        };
    }

    // Toggle file upload modal
    toggleFileUploadModal = (callback) => {

        // If callback parameter is given, call it
        callback && callback();

        this.setState({ modalToggle: !this.state.modalToggle });
    };

    // Fn: Validate if file has reached given limit
    hasFileLimitReached = (fileList, uploadLimit) => {

        // If no uploadLimit OR number didnt exceed uploadLimit
        if (uploadLimit === 0 || fileList.length < uploadLimit) {
            // Reset / Display no error text
            this.setState({ fileLimitError: '' });
            return false;
        }

        else {
            // Display error text
            this.setState({ fileLimitError: ERROR.FILE_LIMIT_REACHED });
            return true;
        }
    };

    // Fn: Handle the actions of adder button
    handleAdderButton = ({ fileList, uploadLimit }) => {

        // If file upload limit has not reached
        if (!this.hasFileLimitReached(fileList, uploadLimit)) {

            // Toggle file upload modal
            this.toggleFileUploadModal();
        }
    };

    render() {
        return (
            <React.Fragment>

                {/* Button: For modal toggling */}
                <Button color="neutral" onClick={() => this.handleAdderButton(this.props)}>
                    {`Add ${this.props.sectionName}`}
                </Button>

                {/* Error Label: For file upload limit */}
                <ErrorLabel
                    message={this.state.fileLimitError}
                    formatter={{ limit: this.props.uploadLimit }}
                />

                {/* Incorporates the Viewer component inside*/}
                <FileUploadSectionViewer
                    name={this.props.name}
                    fileList={this.props.fileList}
                    getFileURL={this.props.getFileURL}
                    deleteFileURL={this.props.deleteFileURL}
                    onChangeField={this.props.onChangeField}
                    readonly={this.props.readonly}
                    hasFileLimitReached={() => this.hasFileLimitReached(this.props.fileList, this.props.uploadLimit)}
                />

                {/* Modal: Renders FileUploadModal */}
                <ModalFileUpload
                    fileName={this.props.name}
                    fileList={this.props.fileList}
                    onConfirm={this.props.onChangeField}
                    isOpen={this.state.modalToggle}
                    toggleModal={this.toggleFileUploadModal}
                    contentHeader={`Add ${this.props.sectionName}`}
                />
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
FileUploadSectionAdder.propTypes = {
    name: PropTypes.string.isRequired,
    fileList: PropTypes.array.isRequired,
    sectionName: PropTypes.string.isRequired,
    onChangeField: PropTypes.func.isRequired,
    uploadLimit: PropTypes.number                   // 0 is unlimited
};

// PropTypes: Defaulting value for optional props
FileUploadSectionAdder.defaultProps = {
    uploadLimit: 0
};


export default FileUploadSectionAdder;