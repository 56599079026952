import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div className={"navbar-footer"}>
                {this.props.footerExtension}
                
                <div className="footer-copyright">&copy; <b>Copyright</b> CrimsonLogic {(new Date()).getFullYear()}</div>
            </div>
        );
    }
}

export default Footer;