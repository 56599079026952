import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withTranslationWrapper } from '../../Shared/Forms';
import { BUTTON_TITLE } from '../../Localization';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { getTranslationValue } from '../../Shared/Forms/FormUtils';


class ModalConfirm extends React.Component {
    getContentHeader = ({ contentHeader, formatter, getStaticText, translation }) => {
        const contentHeaderText = translation !== undefined ? getTranslationValue(contentHeader, getStaticText, translation.translationKeys) : contentHeader;
        return formatter !== undefined && typeof contentHeader === "string" ?
            _.template(contentHeaderText)(formatter) : contentHeaderText;
    };

    getContentBody = ({ contentBody, formatter, getStaticText, translation }) => {
        const contentBodyText = translation !== undefined ? getTranslationValue(contentBody, getStaticText, translation.translationKeys) : contentBody;
        return formatter !== undefined && typeof contentBody === "string" ?
            _.template(contentBodyText)(formatter) : contentBodyText;
    };

    render() {
        let { confirmText, cancelText, getStaticText } = this.props;
        confirmText = confirmText === 'Yes' ? BUTTON_TITLE(getStaticText).YES : confirmText;
        cancelText = cancelText === 'No' ? BUTTON_TITLE(getStaticText).NO : cancelText;

        return (
            <Modal isOpen={this.props.isOpen} className={"modal-content " + this.props.className}>
                <ModalHeader>{this.getContentHeader(this.props)}</ModalHeader>
                <ModalBody>{this.getContentBody(this.props)}</ModalBody>
                <ModalFooter>
                    {this.props.cancelCallback && <Button color="backward" size="sm" onClick={this.props.cancelCallback} disabled={this.props.cancelBtnDisabled}>{cancelText}</Button>}
                    {this.props.confirmCallback && <Button color="forward" size="sm" onClick={this.props.confirmCallback} disabled={this.props.confirmBtnDisabled}>{confirmText}</Button>}
                </ModalFooter>
            </Modal>
        );
    }
}

// PropTypes: For prop validation
ModalConfirm.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func
};

// PropTypes: Defaulting value for optional props
ModalConfirm.defaultProps = {
    isOpen: false,
    confirmText: 'Yes',
    cancelText: 'No'
};


export default compose(withRouter, withTranslationWrapper)(ModalConfirm);