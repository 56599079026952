import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Divider } from 'antd';
import * as Yup from 'yup';
import { ADD_FAQ_URL, FAQ_MANAGEMENT_URL, APPLICATION_LIST_URL,
    CODETABLE_LANGUAGE_LIST_URL, SUPPORTED_LANGUAGE_LIST_URL, FORM_LAYOUT_TYPES } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, SMARTForm, Text, SelectList, ToggleSwitch, Paragraph, Checkbox } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "AddFaq";
const dropDownArrayApplication = 'dropDownArrayApplication';
const dropDownArrayLanguage = 'dropDownArrayLanguage';
const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';
const FORM_INITIAL_VALUES = {
    Title: '',
    Description: '',
    Application: '',
    IsLocaleEnabled: "No",
    IsQnAMakerSyncEnabled: "No"
}
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;
const TOGGLE_YES = "Yes";

class AddFaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                "Language": {}
            },
            [dropDownArrayApplication]: [],
            [dropDownArrayLanguage]: [],
            [dropDownArraySupportedLanguage]: [],
            toggleValue: false,
            applicationName: '',
            renderChooseLanguage: false
        }
    }

    componentDidMount = async () => {
        this.props.loader.start();
        
        // Get all Applications
        await this.getApplicationDropdownList(APPLICATION_LIST_URL, dropDownArrayApplication);

        this.props.loader.done();
    }

    // Fn: Get all Application list
    getApplicationDropdownList = async (url, dropDownName) => {

        const response = await fetchRequest(url, getParams());
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {
                
                // Add data object in array
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.Name, 'value': name.Name });
                });

                // Set array to dropDownArrayApplication array
                this.setState({
                    [dropDownName]: dropDownArray
                });

            } else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }

    // Fn: Get language list from code table
    getLanguageDropdownList = async (url, dropDownName, applicationName) => {
        const params = {
            ApplicationName: applicationName
        };

        const response = await fetchRequest(url, getParams(params));
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {

                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.CodeTitle, 'value': name.CodeValue });
                });

                // Set array to dropDownArrayLanguage
                this.setState({
                    [dropDownName]: dropDownArray
                });

            } else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }

    // Fn: Set application name and get language list if toggle value is true
    getSupportedLanguage = async (name, value, onChangeField) => {
        
        onChangeField(name, value);

        // Set application name
        this.setState({
            applicationName: value,
            renderChooseLanguage: true
        });

        // If toggle value is true, get supported language
        if (this.state.toggleValue) {
            // Call GetSupportedLanguage from backend
            await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, dropDownArraySupportedLanguage, this.state.applicationName);

        }
        else {
            // Get all language from code table
            await this.getLanguageDropdownList(CODETABLE_LANGUAGE_LIST_URL, dropDownArrayLanguage, value);
        }
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            Title: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .max(255, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            Description: Yup.string()
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(2000, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT))
        });
    };

    // Fn: Set Toggle value to true or false 
    setToggleValue = (name, value, onChangeField) => {
        let appName = this.state.applicationName;

        if (value === TOGGLE_YES) {

             // Set toggle value to false if is Yes
            this.setState({
                toggleValue: true
            });


            // Get supported language from application
            this.getSupportedLanguageDropdownList(
                SUPPORTED_LANGUAGE_LIST_URL,
                dropDownArraySupportedLanguage,
                appName);


        }
        else {

            // Set toggle value to false if is No
            this.setState({
                toggleValue: false
            });
        }
        onChangeField(name, value);
    }

    // Fn: Get language list from code table
    getSupportedLanguageDropdownList = async (url, dropDownName, value) => {
        this.props.loader.start();
        const response = await fetchRequest(url, getParams({applicationName: value}));
        const { Data, IsSuccess, Message } = response.body;
        let dropDownArray = [];

        this.props.loader.done();
        if (response.success) {
            if (IsSuccess) {

                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push(name.LanguageCodeTitle);
                });

                // Set array to dropDownArraySupportedLanguage
                this.setState({
                    [dropDownName]: dropDownArray
                });

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }

    // Fn: Show check box when Application is selected and IsLocaleEnabled is true
    showCheckbox = (onChangeField) => {
        return this.state.applicationName ?

            // Return checkbox when Application is selected
            (
                <Checkbox
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                    name="Language"
                    value={this.state.dropDownArraySupportedLanguage}
                    options={this.createLanguageCheckboxOptions(this.state.dropDownArraySupportedLanguage)}
                    onChangeField={onChangeField}
                    labelSize={LABEL_SIZE}
                    inputSize={INPUT_SIZE}
                    checked
                    required
                />
            )

            // Return empty if no Application is selected
            : ""
    }

    // Fn: Create array of language object for checkbox options
    createLanguageCheckboxOptions = (languageList) => {
        
        const languageObjArray = [];

        // Add Language object to array
        languageList.map((lang) => 
            languageObjArray.push({
                label: lang,
                value: lang
            })
        );

        return languageObjArray;
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(FAQ_MANAGEMENT_URL, "");
                }
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATE, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.FAQ);

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }
    
    render() {
        const { dropDownArrayApplication, dropDownArrayLanguage } = this.state;
        return (
            <>
                <Layout type={FORM_LAYOUT_TYPES.FULL_PAGE} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_ADDFAQ, DEFAULT_TEXT.ADD_FAQ)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={FORM_INITIAL_VALUES}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={ADD_FAQ_URL}
                        nextUrl={FAQ_MANAGEMENT_URL}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col>
                                    <Col>
                                        <SelectList
                                            name="Application"
                                            value={values.Application}
                                            options={dropDownArrayApplication}
                                            onChangeField={(name, value) => {this.getSupportedLanguage(name, value, onChangeField)}}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_APPLICATION, DEFAULT_TEXT.SELECT_APP)}
                                            isMulti={false}
                                            labelSize={FIELD_SIZE.SMALL}
                                            inputSize={FIELD_SIZE.MEDIUM}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                            required
                                            error={errors.Application}
                                        />
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE, DEFAULT_TEXT.TITLE)}
                                            onChange={onChange}
                                            maxLength={255}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            onChange={onChange}
                                            rows={3}
                                            maxLength={2000}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                        
                                        {this.state.renderChooseLanguage ? 

                                            <React.Fragment>
                                                <Col lg="12" className="label-default">
                                                    <Divider className="db-divider" orientation="left">
                                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_DIVIDER_AICHATBOT, DEFAULT_TEXT.AI_CHATBOT)}
                                                    </Divider>
                                                </Col>
                                                <ToggleSwitch
                                                    name="IsQnAMakerSyncEnabled"
                                                    value={values.IsQnAMakerSyncEnabled}
                                                    onChangeField={onChangeField}
                                                    defaultOn={false}
                                                    labelSize={FIELD_SIZE.SMALL}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEAI, DEFAULT_TEXT.ENABLE_AI)}
                                                    required
                                                    error={errors.Language}
                                                />
                                                <Col lg="12" className="label-default">
                                                    <Divider className="db-divider" orientation="left">
                                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_DIVIDER_MULTILANG, DEFAULT_TEXT.MULTI_LANGUAGE)}
                                                    </Divider>
                                                </Col>
                                                <ToggleSwitch
                                                    name="IsLocaleEnabled"
                                                    value={values.IsLocaleEnabled}
                                                    onChangeField={(name, value) => {this.setToggleValue(name, value, onChangeField)}}
                                                    defaultOn={false}
                                                    labelSize={FIELD_SIZE.SMALL}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEMULTILANG, DEFAULT_TEXT.ENABLE_MULTI_LANG)}
                                                    required
                                                    error={errors.Language}
                                                />
                                            </React.Fragment>
                                        :
                                        ""
                                        }
                                        
                                        {
                                            this.state.renderChooseLanguage ? 
                                                this.state.toggleValue ? 
                                                this.showCheckbox(onChangeField)
                                                : 
                                                <SelectList 
                                                    name="Language"
                                                    value={values.Language}
                                                    options={dropDownArrayLanguage}
                                                    onChangeField={onChangeField}
                                                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_LANG)}
                                                    isMulti={false}
                                                    labelSize={FIELD_SIZE.SMALL}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                                                    required
                                                    error={errors.Language}
                                                /> 
                                                :
                                                ""
                                        }

                                    </Col>
                                    <PageFooter type={FORM_LAYOUT_TYPES.FULL_PAGE}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(FAQ_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(AddFaq));
