export const ModalTranslationKeys = [
    {
        "DefaultLanguageValue": "Roles",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Tab",
        "TranslationKey": "Roles"
    },
    {
        "DefaultLanguageValue": "Users",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Tab",
        "TranslationKey": "Users"
    },
    {
        "DefaultLanguageValue": "Confirm creation of User Group with the following roles?",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "CreateUserGroupBody"
    },
    {
        "DefaultLanguageValue": "Confirm update of User Group with the following following roles?",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "UpdateUserGroupBody"
    },
    {
        "DefaultLanguageValue": "Add new User",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "AddNewUser"
    },
    {
        "DefaultLanguageValue": "Edit User",
        "Scope": "MeUserGroup",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "EditUser"
    }
]