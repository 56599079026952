export const MenuTranslationKeys = [
    {
        "DefaultLanguageValue": "Organisation",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "Organisation"
    },
    {
        "DefaultLanguageValue": "Permission",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "Permission"
    },
    {
        "DefaultLanguageValue": "Role",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "Role"
    },
    {
        "DefaultLanguageValue": "User Group",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "UserGroup"
    },
    {
        "DefaultLanguageValue": "User",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "User"
    },
    {
        "DefaultLanguageValue": "List of Active Users",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "ListOfActiveUsers"
    },
    {
        "DefaultLanguageValue": "List of Non-Active Users",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "ListOfNonActiveUsers"
    },
    {
        "DefaultLanguageValue": "User Approval",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "UserApproval"
    },
    {
        "DefaultLanguageValue": "Pending User Requests",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "PendingUserRequests"
    },
    {
        "DefaultLanguageValue": "Policy",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "Policy"
    },
    {
        "DefaultLanguageValue": "Password Policy",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "PasswordPolicy"
    },
    {
        "DefaultLanguageValue": "Webhook",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "Webhook"
    },
    {
        "DefaultLanguageValue": "System Configuration",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "SystemConfiguration"
    },
    {
        "DefaultLanguageValue": "Application Configuration",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "ApplicationConfiguration"
    },
    {
        "DefaultLanguageValue": "Code Table",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "CodeTable"
    },
    {
        "DefaultLanguageValue": "Notification Template",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "NotificationTemplate"
    },
    {
        "DefaultLanguageValue": "Email Template",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "EmailTemplate"
    },
    {
        "DefaultLanguageValue": "SMS Template",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "SMSTemplate"
    },
    {
        "DefaultLanguageValue": "Language Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "LanguageTemplate"
    },
    {
        "DefaultLanguageValue": "Language Registration",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "LanguageRegistration"
    },
    {
        "DefaultLanguageValue": "Translation Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "TranslationManagement"
    },
    {
        "DefaultLanguageValue": "Scope Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "ScopeManagement"
    },
    {
        "DefaultLanguageValue": "Category Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "CategoryManagement"
    },
    {
        "DefaultLanguageValue": "Sub-Category Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "SubCategoryManagement"
    },
    {
        "DefaultLanguageValue": "Language Pack Update",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "LanguagePackUpdate"
    },
    {
        "DefaultLanguageValue": "FAQ Management",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "FAQManagement"
    },
    {
        "DefaultLanguageValue": "Public Holiday",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "PublicHoliday"
    },
    {
        "DefaultLanguageValue": "Working Hour",
        "Scope": "SMARTMe",
        "Category": "Menu",
        "SubCategory": "Label",
        "TranslationKey": "WorkingHour"
    }
]