import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row } from 'reactstrap';
import Profile from '../Profile';
import { LOGO_URL } from '../App/AppSettings';

class Header extends React.Component {

  toggleSidebar() {
    this.props.onClick();
  }
  
  render() {
    return (
        <Row className={"header-nav align-items-center flex-nowrap"}> 
          <div className="col-auto" id="sidebarBtn" data-toggle="collapse" href="#sidebarMobile" aria-expanded="false" aria-controls="sidebarMobile">
            <div className="btn btn-neutral" onClick={this.toggleSidebar.bind(this)}>
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
          <div className="col-auto header-logo-col"><img src={LOGO_URL} className="header-logo" alt="header-logo" /></div>
          <Profile profileDisplay={true}/>
        </Row>
    );
  }
}


export default Header;