import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE, ADDRESS, OPTIONAL_ADDRESS_TYPE, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { Plaintext } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';
import { constructNumber } from '../../Shared/SmartMe/Utils/FormUtils';
import history from '../../Shared/History';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE } from '../../Localization';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const OrgTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    getData = (Data) => {
        const strData = JSON.stringify(Data, function (key, value) {
            // if value is null, return "" as a replacement
            if (value === null) {
                return "";
            }

            // otherwise, leave the value unchanged
            return value;
        });

        return JSON.parse(strData);
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(URL + "Organisation/GetOrganisation/" + state.ID, getParams(), false);
            const { IsSuccess, Data } = response.body;
            const organisationData = this.getData(Data);

            if (response.success && IsSuccess) {
                this.setState({
                    values: organisationData,
                    isLoaded: true
                });
            }
            else {
                loader.done();
                pageNotFound();
            }
            loader.done();
        }
    }

    render() {
        const values = this.state.values;
        const contactNumber = values && values.OrganisationDetails.ContactNumber;
        const faxNumber = values && values.OrganisationDetails.FaxNumber;

        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: OrgTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Organisation Details">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col className="smart-me-content">

                                    {/* Organisation */}
                                    <Plaintext
                                        label="Application"
                                        translation={translation}
                                        name="AppName"
                                        className="mb-1"
                                        value={this.state.values.AppName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Organisation (Abbreviated Name)"
                                        translation={translation}
                                        name="AbbreviatedName"
                                        className="mb-1"
                                        value={this.state.values.AbbreviatedName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Organisation (Full Name)"
                                        translation={translation}
                                        name="FullName"
                                        className="mb-1"
                                        value={this.state.values.FullName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Contact Email"
                                        translation={translation}
                                        name="Email"
                                        className="mb-1"
                                        value={this.state.values.Email}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Effective From"
                                        translation={translation}
                                        name="EffectiveFromDate"
                                        className="mb-1"
                                        value={this.state.values.EffectiveFromDate}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Effective To"
                                        translation={translation}
                                        name="EffectiveToDate"
                                        className="mb-1"
                                        value={this.state.values.EffectiveToDate}
                                        labelSize={LabelSize}
                                    />

                                    {/* Contact Info */}
                                    <Plaintext
                                        label="Contact Number"
                                        translation={translation}
                                        name="Contact Number"
                                        className="mb-1"
                                        value={constructNumber(contactNumber.CountryCode, contactNumber.Number)}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Fax Number"
                                        translation={translation}
                                        name="Fax Number"
                                        className="mb-1"
                                        value={constructNumber(faxNumber.CountryCode, faxNumber.Number)}
                                        labelSize={LabelSize}
                                    />

                                    {/* Address */}
                                    <Plaintext
                                        label="Address Type"
                                        translation={translation}
                                        name="OrganisationDetails.Address.AddressType"
                                        className="mb-1"
                                        value={this.state.values.OrganisationDetails.Address.AddressType}
                                        labelSize={LabelSize}
                                    />
                                    {this.state.values.OrganisationDetails.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                        <>
                                            <Plaintext
                                                label="ZIP / Postal Code"
                                                translation={translation}
                                                name="OrganisationDetails.Address.PostalCode"
                                                className="mb-1"
                                                value={this.state.values.OrganisationDetails.Address.PostalCode}
                                                labelSize={LabelSize}
                                            />
                                            {this.state.values.OrganisationDetails.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                <>
                                                    <Plaintext
                                                        label="Block / House No."
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.AddressLine1.BlockNo"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.AddressLine1.BlockNo}
                                                        labelSize={LabelSize}
                                                    />
                                                    <Plaintext
                                                        label="Street Name"
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.AddressLine1.StreetName"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.AddressLine1.StreetName}
                                                        labelSize={LabelSize}
                                                    />
                                                    {this.state.values.OrganisationDetails.Address.AddressLine2.LevelNo !== null &&
                                                        <Plaintext
                                                            label="Level"
                                                            translation={translation}
                                                            name="OrganisationDetails.Address.AddressLine2.LevelNo"
                                                            className="mb-1"
                                                            value={this.state.values.OrganisationDetails.Address.AddressLine2.LevelNo}
                                                            labelSize={LabelSize}
                                                        />
                                                    }
                                                    {this.state.values.OrganisationDetails.Address.AddressLine2.UnitNo !== null &&
                                                        <Plaintext
                                                            label="Unit No."
                                                            translation={translation}
                                                            name="OrganisationDetails.Address.AddressLine2.UnitNo"
                                                            className="mb-1"
                                                            value={this.state.values.OrganisationDetails.Address.AddressLine2.UnitNo}
                                                            labelSize={LabelSize}
                                                        />
                                                    }
                                                    {this.state.values.OrganisationDetails.Address.AddressLine2.BuildingName !== null &&
                                                        <Plaintext
                                                            label="Building Name"
                                                            translation={translation}
                                                            name="OrganisationDetails.Address.AddressLine2.BuildingName"
                                                            className="mb-1"
                                                            value={this.state.values.OrganisationDetails.Address.AddressLine2.BuildingName}
                                                            labelSize={LabelSize}
                                                        />
                                                    }
                                                </>
                                            }
                                            {this.state.values.OrganisationDetails.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                                <>
                                                    <Plaintext
                                                        label="Country"
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.Country"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.Country}
                                                        labelSize={LabelSize}
                                                    />
                                                    {this.state.values.OrganisationDetails.Address.City !== null &&
                                                        <Plaintext
                                                            label="City"
                                                            translation={translation}
                                                            name="OrganisationDetails.Address.City"
                                                            className="mb-1"
                                                            value={this.state.values.OrganisationDetails.Address.City}
                                                            labelSize={LabelSize}
                                                        />
                                                    }
                                                    <Plaintext
                                                        label="State / Province / Region"
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.State"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.State}
                                                        labelSize={LabelSize}
                                                    />
                                                    <Plaintext
                                                        label="Address Line 1"
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.AddressLine1.AddressLine1"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.AddressLine1.AddressLine1}
                                                        labelSize={LabelSize}
                                                    />
                                                    <Plaintext
                                                        label="Address Line 2"
                                                        translation={translation}
                                                        name="OrganisationDetails.Address.AddressLine2.AddressLine2"
                                                        className="mb-1"
                                                        value={this.state.values.OrganisationDetails.Address.AddressLine2.AddressLine2}
                                                        labelSize={LabelSize}
                                                    />
                                                </>

                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default withPageNotFound(withLoader(withRouter(View)));