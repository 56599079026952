import 'core-js/es/object';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LAYOUT_TYPE } from '../App/AppSettings';
import Header from '../Header';
import Navbar from '../Navbar';
import { navigateToSamePage } from '../Shared/Actions';
import { Toaster, withSMARTWrapper } from '../Shared/Forms';

export const withSMARTLayout = (LoadedComponent, showNavDisplay) => {
  class SMARTLayout extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        collapseNavbar: false,
        isCollapsing: false,
        navbarLayoutD: LAYOUT_TYPE.NAVBAR_DESKTOP,
        navbarLayoutM: LAYOUT_TYPE.NAVBAR_MOBILE,
        width: window.innerWidth,
        showNavDisplay: showNavDisplay === false ? false : true
      };
    }
    toggleSidebar = () => {
      if (!this.state.isCollapsing) {
        this.setState({ collapseNavbar: !this.state.collapseNavbar });
      }
    }
    toggleIsCollapsing = () => {
      this.setState({ isCollapsing: !this.state.isCollapsing });
    }

    //Mobile Responsive Listener
    componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    componentDidUpdate = (prevProps) => {
      if (prevProps.context.currLang && prevProps.context.currLang !== this.props.context.currLang) {
        navigateToSamePage();
      }
    }
    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };


    //Layout Split 
    LayoutToggle = () => {
      const { width } = this.state;
      const isMobile = width <= 992;

      if (isMobile) {
        return this.state.navbarLayoutM + "M";
      } else {
        return this.state.navbarLayoutD + "D";
      }

    }

    NavbarVerticalChange = () => {
      if (this.LayoutToggle() === "1D" || this.LayoutToggle() === "1M") {
        return (<Header onClick={this.toggleSidebar} />)
      }
    }

    adjustNavDisplay = () => {
      return this.state.showNavDisplay ? "" : "no-navdisplay";
    }

    render() {
      return (
        <Container fluid className="app-container">
          <Row>
            <Navbar showNavDisplay={this.state.showNavDisplay} navbarLayout={this.LayoutToggle()} collapseNavbar={this.state.collapseNavbar} onClick={this.toggleSidebar} onUpdate={this.toggleIsCollapsing} />
            <Col className={`site-container ${this.adjustNavDisplay()}`}>
              {this.NavbarVerticalChange()}
              <LoadedComponent {...this.props} />
            </Col>
            <Toaster isStack={false} />
            {/* <ScrollToTop /> */}
          </Row>
        </Container>
      )
    }
  }
  return withSMARTWrapper(SMARTLayout);
};