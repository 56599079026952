export const ButtonTranslationKeys = [
    {
        "DefaultLanguageValue": "Add",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Add"
    },
    {
        "DefaultLanguageValue": "Next",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Next"
    },
    {
        "DefaultLanguageValue": "Back",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Back"
    },
    {
        "DefaultLanguageValue": "Previous",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Previous"
    },
    {
        "DefaultLanguageValue": "Submit",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Submit"
    },
    {
        "DefaultLanguageValue": "Save",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Save"
    },
    {
        "DefaultLanguageValue": "Cancel",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Cancel"
    },
    {
        "DefaultLanguageValue": "View",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "View"
    },
    {
        "DefaultLanguageValue": "Amend",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Amend"
    },
    {
        "DefaultLanguageValue": "Update",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Update"
    },
    {
        "DefaultLanguageValue": "Delete",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Delete"
    },
    {
        "DefaultLanguageValue": "Yes",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Yes"
    },
    {
        "DefaultLanguageValue": "No",
        "Scope": "DefaultStatic",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "No"
    },
    {
        "DefaultLanguageValue": "Send User Activation Email",
        "Scope": "SMARTMe",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "SendUserActivationEmail"
    },
    {
        "DefaultLanguageValue": "Review",
        "Scope": "SMARTMe",
        "Category": "Navigation",
        "SubCategory": "Button",
        "TranslationKey": "Review"
    }
]