import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { serverTableNavigateTo } from '../../Shared/SmartMe/Actions';
import { withSMARTWrapper } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';

const GRID_ENDPOINT = URL + "Role/ListRolePermissions";

class Grid extends React.Component {

    renderMobileCardActions = (rowData) => {
        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton 
                    onClick={() => serverTableNavigateTo('View/', ID)}
                    icon={faEye} 
                    label='View'
                />
                <MobileCardActionButton 
                    onClick={() => serverTableNavigateTo('Update/', ID)}
                    icon={faEdit} 
                    label='Update'
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue="List of Role Permissions" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ServerSideDataTable
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No roles found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '5%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        AppName: { title: 'Application', width: '15%', DBkey: 'AppName' },
                                        RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                                        DisplayRoleName: { title: 'Display Role Name', width: '15%', DBkey: 'DisplayRoleName' },
                                        Description: { title: 'Description', width: '20%', DBkey: 'Description' },
                                        Total: { title: 'Total', width: '15%', DBkey: 'Total' },
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        <ActionButton tooltip="Update Role Permissions" color="neutral" onClick={() => serverTableNavigateTo('Update/', rowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                        <ActionButton tooltip="View Role Permissions" color="forward" onClick={() => serverTableNavigateTo('View/', rowData)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);