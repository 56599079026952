import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { RouterExtension } from '../Extensions/RouterExtension';
import ProtectedRoute from '../Shared/Authentication/ProtectedRoute';

import Home from '../Content/Home';
import { PermissionNew, PermissionUpdate, PermissionView, PermissionGrid } from '../Content/Permission';
import { RoleNew, RoleUpdate, RoleView, RoleGrid } from '../Content/Role';
import { UserNew, UserUpdate, UserView, UserGrid, UserNewCheck } from '../Content/User';
import { UserApprovalGrid, UserApproval, AmendUserRequest } from '../Content/UserApproval';
import { OrganisationNew, OrganisationUpdate, OrganisationView, OrganisationGrid } from '../Content/Organisation';
import { UserGroupNew, UserGroupUpdate, UserGroupView, UserGroupGrid } from '../Content/UserGroup';
import { RolePermissionsUpdate, RolePermissionsView, RolePermissionsGrid } from '../Content/RolePermissions';
import { UserGroupRolesUpdate, UserGroupRolesView, UserGroupRolesGrid } from '../Content/UserGroupRoles';
import { UserGroupUsersUpdate, UserGroupUsersView, UserGroupUsersGrid } from '../Content/UserGroupUsers';
import { UserRolesUpdate, UserRolesView, UserRolesGrid } from '../Content/UserRoles';
import { PasswordPolicy } from '../Content/Policy';
import { WebHookNew, WebHookUpdate, WebHookView, WebHookGrid } from '../Content/WebHook';
import { NonActiveUserGrid, UserViewNonActive } from '../Content/NonActiveUser';


//System Config
//AdminLanguage
//AdminLanguage - List Applications Bundle (Start)
import LanguageList from '../Content/LanguageList';
import CreateNewKey from './LanguageList/CreateNewKey';
import EditKey from './LanguageList/EditKey';
import UploadFile from './LanguageList/UploadFile';
import AddScope from './LanguageList/AddScope';
import ViewScope from './LanguageList/ViewScope';
import EditScope from './LanguageList/EditScope';
import AddCategory from './LanguageList/AddCategory';
import ViewCategory from './LanguageList/ViewCategory';
import EditCategory from './LanguageList/EditCategory';
import AddSubCategory from './LanguageList/AddSubCategory';
import ViewSubCategory from './LanguageList/ViewSubCategory';
import EditSubCategory from './LanguageList/EditSubCategory';

//AdminLanguage - List Applications Bundle (End)

// FAQ (Start)
import AddFaq from './Faq/AddFaq';
import EditFaq from './Faq/EditFaq';
import ViewFaq from './Faq/ViewFaq';
import ViewFaqSettings from './Faq/ViewFaqSettings';
// FAQ (End)

import { CodeTableGrid, CodeTableView, CodeTableUpdate, CodeTableCreate } from './Code';
import { ApplicationConfigCreate, ApplicationConfigUpdate, ApplicationConfigList, ApplicationConfigView } from './AppConfig';
import {
    EmailTemplateCreate, EmailTemplateList, EmailTemplateUpdate,
    LetterTemplateCreate, LetterTemplateList, LetterTemplateUpdate,
    SmsTemplateCreate, SmsTemplateList, SmsTemplateUpdate, SmsTemplateView
} from './NotificationTemplate';
import { PublicHolidayCreate, PublicHolidayUpdate, PublicHolidayView, PublicHolidayList } from './PublicHoliday';
import { WorkingHourCreate, WorkingHourUpdate, WorkingHourList, WorkingHourCreateException, WorkingHourUpdateException } from './WorkingHour';
import { OnlineHelpCreate, OnlineHelpUpdate, OnlineHelpList } from './OnlineHelp';

import ApplicationsLanguageList from './LanguageList/ApplicationsLanguageList';
import EditApplicationLanguage from './LanguageList/EditApplicationLanguage';
import ScopeManagement from './LanguageList/ScopeManagement';
import CategoryManagement from './LanguageList/CategoryManagement';
import SubCategoryManagement from './LanguageList/SubCategoryManagement';
import ExcelUpdate from './LanguageList/ExcelUpdate';
import FAQManagement from './Faq/FaqManagement';

// Authentication
import Callback from '../Shared/Authentication/Callback';
import Silent from '../Shared/Authentication/Silent';
import Logout from '../Shared/Authentication/Logout';
import Timeout from '../Shared/Authentication/Timeout';

import { withSMARTLayout } from '../Layout/SMARTLayout';
import { withEmptyLayout } from '../Layout/EmptyLayout';

import { PATH } from '../App/AppSettings';

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: '' }
    }

    render() {
        return (
            <>
                <Timeout />
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to="/Home" />)} />
                    <ProtectedRoute exact path="/Home" component={withSMARTLayout(Home)} />

                    {/* ROLE */}
                    <ProtectedRoute exact path={PATH.ROLE} component={withSMARTLayout(RoleGrid)} />
                    <ProtectedRoute exact path="/Role/New" component={withSMARTLayout(RoleNew)} />
                    <ProtectedRoute path="/Role/Update" component={withSMARTLayout(RoleUpdate)} />
                    <ProtectedRoute path="/Role/View" component={withSMARTLayout(RoleView)} />

                    {/* PERMISSION */}
                    <ProtectedRoute exact path={PATH.PERMISSION} component={withSMARTLayout(PermissionGrid)} />
                    <ProtectedRoute exact path="/Permission/New" component={withSMARTLayout(PermissionNew)} />
                    <ProtectedRoute path="/Permission/Update" component={withSMARTLayout(PermissionUpdate)} />
                    <ProtectedRoute path="/Permission/View" component={withSMARTLayout(PermissionView)} />

                    {/* USER */}
                    <ProtectedRoute exact path={PATH.USER.ACTIVE_USER} component={withSMARTLayout(UserGrid)} />
                    <ProtectedRoute exact path="/User/New" component={withSMARTLayout(UserNew)} />
                    <ProtectedRoute path="/User/Update" component={withSMARTLayout(UserUpdate)} />
                    <ProtectedRoute path="/User/View" component={withSMARTLayout(UserView)} />
                    <ProtectedRoute exact path="/User/Check" component={withSMARTLayout(UserNewCheck)} />

                    {/* NON ACTIVE USER*/}
                    <ProtectedRoute exact path={PATH.USER.NON_ACTIVE_USER} component={withSMARTLayout(NonActiveUserGrid)} />
                    <ProtectedRoute exact path="/User/ViewNonActiveUser" component={withSMARTLayout(UserViewNonActive)} />

                    {/* USER APPROVAL */}
                    <ProtectedRoute exact path={PATH.USERAPPROVAL.PENDING_USER_REQUESTS} component={withSMARTLayout(UserApprovalGrid)} />
                    <ProtectedRoute exact path="/UserApproval/:activationkey" component={withSMARTLayout(UserApproval)} />
                    <ProtectedRoute exact path="/UserApproval/AmendUserRequest/:activationkey" component={withSMARTLayout(AmendUserRequest)} />

                    {/* ORGANISATION */}
                    <ProtectedRoute exact path={PATH.ORGANISATION} component={withSMARTLayout(OrganisationGrid)} />
                    <ProtectedRoute exact path="/Organisation/New" component={withSMARTLayout(OrganisationNew)} />
                    <ProtectedRoute path="/Organisation/Update" component={withSMARTLayout(OrganisationUpdate)} />
                    <ProtectedRoute path="/Organisation/View" component={withSMARTLayout(OrganisationView)} />

                    {/* USER GROUP */}
                    <ProtectedRoute exact path={PATH.USERGROUP} component={withSMARTLayout(UserGroupGrid)} />
                    <ProtectedRoute exact path="/UserGroup/New" component={withSMARTLayout(UserGroupNew)} />
                    <ProtectedRoute path="/UserGroup/Update" component={withSMARTLayout(UserGroupUpdate)} />
                    <ProtectedRoute path="/UserGroup/View" component={withSMARTLayout(UserGroupView)} />

                    {/* ROLE - permission*/}
                    <ProtectedRoute exact path="/Role/Permissions" component={withSMARTLayout(RolePermissionsGrid)} />
                    <ProtectedRoute path="/Role/Permissions/Update/:id" component={withSMARTLayout(RolePermissionsUpdate)} />
                    <ProtectedRoute path="/Role/Permissions/View/:id" component={withSMARTLayout(RolePermissionsView)} />

                    {/* USER GROUP  - roles*/}
                    <ProtectedRoute exact path="/UserGroup/Roles" component={withSMARTLayout(UserGroupRolesGrid)} />
                    <ProtectedRoute path="/UserGroup/Roles/Update/:id" component={withSMARTLayout(UserGroupRolesUpdate)} />
                    <ProtectedRoute path="/UserGroup/Roles/View/:id" component={withSMARTLayout(UserGroupRolesView)} />

                    {/* USER GROUP  - users*/}
                    <ProtectedRoute exact path="/UserGroup/Users" component={withSMARTLayout(UserGroupUsersGrid)} />
                    <ProtectedRoute path="/UserGroup/Users/Update/:id" component={withSMARTLayout(UserGroupUsersUpdate)} />
                    <ProtectedRoute path="/UserGroup/Users/View/:id" component={withSMARTLayout(UserGroupUsersView)} />

                    {/* USER - roles*/}
                    <ProtectedRoute exact path="/User/Roles" component={withSMARTLayout(UserRolesGrid)} />
                    <ProtectedRoute path="/User/Roles/Update/:id" component={withSMARTLayout(UserRolesUpdate)} />
                    <ProtectedRoute path="/User/Roles/View/:id" component={withSMARTLayout(UserRolesView)} />

                    <ProtectedRoute exact path={PATH.POLICY.PASSWORD_POLICY} component={withSMARTLayout(PasswordPolicy)} />

                    {/* WEB HOOK */}
                    <ProtectedRoute exact path={PATH.WEBHOOK} component={withSMARTLayout(WebHookGrid)} />
                    <ProtectedRoute exact path="/Webhook/New" component={withSMARTLayout(WebHookNew)} />
                    <ProtectedRoute path="/Webhook/Update" component={withSMARTLayout(WebHookUpdate)} />
                    <ProtectedRoute path="/Webhook/View" component={withSMARTLayout(WebHookView)} />

                    {/* SYSTEM CONFIG */}
                    <ProtectedRoute exact path="/SystemConfig/Codes" component={withSMARTLayout(CodeTableGrid)} />
                    <ProtectedRoute path="/SystemConfig/Codes/Update" component={withSMARTLayout(CodeTableUpdate)} />
                    <ProtectedRoute path="/SystemConfig/Codes/View" component={withSMARTLayout(CodeTableView)} />
                    <ProtectedRoute exact path="/SystemConfig/Codes/Create" component={withSMARTLayout(CodeTableCreate)} />

                    <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/Create" component={withSMARTLayout(ApplicationConfigCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/ApplicationConfig" component={withSMARTLayout(ApplicationConfigList)} />
                    <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/Update" component={withSMARTLayout(ApplicationConfigUpdate)} />
                    <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/View" component={withSMARTLayout(ApplicationConfigView)} />

                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailCreate" component={withSMARTLayout(EmailTemplateCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailList" component={withSMARTLayout(EmailTemplateList)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailUpdate" component={withSMARTLayout(EmailTemplateUpdate)} />

                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterCreate" component={withSMARTLayout(LetterTemplateCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterList" component={withSMARTLayout(LetterTemplateList)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterUpdate" component={withSMARTLayout(LetterTemplateUpdate)} />

                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsCreate" component={withSMARTLayout(SmsTemplateCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsList" component={withSMARTLayout(SmsTemplateList)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsUpdate" component={withSMARTLayout(SmsTemplateUpdate)} />
                    <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsView" component={withSMARTLayout(SmsTemplateView)} />

                    <ProtectedRoute exact path="/SystemConfig/PublicHoliday" component={withSMARTLayout(PublicHolidayList)} />
                    <ProtectedRoute exact path="/SystemConfig/PublicHoliday/Create" component={withSMARTLayout(PublicHolidayCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/PublicHoliday/Update" component={withSMARTLayout(PublicHolidayUpdate)} />
                    <ProtectedRoute exact path="/SystemConfig/PublicHoliday/View" component={withSMARTLayout(PublicHolidayView)} />

                    <ProtectedRoute exact path="/SystemConfig/WorkingHour" component={withSMARTLayout(WorkingHourList)} />
                    <ProtectedRoute exact path="/SystemConfig/WorkingHour/Create" component={withSMARTLayout(WorkingHourCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/WorkingHour/Update" component={withSMARTLayout(WorkingHourUpdate)} />
                    <ProtectedRoute exact path="/SystemConfig/WorkingHour/CreateException" component={withSMARTLayout(WorkingHourCreateException)} />
                    <ProtectedRoute exact path="/SystemConfig/WorkingHour/UpdateException" component={withSMARTLayout(WorkingHourUpdateException)} />

                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp" component={withSMARTLayout(OnlineHelpList)} />
                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Create" component={withSMARTLayout(OnlineHelpCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Update" component={withSMARTLayout(OnlineHelpUpdate)} />

                    {/* LanguageAdminMgmt - List Applications Bundle (Start)*/}
                    <ProtectedRoute path="/SystemConfig/language/list" component={withSMARTLayout(LanguageList)} />
                    <ProtectedRoute path="/SystemConfig/language/addEntry" component={withSMARTLayout(CreateNewKey)} />
                    <ProtectedRoute path="/SystemConfig/language/editEntry" component={withSMARTLayout(EditKey)} />
                    <ProtectedRoute path="/SystemConfig/language/uploadFile" component={withSMARTLayout(UploadFile)} />
                    <ProtectedRoute path="/SystemConfig/language/addScope" component={withSMARTLayout(AddScope)} />
                    <ProtectedRoute path="/SystemConfig/language/viewScope" component={withSMARTLayout(ViewScope)} />
                    <ProtectedRoute path="/SystemConfig/language/editScope" component={withSMARTLayout(EditScope)} />
                    <ProtectedRoute path="/SystemConfig/language/addCategory" component={withSMARTLayout(AddCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/viewCategory" component={withSMARTLayout(ViewCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/editCategory" component={withSMARTLayout(EditCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/addSubCategory" component={withSMARTLayout(AddSubCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/viewSubCategory" component={withSMARTLayout(ViewSubCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/editSubCategory" component={withSMARTLayout(EditSubCategory)} />
                    <ProtectedRoute path="/SystemConfig/language/scopeManagement" component={withSMARTLayout(ScopeManagement)} />
                    <ProtectedRoute path="/SystemConfig/language/categoryManagement" component={withSMARTLayout(CategoryManagement)} />
                    <ProtectedRoute path="/SystemConfig/language/subcategoryManagement" component={withSMARTLayout(SubCategoryManagement)} />
                    <ProtectedRoute path="/SystemConfig/language/languageRegistration" component={withSMARTLayout(ApplicationsLanguageList)} />
                    <ProtectedRoute path="/SystemConfig/language/editApplicationLanguages" component={withSMARTLayout(EditApplicationLanguage)} />
                    <ProtectedRoute path="/SystemConfig/language/excelUpdate" component={withSMARTLayout(ExcelUpdate)} />

                    <ProtectedRoute path="/SystemConfig/faq/faqManagement" component={withSMARTLayout(FAQManagement)} />
                    <ProtectedRoute path="/SystemConfig/faq/addFaq" component={withSMARTLayout(AddFaq)} />
                    <ProtectedRoute path="/SystemConfig/faq/editFaq" component={withSMARTLayout(EditFaq)} />
                    <ProtectedRoute path="/SystemConfig/faq/viewFaq" component={withSMARTLayout(ViewFaq)} />
                    <ProtectedRoute path="/SystemConfig/faq/viewFaqSettings" component={withSMARTLayout(ViewFaqSettings)} />

                    {/* LanguageAdminMgmt - List Applications Bundle (End)*/}
                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp" component={withSMARTLayout(OnlineHelpList)} />
                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Create" component={withSMARTLayout(OnlineHelpCreate)} />
                    <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Update" component={withSMARTLayout(OnlineHelpUpdate)} />

                    <Route path="/Authentication/Callback" component={withEmptyLayout(Callback)} />
                    <Route path="/Authentication/Silent" component={withEmptyLayout(Silent)} />
                    <Route path="/Authentication/Logout" component={withEmptyLayout(Logout)} />
                    <RouterExtension />
                </Switch>
            </>
        );
    }
}

export default Content;