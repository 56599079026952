import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { ERROR } from '../../Localization';
import { convertToFormData } from '../../Shared/Forms/FormUtils';
import { CONTENT_TYPE, fetchRequest, formParams as FormParams, navigateTo, navigateToPageWithFormValues } from '../Actions';
import { withFormAlert } from '../Forms';
import { withLoader } from '../Loading';
import { withTranslationWrapper } from './TranslationWrapper';
import { toastError, toastSuccess } from './Toaster';

// Const: Various states of the form
const FORM_STATE = {
    DRAFT: "form_state_draft",
    NAVIGATE: "form_state_navigate",
    SUBMIT: "form_state_submit",
    PLAIN_SUBMIT: "form_state_plain_submit"
};

const DELETE_FILES_ARRAY = "SMARTDeleteFilesArray";

// HoC function to wrap all generated forms
export const withBaseForm = (FormComponent) => {
    class BaseForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                sectionState: this.props.defaultSection,     // Section: Accordion or Tab
                referenceNo: "",
                formState: undefined,
                currentStep: 0,
                formID: 0,
                toggleToResetForm: false
            };
        }

        // Fn: Toggles state of Section dependently based on given sectionName
        toggleSectionDependentState = (sectionName) => {
            // Set section state as clicked section name
            let newSectionName = sectionName;

            // If hide/uncollapse is allowed & collapsed state is clicked, uncollapse it
            if (this.state.sectionState === sectionName) {
                newSectionName = '';
            }
            // Update new sectionState
            this.setState({ sectionState: newSectionName });
        };

        // Fn: Toggles state of Section independently based on given attributes in sections & sectionName
        toggleSectionIndividualState = (sections, sectionName) => {
            let newSectionName = sectionName;
            let propertyHolder; // react has problems setting state for nested objects, so need a holder to help setState

            Object.entries(sections).forEach(([key, value]) => {
                if (newSectionName === value.title) {
                    propertyHolder = value;
                    propertyHolder.status = !value.status;
                    this.setState({ propertyHolder });
                }
            });
        }

        // Fn: Changes state of section collapsing based on given sectionState
        toggleSection = (isCollapsible, isIndividual, sectionName, sections) => {

            // Set section state as clicked section name
            let newSectionName = sectionName;

            // If section is collapsible ()
            if (isCollapsible) {
                if (isIndividual) {
                    this.toggleSectionIndividualState(sections, sectionName)
                } else {
                    this.toggleSectionDependentState(newSectionName);
                }
            } else {
                this.setState({ sectionState: newSectionName });
                this.setCurrentStepFromSectionName(newSectionName);
            }
        };

        setCurrentStepFromSectionName(newSectionName) {
            let tabsArray = Object.values(this.props.sectionNames);

            // Loop through array to find index
            for (let i = 0; i < tabsArray.length; i++) {
                if (tabsArray[i] === newSectionName) {
                    this.setState({ currentStep: i })
                    break;
                }
            }
        }

        // Fn: Handle SMARTForm/SMARTView Submission
        submitForm = async (nextURL, values, serverURL, isDummy, formikActions) => {

            const { loader, submitCallback, resetFormAfterSubmit, passFormValuesToNextPage, formParams, defaultSection, getStaticText, translation } = this.props;

            // Start loading
            loader.start();

            // Send Delete Files Array
            values[DELETE_FILES_ARRAY] = this.getDeletedFiles(values);

            // Get results of server-side form posting
            if (formParams.contentType === CONTENT_TYPE.FORM_DATA) {
                formParams.data = convertToFormData(values);
            } else {
                formParams.data = JSON.stringify(values);
            }

            let response = await fetchRequest(serverURL, formParams, isDummy);

            // End loading
            loader.done();

            // If user specify to reset Form after submitting
            if (resetFormAfterSubmit && response.body.IsSuccess) {
                // Reset form
                if (defaultSection) {
                    this.toggleSection(false, false, this.props.defaultSection, null);
                }
                formikActions.resetForm();
                this.setState((prevState) => ({
                    toggleToResetForm: !prevState.toggleToResetForm
                }));
            }

            // If submitCallback prop is used
            if (submitCallback) {
                // Execute user's callback(s)
                submitCallback({ response });
            }

            // Otherwise, execute our default response processing logic
            else {
                // HTML status response: Good
                if (response.success) {
                    const { IsSuccess, Messages, RedirectURL, MessageLanguageKey, MessageDataValues } = response.body;

                    let message = Messages;
                    if (MessageLanguageKey) {
                        message = getStaticText(MessageLanguageKey, Messages);
                    }
                    
                    // Server response: Transaction Successful
                    if (IsSuccess) {

                        // 1st Priority: Check & process RedirectURL
                        if (RedirectURL) {
                            // Navigate to given RedirectURL
                            navigateTo(RedirectURL, response.body);
                        }

                        // 2nd Priority: Check & process nextURL
                        else if (nextURL) {
                            // Navigate to given nextURL
                            if (passFormValuesToNextPage) {
                                //Pass form data
                                navigateToPageWithFormValues(nextURL, values);
                            } else {
                                //Pass response data
                                navigateTo(nextURL, response.body);
                            }
                        }

                        // Defaulting action
                        else {
                            // Show Success Toaster
                            toastSuccess(message, MessageDataValues, translation);
                        }
                    }

                    // Server Response: Transaction Unsuccessful
                    else {
                        const { formAlert } = this.props;

                        // Display Form Alert
                        formAlert.showAlert(message, MessageDataValues);
                    }
                }

                // HTML status response: Bad
                else {
                    // Show Error Toaster
                    toastError(ERROR.FORM_DEFAULT_FAIL, translation);
                }
            }
        };

        // Fn: Handle SMARTForm/SMARTView Draft Submission 
        submitDraft = async (values, draftServerURL, isDummy) => {

            const { loader, submitCallback, formParams, getStaticText, translation } = this.props;

            // Start loading
            loader.start();

            // Send Delete Files Array
            values[DELETE_FILES_ARRAY] = this.getDeletedFiles(values);

            // Get results of server-side form posting
            if (formParams.contentType === CONTENT_TYPE.FORM_DATA) {
                formParams.data = convertToFormData(values);
            } else {
                formParams.data = JSON.stringify(values);
            }

            let response = await fetchRequest(draftServerURL, formParams, isDummy);

            // If submitCallback prop is used
            if (submitCallback) {
                // Execute user's callback(s)
                submitCallback({ response });
            }

            // End loading
            loader.done();

            // HTML status response: Good
            if (response.success) {
                const { IsSuccess, Messages, MessageLanguageKey, MessageDataValues, FormID } = response.body;

                let message = Messages;
                if (MessageLanguageKey) {
                    message = getStaticText(MessageLanguageKey, Messages);
                }

                // Server response: Transaction Successful
                if (IsSuccess) {
                    toastSuccess(message, MessageDataValues, translation);
                    this.setState({ formID: FormID });
                }

                // Server response: Transaction Unsuccessful
                else {
                    toastError(message, translation, MessageDataValues);
                }
            }

            // HTML status response: Bad
            else {
                toastError(ERROR.DRAFT_SAVED_FAIL, translation);
            }
        }

        // Fn: Perform per-submission processing
        preSubmitHandler = (values, handleSubmit, formState) => {

            const { showAlert } = this.props.formAlert;
            let FormID = (this.state.formID !== 0) ? this.state.formID : ((values.FormID !== undefined) ? values.FormID : 0);

            switch (formState) {
                case FORM_STATE.DRAFT:
                    (this.allFieldsEmpty(values)) ?
                        showAlert(ERROR.DRAFT_EMPTY_FIELD)
                        :
                        this.setState({
                            formID: FormID,
                            formState
                        }, () => handleSubmit());
                    break;

                case FORM_STATE.NAVIGATE:
                case FORM_STATE.SUBMIT:
                case FORM_STATE.PLAIN_SUBMIT:
                default:
                    this.setState({
                        formID: FormID,
                        formState
                    }, () => handleSubmit());
                    break;
            }
        };

        // Fn: Perform on-submission processing
        onSubmitHandler = (values, formikActions) => {

            // Destructure props
            const { formContext, nextURL, serverURL, draftServerURL, isDummy } = this.props;
            const { hideAlert } = this.props.formAlert;

            // Hide alert
            hideAlert();

            switch (this.state.formState) {

                case FORM_STATE.NAVIGATE:
                    // Append ReferenceNo/ formID into values before navigating
                    _.assign(values, {
                        "ReferenceNo": this.state.referenceNo,
                        "FormID": this.state.formID
                    });

                    // Navigate to new page
                    navigateTo(nextURL, { [formContext]: values });
                    break;

                case FORM_STATE.DRAFT:
                    // Append IsDraft, FormType & formID into values before submitting
                    _.assign(values, {
                        "IsDraft": true,
                        "FormType": formContext,
                        "FormID": this.state.formID
                    });

                    // Submit Draft
                    this.submitDraft(values, draftServerURL, isDummy);
                    break;

                case FORM_STATE.PLAIN_SUBMIT:
                    this.submitForm(nextURL, values, serverURL, isDummy, formikActions);
                    break;

                case FORM_STATE.SUBMIT:
                default:
                    // Append IsDraft & FormType into values before submitting
                    _.assign(values, {
                        "IsDraft": false,
                        "FormType": formContext,
                        "FormID": this.state.formID
                    });
                    // Send Delete Files Array
                    values[DELETE_FILES_ARRAY] = this.getDeletedFiles(values);
                    // Submit Form
                    this.submitForm(nextURL, values, serverURL, isDummy, formikActions);
                    break;
            }
        };

        // Fn: Display appropriate schema based on formState
        schemaHandler = (draftValidationSchema, validationSchema) => {
            switch (this.state.formState) {
                case FORM_STATE.DRAFT:
                    return draftValidationSchema;

                default:
                    return validationSchema;
            }
        };

        // Fn: Check if all the fields in forms are empty (not filled up)
        allFieldsEmpty = (values) => {

            let isEmpty = true;

            // Check if any values are not empty
            for (let key in values) {
                if (!_.isEmpty(values[key])) {
                    isEmpty = false;
                    break;
                }
            }

            return isEmpty;
        };

        prevStep = () => {
            let decrementStep = this.state.currentStep - 1;
            this.setState({ currentStep: decrementStep });
            this.toggleSection(false, false, Object.values(this.props.sectionNames)[decrementStep], null)
        }

        nextStep = () => {
            let incrementStep = this.state.currentStep + 1;
            this.setState({ currentStep: incrementStep });
            this.toggleSection(false, false, Object.values(this.props.sectionNames)[incrementStep], null)
        }

        getDeletedFiles = ({ FileUploadFiles, FileUploadSectionFiles }) => {
            if (FileUploadFiles !== undefined && FileUploadSectionFiles !== undefined) {
                let files = FileUploadFiles.concat(FileUploadSectionFiles);
                let deletedFiles = _.filter(files, 'IsDeleted');
                let deletedFilesId = _.map(deletedFiles, 'Id');

                return deletedFilesId;
            }
            return [];
        }

        render() {
            const { formValues, draftValidationSchema, validationSchema } = this.props;

            return (
                <Formik
                    validationSchema={this.schemaHandler(draftValidationSchema, validationSchema)}
                    initialValues={formValues}
                    enableReinitialize={true}
                    onSubmit={(values, formikActions) => this.onSubmitHandler(values, formikActions)}
                >
                    {(formikProps) => (
                        <FormComponent
                            {...this.props}
                            key={this.state.toggleToResetForm}
                            baseForm={{
                                sectionState: this.state.sectionState,
                                toggleSection: this.toggleSection,
                                formik: formikProps,
                                submitPlainForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.PLAIN_SUBMIT),
                                submitForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.SUBMIT),
                                submitDraft: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.DRAFT),
                                navigateForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.NAVIGATE),
                                referenceNo: this.state.referenceNo,
                                currentStep: this.state.currentStep,
                                prevStep: this.prevStep,
                                nextStep: this.nextStep
                            }}
                        />
                    )}
                </Formik>
            );
        }
    }

    // PropTypes: For prop validation
    BaseForm.propTypes = {
        formContext: PropTypes.string,              // Note: The unique name/type of Form          
        formValues: PropTypes.object,               // Note: initial Form Values you would want SMARTForms to manage for you    
        draftValidationSchema: PropTypes.object,    // Note: Field validation schema for DRAFT SUBMISSION
        validationSchema: PropTypes.object,         // Note: Field validation schema for FORM SUBMISSION    
        defaultSection: PropTypes.string,           // Note: Default section to open when page is loaded
        nextURL: PropTypes.string,                  // Note: Next page URL you are navigating to
        serverURL: PropTypes.string,                // Note: Server endpoint URL you are requesting
        isDummy: PropTypes.bool,                    // Note: Allow user to fetch dummy json responses when need be
        submitCallback: PropTypes.func,             // Note: Allow user to inject callbacks after form submission
        resetFormAfterSubmit: PropTypes.bool        // Note: Allow fields to be reset after submit
    };

    // PropTypes: Defaulting value for optional props
    BaseForm.defaultProps = {
        formContext: 'default',
        formValues: {},
        draftValidationSchema: Yup.object().shape(),
        validationSchema: Yup.object().shape(),
        defaultSection: undefined,
        nextURL: '',
        serverURL: '',
        isDummy: false,
        formParams: FormParams()
    };


    return compose(withLoader, withFormAlert, withTranslationWrapper)(BaseForm);
};