import { DEFAULT_HOME_COUNTRY } from "../../../../App/AppSettings";

export const getIdTypesConfig = (countryOptions) => {
    const countryOptionvalues = countryOptions.map(function (country) { return country.value });
    const NRICCountryOptions = countryOptionvalues.filter(country => country === DEFAULT_HOME_COUNTRY);
    const passportCountryOptions = countryOptionvalues.filter(country => country !== DEFAULT_HOME_COUNTRY);

    return (
        {
            NRIC: {
                NationalityOptions: NRICCountryOptions,
                IssuingCountryOptions: NRICCountryOptions
            },
            Passport: {
                NationalityOptions: passportCountryOptions,
                IssuingCountryOptions: passportCountryOptions
            }
        }
    )
};