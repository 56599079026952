import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { SMARTForm, Text, toastError, withSMARTWrapper, SelectList } from '../../Shared//Forms';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { createLabelAndValueArray } from '../../Shared/SmartMe/Actions';
import { URL, USER_CHECK_TYPE } from '../../Shared/SmartMe/Constants';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import PageFooter from '../../Shared/PageFooter';
import { FIELD_SIZE } from '../../Shared/Constants';
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const invalidMsg = ErrorMsgList.INVALID;

    return Yup.object().shape({
        Email: Yup.string().email(invalidMsg).required(requiredMsg),
        UserType: Yup.string().required(requiredMsg)
    });
};

const SERVER_URL = URL + "User/CheckEmailForUser";
const GET_USER_TYPE_URL = URL + 'User/GetUserType';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class NewCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                Email: "",
                UserType: ""
            },
            server_url: SERVER_URL,
            contentHeader: "Add New User Email Check Confirmation",
            contentBody: "Confirm ?",
            title: "Add New User (Email Check)"
        };

    }

    async componentDidMount() {
        const { loader } = this.props;

        loader.start();

        let userTypeResponse = await fetchRequest(GET_USER_TYPE_URL, getParams(), false);

        await this.setUserTypeData(userTypeResponse);

        loader.done();
    }

    setUserTypeData = async (response) => {
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            let userTypeData = createLabelAndValueArray(Data);
            //set first item as value if list only have 1
            if (userTypeData.length === 1) {
                this.setState({
                    userTypes: userTypeData,
                    values: Object.assign(this.state.values, { UserType: userTypeData[0].value })
                });
            } else {
                this.setState({
                    userTypes: userTypeData
                });
            }
        }
        else {
            toastError(Messages);
        }
    }

    handleSubmit = (e, submitForm) => {
        e.preventDefault();
        submitForm();
    }

    render() {
        const { getStaticText } = this.props;

        const validationSchema = FORM_VALIDATIONS(getStaticText);
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        return (
            <React.Fragment>

                <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                    <SMARTForm
                        formValues={this.state.values}
                        validationSchema={validationSchema}
                        validateOnSubmit={true}
                        serverURL={this.state.server_url}
                        isDummy={false}
                        submitCallback={({ response }) => {
                            if (response.success && response.body.IsSuccess) {
                                const { Data } = response.body;
                                if (Data.UserCheckType === USER_CHECK_TYPE.NEW_USER) {
                                    let checkType = {
                                        ID: 0,
                                        UserCheckType: USER_CHECK_TYPE.NEW_USER,
                                        Email: Data.Email,
                                        UserType: Data.UserType
                                    };
                                    navigateTo("/User/New", checkType);
                                }
                                else if (Data.UserCheckType === USER_CHECK_TYPE.ADD_EXISTING) {
                                    let checkType = {
                                        ID: Data.userId,
                                        UserCheckType: USER_CHECK_TYPE.ADD_EXISTING
                                    };
                                    navigateTo("/User/Update", checkType);
                                }
                            }
                            else {
                                toastError(response.body.Messages, translation);
                            }
                        }
                        }
                    >
                        {({ values, errors, onChange, submitForm, onChangeField }) => (
                            <React.Fragment>
                                <Row className="body-content">
                                    <Col>
                                        {this.state.userTypes &&
                                            <SelectList
                                                name="UserType"
                                                value={values.UserType}
                                                options={this.state.userTypes}
                                                onChangeField={onChangeField}
                                                inputSize={FIELD_SIZE.SMALL}
                                                label={"User Type"}
                                                translation={translation}
                                                error={errors.UserType}
                                                isMulti={false}
                                                required
                                            />
                                        }

                                        <Text
                                            name="Email"
                                            value={values.Email}
                                            placeholder="Email"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={7}
                                            label="Email"
                                            translation={translation}
                                            error={errors.Email}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <PageFooter>
                                    <Button
                                        color="primary" type="submit"
                                        onClick={(e) => this.handleSubmit(e, submitForm)}
                                    >
                                        {BUTTON_TITLE(getStaticText).NEXT}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>

            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(NewCheck);