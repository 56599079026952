import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { SMARTForm, Text, toastSuccess, toastError, SelectList, Plaintext, withSMARTWrapper } from '../../Shared//Forms';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { isValidForm, createLabelAndValueArray } from '../../Shared/SmartMe/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ModalConfirm } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { FIELD_SIZE } from '../../Shared/Constants';
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const FORM_VALIDATIONS = (getStaticText) => {
    const requiredMsg = ERROR_MSG(getStaticText).REQUIRED;
    return Yup.object().shape({
        AppId: Yup.string().required(requiredMsg),
        DataType: Yup.string().required(requiredMsg),
        NotifyUrl: Yup.string().required(requiredMsg),
    });
}

const FORM_VALIDATIONS_UPDATE = (getStaticText) => {
    const requiredMsg = ERROR_MSG(getStaticText).REQUIRED;
    return Yup.object().shape({
        NotifyUrl: Yup.string().required(requiredMsg)
    });
}

const GET_WEBHOOK_DATA_TYPES_URL = URL + "Code/GetWebhookDataTypes";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const WebhookTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            isLoaded: false,
            server_url: props.server_url,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            prevState: props.prevState
        };
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;

        loader.start();

        let response = await fetchRequest(GET_WEBHOOK_DATA_TYPES_URL, getParams(), false);

        if (response.success && response.body.IsSuccess) {

            let dataTypeList = createLabelAndValueArray(response.body.Data);

            this.setState({
                dataTypeList: dataTypeList,
                isLoaded: true
            });
        }
        else {
            pageNotFound();
        }
        loader.done();
    }

    render() {
        const { modal, getStaticText } = this.props;
        const validationSchema = this.state.prevState.AppIds !== "" ? FORM_VALIDATIONS(getStaticText) : FORM_VALIDATIONS_UPDATE(getStaticText);

        const translation = {
            getStaticText: getStaticText,
            translationKeys: WebhookTranslationKeys
        };

        return (
            <React.Fragment>

                {this.state.isLoaded ? (
                    <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                        <SMARTForm
                            formValues={this.state.values}
                            validationSchema={validationSchema}
                            validateOnSubmit={true}
                            serverURL={this.state.server_url}
                            isDummy={false}
                            submitCallback={({ response }) => {

                                if (response.success && response.body.IsSuccess) {
                                    navigateTo("/Webhook");
                                    toastSuccess(response.body.Messages, null, translation)
                                }
                                else {
                                    toastError(response.body.Messages, translation)
                                }
                            }}
                        >
                            {({ values, errors, onChange, submitForm, onChangeField }) => (
                                <React.Fragment>
                                    <Row className="body-content">
                                        <Col>
                                            {this.state.prevState.AppIds !== "" ?
                                                <SelectList
                                                    name="AppId"
                                                    value={values.AppId}
                                                    options={this.state.prevState.AppIds}
                                                    onChangeField={onChangeField}
                                                    isMulti={false}
                                                    error={errors.AppId}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label="Application"
                                                    translation={translation}
                                                    required
                                                /> :
                                                <Plaintext
                                                    label="Application"
                                                    name="AppName"
                                                    value={this.state.prevState.AppName}
                                                    translation={translation}
                                                />
                                            }

                                            {this.state.prevState.AppIds !== "" ?
                                                <SelectList
                                                    name="DataType"
                                                    value={values.DataType}
                                                    options={this.state.dataTypeList}
                                                    onChangeField={onChangeField}
                                                    isMulti={false}
                                                    error={errors.DataType}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label="Data Type"
                                                    translation={translation}
                                                    required
                                                /> :
                                                <Plaintext
                                                    label="Data Type"
                                                    name="DataType"
                                                    translation={translation}
                                                    translateValue={true}
                                                    value={this.state.prevState.DataType}
                                                />
                                            }
                                            <Text
                                                name="NotifyUrl"
                                                value={values.NotifyUrl}
                                                placeholder="Notify URL"
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Notify URL"
                                                translation={translation}
                                                error={errors.NotifyUrl}
                                                required
                                            />


                                            <Text
                                                name="Description"
                                                value={values.Description}
                                                placeholder="Description"
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Description"
                                                translation={translation}
                                                error={errors.Description}
                                            />
                                        </Col>
                                    </Row>
                                    <PageFooter>
                                        <Button color="primary" type="button" onClick={() => isValidForm(validationSchema, values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                    </PageFooter>

                                    <ModalConfirm
                                        translation={translation}
                                        isOpen={modal.modalState === "Submit"}
                                        contentHeader={this.state.contentHeader}
                                        contentBody={this.state.contentBody}
                                        confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                        cancelCallback={() => modal.toggleModal("Submit")}
                                        formatter={{ component: getTranslationValue('Webhook', getStaticText, WebhookTranslationKeys) }}
                                    />
                                </React.Fragment>
                            )}
                        </SMARTForm>
                    </Layout>)
                    : null}
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Form);