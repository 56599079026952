import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { DataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { withSMARTWrapper, toastError } from '../../Shared/Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';

const GRID_ENDPOINT = URL + "Organisation/ListOrganisation";
const VIEW_ORGANISATION_URL_PAGE = "Organisation/View";
const UPDATE_ORGANISATION_URL_PAGE = "Organisation/Update"
const NEW_ORGANISATION_URL_PAGE = "/Organisation/New";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const OrgTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAppAdmin: false
        };
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        let appAdminResponse = await fetchRequest(URL + "App/GetIsUserAppAdmin", getParams(), false);

        if (appAdminResponse.success && appAdminResponse.body.IsSuccess) {
            let isAppAdmin = appAdminResponse.body.Data;

            this.setState({
                isAppAdmin: isAppAdmin,
            });
        }
        else {
            toastError(appAdminResponse.body.messages || appAdminResponse.body.Messages);
        }
        loader.done();
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;

        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_ORGANISATION_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                <MobileCardActionButton
                    key={'Update' + Id}
                    onClick={() => navigateTo(UPDATE_ORGANISATION_URL_PAGE, ID)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
            </React.Fragment>
        )
    }

    setContent = (rowData) => {
        return <div className="div-link" onClick={() => navigateTo(VIEW_ORGANISATION_URL_PAGE, { ID: rowData.Id })}>{rowData.AbbreviatedName}</div>
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: OrgTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of Organisations" />
                <div className="grid-container">
                    <Row>
                        <Col>

                            {this.state.isAppAdmin &&
                                <ActionButton className='add-button' color="neutral" onClick={() => navigateTo(NEW_ORGANISATION_URL_PAGE)}>
                                    <FontAwesomeIcon icon={faPlus} /></ActionButton>
                            }
                            <DataTable
                                translation={translation}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No Organisations found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        AppName: { title: 'Application', width: '15%', DBkey: 'AppName' },
                                        AbbreviatedName: { title: 'Organisation', width: '10%', DBkey: 'AbbreviatedName', setContent: this.setContent },
                                        FullName: { title: 'Organisation (Full Name)', width: '30%', DBkey: 'FullName' },
                                        EffectiveFromDate: { title: 'Effective From', width: '15%', DBkey: 'EffectiveFromDate' },
                                        EffectiveToDate: { title: 'Effective To', width: '15%', DBkey: 'EffectiveToDate' },
                                        Status: { title: 'Status', width: '7%', DBkey: 'Status' }
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        {rowData.RowData.IsEditable && <ActionButton key={'Update' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral" onClick={() => navigateTo(UPDATE_ORGANISATION_URL_PAGE, { ID: rowData.ID })}> <FontAwesomeIcon icon={faEdit} /></ActionButton>}
                                        {/* <ActionButton key={'View' + rowData.ID} tooltip="View Organisation" color="forward" onClick={() => navigateTo(VIEW_ORGANISATION_URL_PAGE, { ID: rowData.ID })}> <FontAwesomeIcon icon={faEye} /></ActionButton> */}
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);