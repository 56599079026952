import React from 'react';
import { AuthConsumer } from '../../Shared/Authentication/AuthContext';
import AuthHelper from '../../Shared/Authentication/AuthHelper';

let TIMEOUT_IN_SECONDS = 0;
let timeInSecondsAfterSessionOut = TIMEOUT_IN_SECONDS;

const withTimerContext = WrappedComponent => {
    class TimerContext extends React.Component {
        render() {
            return (
                <AuthConsumer>
                    {({ userAccountSettings }) => (                     
                        <WrappedComponent
                            idleTimeoutInSeconds={userAccountSettings && userAccountSettings.IdleTimeoutInSeconds}
                            {...this.props}
                        />
                    )}
                </AuthConsumer>
            );
        }
    }

    return TimerContext;
};

class Timeout extends React.Component {

    componentDidMount() {
        TIMEOUT_IN_SECONDS = this.props.idleTimeoutInSeconds;
        timeInSecondsAfterSessionOut = TIMEOUT_IN_SECONDS;

        this.timingInterval();

        window.addEventListener('click', this.resetThisSession);
        window.addEventListener('keypress', this.resetThisSession);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.resetThisSession);
        window.removeEventListener('keypress', this.resetThisSession);
    }

    resetThisSession = () => {
        timeInSecondsAfterSessionOut = TIMEOUT_IN_SECONDS;
    }

    addIdlingTime = () => {
        --timeInSecondsAfterSessionOut;

        if (timeInSecondsAfterSessionOut === 0) {
            // Login prompt after timeout (force user to login again)
            AuthHelper.getPromptManager().signinRedirect({
                state: {
                    returnUrl: '/',
                    referrerAppUrl: document.referrer
                }
            });
        }
    }

    timingInterval = () => setInterval(this.addIdlingTime, 1000);

    render() {
        return null;
    }
}
export default withTimerContext(Timeout);