import React from 'react'
import AuthHelper from './AuthHelper.js';
import { IDENTITY_URL } from '../../Shared/SmartMe/Constants';
import { GET_USER_PERMISSION_URL } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { toastError } from '../../Shared/Forms';

const AuthContext = React.createContext()

const GET_USER_ACCOUNT_SETTINGS_URL = IDENTITY_URL + '/Account/GetUserAccountSettings';
const IS_USER_SECURITY_QNS_PRESENT_URL = IDENTITY_URL + '/Account/IsSecurityQuestionsPresent/';

class AuthProvider extends React.Component {
  constructor() {
    super()
    this.mgr = AuthHelper.getManager();

    this.state = {
      isAuthenticated: false,
      profile: null,
      roles: [],
      permissions: [],
      isReady: false
    }
  }

  getUserPermissions = async (userGuid) => {
    const response = await fetchRequest(GET_USER_PERMISSION_URL + userGuid, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(Messages);
    }
  }

  getUserAccountSettings = async () => {
    const response = await fetchRequest(GET_USER_ACCOUNT_SETTINGS_URL, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(Messages);
    }
  }

  async isSecurityQuestionsPresent(userId) {
    const response = await fetchRequest(IS_USER_SECURITY_QNS_PRESENT_URL + userId, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(Messages);
    }
  }

  componentDidMount() {
    let self = this;

    this.mgr.getUser().then(async function (user) {
      let isAuth = !(typeof user === 'undefined' || user === null);
      self.setState({ isAuthenticated: isAuth })

      if (isAuth) {
        self.setState({
          userAccountSettings: await self.getUserAccountSettings(),
          isSecurityQuestionsPresent: await self.isSecurityQuestionsPresent(user.profile.sub),
          profile: user.profile,
          permissions: await self.getUserPermissions(user.profile.userguid)
        });
      }

      self.setState({
        isReady: true
      });
    });
  }

  login = (returnUrl) => {
    this.mgr.signinRedirect({
      state: {
        returnUrl: returnUrl,
        referrerAppUrl: document.referrer
      }
    });
  }

  logout = (e) => {
    e.preventDefault();
    this.setState({
      isAuthenticated: false,
      profile: null
    });
    this.mgr.signoutRedirect();
  }

  render() {
    return (this.state.isReady ?
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          userAccountSettings: this.state.userAccountSettings,
          isSecurityQuestionsPresent: this.state.isSecurityQuestionsPresent,
          profile: this.state.profile,
          permissions: this.state.permissions,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider> :
      <AuthContext.Provider></AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }