export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Group Name",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "GroupName"
    },
    {
        "DefaultLanguageValue": "Organisation Admin",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "OrganisationAdmin"
    },
    {
        "DefaultLanguageValue": "Organisation Specific",
        "Scope": "MeUserGroup",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "OrganisationSpecific"
    }
]