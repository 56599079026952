import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../../../Localization';
import { ActionButton, DataTable, MobileCardActionButton } from '../../../DataTable';
import { withLoader } from '../../../Loading';
import { AssignUserToUserGroupModal } from '../../Modal';
import '../AssignUserToUserGroup/Style.css';
import { getTranslationValue } from '../../../../Shared/Forms/FormUtils';


const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

class AssignUserToUserGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            deleteItemArray: props.deleteItemArray,
            isOpen: false,
            isUpdatePage: props.isUpdatePage,
            toggleUpdate: false,
            isOrganisationSpecific: props.isOrganisationSpecific,
            selectedOrganisation: props.selectedOrganisation,
            organisationsOptions: props.organisationsOptions,
        }
    }

    toggle = () => {
        this.setState({
            modalTitle: 'Add new User',
            modal: !this.state.modal,
        });
    }

    clearEditItem = () => {
        this.setState({
            editItem: undefined
        })
    }

    getRowIndex = (rowData) => {
        const currentRowData = Object.assign({}, rowData);
        delete currentRowData.griddleKey;

        const { data } = this.props;
        const isRowData = (x) => _.isEqual(x, currentRowData);
        const rowIndex = data.findIndex(isRowData);

        return rowIndex;
    }

    editItem = (rowData) => {
        const { data } = this.props;
        const rowIndex = this.getRowIndex(rowData);

        this.setState({
            modal: !this.state.modal,
            modalTitle: 'Edit User',
            editItem: { data: data[rowIndex], rowIndex: rowIndex }
        });
    }

    saveEditItem = (values, rowIndex) => {
        const { data } = this.props;
        data[rowIndex] = values;

        this.setState({
            modal: !this.state.modal,
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    deleteItem = (rowData) => {
        const { data } = this.props;
        const rowIndex = this.getRowIndex(rowData);

        data.splice(rowIndex, 1);

        this.setState({
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    addItem = (values) => {
        const { data } = this.props;

        const effectiveEndDate = values.EffectiveEndDate;
        values.StrEffectiveEndDate = effectiveEndDate === "" ? "-" : effectiveEndDate;
        values.UserGroupToUserOrganisationId = 0;

        data.push(values);

        this.setState({
            modal: !this.state.modal,
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    renderMobileCardActions = (data) => {
        const { translation } = this.props;
        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys

        const rowIndex = parseInt(_.uniqueId());

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'Update' + rowIndex}
                    onClick={() => this.editItem(data)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
                <MobileCardActionButton
                    key={'Delete' + rowIndex}
                    onClick={() => this.deleteItem(data)}
                    modal={DELETE_MODAL(getStaticText)}
                    icon={faTrash}
                    label={BUTTON_TITLE(getStaticText).DELETE}
                    formatter={{ component: getTranslationValue('User', getStaticText, translationKeys) }}
                />
            </React.Fragment>
        )
    }

    render() {
        const { translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        return (
            <Row>
                <Col lg={12}>
                    {this.state.isUpdatePage &&
                        <Button color="primary" className="add-modal-button" type="button" onClick={this.toggle}>{BUTTON_TITLE(getStaticText).ADD}</Button>
                    }
                </Col>
                <Col lg={12}>
                    <DataTable
                        translation={translation}
                        key={this.state.toggleUpdate}
                        data={this.props.data}
                        pageSize='5'
                        minFilterChars='2'
                        noResultsMessage='No users found'
                        columns={
                            {
                                RunningNumber: { title: 'No.' },
                                Actions: { title: 'Actions', visible: this.state.isUpdatePage },
                                OrganisationName: { title: 'Organisation Name', DBkey: 'OrganisationName', translateValue: true },
                                // Fullname: { title: 'Fullname', DBkey: 'Fullname' },
                                Username: { title: 'Username', DBkey: 'Username' },
                                Email: { title: 'Email', DBkey: 'Email' },
                                EffectiveStartDate: { title: 'Effective Start Date', DBkey: 'EffectiveStartDate' },
                                StrEffectiveEndDate: { title: 'Effective End Date', DBkey: 'StrEffectiveEndDate' }
                            }
                        }
                        id="MeUsers"
                        renderActions={({ RowData, ID }) =>
                        (
                            <React.Fragment>
                                {this.state.isUpdatePage && RowData.Organisation !== null &&
                                    <>
                                        <ActionButton key={'Update' + ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                            onClick={() => this.editItem(RowData)} >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </ActionButton>
                                        <ActionButton key={'Delete' + ID} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                            onClick={() => this.deleteItem(RowData)} modal={DELETE_MODAL(getStaticText)}
                                            formatter={{ component: getTranslationValue('User', getStaticText, translationKeys) }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </ActionButton>
                                    </>
                                }
                            </React.Fragment>
                        )
                        }
                        renderMobileCardActions={this.state.isUpdatePage && this.renderMobileCardActions}
                    />
                    {this.state.isUpdatePage &&
                        <AssignUserToUserGroupModal
                            translation={translation}
                            toggle={this.toggle}
                            modalState={this.state.modal}
                            data={this.props.data}
                            addItem={this.addItem}
                            editItem={this.state.editItem}
                            saveEditItem={this.saveEditItem}
                            clearEditItem={this.clearEditItem}
                            modalTitle={this.state.modalTitle}
                            isOrganisationSpecific={this.state.isOrganisationSpecific}
                            selectedOrganisation={this.state.selectedOrganisation}
                            organisationsOptions={this.state.organisationsOptions}
                        />
                    }
                </Col>
            </Row>
        );
    }
}

export default withLoader(withRouter(AssignUserToUserGroup));