import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { LAYOUT_TYPE } from '../../../App/AppSettings';
import Layout from '../../../Layout';

class Notification extends React.Component {
	render() {
		const { cardTitle, cardHeader, cardMessage, iconClassNames, iconTitle,
			btnVisible, btnTitle, btnMessage, btnClick } = this.props;

		const onBtnClick = btnClick === undefined ?
			null : btnClick;

		return (
			<React.Fragment>
				<Layout type={LAYOUT_TYPE.FORM} title={cardTitle}>
					<Col>
						{cardHeader}
						<Row>
							<Col className="body-content text-center">
								<p><i className={iconClassNames} title={iconTitle}></i></p>
								{cardMessage}
								<div className="form-footer">
									{btnVisible === false ?
										null :
										<Button className="custom-btn"
											onClick={onBtnClick}
											name="button"
											title={btnTitle}>
											{btnMessage}
										</Button>
									}
								</div>
							</Col>
						</Row>
					</Col>
				</Layout>
			</React.Fragment>
		);
	}
}

export default Notification;