import { fetchRequest, getParams } from '../../../Actions';
import { toastError } from '../../../Forms';
import { selectListMapper } from '../../Actions';
import { URL } from '../../Constants';

export const getRoles = async (loader) => {
    const response = await fetchRequest(URL + "Role/GetRoles", getParams(), false);
    const { IsSuccess, Data } = response.body;

    let rolesResponse = { selectable: [], allRoles: [] };
    if (response.success && IsSuccess) {
        let roles = Data;

        //map all selectable items to select input {value:label} pair 
        let mappedSelectable = selectListMapper(roles, "Id", "RoleName");

        rolesResponse = {
            selectable: mappedSelectable,
            allRoles: roles
        };
    } else {
        loader.done();
        toastError("Unable to load roles");
    }

    return rolesResponse;
};

// Assign Roles to User
export const userRolesMapper = (response, allRoles) => {
    var result = [];
    response.map(x => {
        var role = {};
        allRoles.map(y => {
            if (x.MeRoleId === y.Id) {
                Object.assign(role, { Id: y.Id, RoleName: y.RoleName, DisplayRoleName: y.DisplayRoleName })
                return null;
            }
            return null;
        })
        Object.assign(role, { MeRoleId: x.MeRoleId, EffectiveStartDate: x.EffectiveStartDate, EffectiveEndDate: x.EffectiveEndDate, Existing: true, MeRoleToUserID: x.Id })
        result.push(role);
        return null;
    })
    return result;
}

export const mapUserRoles = (data, deleteItemArray, id) => {
    var addArray = [];
    if (typeof (data) !== 'undefined') {
        data.map((x) => {
            if (!x["Existing"]) {
                var obj = {
                    MeRoleId: x["Id"], EffectiveStartDate: x["EffectiveStartDate"], EffectiveEndDate: x["EffectiveEndDate"]
                }
                addArray.push(obj);
            }
            return null;
        })
    }

    var deleteArray = [];
    if (deleteItemArray.length > 0) {
        deleteItemArray.map(x => {
            deleteArray.push(x["MeRoleToUserID"])
            return null;
        })
    }

    return { AddItems: addArray, DeleteItems: deleteArray, Id: id }
}