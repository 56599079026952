export const TableTranslationKeys = [
    {
        "DefaultLanguageValue": "No.",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Column",
        "TranslationKey": "No"
    },
    {
        "DefaultLanguageValue": "Actions",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Column",
        "TranslationKey": "Actions"
    },
    {
        "DefaultLanguageValue": "Created Date",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Column",
        "TranslationKey": "CreatedDate"
    },
    {
        "DefaultLanguageValue": "Last Updated",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Column",
        "TranslationKey": "LastUpdated"
    },
    {
        "DefaultLanguageValue": "Filter",
        "Scope": "DefaultStatic",
        "Category": "Table",
        "SubCategory": "Placeholder",
        "TranslationKey": "Filter"
    }
]