import { ErrorTranslationKeys } from '../App/TranslationKeys/ErrorTranslationKeys';
import { ModalTranslationKeys } from '../App/TranslationKeys/ModalTranslationKeys';
import { ButtonTranslationKeys } from '../App/TranslationKeys/ButtonTranslationKeys';
import { TooltipTranslationKeys } from '../App/TranslationKeys/TooltipTranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../Shared/Forms/FormUtils';
import { LANGUAGE_APPLICATION_NAME } from '../App/AppSettings';

const App = LANGUAGE_APPLICATION_NAME;
const ErrorKeys = getTranslationKeys(App, ErrorTranslationKeys);
const ModalKeys = getTranslationKeys(App, ModalTranslationKeys);
const ButtonKeys = getTranslationKeys(App, ButtonTranslationKeys);
const ToolTipKeys = getTranslationKeys(App, TooltipTranslationKeys);

// Const: Error Messages
export const ERROR = {
    REQUIRED: '<%= label %> is required.',
    INVALID: '<%= label %> is invalid.',
    TICK_REQUIRED: 'Please tick the box to confirm that you have read the above.',
    MIN_VALUE: 'Minimum value is <%= minValue %>.',
    MAX_VALUE: 'Maximum value is <%= maxValue %>.',
    FILE_LIMIT_REACHED: 'Maximum number of file(s) uploaded is <%= limit %>.',
    FILE_ITEM_REQUIRED: 'File is required.',
    FILE_DESC_REQUIRED: 'File description is required.',
    FORM_VALIDATION_FAIL: 'Kindly fill in the required fields appropriately. Please try again.',
    FORM_DEFAULT_FAIL: 'Application has not been processed successfully.',
    PASSWORD_REGEX_UNMATCH: 'The password should contain at least 8 characters, with 1 uppercase, 1 lowercase and 1 number.',
    EMAIL_INVALID: 'Email is invalid.',
    MIN_LENGTH_LIMIT: 'Minimum length is <%= minLength %>.',
    MAX_LENGTH_LIMIT: 'Maximum length is <%= maxLength %>.',
    TELEPHONE_INVALID: 'Telephone number provided is invalid.',
    NUMBER_INVALID: 'Number provided is invalid.',
    APPLICATION_DELETE_FAIL: 'Application has not been deleted. Please try again.',
    LIST_RETRIEVAL_FAIL: 'Unable to retrieve list. Please try again.',
    DRAFT_SAVED_FAIL: 'Draft has not been saved. Please try again.',
    DRAFT_EMPTY_FIELD: 'Please fill in at least one field.',
    DATE_INVALID: 'Date is invalid.',
    TIME_INVALID: 'Time is invalid.',
    DATA_RETRIEVAL_FAIL: 'Failed to retrieve required data. Please try again.',
    //Work Hour Error
    TIME_INVALID_WE: 'Work End should be after Work Start',
    TIME_INVALID_BS: 'Break Start should be after Work Start',
    TIME_INVALID_BSWE: 'Break Start should be before Work End',
    TIME_INVALID_BE: 'Break End should be after Break Start',
    SERVER_UNREACHABLE: 'Server could not be reached, please try again later.',
    SERVER_ERROR: 'Server has encountered an unexpected error. Please try again later.',
    SEQUENCE_NO: 'Sequence must be a number.',
    EED_BEFORE_ESD: 'Effective End Date should not be earlier than Effective Start Date',
};

const getErrorMsg = (errorMsgType, getStaticText) => {
    return getTranslationValue(errorMsgType, getStaticText, ErrorKeys);
}

export const ERROR_MSG = (getStaticText) => {
    return ({
        REQUIRED: getErrorMsg(ERROR.REQUIRED, getStaticText),
        INVALID: getErrorMsg(ERROR.INVALID, getStaticText),
        EED_BEFORE_ESD: getErrorMsg(ERROR.EED_BEFORE_ESD, getStaticText),
        SERVER_ERROR: getErrorMsg(ERROR.SERVER_ERROR, getStaticText)
    })
}

export const REQUIRED_VALIDATION_MESSAGE = (Field) => {
    return Field + " is required.";
}

export const INVALID_NUMBER_MESSAGE = (Field) => {
    return Field + " is an invalid number.";
}

// Const: Modal Messages
export const MODAL = {
    CREATE: {
        HEADER: 'Create <%= component %> Confirmation',
        BODY: 'Confirm creation of <%= component %>?'
    },
    UPDATE: {
        HEADER: 'Update <%= component %> Confirmation',
        BODY: 'Confirm update of <%= component %>?'
    },
    DELETE: {
        HEADER: 'Delete <%= component %>',
        BODY: 'Are you sure you want to delete <%= component %>?'
    }
};

const getModalTitle = (modalMsgType, getStaticText) => {
    return getTranslationValue(modalMsgType, getStaticText, ModalKeys);
}

export const MODAL_TITLE = (getStaticText) => {
    return ({
        CREATE: {
            HEADER: getModalTitle(MODAL.CREATE.HEADER, getStaticText),
            BODY: getModalTitle(MODAL.CREATE.BODY, getStaticText)
        },
        UPDATE: {
            HEADER: getModalTitle(MODAL.UPDATE.HEADER, getStaticText),
            BODY: getModalTitle(MODAL.UPDATE.BODY, getStaticText)
        },
        DELETE: {
            HEADER: getModalTitle(MODAL.DELETE.HEADER, getStaticText),
            BODY: getModalTitle(MODAL.DELETE.BODY, getStaticText)
        }
    })
}

// Const: Success Messages
export const SUCCESS = {
    FORM_DEFAULT_SUCCESS: 'Application has been processed successfully.',
    APPLICATION_DELETE_SUCCESS: 'Application has been deleted successfully.',
    CREATE_SUCCESS: '<%= component %> has been added successfully.',
    UPDATE_SUCCESS: '<%= component %> has been updated successfully.',
    DELETE_SUCCESS: '<%= component %> has been deleted successfully.',
    PUBLISH_SUCCESS: '<%= component %> has been published successfully.',
    REVERT_SUCCESS: '<%= component %> has been reverted successfully.',
    CREATE_DRAFT_SUCCCESS: '<%= component %> draft has been created successfully.'
};

//Const: Button Labels
export const BUTTON = {
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    CREATE: 'Create',
    SAVE_CHANGES: 'Save Changes',
    BACK: 'Back',
    SUBMIT: 'Submit',
    VIEW: 'View',
    UPDATE: 'Update',
    DELETE: 'Delete',
    ADD: 'Add',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    PUBLISH: 'Publish',
    CREATE_DRAFT: 'Create Draft',
    DELETE_DRAFT: 'Delete Draft',
    PREVIEW: 'Preview',
    NEW: 'New <%= component %>',
    SEND_USER_ACTIVATION_EMAIL: 'Send User Activation Email',
    AMEND: 'Amend',
    REVIEW: 'Review'
};

const getButtonTitle = (buttonType, getStaticText) => {
    return getTranslationValue(buttonType, getStaticText, ButtonKeys);
}

export const BUTTON_TITLE = (getStaticText) => {
    return ({
        NEXT: getButtonTitle(BUTTON.NEXT, getStaticText),
        BACK: getButtonTitle(BUTTON.BACK, getStaticText),
        PREVIOUS: getButtonTitle(BUTTON.PREVIOUS, getStaticText),
        SUBMIT: getButtonTitle(BUTTON.SUBMIT, getStaticText),
        ADD: getButtonTitle(BUTTON.ADD, getStaticText),
        SAVE: getButtonTitle(BUTTON.SAVE, getStaticText),
        CANCEL: getButtonTitle(BUTTON.CANCEL, getStaticText),
        VIEW: getButtonTitle(BUTTON.VIEW, getStaticText),
        UPDATE: getButtonTitle(BUTTON.UPDATE, getStaticText),
        DELETE: getButtonTitle(BUTTON.DELETE, getStaticText),
        YES: getButtonTitle(BUTTON.YES, getStaticText),
        NO: getButtonTitle(BUTTON.NO, getStaticText),
        SEND_USER_ACTIVATION_EMAIL: getButtonTitle(BUTTON.SEND_USER_ACTIVATION_EMAIL, getStaticText),
        AMEND: getButtonTitle(BUTTON.AMEND, getStaticText),
        REVIEW: getButtonTitle(BUTTON.REVIEW, getStaticText)
    })
}

//Const: Tooltip Texts
export const TOOLTIP = {
    VIEW: 'View',
    UPDATE: 'Update',
    DELETE: 'Delete',
    SEND_USER_ACTIVATION_EMAIL: 'Send User Activation Email',
    REVIEW_USER_REQUEST: 'Review User Request'
};

const getTooltipText = (tooltipType, getStaticText) => {
    return getTranslationValue(tooltipType, getStaticText, ToolTipKeys);
}

export const TOOLTIP_TEXT = (getStaticText) => {
    return ({
        VIEW: getTooltipText(TOOLTIP.VIEW, getStaticText),
        UPDATE: getTooltipText(TOOLTIP.UPDATE, getStaticText),
        DELETE: getTooltipText(TOOLTIP.DELETE, getStaticText),
        SEND_USER_ACTIVATION_EMAIL: getTooltipText(TOOLTIP.SEND_USER_ACTIVATION_EMAIL, getStaticText),
        REVIEW_USER_REQUEST: getTooltipText(TOOLTIP.REVIEW_USER_REQUEST, getStaticText)
    })
}