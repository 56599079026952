export const GridTranslationKeys = [
    {
        "DefaultLanguageValue": "No. of Roles",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "Label",
        "TranslationKey": "NoOfRoles"
    },
    {
        "DefaultLanguageValue": "No. of Users",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "Label",
        "TranslationKey": "NoOfUsers"
    },
    {
        "DefaultLanguageValue": "Role Name",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "RolesToUserGroup",
        "TranslationKey": "RoleName"
    },
    {
        "DefaultLanguageValue": "Display Role Name",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "RolesToUserGroup",
        "TranslationKey": "DisplayRoleName"
    },
    {
        "DefaultLanguageValue": "Organisation Name",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "UsersToUserGroup",
        "TranslationKey": "OrganisationName"
    },
    {
        "DefaultLanguageValue": "Fullname",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "UsersToUserGroup",
        "TranslationKey": "Fullname"
    },
    {
        "DefaultLanguageValue": "Username",
        "Scope": "MeUserGroup",
        "Category": "Grid",
        "SubCategory": "UsersToUserGroup",
        "TranslationKey": "Username"
    }
]