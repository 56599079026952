import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { compose } from 'recompose';
import { Plaintext, withTranslationWrapper } from '../../Shared/Forms';
import { BUTTON_TITLE } from '../../Localization';


const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {

            let response = await fetchRequest(URL + "User/GetNonActiveUser/" + state.ID, getParams(), false);

            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;
                this.setState({
                    values: {
                        IdentificationNo: data.Profile.IdentificationNo,
                        FirstName: data.Profile.FirstName,
                        MiddleName: data.Profile.MiddleName || "-",
                        LastName: data.Profile.LastName,
                        Gender: data.Profile.Gender,
                        Username: data.Username,
                        Email: data.Email,
                        DateOfBirth: data.Profile.DateOfBirth,
                    },
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }
        }


        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Non-Active User Details">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col className="smart-me-content">
                                    <Plaintext
                                        label="First Name"
                                        name="FirstName"
                                        className="mb-1"
                                        value={this.state.values.FirstName}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Middle Name"
                                        name="MiddleName"
                                        className="mb-1"
                                        value={this.state.values.MiddleName}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Last Name"
                                        name="LastName"
                                        className="mb-1"
                                        value={this.state.values.LastName}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Gender"
                                        name="Gender"
                                        className="mb-1"
                                        value={this.state.values.Gender}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Date Of Birth"
                                        name="DateOfBirth"
                                        className="mb-1"
                                        value={this.state.values.DateOfBirth}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Username"
                                        name="Username"
                                        className="mb-1"
                                        value={this.state.values.Username}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                    <Plaintext
                                        label="Primary Email Address"
                                        name="Email"
                                        className="mb-1"
                                        value={this.state.values.Email}
                                        labelSize={LabelSize}
                                        translation={translation}
                                    />
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default compose(withTranslationWrapper, withPageNotFound, withLoader, withRouter)(View);