import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { withFormAlert } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import { ADDRESS } from '../../App/AppSettings';
import { withLoader } from '../../Shared/Loading';
import { getRoles } from '../../Shared/SmartMe/Components/AssignRolesToUser/Utils';
import { fetchRequest, getParams } from '../../Shared/Actions';
import history from '../../Shared/History';
import { withPageNotFound } from '../../Shared/Error';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'User/CreateUser';

class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;
    
        loader.start();
        if (state === undefined || state.ID === undefined || state.UserCheckType === undefined) {
            loader.done();
            pageNotFound();
        }

        let appIDRequest = await fetchRequest(URL + "App/GetAppsByUserId", getParams(), false);
        let rolesResponse = await getRoles(loader);

        if (appIDRequest.success && appIDRequest.body.IsSuccess) {

            const idNumberArr = [{ IdType: '', IdNumber: '', IdCountry: '' }];

            this.setState({
                values: {
                    Profile: {
                        FirstName: '',
                        MiddleName: '',
                        LastName: '',
                        Gender: '',
                        Username: '',
                        Email: '',
                        DateOfBirth: '',
                        Salutation: '',
                        Nationality: '',
                        AltEmailAddress: '',
                        PrimaryContact: '',
                        MobileNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        OfficeNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        HomeNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        FaxNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        IdNumbersArr: idNumberArr,
                        IdNumbers: JSON.stringify(idNumberArr),
                        Address: {
                            AddressType: '',
                            PostalCode: '',
                            Country: '', // Only applicable to Address Type: Overseas
                            City: '',
                            State: '',
                            AddressLine1: ADDRESS.ADDRESS_FIELDS.LINE1,
                            AddressLine2: ADDRESS.ADDRESS_FIELDS.LINE2
                        }
                    },
                    Organisation: [],
                    GlobalUserGroups: [],
                    UserGroups: [],
                    // Roles: [],
                    UserType: state.UserType,
                    Username: '',
                    Email: state.Email,
                },
                roles: rolesResponse,
                isLoaded: true
            });
        }
        else {
            const { formAlert } = this.props; 
            formAlert.showAlert(appIDRequest.body.messages || appIDRequest.body.Messages);

            loader.done();
        }
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <>
                {this.state.isLoaded &&
                    <Form
                        loader={this.props.loader}
                        values={this.state.values}
                        server_url={SERVER_URL}
                        title="New User"
                        roles={this.state.roles}
                        contentHeader={MODAL_TITLE(getStaticText).CREATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).CREATE.BODY}
                        action="New"
                    />
                }
            </>
        )
    }
}
export default withPageNotFound(withFormAlert(withLoader(withRouter(New))));
















