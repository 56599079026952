import Oidc from 'oidc-client';
import { AUTHORITY_CONFIG } from '../../App/AppSettings.js';

let mgr = new Oidc.UserManager(AUTHORITY_CONFIG.openid_client_configuration.authentication_settings);
let promptMgr = new Oidc.UserManager(AUTHORITY_CONFIG.openid_client_prompt_configuration.authentication_settings);

mgr.events.addAccessTokenExpiring(function () {});
mgr.events.addUserSignedOut(function() {
  mgr.signoutRedirect();
});

class AuthHelper {
  static async getUser(callback) {
    mgr.getUser().then(function (user) {
      return callback(user);
    });
  }

  static intersect(a, b) {
    var t;
    if (b.length > a.length) 
    {
      t = b; 
      b = a; 
      a = t;
    }
    
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
  }

  static intersectPermissions = (permissions, givenPermissions) => {
    permissions = permissions.map(function (x) { return x.toUpperCase() });
    givenPermissions = givenPermissions.map(function (x) { return x.toUpperCase() });

    return AuthHelper.intersect(permissions, givenPermissions).length > 0;
  }

  static getManager()
  {
    return mgr;
  }

  static getPromptManager()
  {
    return promptMgr;
  }
}

export default AuthHelper;