import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { APP_REGISTERED_LANGUAGE_URL, LANGUAGE_CODE_LIST_URL, LANGUAGE_REGISTRATION_EDIT_URL, LANGUAGE_REGISTRATION_LIST, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { CONTENT_TYPE, fetchRequest, formParams, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, Checkbox, Plaintext, Radio, SelectList, SMARTForm, toastError, toastSuccess } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';

const FORM_CONTEXT = "EditAppLanguage";

const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

class EditApplicationLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                "LanguageObj": {}
            },
            languagesDropDown: []
        }
        if (history.location.state) {
            this.state = {
                values: {
                    "LanguageObj": {}
                },
                languagesDropDown: [],
                appData: history.location.state.appData,
                isLanguagesDropDownFetched: false,
                isExistingLanguagesFetched: false
            }
        }
    }

    async componentDidMount() {
        this.props.loader.start();
        this.fetchLanguageSettings();
    }

    fetchLanguageSettings = async () => {
        const { loader } = this.props;
        const params = {
            applicationId: this.state.appData.ApplicationId
        }
        const response = await fetchRequest(APP_REGISTERED_LANGUAGE_URL, getParams(params));
        const { Data, IsSuccess } = response.body;
        if (IsSuccess) {
            this.setState({
                languageList: Data,
            });
            this.fetchAvailableLanguages(Data);
            this.getExistingValues(Data);
        }
        this.setState({
            isExistingLanguagesFetched: true
        }, () => this.stopLoader(loader))
    }

    fetchAvailableLanguages = async (currLanguages) => {
        const { loader } = this.props;
        const response = await fetchRequest(LANGUAGE_CODE_LIST_URL, getParams());
        const { Data, IsSuccess } = response.body;
        let dropdown = [];

        if (IsSuccess) {
            const filteredLanguages = Data.filter(lang => !currLanguages.some(currL => currL.LanguageCodeValue === lang.LanguageCodeValue));
            for (let lang of filteredLanguages) {
                dropdown.push({
                    value: lang.LanguageCodeValue, label: `${lang.LanguageCodeTitle} (${lang.LanguageCodeValue})`
                })
            }
            this.setState({
                languagesDropDown: dropdown,
            });
        }
        this.setState({
            isLanguagesDropDownFetched: true
        }, () => this.stopLoader(loader))
    }

    getExistingValues = (data) => {
        let values = this.state.values;
        data.forEach(lang => {
            values["LanguageObj"][lang.LanguageCodeValue] = {
                "IsDefault": lang.IsDefault === true ? "true" : "false",
                "IsActive": lang.IsActive === true ? ["true"] : [],
                "IsAlignRight": lang.IsAlignRight === true ? ["true"] : [],
            }
        })
        this.setState({
            values
        })
    }

    stopLoader = async (loader) => {
        const { isLanguagesDropDownFetched, isExistingLanguagesFetched } = this.state;

        if (isLanguagesDropDownFetched && isExistingLanguagesFetched) {
            loader.done();
        }
    }

    isDefaultChange = (lang, val) => {
        const langObj = val["LanguageObj"];
        const keys = Object.keys(langObj);
        let changedValue = langObj[lang].IsDefault === "true" ? "false" : "true";
        keys.forEach(language => {
            if (language === lang) {
                langObj[language].IsDefault = changedValue;
            } else {
                langObj[language].IsDefault = changedValue === "true" ? "false" : "true";
            }
        })
        this.setState({
            values: val
        })
    }

    isActiveChange = (lang, values, value) => {
        const langObj = values["LanguageObj"];
        if (value.length === 0) {
            langObj[lang].IsActive = [];
        } else {
            langObj[lang].IsActive = ["true"];
        }
        this.setState({
            values: values
        })
    }

    isAlignRightChange = (lang, values, value) => {
        const langObj = values["LanguageObj"];
        if (value.length === 0) {
            langObj[lang].IsAlignRight = [];
        } else {
            langObj[lang].IsAlignRight = ["true"];
        }
        this.setState({
            values: values
        })
    }

    setNewDefault = (langToRemove, langObj) => {
        const isDefaultWasSelected = langObj[langToRemove].IsDefault === "true" ? true : false;
        let newLangObj = Object.assign({}, langObj);
        delete newLangObj[langToRemove];
        if (isDefaultWasSelected) {
            const entries = Object.entries(newLangObj);
            const langArr = entries.find(key => key[1].IsActive.length > 0 ? true : false);
            if (entries.length > 0) {
                if (langArr === undefined) {
                    newLangObj[entries[0][0]].IsDefault = "true";
                } else {
                    newLangObj[langArr[0]].IsDefault = "true";
                }
            }
        }
        return newLangObj;
    }

    removeLanguage = (langToRemove, formProps) => {
        const newLangArr = formProps.values.NewLanguage.filter(l => l !== langToRemove);
        const newLang = this.setNewDefault(langToRemove, formProps.values["LanguageObj"]);
        const newValues = Object.assign({}, formProps.values);
        newValues.LanguageObj = newLang;
        newValues.NewLanguage = newLangArr;
        this.setState({
            values: newValues
        })
    }

    populateValues = (name, value, values, onChangeField) => {
        //on language removed
        if (!value || (Array.isArray(value) && values.NewLanguage && values.NewLanguage.length > value.length)) {
            const langToRemove = values.NewLanguage.filter(l => !value.includes(l));
            const newLang = this.setNewDefault(langToRemove, values["LanguageObj"]);
            const newValues = Object.assign({}, values);
            newValues.LanguageObj = newLang;
            newValues.NewLanguage = value;
            this.setState({
                values: newValues
            })
        }
        //on language added
        else {
            onChangeField(name, value);
        }
    }

    validateBeforeSubmission = (values, submitPlainForm) => {
        const langObj = values["LanguageObj"];
        let hasError = false;
        let defaultCount = 0;
        for (let lang in langObj) {
            let isDefault = langObj[lang].IsDefault === "true";
            if (isDefault) {
                defaultCount++;
            }
            let isActive = langObj[lang].IsActive.length > 0;
            if (isDefault && !isActive) {
                hasError = true;
            }
        }
        if (!hasError && defaultCount === 1) {
            this.prepareValuesForSubmission(values, submitPlainForm);
        }
    }

    prepareValuesForSubmission = (values, submitPlainForm) => {
        let languageValues = [];
        const { ApplicationId, ApplicationName } = this.state.appData;
        const langObj = values["LanguageObj"];

        for (let lang in langObj) {
            let isDefault = langObj[lang].IsDefault === "true";
            languageValues.push({
                "LanguageCode": lang,
                "IsDefault": isDefault ? true : false,
                "IsActive": langObj[lang].IsActive.length > 0 ? true : false,
                "IsAlignRight": langObj[lang].IsAlignRight.length > 0 ? true : false,
            });
        }
        values = { ...values, "LanguageDetails": languageValues, "ApplicationId": ApplicationId, "ApplicationName": ApplicationName }
        this.setState({
            values: values
        }, () => submitPlainForm())
    }

    submitCallback = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                navigateTo(LANGUAGE_REGISTRATION_LIST);
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEAPPLANG, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.APPLICATION_LANGUAGE);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    renderExistingLangRows = (lang, formProps) => {
        let arr = [];
        const langObj = formProps.values["LanguageObj"][lang.LanguageCodeValue];

        let isDefault = langObj.IsDefault === "true";
        let isActive = langObj.IsActive.length > 0;
        arr.push(
            <Row key={lang.LanguageCodeValue}>
                <Col xs="3">
                    <p>{`${lang.LanguageCodeTitle} (${lang.LanguageCodeValue})`}</p>
                    {isDefault && !isActive &&
                        <p className="label-feedback edit-error-msg">
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_DEFAULTLANG, DEFAULT_TEXT.ERROR_DEFAULT_LANG)}
                        </p>
                    }
                </Col>
                {this.renderCheckboxes(lang.LanguageCodeValue, formProps)}
            </Row>
        )
        return (
            <>{arr}</>
        )
    }

    renderAddedLangRows = (lang, formProps) => {
        const chosenLangOption = this.state.languagesDropDown.find(langOption => langOption.value === lang);
        const { values } = formProps;
        const langObj = values["LanguageObj"];
        const setAsDefault = this.state.languageList.length === 0 && formProps.values.NewLanguage.length <= 1;
        let arr = [];
        if (langObj[lang] === undefined) {
            formProps.values["LanguageObj"][lang] = {
                "IsDefault": setAsDefault ? "true" : "false",
                "IsActive": ["true"],
                "IsAlignRight": [],
            }
        }
        let isDefault = langObj[lang].IsDefault === "true";
        let isActive = langObj[lang].IsActive.length > 0;

        arr.push(
            <Row key={chosenLangOption.label}>
                <Col xs="3">
                    <p>{chosenLangOption.label}</p>
                    {isDefault && !isActive &&
                        <p className="label-feedback edit-error-msg">
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_DEFAULTLANG, DEFAULT_TEXT.ERROR_DEFAULT_LANG)}
                        </p>
                    }
                </Col>
                {this.renderCheckboxes(lang, formProps)}
                <Col xs="2">
                    <FontAwesomeIcon 
                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REMOVE, DEFAULT_TEXT.REMOVE)} 
                        className="remove-language-icon" 
                        icon={faTimesCircle} 
                        onClick={() => this.removeLanguage(lang, formProps)} 
                    />
                </Col>
            </Row>
        )
        return (
            <>{arr}</>
        )
    }

    renderCheckboxes = (lang, formProps) => {
        const langObj = formProps.values["LanguageObj"];
        return (
            <>
                <Col xs="2">
                    <Radio
                        name={`LanguageObj.${lang}.IsDefault`}
                        value={langObj[lang]["IsDefault"]}
                        options={[{ value: "true" }]}
                        labelSize={FIELD_SIZE.NONE}
                        onChange={() => this.isDefaultChange(lang, formProps.values)}
                        disabled={langObj[lang]["IsActive"].length > 0 ? false : true}
                    />
                </Col>
                <Col xs="2">
                    <Checkbox
                        className="checkbox-declaration"
                        name={`LanguageObj.${lang}.IsActive`}
                        value={langObj[lang]["IsActive"]}
                        options={[{ value: "true" }]}
                        onChangeField={(name, value) => this.isActiveChange(lang, formProps.values, value)}
                        labelSize={FIELD_SIZE.NONE}
                    />
                </Col>
                <Col xs="2">
                    <Checkbox
                        className="checkbox-declaration"
                        name={`LanguageObj.${lang}.IsAlignRight`}
                        value={langObj[lang]["IsAlignRight"]}
                        options={[{ value: "true" }]}
                        onChangeField={(name, value) => this.isAlignRightChange(lang, formProps.values, value)}
                        labelSize={FIELD_SIZE.NONE}
                    />
                </Col>
            </>
        );
    }

    render() {
        const { languageList, values, languagesDropDown, appData } = this.state;

        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITAPPLANG, DEFAULT_TEXT.EDIT_APP_LANG)}>
                    {languageList &&
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={values}
                            serverURL={LANGUAGE_REGISTRATION_EDIT_URL}
                            submitCallback={this.submitCallback}
                            formParams={formParams("POST", CONTENT_TYPE.JSON)}
                        >
                            {({ values, errors, onChangeField, submitPlainForm }) => {
                                const formProps = {
                                    values: values,
                                    onChangeField: onChangeField,
                                    errors: errors
                                };
                                const hasAtLeastOneLang = (languageList && languageList.length > 0) || (values.NewLanguage && values.NewLanguage.length > 0);
                                return (
                                    <>
                                        <Row className="body-content admin-content-wrapper">
                                            <Col>
                                                <Plaintext
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                                    name="Full Key"
                                                    value={appData.ApplicationName}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <SelectList
                                                    name="NewLanguage"
                                                    value={values.NewLanguage}
                                                    options={languagesDropDown}
                                                    onChangeField={(name, value) => this.populateValues(name, value, values, onChangeField)}
                                                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_A_LANGUAGE)}
                                                    isMulti={true}
                                                    isClearable={false}
                                                    labelSize={FIELD_SIZE.SMALL}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_ADDLANG, DEFAULT_TEXT.ADD_LANGUAGE)}
                                                />

                                                {hasAtLeastOneLang &&
                                                    <>
                                                        <div className="row">
                                                            <Col lg="12" className="label-default">
                                                                <Divider className="db-divider" orientation="left">
                                                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_DIVIDER_LANGSETTINGS, DEFAULT_TEXT.LANG_SETTINGS)}
                                                                </Divider>
                                                            </Col>
                                                        </div>
                                                        <Row className="lang-settings-header">
                                                            <Col xs="3"><b>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                                                            </b></Col>
                                                            <Col xs="2"><b>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULT, DEFAULT_TEXT.DEFAULT)}
                                                            </b></Col>
                                                            <Col xs="2"><b>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIVE, DEFAULT_TEXT.ACTIVE)}
                                                            </b></Col>
                                                            <Col xs="2"><b>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_ALIGNRIGHT, DEFAULT_TEXT.ALIGN_TO_RIGHT)}
                                                            </b></Col>
                                                        </Row>
                                                    </>
                                                }

                                                {languageList.map(lang => {
                                                    return (
                                                        <React.Fragment key={lang.LanguageCodeValue}>
                                                            {
                                                                values["LanguageObj"][lang.LanguageCodeValue] &&
                                                                this.renderExistingLangRows(lang, formProps)
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })
                                                }
                                                {values.NewLanguage && values.NewLanguage.map(lang => {
                                                    return (
                                                        <React.Fragment key={lang}>
                                                            {
                                                                this.renderAddedLangRows(lang, formProps)
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })
                                                }
                                            </Col>
                                        </Row>
                                        <PageFooter type={LAYOUT_TYPE.FORM}>
                                            <Button color="backward" size="sm" type="button" onClick={() => navigateTo(LANGUAGE_REGISTRATION_LIST)}>
                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                            </Button>

                                            {hasAtLeastOneLang &&
                                                <Button color="forward" size="sm" type="button" onClick={() => this.validateBeforeSubmission(values, submitPlainForm)}>
                                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                                </Button>
                                            }
                                        </PageFooter>
                                    </>
                                );
                            }}
                        </SMARTForm>
                    }
                </Layout>

            </>
        );
    }
}

export default withSMARTWrapper(withLoader(EditApplicationLanguage));
