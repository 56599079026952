import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ActionButton, DataTable, MobileCardActionButton } from '../../../DataTable';
import { withLoader } from '../../../Loading';
import { AssignUserGroupsToUserModal } from '../../Modal';
import '../AssignUserGroupsToUser/Style.css';
import { getUserGroupsByAppIdAndOrganisationId } from './Utils';
import { getTranslationValue } from '../../../../Shared/Forms/FormUtils';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../../../Localization';


const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

class AssignUserGroupsToUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            organisationList: props.organisationList,
            organisationSelectedOptions: props.organisationSelectedOptions,
            isOpen: false,
            isUpdatePage: props.isUpdatePage,
            toggleUpdate: false,
            isLoaded: false,
            availableOrganisationOptions: props.availableOrganisationOptions
        }
    }

    toggle = () => {
        this.setState({
            modalTitle: 'Add new User Group',
            modal: !this.state.modal,
        });
    }

    clearEditItem = () => {
        this.setState({
            editItem: undefined
        })
    }

    getRowIndex = (rowData) => {
        const currentRowData = Object.assign({}, rowData);
        delete currentRowData.griddleKey;

        const { data } = this.props;
        const isRowData = (x) => _.isEqual(x, currentRowData);
        const rowIndex = data.findIndex(isRowData);

        return rowIndex;
    }

    editItem = async (rowData) => {
        const { data, loader, translation } = this.props;
        const rowIndex = this.getRowIndex(rowData);
        const userGroupData = data[rowIndex];
        const { AppId, OrganisationId } = userGroupData;

        loader.start();

        const userGroups = await getUserGroupsByAppIdAndOrganisationId(AppId, OrganisationId, translation);

        loader.done();

        this.setState({
            modalTitle: 'Edit User Group',
            modal: !this.state.modal,
            editItem: { data: userGroupData, rowIndex: rowIndex, userGroupsList: userGroups }
        });
    }

    saveEditItem = (values, rowIndex) => {
        const { data } = this.props;
        data[rowIndex] = values;

        this.setState({
            modal: !this.state.modal,
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    deleteItem = (rowData) => {
        const { data } = this.props;
        const rowIndex = this.getRowIndex(rowData);

        data.splice(rowIndex, 1);

        this.setState({
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    addItem = (values) => {
        const { data } = this.props;

        const effectiveEndDate = values.EffectiveEndDate;
        values.StrEffectiveEndDate = effectiveEndDate === "" ? "-" : effectiveEndDate;

        data.push(values);

        this.setState({
            modal: !this.state.modal,
            toggleUpdate: !this.state.toggleUpdate
        });
    }

    renderMobileCardActions = (data) => {
        const rowIndex = parseInt(_.uniqueId());

        const { translation } = this.props;
        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'Update' + rowIndex}
                    onClick={() => this.editItem(data)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
                <MobileCardActionButton
                    key={'Delete' + rowIndex}
                    onClick={() => this.deleteItem(data)}
                    modal={DELETE_MODAL(getStaticText)}
                    icon={faTrash}
                    label={BUTTON_TITLE(getStaticText).DELETE}
                    formatter={{ component: getTranslationValue('User Group', getStaticText, translationKeys) }}
                />
            </React.Fragment>
        )
    }

    render() {
        const { organisationSelectedOptions, isUpdatePage, availableOrganisationOptions, modalTitle } = this.state;
        const { translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const modalTitleValue = getStaticText && translationKeys ? getTranslationValue(modalTitle, getStaticText, translationKeys) : modalTitle;

        let userGroupInfo = "Please select one or more organisation under User Information (Account Info).";
        userGroupInfo = getStaticText && translationKeys ? getTranslationValue(userGroupInfo, getStaticText, translationKeys) : userGroupInfo;

        return (
            <Row>
                {isUpdatePage && organisationSelectedOptions.length === 0 &&
                    <Col lg={12} className="mb-4">
                        <b>{userGroupInfo}</b>
                    </Col>
                }
                {isUpdatePage && organisationSelectedOptions.length > 0 &&
                    <Col lg={12}>
                        <Button color="primary" className="add-modal-button" type="button" onClick={this.toggle}>{BUTTON_TITLE(getStaticText).ADD}</Button>
                    </Col>
                }
                <Col lg={12}>
                    <DataTable
                        translation={translation}
                        className="datatable-small-details"
                        key={this.state.toggleUpdate}
                        data={this.props.data}
                        pageSize='5'
                        minFilterChars='2'
                        noResultsMessage='No user groups found'
                        columns={
                            {
                                RunningNumber: { title: 'No.', width: '5%' },
                                Actions: { title: 'Actions', visible: this.state.isUpdatePage, width: '5%' },
                                OrganisationName: { title: 'Organisation', width: '20%', DBkey: 'OrganisationName', translateValue: true },
                                GroupName: { title: 'Group Name', width: '20%', DBkey: 'GroupName' },
                                Description: { title: 'Description', width: '15%', DBkey: 'Description' },
                                EffectiveStartDate: { title: 'Effective Start Date', width: '15%', DBkey: 'EffectiveStartDate' },
                                StrEffectiveEndDate: { title: 'Effective End Date', width: '15%', DBkey: 'StrEffectiveEndDate' }
                            }
                        }
                        renderActions={({ RowData, ID }) =>
                        (
                            <React.Fragment>
                                {this.state.isUpdatePage && (availableOrganisationOptions.filter(opt => RowData.OrganisationId === opt.value)).length === 1 &&
                                    <>
                                        <ActionButton key={'Update' + ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                            onClick={() => this.editItem(RowData)} >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </ActionButton>
                                        <ActionButton key={'Delete' + ID} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                            onClick={() => this.deleteItem(RowData)}
                                            modal={DELETE_MODAL(getStaticText)}
                                            formatter={{ component: getTranslationValue('User Group', getStaticText, translationKeys) }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </ActionButton>
                                    </>
                                }
                            </React.Fragment>
                        )
                        }
                        renderMobileCardActions={this.renderMobileCardActions}
                    />
                    <AssignUserGroupsToUserModal
                        toggle={this.toggle}
                        modalState={this.state.modal}
                        data={this.props.data}
                        addItem={this.addItem}
                        editItem={this.state.editItem}
                        saveEditItem={this.saveEditItem}
                        clearEditItem={this.clearEditItem}
                        organisationList={this.state.organisationList}
                        organisationSelectedOptions={this.state.organisationSelectedOptions}
                        modalTitle={modalTitleValue}
                        translation={translation}
                    />
                </Col>
            </Row>
        );
    }
}

export default withLoader(withRouter(AssignUserGroupsToUser));