import React from 'react';
import { withRouter } from 'react-router-dom';



class Home extends React.Component {


    render() {
        return (
            <React.Fragment>
                <h1>Welcome</h1>
            </React.Fragment>
        )
    }
}
export default withRouter(Home);