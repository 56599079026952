import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { URL } from '../../Shared/SmartMe/Constants';
import { ADDRESS } from '../../App/AppSettings';
import { withFormAlert } from '../../Shared/Forms';
import { selectListMapper } from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withLoader } from '../../Shared/Loading';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'Organisation/CreateOrganisation';

class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        let appIDRequest = await fetchRequest(URL + "App/GetAppsForApplicationAdminByUserId", getParams());

        if (appIDRequest.success && appIDRequest.body.IsSuccess) {
            let selection = appIDRequest.body.Data;
            const mappedSelection = selectListMapper(selection, "AppId", "AppName");

            this.setState({
                values: {
                    AppId: '',
                    AbbreviatedName: '',
                    FullName: '',
                    Email: '',
                    OrganisationDetails: {
                        ContactNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        FaxNumber: {
                            CountryCode: '',
                            Number: ''
                        },
                        Address: {
                            AddressType: 'None',
                            PostalCode: '',
                            Country: '', // Only applicable to Address Type: Overseas
                            City: '',
                            State: '',
                            AddressLine1: ADDRESS.ADDRESS_FIELDS.LINE1,
                            AddressLine2: ADDRESS.ADDRESS_FIELDS.LINE2
                        }
                    },
                    EffectiveFromDate: '',
                    EffectiveToDate: ''
                },
                AppIds: mappedSelection,
                isLoaded: true
            });
        }
        else {
            const { formAlert } = this.props;
            formAlert.showAlert(appIDRequest.body.messages || appIDRequest.body.Messages);
        }
        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <>
                {this.state.isLoaded ?
                    <Form values={this.state.values}
                        server_url={SERVER_URL}
                        title={"New Organisation"}
                        contentHeader={MODAL_TITLE(getStaticText).CREATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).CREATE.BODY}
                        prevState={this.state}
                    /> :
                    null
                }
            </>
        )
    }
}
export default withFormAlert(withLoader(withRouter(New)));