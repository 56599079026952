export const SubTitleTranslationKeys = [
    {
        "DefaultLanguageValue": "Supporting Documents",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "SupportingDocuments"
    },
    {
        "DefaultLanguageValue": "Approval",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "Approval"
    }    
]