import React from 'react';
import PropTypes from 'prop-types';
import FileUploadSectionAdder from './FileUploadSectionAdder';
import FileUploadSectionViewer from './FileUploadSectionViewer';


class FileUploadSection extends React.Component {

    // Lifecycle: Optimize performance for FileUploadSection
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.fileList !== nextProps.fileList;
    }
    
    render() {
        // Depending on readonly prop, Viewer or Adder component will be rendered
        return (this.props.readonly) ?
            (<FileUploadSectionViewer
                name={this.props.name}
                fileList={this.props.fileList}
                getFileURL={this.props.getFileURL}
                deleteFileURL={this.props.deleteFileURL}
                readonly={this.props.readonly}
                onChangeField={this.props.onChangeField}
                isUpper={this.props.isUpper}
            />)
            :
            (<FileUploadSectionAdder
                name={this.props.name}
                fileList={this.props.fileList}
                getFileURL={this.props.getFileURL}
                deleteFileURL={this.props.deleteFileURL}
                sectionName={this.props.sectionName}
                onChangeField={this.props.onChangeField}
                uploadLimit={this.props.uploadLimit}
            />)
    }
}

// PropTypes: For prop validation
FileUploadSection.propTypes = {
    name: PropTypes.string.isRequired,
    fileList: PropTypes.array,
    getFileURL: PropTypes.string,
    deleteFileURL: PropTypes.string,
    sectionName: PropTypes.string,                      // Required if readonly={false}
    onChangeField: PropTypes.func,                      // Required if readonly={false} 
    uploadLimit: PropTypes.number,                      // 0 is unlimited
    isUpper: PropTypes.bool,
    readonly: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
FileUploadSection.defaultProps = {
    sectionName: '',
    onChangeField: () => { },
    uploadLimit: 0,
    readonly: false
};


export default FileUploadSection;