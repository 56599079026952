import React, { Component } from 'react';
import { CardBody, Card } from 'reactstrap';
import { getTranslationValue } from '../../Forms/FormUtils';

class Panel extends Component {
    render() {
        const { panelTitle, panelBody, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const panelTitleValue = getStaticText && translationKeys ? getTranslationValue(panelTitle, getStaticText, translationKeys) : panelTitle;
            
        return (
            <div className={`${this.props.panelDividerClassName}`}>
                <div className="panel panel-default">
                    <div className={`panel-heading text-center ${this.props.panelHeaderClassName}`}>
                        { panelTitleValue }
                    </div>
                </div>
                <Card>
                    <CardBody>
                        { panelBody }
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Panel;