import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'Organisation/UpdateOrganisation';

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    getData = (Data) => {
        const strData = JSON.stringify(Data, function (key, value) {
            // if value is null, return "" as a replacement
            if (value === null) {
                if (key === "Address" || key === "AddressLine1" || key === "AddressLine2") {
                    return {};
                }
                else if (key === "ContactNumber" || key === "FaxNumber") {
                    return { Number: '', CountryCode: '' };
                }
                return '';
            }

            // otherwise, leave the value unchanged
            return value;
        });

        return JSON.parse(strData);
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            const response = await fetchRequest(URL + "Organisation/GetOrganisation/" + state.ID, getParams(), false);
            const { IsSuccess, Data } = response.body;
            const organisationData = this.getData(Data);

            if (response.success && IsSuccess) {
                this.setState({
                    values: organisationData,
                    AppId: Data.AppId,
                    AppIds: "",
                    isLoaded: true
                });
            }
            else {
                loader.done();
                pageNotFound();
            }
        }
        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Form
                        values={this.state.values}
                        server_url={SERVER_URL}
                        title={"Update Organisation"}
                        contentHeader={MODAL_TITLE(getStaticText).UPDATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).UPDATE.BODY}
                        prevState={this.state}
                    />)
                    : null}
            </React.Fragment>
        )
    }
}
export default withPageNotFound(withLoader(withRouter(Update)));