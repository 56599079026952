import React from 'react';
import { Col, Row } from 'reactstrap';
import { PREVIEW_FAQ_URL, SUPPORTED_LANGUAGE_LIST_URL } from '../../App/AppSettings';
import { ERROR } from '../../Localization/index';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, Plaintext, SelectList } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError } from '../../Shared/Forms/Toaster.js';
import { withLoader } from '../../Shared/Loading';
import AccordionTopicSubTopic from './AccordionTopicSubTopic';
import './Faq.css';
import QuestionAnswerDisplay from './QuestionAnswerDisplay';


const LABEL_SIZE = 3;
const INPUT_SIZE = 9;
const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';
class PreviewFaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqName: '',
            selectedTopic: '',
            selectedSubTopic: '',
            topics: [],
            questionSets: [],
            [dropDownArraySupportedLanguage]: [],
            languageCode: this.props.languageCode
        }
    }

    componentDidMount = async () => {
        // Get Faq details

        await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, dropDownArraySupportedLanguage);

        await this.getFaq(PREVIEW_FAQ_URL, this.state.languageCode);
        //this.props.loader.done();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextState.questionSets !== this.state.questionSets
            || nextState.faqName !== this.state.faqName
            || nextState.dropDownArraySupportedLanguage !== this.state.dropDownArraySupportedLanguage
            || nextState.languageCode !== this.state.languageCode)
    }

    // Fn: Get language list from code table
    getSupportedLanguageDropdownList = async (url, dropDownName) => {

        const { application } = this.props;
        const response = await fetchRequest(url, getParams({ applicationName: application }));

        const { Data, IsSuccess, Message } = response.body;

        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {
                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.LanguageCodeTitle, 'value': name.LanguageCodeValue });
                });

                // Set array to dropDownArraySupportedLanguage
                this.setState({
                    [dropDownName]: dropDownArray,
                    //values: { 'Language': this.state.values.LanguageTitle }
                });

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }


    // Fn: Get Faq details
    getFaq = async (url, languageCode) => {
        const { application, faqGuid, isPublished } = this.props;
        let fullurl = url;
        const params = {
            application: application,
            languageCode: languageCode,
            faqGuid: faqGuid,
            isPublished: isPublished
        };

        this.props.loader.start();
        const response = await fetchRequest(fullurl, getParams(params));
        this.props.loader.done();
        const { Data, IsSuccess, Message } = response.body;
        if (response.success) {

            if (IsSuccess) {
                var selectedTopic = '';
                var selectedSubTopic = '';
                var questionSets = [];
                if (Data.Topics.length !== 0 && Data.Topics[0].SubTopics.length !== 0) {
                    selectedTopic = Data.Topics[0].TopicName;
                    selectedSubTopic = Data.Topics[0].SubTopics[0].SubTopicName;
                    questionSets = Data.Topics[0].SubTopics[0].QuestionSets;
                }

                this.setState({
                    values: Data,
                    topics: Data.Topics,
                    faqName: Data.FaqName,
                    isSuccess: true,
                    questionSets,
                    selectedTopic,
                    selectedSubTopic
                });
            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
        this.setState({
            languageCode
        })
    }

    handleOnClickSubTopic = (questionSets, selectedTopic, selectedSubTopic) => {
        this.setState({ questionSets, selectedTopic, selectedSubTopic });
    }


    changePageBasedOnLanguage = async (name, value) => {
        this.getFaq(PREVIEW_FAQ_URL, value);

    }


    renderLanguage = (values) => {
        return (this.props.isLocaleEnabled) ?
            <SelectList
                name="Language"
                value={this.state.languageCode}
                options={this.state.dropDownArraySupportedLanguage}
                onChangeField={(name, value) => this.changePageBasedOnLanguage(name, value)}
                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_A_LANGUAGE)}
                isMulti={false}
                labelSize={FIELD_SIZE.SMALL}
                inputSize={FIELD_SIZE.XLARGE}
                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
            />
            :
            <Plaintext
                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                name="Language"
                value={this.state.languageCode}
                labelSize={LABEL_SIZE}
                inputSize={INPUT_SIZE}
            />

    }

    render() {
        const { faqName, questionSets, isSuccess, topics, selectedTopic, selectedSubTopic } = this.state;
        return (
            <Row>
                {isSuccess &&
                    <>
                        <Col xs="12" md="8">
                            <div className="preview-faq-name" >{faqName}</div>
                        </Col>
                        <Col xs="12" md="4">
                            {this.renderLanguage()}
                        </Col>

                        {topics.length > 0 ?
                            <>
                                <Col xs="12" md="3">
                                    {topics.map((topic, topicIndex) => (
                                        <AccordionTopicSubTopic topic={topic} key={"topic" + topicIndex} handleOnClickSubTopic={this.handleOnClickSubTopic}>

                                        </AccordionTopicSubTopic>
                                    ))}
                                </Col>
                                <Col xs="12" md="9">
                                    {questionSets.length > 0 ?
                                        <QuestionAnswerDisplay questionSets={questionSets} selectedTopic={selectedTopic} selectedSubTopic={selectedSubTopic} /> :
                                        this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS, DEFAULT_TEXT.NO_QUESTIONS_AVAILABLE)}

                                </Col>
                            </>
                            :
                            <Col xs="12">
                                <div>{this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS, DEFAULT_TEXT.NO_QUESTIONS_AVAILABLE)}</div>
                            </Col>
                        }
                    </>
                }
            </Row>

        );
    }
}

export default withSMARTWrapper(withLoader(PreviewFaq));