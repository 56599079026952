import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { CONTENT_TYPE, fetchRequest, formParams, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { CreatableSelectList, DateInput, Paragraph, RichTextEditor, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { NOTIFICATIONTEMPLATE_URL } from '../../App/AppSettings';
import { RetrieveGlobalVariables } from './RetrieveGlobalVariables';

const FORM_CONTEXT = "NotificationTemplate";
const NEXT_URL = '/SystemConfig/NotificationTemplate/LetterList';
const RETRIEVE_URL = NOTIFICATIONTEMPLATE_URL + "/";
const SERVER_URL = NOTIFICATIONTEMPLATE_URL + "/";
const DROPDOWN_CATEGORY_URL = NOTIFICATIONTEMPLATE_URL + "/category";

const FORM_VALIDATIONS = Yup.object().shape({
    TemplateName: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),
    Category: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),
    Type: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),
    DateOfLetter : Yup.string().required(ERROR.REQUIRED),
    ReturnAddress: Yup.string(),
    TemplateBody: Yup.string().required(ERROR.REQUIRED)
});

const FORM_INITIAL_VALUES = {
    TemplateName: '',
    Category: '',
    Type: '',
    ReturnAddress: '',
    TemplateBody: '',
};

class LetterTemplateUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            dropDownArrayCategory: [],
            globalVariableList: []
        };
    }

    async componentDidMount() {
        await this.getGlobalVariables();

        const { state } = history.location;
        const { loader, pageNotFound } = this.props;
        if (state && state.ID) {

            loader.start();
            const response = await fetchRequest(RETRIEVE_URL + state.ID, getParams(), false);

            loader.done();

            const { IsSuccess, Data, Messages } = response.body;

            if (response.success && IsSuccess) {
                this.setState({ values: Data });
            }
            else {
                // Error message to be loaded here
                loader.error(Messages);
            }
            await this.dropdownList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");
        }
        else {
            pageNotFound();
        }
    }

    dropdownList = async (url, dropDownName) => {

        const response = await fetchRequest(url, getParams(), false);

        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element.label, 'value': element.value });
                });
                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError("Unable to reach the server, please try again later.");
            return null;
        }
        return dropDownArray;
    }
    
    getGlobalVariables = async () => {
        this.setState({
            globalVariableList: await RetrieveGlobalVariables()
        })
    }

    submitCallback = ({ response }) => {

        if (response.success) {
            const { IsSuccess, RedirectURL } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(NEXT_URL, "");
                }
                toastSuccess("Notification template successfully updated");
            }
            else {
                toastError("Notification template was not updated");
            }
        } else {
            toastError("Server could not be reached, please try again later")
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="Update Letter Template">
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        formParams={formParams("PUT", CONTENT_TYPE.JSON)}
                        validationSchema={FORM_VALIDATIONS}
                        validateOnSubmit={true}
                        serverURL={SERVER_URL + history.location.state.ID}
                        retrieveURL={RETRIEVE_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, onChange, onChangeField, submitPlainForm, errors }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TemplateName"
                                            value={values.TemplateName}
                                            placeholder="Template Name"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Template Name"
                                            error={errors.TemplateName}
                                            required
                                        />
                                        <Text
                                            name="Type"
                                            value={values.Type}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Type"
                                            error={errors.Type}
                                            readOnly
                                        />
                                        <CreatableSelectList
                                            isClearable={true}
                                            name="Category"
                                            value={values.Category}
                                            placeholder="Category"
                                            onChangeField={onChangeField}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Category"
                                            helpLabel="Please choose an existing category, or type a new one then press enter to create a new option."
                                            options={this.state.dropDownArrayCategory}
                                            isMulti={false}
                                            error={errors.Category}
                                            required
                                        />
                                        <Paragraph
                                            name="ReturnAddress"
                                            value={values.ReturnAddress}
                                            placeholder="Return Address"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Return Address"
                                            error={errors.ReturnAddress}
                                        />
                                        <DateInput
                                            name="DateOfLetter"
                                            value={values.DateOfLetter}
                                            placeholder="Date of Letter"
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min="1900/01/01"
                                            max="2100/01/01"
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.SMALL}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Date of Letter"
                                            error={errors.DateOfLetter}
                                            required
                                        />
                                        <RichTextEditor
                                            name="TemplateBody"
                                            value={values.TemplateBody}
                                            onChangeField={onChangeField}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label="Template Body"
                                            showHtml={false}
                                            suggestions={this.state.globalVariableList}
                                            error={errors.TemplateBody}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>Back</Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>Update</Button>
                                </PageFooter>

                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(LetterTemplateUpdate);

