import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App/App';
import Spinner from './Components/Shared/Loading/Spinner';
import './i18n';
import registerServiceWorker from './registerServiceWorker';
import { DirectionContextProvider } from './Components/Shared/DirectionContext';

ReactDOM.render(
    <Suspense fallback={<Spinner visible={true} />}>
        <DirectionContextProvider>
            <App />
        </DirectionContextProvider>
    </Suspense>,
    document.getElementById('root')
);

registerServiceWorker();
