export const SubTitleTranslationKeys = [
    {
        "DefaultLanguageValue": "Password Length",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "PasswordLength"
    },
    {
        "DefaultLanguageValue": "Password Character Requirements",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "SubTitle",
        "TranslationKey": "PasswordCharacterRequirements"
    }
]