import React from 'react';
import PropTypes from 'prop-types';
import { getTranslationValue } from '../../Shared/Forms/FormUtils';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { withBaseControl } from './BaseControl';
import { TEXT_DIRECTIONS } from '../Constants';

class Text extends React.Component {

    render() {
        const { placeholder, translation } = this.props;
        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        let TextInput =
            <Input
                dir={this.props.direction}
                type="text"
                name={this.props.name}
                value={this.props.value}
                placeholder={translation === undefined ? placeholder : getTranslationValue(placeholder, getStaticText, translationKeys)}
                onChange={this.props.onChange}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                autoComplete="off"
                readOnly={this.props.readOnly}
            />;

        return (
            <React.Fragment>
                {(this.props.append) ?

                    // InputGroupAddon Append
                    <InputGroup>
                        {TextInput}
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="append">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                    </InputGroup>
                    :

                    // InputGroupAddon Prepend
                    <InputGroup>
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="prepend">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                        {TextInput}
                    </InputGroup>

                }
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Text.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    unitOfMeasurement: PropTypes.string,
    append: PropTypes.bool,
    direction: PropTypes.string
};

// PropTypes: Defaulting value for optional props
Text.defaultProps = {
    direction: TEXT_DIRECTIONS.AUTO
};

export default withBaseControl(Text);