import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { PARAGRAPH_TYPES } from '../Constants';
import { withBaseControl } from './BaseControl';
import { withSMARTWrapper } from './SMARTWrapper';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Paragraph extends React.Component {
    
    constructor(props) {
        super(props);
        this.renderParagraph = this.renderParagraph.bind(this);
    }

    renderParagraph({type, maxLength, className, inputClassName, name, value, placeholder, onChange, rows, minLength, readOnly, charLeftText, icon }) {
        let inputParagraph = 
        (
            <Input
                className = {`${className} ${inputClassName} text-area`}
                type="textarea"
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={((e) => this.props.onChange(e, name))}
                rows={rows}
                minLength={minLength}
                maxLength={maxLength}
                readOnly={readOnly}
            />
        );
        
        let charLeft = maxLength;
        if (value === undefined || value === null) {
            charLeft = maxLength;
        } else {
            charLeft = maxLength - value.length;
        }
        
        charLeftText = (this.props.charLeftText && this.props.maxLength) ? 
            <p className="paragraph-char">{charLeft} {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_FORM_HELPTEXT_CHARREMAINING, DEFAULT_TEXT.CHAR_REMAINING)}</p> 
            : 
            '';

        switch (type) {
            case PARAGRAPH_TYPES.DEFAULT:
                default:
                    return (
                        <React.Fragment>
                            {inputParagraph}
                            {charLeftText}
                        </React.Fragment>
                    );

            case PARAGRAPH_TYPES.WITH_ICON: 
                return (
                    <React.Fragment>
                        <Row>
                            <Col lg="11">
                            {inputParagraph}
                            {charLeftText}
                            </Col>
                            <Col className="rich-text-icon" lg="1">
                                <Badge color="normal" className="badge-icon">
                                    <FontAwesomeIcon icon={icon} />
                                </Badge>
                            </Col>
                        </Row>
                    </React.Fragment>
                );
        }
    } 

    render() {
        return (
            <React.Fragment>
                {this.renderParagraph(this.props)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Paragraph.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rows: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    charLeftText: PropTypes.bool,
    readOnly: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
Paragraph.defaultProps = {
    type: PARAGRAPH_TYPES.DEFAULT,
    rows: 3,
    charLeftText: true
};


export default withSMARTWrapper(withBaseControl(Paragraph));