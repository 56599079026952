import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import Layout from '../../Layout';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { Plaintext, SMARTForm, withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { LAYOUT_TYPE, GET_APPCONFIG_URL } from '../../App/AppSettings';

const FORM_CONTEXT = "Configuration";
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

const RETRIEVE_URL = GET_APPCONFIG_URL;

class AppConfigMgmtView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {}
        };
    }

    async componentDidMount() {

        const { state } = history.location;
        const { loader, pageNotFound } = this.props;

        if (state && state.Category && state.Name) {

            loader.start();
            const response = await fetchRequest(RETRIEVE_URL, getParams({ Category: state.Category, Name: state.Name }), false);
            loader.done();
            const { IsSuccess, Data, Messages } = response.body;
            if (response.success && IsSuccess) {
                this.setState({ values: Data });
            }
            else {
                // Error message to be loaded here
                loader.error(Messages);
            }
        }
        else {
            pageNotFound();
        }
    }

    render() {

        const {
            AppConfigCategory, AppConfigName, AppConfigValueType,
            AppConfigValue, AppConfigDescription, AppConfigEffectiveStartDate, AppConfigEffectiveEndDate,
            AppConfigCreatedBy, AppConfigCreatedDate, AppConfigLastModifiedBy, AppConfigLastModifiedDate
        } = this.state.values;

        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_VIEWCONFIGURATION, DEFAULT_TEXT.VIEWCONFIGURATION)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                    >
                        {() => (
                            <Row className="body-content">
                                <Col>
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                        name="AppConfigCategory"
                                        value={AppConfigCategory}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                        name="AppConfigName"
                                        value={AppConfigName}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUETYPE, DEFAULT_TEXT.VALUE_TYPE)}
                                        name="AppConfigValueType"
                                        value={AppConfigValueType}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUE, DEFAULT_TEXT.VALUE)}
                                        name="AppConfigValue"
                                        value={AppConfigValue}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                        name="AppConfigDescription"
                                        value={AppConfigDescription}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                        name="AppConfigEffectiveStartDate"
                                        value={AppConfigEffectiveStartDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                        name="AppConfigDescription"
                                        value={AppConfigEffectiveEndDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                        name="AppConfigCreatedBy"
                                        value={AppConfigCreatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDDATE, DEFAULT_TEXT.CREATED_DATE)}
                                        name="AppConfigCreatedDate"
                                        value={AppConfigCreatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                        name="AppConfigLastModifiedBy"
                                        value={AppConfigLastModifiedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                        name="AppConfigLastModifiedDate"
                                        value={AppConfigLastModifiedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>
                                           {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(AppConfigMgmtView);

