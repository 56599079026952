export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "Password Policy",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "PasswordPolicy"
    },
    {
        "DefaultLanguageValue": "Please fill up the Password Policy.",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "TitleChildren",
        "TranslationKey": "PasswordPolicyMessage"
    }
]