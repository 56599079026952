import React from 'react';
import PropTypes from 'prop-types';
import { getTranslationValue } from '../../Shared/Forms/FormUtils';
import { withBaseControl } from './BaseControl';
import { Dropdown } from "semantic-ui-react";

// CSS
import 'semantic-ui-css/components/dropdown.css';
import 'semantic-ui-css/components/transition.css';
import 'semantic-ui-css/components/reset.css';


class DropdownList extends React.Component {

    // Fn: Handle changes in dropbox
    handleChange = (e, { value }) => {
        const { onChangeField, name } = this.props;

        // Update changes in Formik handleChange event
        onChangeField(name, value);
    };

    render() {
        const { placeholder, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const placeholderValue = getStaticText && translationKeys ? getTranslationValue(placeholder, getStaticText, translationKeys) : placeholder;
        
        return (
            <div className="dropdown-semantic-ui">
                <Dropdown
                    fluid
                    search
                    clearable={this.props.isClearable}
                    onChange={this.handleChange}
                    options={this.props.options}
                    placeholder={placeholderValue}
                    selection
                    value={this.props.value}
                />
            </div>
        );
    }
}

// PropTypes: For prop validation
DropdownList.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
    placeholder: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired
};


export default withBaseControl(DropdownList);