export const NavbarTranslationKeys = [
    {
        "DefaultLanguageValue": "Change Password",
        "Scope": "Menu",
        "Category": "Content",
        "SubCategory": "Dropdown",
        "TranslationKey": "ChangePassword"
    },
    {
        "DefaultLanguageValue": "Change Security Info",
        "Scope": "Menu",
        "Category": "Content",
        "SubCategory": "Dropdown",
        "TranslationKey": "ChangeSecurityInfo"
    },
    {
        "DefaultLanguageValue": "Language",
        "Scope": "Menu",
        "Category": "Content",
        "SubCategory": "Dropdown",
        "TranslationKey": "Language"
    },
    {
        "DefaultLanguageValue": "Log Out",
        "Scope": "Menu",
        "Category": "Content",
        "SubCategory": "Dropdown",
        "TranslationKey": "LogOut"
    }
]