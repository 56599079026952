import { DEFAULT_ENQUIRY_MONTHS_AGO, STATUS_BADGE_COLOR } from '../../App/AppSettings.js';
import { FILE_UPLOAD_SECTION_FILES } from '../../Shared/Constants';
import { DEFAULT_APPLICATION_NAME } from '../../Shared/Constants/LanguageKeys'

// addMonth - START : Date parsers to prevent errors in the addMonth function
export function isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

export function getDaysInMonth(year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export function addMonths(value) {
    var date = new Date();
    var n = date.getDate();
    date.setDate(1);
    date.setMonth(date.getMonth() + value);
    date.setDate(Math.min(n, getDaysInMonth(date.getFullYear(), date.getMonth())));
    return date;
}

export function getDateString(date) {
    if (date.type === Date.type) {
        let dateString = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        })
            .format(date)
            .replace(/ /g, "-");
        return dateString;
    }
    else {
        return null;
    }
};
//addMonth - END

export const customMonthAgoString = getDateString(addMonths(-(DEFAULT_ENQUIRY_MONTHS_AGO)))

export const todayString = getDateString(new Date());

export function getBadgeColor(stateString) {
    var badgeColor = "normal"
    if ((STATUS_BADGE_COLOR.success).indexOf(stateString) > -1) {
        badgeColor = "success"
    }
    else if ((STATUS_BADGE_COLOR.warning).indexOf(stateString) > -1) {
        badgeColor = "warning"
    }
    else if ((STATUS_BADGE_COLOR.danger).indexOf(stateString) > -1) {
        badgeColor = "danger"
    }

    return badgeColor;
}

export const arrayToSelectListOptions = (array, dropdownArray) => {
    if (Array.isArray(array)) {
        if (!(array === undefined || array.length === 0)) {
            array.forEach(element => {
                if (element !== null) {
                    dropdownArray.push({ label: element, value: element })
                }
            });
        }
    }
}

export const isEmptyValue = (value) => {
    return (value === null || value === undefined || value === '');
}

export const convertToFormData = (data, _formData, _name) => {
    const formData = _formData || new FormData();

    for (let name in data) {

        if (!data.hasOwnProperty(name) || !data[name]) {
            continue;
        }

        const formKey = _name ? `${_name}[${name}]` : name;

        // Date
        if (data[name] instanceof Date) {
            formData.append(formKey, data[name].toISOString());
        }

        // Multi FileUpload
        else if (name === FILE_UPLOAD_SECTION_FILES) {

            if (data[name].constructor === Array) {
                // For each file in FileList
                for (let i = 0; i < (data[name].length); i++) {

                    if (data[name][i].key) {
                        // Append fileName
                        let fileNameStr = `${data[name][i].key}%%Multi`;

                        // Append file with key as fileName
                        formData.append(name, data[name][i], fileNameStr);

                        // Append key as fileKey, and value as description
                        formData.append(fileNameStr, data[name][i].description);
                    }
                }
            }
            else {
                // Append file with key as fileName
                formData.append(name, data[name], data[name].key);
            }
        }
        // Single FileUpload
        else if ((data[name] && ((data[name][0] instanceof File) || (data[name] instanceof File)))) {

            // Append fileName
            let fileNameStr = `${data[name].key}%%Single`;

            // Append file with key as fileName
            formData.append(name, data[name], fileNameStr);

            // Append key as fileKey, and value as description
            formData.append(fileNameStr, name);
        }

        // Else if the value is an object (Array is seen as an object too)
        else if (typeof (data[name]) === 'object') {

            // Appends nested child objects to form recursively
            convertToFormData(data[name], formData, formKey);
        }

        // Otherwise, it is a normal input
        else {
            // Append key value to form
            formData.append(formKey, data[name]);
        }
    }

    return formData;
}


// Overlapping dates (where start date: mandatory & end date: nullable)
export const isDateOverlapped = (startDate1, startDate2, endDate1, endDate2) => {
    if (endDate1 == null && endDate2 != null && (startDate1 <= endDate2)) {
        return true;
    }
    if (endDate1 == null && endDate2 == null) {
        return true;
    }
    if (endDate1 != null && endDate2 == null && (startDate2 <= endDate1)) {
        return true;
    }
    if ((startDate1 <= endDate2) && (endDate1 >= startDate2)) {
        return true;
    }
    return false;
}

// Language translation
export const constructStaticKey = (app, translationKey) => {
    const t = translationKey;
    return `${DEFAULT_APPLICATION_NAME}.${t.Scope}.${t.Category}.${t.SubCategory}.${t.TranslationKey}`;
}

export const getTranslationKeys = (app, translationKeys) => {
    let constructedTranslationKeys = translationKeys.map(function (key) {
        let staticKey = constructStaticKey(app, key);
        let translationKey = {};
        translationKey[staticKey] = key.DefaultLanguageValue;

        return translationKey;
    });
    return constructedTranslationKeys;
}

export const getStaticTextFn = (keys, getStaticText, defaultText, isParserEnabled) => {
    if (keys !== undefined) {
        const keyObject = keys.find((keyObjs) => {
            for (var name in keyObjs) {
                return keyObjs[name] === defaultText;
            }
            return keyObject;
        });
        const staticKey = keyObject === undefined ? undefined : Object.keys(keyObject)[0];
        return staticKey === undefined ? defaultText : getStaticText(staticKey, defaultText, isParserEnabled);
    }
    return defaultText;
};

export const getTranslationValue = (defaultText, getStaticText, translationKeys, isParserEnabled) => {
    return (defaultText !== undefined && translationKeys !== undefined) ?
        getStaticTextFn(translationKeys, getStaticText, defaultText, isParserEnabled) : defaultText;
}

export const getTranslationOptions = (defaultOptions, getStaticText, translationKeys) => {
    if (defaultOptions !== undefined && translationKeys !== undefined) {
        let translatedOptions = defaultOptions.map(opt => {
            const optLabel = opt.label;
            const translatedLabel = getStaticTextFn(translationKeys, getStaticText, optLabel);

            return Object.assign({}, { label: translatedLabel, value: opt.value });
        });
        return translatedOptions;
    }
    return defaultOptions;
}

export const getTranslationObjects = (defaultObjs, getStaticText, translationKeys) => {
    const translatedObjs = {};
    if (defaultObjs !== undefined && translationKeys !== undefined) {
        for (const key in defaultObjs) {
            const value = defaultObjs[key];
            const translatedValue = getStaticTextFn(translationKeys, getStaticText, value);

            translatedObjs[key] = translatedValue;
        }
        return translatedObjs;
    }
    return defaultObjs;
}

export const getTranslationColumns = (columns, getStaticText, translationKeys) => {
    if (columns !== undefined && translationKeys !== undefined) {
        for (const key in columns) {
            const title = columns[key].title;
            const translatedTitle = getStaticTextFn(translationKeys, getStaticText, title);

            columns[key].title = translatedTitle;
        }
        return columns;
    }
    return columns;
}