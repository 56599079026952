import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { AuthConsumer } from '../../Shared/Authentication/AuthContext'
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'Role/UpdateRole';
const IS_ORGANISATION_SPECIFIC = {
    YES: "true",
    NO: "false"
}

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    convertToArrayInt = (data) => {
        let values = [];
        if (data != null && data.length > 0) {
            let strArr = data.split(',');
            strArr.map(x => values.push(parseInt(x)));
        }
        return values;
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();
        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(URL + "Role/GetRole/" + state.ID, getParams(), false);
            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;

                this.setState({
                    values: {
                        Id: data.Id,
                        RoleName: data.RoleName,
                        DisplayRoleName: data.DisplayRoleName,
                        Description: data.Description || "",
                        IsOrganisationSpecific: data.IsOrganisationSpecific === true ? IS_ORGANISATION_SPECIFIC.YES : IS_ORGANISATION_SPECIFIC.NO,
                        Organisation: data.Organisation == null ? [] : data.Organisation,
                        PermissionList: data.PermissionList,
                        DisplayAppId: data.DisplayAppId,
                        AppId: data.AppId,
                    },

                    AppIds: "",
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }

        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    <AuthConsumer>
                        {({ profile }) =>
                            <Form
                                values={this.state.values}
                                profile={profile}
                                server_url={SERVER_URL}
                                title={"Update Role"}
                                contentHeader={MODAL_TITLE(getStaticText).UPDATE.HEADER}
                                contentBody={MODAL_TITLE(getStaticText).UPDATE.BODY}
                                prevState={this.state}
                            />
                        }
                    </AuthConsumer>

                    : null}
            </React.Fragment>
        )
    }
}
export default withPageNotFound(withLoader(withRouter(Update)));