import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SMARTForm, SelectList, Plaintext, toastSuccess, toastError, withSMARTWrapper } from '../../Shared//Forms';
import PageFooter from '../../Shared/PageFooter';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ModalConfirm } from '../../Shared/Modal';

class Form extends React.Component {
	constructor(props) {
        super(props);
        this.state = { values: props.values, server_url:props.server_url, title:props.title, readOnlyValues: props.readOnlyValues, contentHeader:props.contentHeader, contentBody:props.contentBody};
	}

	render() {
		const { modal } = this.props;

		return (
			<React.Fragment>
				<Layout type={LAYOUT_TYPE.FORM} title={this.state.title}>
				<SMARTForm
					formValues={this.state.values}
					validateOnSubmit={true}
					serverURL={this.state.server_url}
					isDummy={false}
					submitCallback={({ response }) => {
                        if(response.success && response.body.IsSuccess){
							toastSuccess(response.body.Messages)
                        }
                        else{
                            toastError(response.body.Messages)
                        }
                    }
                    }
				>
					{({ values, onChangeField, submitForm }) => (
						<React.Fragment>
							<Row className="body-content">
								<Col>
									<Plaintext 
										name="User Group"
										value={this.state.readOnlyValues.GroupName}
										label="Group Name"
									/>
									<Plaintext 
										name="Description"
										value={this.state.readOnlyValues.Description}
										label="Description"
									/>
									<SelectList
                                        name="Assigned"
                                        value={values.Assigned}
                                        options={this.state.readOnlyValues.Selection}
                                        onChangeField={onChangeField}
                                        isMulti={true}
                                        inputSize={FIELD_SIZE.XLARGE}
                                        label="Users"
                                    />
								</Col>
							</Row>
							<PageFooter>
								<Button color="primary" type="button" onClick={() => modal.toggleModal("Submit")}>Submit</Button>
                            </PageFooter>
                            <ModalConfirm
                                isOpen={modal.modalState === "Submit"}
                                contentHeader={this.state.contentHeader}
                                contentBody={this.state.contentBody}
                                confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                cancelCallback={() => modal.toggleModal("Submit")}
                            />
						</React.Fragment>
					)}
				</SMARTForm>
				</Layout>
			</React.Fragment>
		);
	}
}

export default withSMARTWrapper(Form);