import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DELETE_FAQ_URL, VIEW_FAQ_URL, EDIT_FAQ_URL,
    PUBLISH_FAQ_VALIDATION_URL, FORM_LAYOUT_TYPES, FAQ_MANAGEMENT_URL, PUBLISH_FAQ_URL, SUPPORTED_LANGUAGE_LIST_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { FIELD_SIZE } from '../../Shared/Constants';
import { navigateTo, fetchRequest, getParams, postParams, deleteParams } from '../../Shared/Actions';
import { withSMARTWrapper, Plaintext, SMARTForm, SelectList } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { FaqTree } from '../../Shared/Tree';
import { ModalConfirm } from '../../Shared/Modal';
import PreviewFaq from './PreviewFaq';
import './Faq.css';

const FORM_CONTEXT = "EditFaq";
const dropDownArrayApplication = 'dropDownArrayApplication';
const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;
const FORM_INITIAL_VALUES = {
    Name: '',
    Id: '',
    Description: '',
    FaqId: '',
    ApplicationName: '',
    IsLocaleEnabled: '',
    LanguageCode: '',
    Language: ''
}

const PUBLISH_MODAL = {
    hasModal: true,
    name: "Publish"
};

const PUBLISH_LOCALE_MODAL = {
    hasModal: true,
    name: "PublishLocale",
    modalHeader: "Publish Faq?"
};

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete"
};

const PREVIEW_MODAL = {
    hasModal: true,
    name: "Preview"
};


class EditFaq extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {  
            this.state = {
                values: FORM_INITIAL_VALUES,
                Id: history.location.state.Id,
                serverUrl: EDIT_FAQ_URL,
                [dropDownArrayApplication]: [],
                [dropDownArraySupportedLanguage]: [],
                app: '',
                languageCheckboxOptions: [],
                faqTree: [],
                faqName: '',
                faqTitle: '',
                isSuccess: false,
                modalMessage: '',
                isFirstLoad: true
            }
        } 
    }

    componentDidMount = async () => {
        // Get Faq details
        await this.getFaq(VIEW_FAQ_URL, "");
    }

    // Fn: Get language list from code table
    getSupportedLanguageDropdownList = async (url, dropDownName) => {

        const response = await fetchRequest(url, getParams({ applicationName: this.state.values.ApplicationName }));

        const { Data, IsSuccess } = response.body;

        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {

                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.LanguageCodeTitle, 'value': name.LanguageCodeValue });
                });

                // Set array to dropDownArraySupportedLanguage
                this.setState({
                    [dropDownName]: dropDownArray,
                    //values: { 'Language': this.state.values.LanguageTitle }
                });

            } else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            Description: Yup.string()
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(2000, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT))
        });
    };

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    // Fn: Get Faq details
    getFaq = async (url, languageCode) => {
        this.props.loader.start();

        let fullurl = url;

        const params = {
            Id: this.state.Id,
            LanguageCode: languageCode,
            IsPublished: true
        };

        const response = await fetchRequest(fullurl, getParams(params));
        const { Data, IsSuccess, Message } = response.body;

        this.props.loader.done();

        if (response.success) {
            if (IsSuccess) {
                this.setState({
                    values: Data,
                    isSuccess: true,
                    isFirstLoad: false,
                    faqGuid: Data.FaqGuid,
                    Id: Data.Id
                });

                await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, dropDownArraySupportedLanguage);


            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    changePageBasedOnLanguage = async (name, value) => {
        
        if (!this.state.isFirstLoad)
        {
            this.setState({
                isSuccess: false
            });      

            await this.getFaq(VIEW_FAQ_URL, value);
        }

    
    }

    renderLanguage = (values, onChangeField) => {
        return (this.state.values.IsLocaleEnabled) ?
            <SelectList
                name="LanguageCode"
                value={values.LanguageCode}
                options={this.state.dropDownArraySupportedLanguage}
                onChangeField={(name, value) => this.changePageBasedOnLanguage(name, value)}
                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_LANG)}
                isMulti={false}
                labelSize={FIELD_SIZE.SMALL}
                inputSize={FIELD_SIZE.MEDIUM}
                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                required
            />
            :
            <Plaintext
                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                name="Language"
                value={values.Language}
                labelSize={LABEL_SIZE}
                inputSize={INPUT_SIZE}
            />

    }

    // Fn: Create array of language object for checkbox options
    createLanguageCheckboxOptions = (languageList) => {

        const languageObjArray = [];

        // Add Language object to array
        languageList.map((lang) =>
            languageObjArray.push({
                label: lang,
                value: lang
            })
        );

        return languageObjArray;

    }
    massageValues = (values, submitPlainForm) => {

        values.FaqId = this.state.values.Id;
        values.Application = this.state.values.ApplicationName;
        values.IsLocaleEnabled = this.state.values.IsLocaleEnabled;
        values.LanguageCode = this.state.values.LanguageCode;

        submitPlainForm();
    }

    // Fn: Delete Faq
    deleteFaq = async () => {
        const params = {
            Id: this.state.Id
        };

        const { loader } = this.props;
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(DELETE_FAQ_URL, deleteParams('', params));
        loader.done();
        const { IsSuccess, Messages } = response.body;
        if (response.success) {
            if (IsSuccess) {

                navigateTo(FAQ_MANAGEMENT_URL);

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETE, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.FAQ_DRAFT);
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Publish Faq
    publishForm = async () => {
        const params = {
            FaqId: this.state.Id,
            Application: this.state.values.ApplicationName
        };

        const { loader } = this.props;

        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(PUBLISH_FAQ_URL, postParams('', params));

        loader.done();

        const { IsSuccess, Messages } = response.body;
        if (response.success) {
            if (IsSuccess) {
                navigateTo(FAQ_MANAGEMENT_URL);
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_PUBLISH, SUCCESS.PUBLISH_SUCCESS), DEFAULT_TEXT.FAQ);
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Render publish validation messages 
    publishValidation = async () => {

        const params = {
            FaqId: this.state.Id,
            Application: this.state.values.ApplicationName
        };

        const { loader } = this.props;
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(PUBLISH_FAQ_VALIDATION_URL, postParams('', params));
        loader.done();
        const { IsSuccess, Messages, Message } = response.body;
        if (response.success) {
            if (IsSuccess) {
                this.setState({
                    modalMessage: (<div>{Message} {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQLOCALE, DEFAULT_TEXT.PUBLISH_FAQ_LOCALE_MODAL_TEXT, true)}</div>)
                });

                this.toggleModal(PUBLISH_LOCALE_MODAL.name);
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Render different publish button based on if Locale is enabled
    renderPublishButton = () => {
        return (
            this.state.values.IsLocaleEnabled ?
                <Button color="forward" size="sm" type="button" onClick={() => this.toggleModal(PUBLISH_MODAL.name, this.state.Id)}>
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH, DEFAULT_TEXT.PUBLISH)}
                </Button>
                :
                <Button color="forward" size="sm" type="button" onClick={() => this.toggleModal(PUBLISH_MODAL.name, this.state.Id)}>
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH, DEFAULT_TEXT.PUBLISH)}
                </Button>
        );
    }

    render() {
        return (this.state.isSuccess &&
            <>
                <Layout type={FORM_LAYOUT_TYPES.FULL_PAGE} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_EDITFAQ, DEFAULT_TEXT.EDIT_FAQ)}>
                    <SMARTForm
                        key={"EditFaq"}
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={this.state.serverUrl}
                        nextURL={FAQ_MANAGEMENT_URL}
                    >
                        {({ values, errors, onChangeField }) => (
                            <React.Fragment>
                                <Row className="faq-body-content">
                                    <Col xs="6" sm="6">
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                            name="Application"
                                            value={values.ApplicationName}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    </Col>
                                    <Col xs="6" sm="6">
                                        {this.renderLanguage(values, onChangeField)}
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                                <Row>
                                    <Col>
                                        <FaqTree
                                            treeHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TREE_TITLE_FAQTREE, DEFAULT_TEXT.FAQ_TREE)}
                                            renderActions={true}
                                            renderChapter={false}
                                            error={errors.OnlineHelpTree}
                                            name="OnlineHelpTree"
                                            value={this.state.faqTree}
                                            onChangeField={onChangeField}
                                            leftTreeContainer="left-tree-container"
                                            rightTreeContainer="right-tree-container"
                                            faqId={values.Id}
                                            faqName={values.Name}
                                            faqTitle={values.Title}
                                            faqLanguageCode={values.LanguageCode}
                                            success={this.state.isSuccess}
                                            status={values.Status}
                                            applicationName={values.ApplicationName}
                                            isLocaleEnabled={values.IsLocaleEnabled}
                                            isDefaultLanguage={values.IsDefaultLanguage}
                                            isViewFaq={false}
                                        />
                                        <ModalConfirm
                                            isOpen={(this.state.modalState === PUBLISH_MODAL.name)}
                                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_PUBLISHFAQ, DEFAULT_TEXT.PUBLISH_FAQ_MODAL_TITLE)}
                                            contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQ, DEFAULT_TEXT.PUBLISH_FAQ_MODAL_TEXT, true)}
                                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                            confirmCallback={() => { this.toggleModal(PUBLISH_MODAL.name); this.publishForm(); }}
                                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                            cancelCallback={() => this.toggleModal(PUBLISH_MODAL.name)}
                                        />

                                        <ModalConfirm
                                            isOpen={(this.state.modalState === PUBLISH_LOCALE_MODAL.name)}
                                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_PUBLISHFAQ, DEFAULT_TEXT.PUBLISH_FAQ_MODAL_TITLE)}
                                            contentBody={this.state.modalMessage}
                                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                            confirmCallback={() => { this.toggleModal(PUBLISH_LOCALE_MODAL.name); this.publishForm(); }}
                                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                            cancelCallback={() => this.toggleModal(PUBLISH_LOCALE_MODAL.name)}
                                        />

                                        <ModalConfirm
                                            isOpen={(this.state.modalState === DELETE_MODAL.name)}
                                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_DELETEFAQ, DEFAULT_TEXT.DELETE_FAQ_MODAL_TITLE)}
                                            contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TEXT_DELETEFAQ, DEFAULT_TEXT.DELETE_FAQ_MODAL_TEXT, true)}
                                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                                            confirmCallback={() => { this.toggleModal(DELETE_MODAL.name); this.deleteFaq(); }}
                                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                            cancelCallback={() => this.toggleModal(DELETE_MODAL.name)}
                                        />

                                        <ModalConfirm
                                            className="preview-faq"
                                            isOpen={(this.state.modalState === PREVIEW_MODAL.name)}
                                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_PREVIEWFAQ, DEFAULT_TEXT.PREVIEW_FAQ_MODAL_TITLE)}
                                            contentBody={<PreviewFaq languageCode={values.LanguageCode} application={values.ApplicationName} faqGuid={values.FaqGuid}
                                            isLocaleEnabled={this.state.values.IsLocaleEnabled} isPublished={false}></PreviewFaq>}
                                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                            cancelCallback={() => this.toggleModal(PREVIEW_MODAL.name)}
                                        />

                                    </Col>
                                    <PageFooter type={FORM_LAYOUT_TYPES.FULL_PAGE}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(FAQ_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="neutral" size="sm" type="button" onClick={() => this.toggleModal(PREVIEW_MODAL.name, values.Id)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_PREVIEW, DEFAULT_TEXT.PREVIEW)} 
                                        </Button>
                                        <Button color="danger" size="sm" type="button" onClick={() => this.toggleModal(DELETE_MODAL.name, values.Id)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETEDRAFT, DEFAULT_TEXT.DELETE_DRAFT)} 
                                        </Button>
                                        {this.renderPublishButton()}
                                    </PageFooter>
                                </Row>
                            </React.Fragment>
                        )}

                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(EditFaq));
