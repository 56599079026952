import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SMARTForm, Text, toastSuccess, toastError, SelectList, Plaintext, withSMARTWrapper, Radio, withFormAlert } from '../../Shared/Forms';
import { isValidForm } from '../../Shared/SmartMe/Actions';
import { VALIDATION_MSG, URL } from '../../Shared/SmartMe/Constants';
import PageFooter from '../../Shared/PageFooter';
import { ModalConfirm } from '../../Shared/Modal';
import { FIELD_SIZE } from '../../Shared/Constants';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { selectListMapper } from '../../Shared/SmartMe/Actions';
import { BUTTON_TITLE, ERROR_MSG } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const GET_USER_ORGANISATION_DETAILS_URL = URL + 'Organisation/GetUserOrganisationDetails?';
const GET_PERMISSION_BY_APP_ID_URL = URL + 'Permission/GetAppPermissionsByAppId?'

const IS_ORGANISATION_SPECIFIC = {
    YES: "true",
    NO: "false"
}

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const orgSpecificRequiredMsg =
        getTranslationValue(VALIDATION_MSG.IS_ORGANISATION_SPECIFIC_REQUIRED, getStaticText, RoleTranslationKeys);

    return Yup.object().shape({
        AppId: Yup.string().required(requiredMsg),
        RoleName: Yup.string().required(requiredMsg),
        DisplayRoleName: Yup.string().required(requiredMsg),
        IsOrganisationSpecific: Yup.string().required(orgSpecificRequiredMsg),
        Organisation: Yup.string()
            .when('IsOrganisationSpecific', {
                is: "true",
                then: Yup.string().required(requiredMsg)
            }),
        PermissionList: Yup.string().required(requiredMsg),
    });
}

const FORM_VALIDATIONS_UPDATE = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const orgSpecificRequiredMsg =
        getTranslationValue(VALIDATION_MSG.IS_ORGANISATION_SPECIFIC_REQUIRED, getStaticText, RoleTranslationKeys);

    return Yup.object().shape({
        RoleName: Yup.string().required(requiredMsg),
        DisplayRoleName: Yup.string().required(requiredMsg),
        IsOrganisationSpecific: Yup.string().required(orgSpecificRequiredMsg),
        Organisation: Yup.string()
            .when('IsOrganisationSpecific', {
                is: "true",
                then: Yup.string().required(requiredMsg)
            }),
        PermissionList: Yup.string().required(requiredMsg),
    });
}

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const RoleTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            server_url: props.server_url,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            prevState: props.prevState,
            organisationSelection: [],
            permissionSelection: [],
            //isShowAppEntity: false,
            isUpdate: props.prevState.AppIds !== "" ? false : true
        };
    }

    async componentDidMount() {
        let appid = this.state.values.AppId
        await this.getAgencySpecific(appid, this.state.isUpdate);
        await this.getPermission(appid);
    }

    handleApplicationChange = async (value) => {
        await this.getAgencySpecific(value, false);
        await this.getPermission(value);
    }

    clearOrganisation = async (values, onChangeField) => {
        values.Organisation = "";
        onChangeField("Organisation", []);
    }

    clearPermission = async (values, onChangeField) => {
        values.PermissionList = "";
        onChangeField("PermissionList", []);
    }

    getAgencySpecific = async (AppId, isUpdate) => {
        const { loader } = this.props;
        loader.start();

        let param = { appId: AppId };
        let response = await fetchRequest(GET_USER_ORGANISATION_DETAILS_URL, getParams(param), false);


        if (response.success && response.body.IsSuccess) {
            let resultData = response.body.Data
            const mappedSelection = selectListMapper(resultData.OrganisationList, "Id", "FullName");

            // Default isSpecific = not selected, if isUpdate will take the update value
            let isSpecific = isUpdate ? this.state.values.IsOrganisationSpecific : "";

            if (resultData.IsOrganisationAdmin && !resultData.IsAppAdmin) {
                isSpecific = IS_ORGANISATION_SPECIFIC.YES;
            }

            this.setState({
                isAppAdmin: resultData.IsAppAdmin,
                isOrganisationAdmin: resultData.IsOrganisationAdmin,
                organisationSelection: mappedSelection,
                values: {
                    ...this.state.values,
                    IsOrganisationSpecific: isSpecific
                }
            });
        }
        else {
            const { formAlert } = this.props;
            formAlert.showAlert(response.Messages);
        }

        loader.done();
    }

    getPermission = async (AppId) => {
        const { loader } = this.props;
        loader.start();

        let param = { appId: AppId };
        let response = await fetchRequest(GET_PERMISSION_BY_APP_ID_URL, getParams(param), false);

        if (response.success && response.body.IsSuccess) {

            this.setState({
                permissionSelection: selectListMapper(response.body.Data, "Id", "PermissionName")
            });
        }
        else {
            this.props.formAlert.showAlert(response.Messages);
        }

        loader.done();
    }


    render() {
        const { modal, getStaticText } = this.props;
        const validationSchema = this.state.isUpdate === false ? FORM_VALIDATIONS(getStaticText) : FORM_VALIDATIONS_UPDATE(getStaticText);

        const translation = {
            getStaticText: getStaticText,
            translationKeys: RoleTranslationKeys
        };

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                    <SMARTForm
                        formValues={this.state.values}
                        validationSchema={validationSchema}
                        validateOnSubmit={true}
                        serverURL={this.state.server_url}
                        isDummy={false}
                        submitCallback={({ response }) => {
                            if (response.success && response.body.IsSuccess) {
                                navigateTo("/Role");
                                toastSuccess(response.body.Messages);
                            }
                            else {
                                toastError(response.body.Messages)
                            }
                        }
                        }
                    >
                        {({ values, errors, onChange, submitForm, onChangeField }) => (
                            <React.Fragment>
                                <Row className="body-content">
                                    <Col>
                                        {this.state.isUpdate === false ?
                                            <SelectList
                                                name="AppId"
                                                value={values.AppId}
                                                options={this.state.prevState.AppIds}
                                                onChangeField={async (name, value) => {
                                                    await this.clearPermission(values, onChangeField);
                                                    await this.clearOrganisation(values, onChangeField);
                                                    await this.handleApplicationChange(value);
                                                    onChangeField(name, value);

                                                }}
                                                isMulti={false}
                                                error={errors.AppId}
                                                label="Application"
                                                translation={translation}
                                                required
                                            />
                                            :
                                            <Plaintext
                                                label="Application"
                                                translation={translation}
                                                name="ApplicationId"
                                                value={values.DisplayAppId}
                                            />
                                        }
                                        <Text
                                            name="RoleName"
                                            value={values.RoleName}
                                            placeholder="Role Name"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={7}
                                            label="Role Name"
                                            translation={translation}
                                            error={errors.RoleName}
                                            required
                                        />
                                        <Text
                                            name="DisplayRoleName"
                                            value={values.DisplayRoleName}
                                            placeholder="Display Role Name"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={7}
                                            label="Display Role Name"
                                            translation={translation}
                                            error={errors.DisplayRoleName}
                                            required
                                        />

                                        {this.state.isOrganisationAdmin && !this.state.isAppAdmin &&
                                            <Plaintext
                                                label="Organisation Specific?"
                                                translation={translation}
                                                name="IsOrganisationSpecific"
                                                value="Yes"
                                            />
                                        }

                                        {this.state.isAppAdmin &&
                                            <Radio
                                                name="IsOrganisationSpecific"
                                                value={values.IsOrganisationSpecific}
                                                options={[
                                                    { label: "Yes", value: IS_ORGANISATION_SPECIFIC.YES },
                                                    { label: "No", value: IS_ORGANISATION_SPECIFIC.NO }]}
                                                onChange={(e) => {
                                                    this.clearOrganisation(values, onChangeField);
                                                    onChange(e);

                                                }}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label="Organisation Specific?"
                                                translation={translation}
                                                error={errors.IsOrganisationSpecific}
                                                required
                                            />
                                        }

                                        {values.IsOrganisationSpecific === IS_ORGANISATION_SPECIFIC.YES &&
                                            <SelectList
                                                name="Organisation"
                                                value={values.Organisation}
                                                options={this.state.organisationSelection}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                inputSize={FIELD_SIZE.LARGE}
                                                error={errors.Organisation}
                                                label="Organisation"
                                                translation={translation}
                                                required
                                            />
                                        }

                                        <Text
                                            name="Description"
                                            value={values.Description}
                                            placeholder="Description"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={7}
                                            label="Description"
                                            translation={translation}
                                            error={errors.Description}
                                        />

                                        {this.state.permissionSelection &&
                                            <SelectList
                                                name="PermissionList"
                                                value={values.PermissionList}
                                                options={this.state.permissionSelection}
                                                onChangeField={onChangeField}
                                                isMulti={true}
                                                inputSize={FIELD_SIZE.LARGE}
                                                error={errors.PermissionList}
                                                label="Permission"
                                                translation={translation}
                                                required
                                            />
                                        }

                                    </Col>
                                </Row>
                                <PageFooter>
                                    <Button color="primary" type="button" onClick={() => isValidForm(validationSchema, values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                </PageFooter>
                                <ModalConfirm
                                    isOpen={modal.modalState === "Submit"}
                                    contentHeader={this.state.contentHeader}
                                    contentBody={this.state.contentBody}
                                    confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                    cancelCallback={() => modal.toggleModal("Submit")}
                                    translation={translation}
                                    formatter={{ component: getTranslationValue('Role', getStaticText, RoleTranslationKeys) }}
                                />
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(withFormAlert(Form));