import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Footer from '../Footer';
import Title from '../Title';
import { getTranslationValue } from '../Shared/Forms/FormUtils';

export const FORM_LAYOUT_TYPES = {
    FULL_PAGE: 1,
    FLOATING_WINDOW: 2
}

class Layout extends React.Component {

    renderLayout(param) {
        const { title, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const titleValue = getStaticText && translationKeys ? getTranslationValue(title, getStaticText, translationKeys) : title;

        switch (param) {
            case FORM_LAYOUT_TYPES.FULL_PAGE:
                return (
                    <React.Fragment>
                        {this.props.title && (<Title className={"first body-title body-title-text " + (this.props.titleClassName || "") + " " + (this.props.titleChildren ? "title-with-children" : "")} titleValue={titleValue}>
                            {this.props.titleChildren}
                        </Title>)}

                        {this.props.children}
                        {this.props.footer ? <Footer footerExtension={this.props.footerExtension} /> : ""}
                    </React.Fragment>
                );
            case FORM_LAYOUT_TYPES.FLOATING_WINDOW:
                return (
                    <React.Fragment>
                        <div className={"background-content"}>
                            <Card className="panel-card">
                                {this.props.title && (<Title className={"second body-title body-title-text " + (this.props.titleClassName || "") + " " + (this.props.titleChildren ? "title-with-children" : "")} titleValue={titleValue}>
                                    {this.props.titleChildren}
                                </Title>)}

                                <CardBody className="start-card-body">
                                    {this.props.children}
                                </CardBody>
                            </Card>
                            <Footer footerExtension={this.props.footerExtension} />
                        </div>
                    </React.Fragment>
                );
            default:
                return <></>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLayout(this.props.type)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Layout.propTypes = {
    type: PropTypes.number,
    footer: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
Layout.defaultProps = {
    type: FORM_LAYOUT_TYPES.FULL_PAGE,
    title: "",
    footer: false,
};

export default Layout;