import React from 'react';
import { Col, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import './Faq.css';


class QuestionAnswerDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { questionSets, selectedTopic, selectedSubTopic } = this.props;
        return (
            <Row >
                <Col xs="12" >
                    <div className="preview-faq-topic">{selectedTopic}</div>
                    <div className="preview-faq-subtopic">{selectedSubTopic}</div>
                    <hr />
                    {questionSets.map((questionSet, questionSetIndex) => (
                        <div key={questionSetIndex}>
                            <div className="preview-faq-question">
                                {questionSet.Question}</div>
                            <div className="preview-faq-answer" >
                                    {ReactHtmlParser(questionSet.Answer)}
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>

        );
    }
}
export default (QuestionAnswerDisplay);