import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import Select from "react-select";
import { Button, Col, Row } from 'reactstrap';
import { CODES_CREATE, CODES_UPDATE, CODES_VIEW, CODETABLE_CATEGORY_LIST_URL, CODETABLE_LIST_URL } from '../../App/AppSettings';
import { ERROR } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FILTER } from '../../Shared/Constants';
import { ActionButton, MobileCardActionButton, ServerSideDataTableV2 } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable';
import { toastError, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import Title from '../../Title';

const DROPDOWN_CATEGORY_URL = CODETABLE_CATEGORY_LIST_URL;
const RETRIEVE_URL = CODETABLE_LIST_URL;

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalState: undefined,
            toggleUpdate: false,
            isLoaded: false,
            categoryFilter: "",
            searchFilter: "",
            data: [],
        };
    }

    componentDidMount = async () => {
        await this.getCodeCategoryList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");
    }

    getCodeCategoryList = async (url, dropDownName) => {
        const { loader } = this.props;
        loader.start();
        const response = await fetchRequest(url, getParams(), false);
        loader.done();
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [{ label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CATEGORIES), value: "" }];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element, 'value': element });
                });
                this.setState({
                    [dropDownName]: dropDownArray,
                    isLoaded: true
                })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        const filterFields = {
            [FILTER]: this.state.searchFilter,
            //pass the drop down list value of code category filter
            'CodeCategoryFilter': this.state.categoryFilter
        };
        setFilter(filterFields);
    }

    Filter = (props) => {
        let codeCategory = this.state.dropDownArrayCategory;
        let codeCategoryValue = this.state.categoryFilter;
        codeCategoryValue = codeCategoryValue === "" ? codeCategory[0] :
            _.find(codeCategory, { 'value': codeCategoryValue });

        return (
            <React.Fragment>
                <Select
                    defaultValue={codeCategoryValue}
                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_PLACEHOLDER_FILTER, DEFAULT_TEXT.CODE_CATEGORY)}
                    onChange={(selectedOption) => this.onFilterChange("categoryFilter", selectedOption.value, props.setFilter)}
                    options={codeCategory}
                    maxMenuHeight={150}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className="select-filter select-list" //  class for css
                />
                <input
                    type="text"
                    name="filter"
                    defaultValue={this.state.searchFilter}
                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_PLACEHOLDER_FILTER, DEFAULT_TEXT.FILTER)}
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => this.onFilterChange("searchFilter", e.target.value, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(CODES_UPDATE, { ID: RowData.ID, Data: RowData })}
                    icon={faEdit}
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_TABLE_TOOLTIP_UPDATECODE, DEFAULT_TEXT.TOOLTIP_UPDATE_CODE)} 
                />
                <MobileCardActionButton
                    onClick={() => navigateTo(CODES_VIEW, { ID: RowData.ID, Data: RowData })}
                    icon={faEye}
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_TABLE_TOOLTIP_VIEWCODE, DEFAULT_TEXT.TOOLTIP_VIEW_CODE)} 
                />
            </React.Fragment>
        )
    }

    getMobileCardSelectFilterValues = () => {
        let codeCategory = this.state.dropDownArrayCategory;
        let selectFilterValues = [
            {
                name: "Category",
                placeholder: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CODECATEGORY, DEFAULT_TEXT.CODE_CATEGORY),
                options: codeCategory
            }
        ]
        return selectFilterValues;
    }

    getCustomComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = this.Filter;

        return customComponents;
    }

    loadGrid = async () => {
        const { loader } = this.props;
        loader.start();
        const response = await fetchRequest(RETRIEVE_URL, getParams());
        loader.done();
        if (response.success) {
            const { Data, IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                this.setState({
                    success: response.success,
                    data: Data,
                    count: ((Data !== undefined) ? Data.length : 0)
                });
            }
            else {
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEMANAGEMENT, DEFAULT_TEXT.CODE_TABLE_MANAGEMENT)}
                /> 
                <div className="grid-container">
                    <Row className="smart-list">
                        <Col className='text-right'>
                            <Button color="neutral" onClick={() => navigateTo(CODES_CREATE)}>
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_NAVIGATION_BUTTON_NEWCODE, DEFAULT_TEXT.NEW_CODE)}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="smart-list">

                        <Col>
                            {this.state.isLoaded ?
                                <ServerSideDataTableV2
                                    components={this.getCustomComponents()}
                                    toggleUpdate={this.state.toggleUpdate}
                                    url={RETRIEVE_URL}
                                    pageSize='5'
                                    isCustomFilter={true}
                                    minFilterChars='2'
                                    rowIdentifier="id"
                                    noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_TABLE_TEXT_NORECORD, DEFAULT_TEXT.CODE_NO_RECORD)}
                                    columns={
                                        {
                                            RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%' },
                                            Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '5%' },
                                            category: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_CATEGORY, DEFAULT_TEXT.CATEGORY), width: "15%", DBkey: "CodeCategory" },
                                            title: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_TITLE, DEFAULT_TEXT.TITLE), width: "15%", DBkey: "CodeTitle" },
                                            value: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_VALUE, DEFAULT_TEXT.VALUE), width: "15%", DBkey: "CodeValue" },
                                            description: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION), width: "15%", DBkey: "CodeDescription" },
                                            StatusBooleanBadge: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_STATUS, DEFAULT_TEXT.STATUS), width: "10%", DBkey: "IsActive", trueText: "Active", falseText: "Inactive" },
                                            EffectiveStartDateFormatted: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE), width: "10%", DBkey: "EffectiveStartDateFormatted" },
                                            EffectiveEndDateFormatted: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE), width: "10%", DBkey: "EffectiveEndDateFormatted" }
                                        }
                                    }
                                    renderActions={({ RowData }) => (
                                        <React.Fragment>
                                            <ActionButton 
                                                key={'Update' + RowData.Id} 
                                                tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_TABLE_TOOLTIP_UPDATECODE, DEFAULT_TEXT.TOOLTIP_UPDATE_CODE)} 
                                                color="forward" 
                                                onClick={() => navigateTo(CODES_UPDATE, { ID: RowData.ID, Data: RowData })}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionButton>

                                            <ActionButton 
                                                key={'View' + RowData.Id} 
                                                tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_TABLE_TOOLTIP_VIEWCODE, DEFAULT_TEXT.TOOLTIP_VIEW_CODE)} 
                                                color="neutral" 
                                                onClick={() => navigateTo(CODES_VIEW, { ID: RowData.ID, Data: RowData })}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </ActionButton>
                                        </React.Fragment>
                                    )}
                                    getMobileCardSelectFilterValues={this.getMobileCardSelectFilterValues}
                                    renderMobileCardActions={this.renderMobileCardActions}

                                />
                                : null}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);