import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { getTranslationOptions } from '../../Shared/Forms/FormUtils';
import { withBaseControl } from './BaseControl';

class Radio extends React.Component {

    render() {
        // De-structure props to be used
        const { name, options, onChange, value, disabled, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const radioOptions = getStaticText && translationKeys ? getTranslationOptions(options, getStaticText, translationKeys) : options;
        
        // For each option item
        return (radioOptions.map((option) => {
            var id = name+option.value;
            id = id.replace(/\s/g, '');
            
            // Render an inline radio button
            return (
                <FormGroup key={option.value} check inline>
                    <Label className={`radio-group ${disabled ? "radio-disabled" : ""}`}  check>
                        {/* Hide original Radio UI, but all react behavior still happens here */}
                        <Input
                            className="radio-control"
                            type="radio"
                            id={id}
                            name={name}
                            value={option.value}
                            onChange={onChange}
                            checked={value === option.value}
                            disabled={disabled}
                            readOnly={disabled}
                        />

                        {/* Re-styled Radio UI for cross-browser compatibility */}
                        <Label for={id} className="radio-display" tabIndex={0}></Label>

                        {/* Radio Text */}
                        <Label for={id} className="radio-label">{option.label}</Label>
                    </Label>
                </FormGroup>
            );
        }));
    }
}

// PropTypes: For prop validation
Radio.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

// PropTypes: Defaulting value for optional props
Radio.defaultProps = {
    value: ''
};

export default withBaseControl(Radio);