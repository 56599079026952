export const OrganisationTranslationKeys = [
    {
        "DefaultLanguageValue": "Organisation",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Organisation"
    },
    {
        "DefaultLanguageValue": "Organisation (Full Name)",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "OrganisationFullName"
    },
    {
        "DefaultLanguageValue": "Organisation (Abbreviated Name)",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "OrganisationAbbreviatedName"
    },
    {
        "DefaultLanguageValue": "Contact Email",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "ContactEmail"
    },
    {
        "DefaultLanguageValue": "National Environment Authority",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "NEA"
    },
    {
        "DefaultLanguageValue": "Ministry of Agriculture",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "MOA"
    },
    {
        "DefaultLanguageValue": "Ministry of Manpower",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "MOM"
    },
    {
        "DefaultLanguageValue": "Tax and Revenue Authority",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "TRA"
    },
    {
        "DefaultLanguageValue": "Building and Construction Authority",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "BCA"
    },
    {
        "DefaultLanguageValue": "Hotel Licensing Board",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "HLB"
    },
    {
        "DefaultLanguageValue": "Halal Certification Agency",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "HCA"
    },
    {
        "DefaultLanguageValue": "Ministry of Health",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "MOH"
    },
    {
        "DefaultLanguageValue": "Urban Redevelopment Authority",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "URA"
    },
    {
        "DefaultLanguageValue": "National Police Force",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "NPF"
    },
    {
        "DefaultLanguageValue": "National Food Agency",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Organisation",
        "TranslationKey": "NFA"
    }
]