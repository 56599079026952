import React from 'react';
import { withRouter } from 'react-router-dom';
import { validateRequest, selectListMapper}from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { URL } from '../../Shared/SmartMe/Constants';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert } from '../../Shared/Forms';
import Form from './Form';

const SERVER_URL = URL+'User/UpdateUserRoles';

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }

    userGroupRolesMapper = (response, allRoles) => {
        var result = [];
        response.map(x =>{
            var role = {};
            allRoles.map(y => {
                if(x.MeRoleId === y.Id){
                    Object.assign(role, {Id: y.Id, RoleName: y.RoleName, DisplayRoleName: y.DisplayRoleName})
                    return null;
                }
                return null;
            })
            Object.assign(role, { MeRoleId: x.MeRoleId, EffectiveStartDate: x.EffectiveStartDate, EffectiveEndDate: x.EffectiveEndDate, Existing:true, MeRoleToUserGroupID:x.Id})
            result.push(role);
            return null;
        })
        return result;
    }

    async componentDidMount(){
        const { loader } = this.props;
        loader.start();
        let userGroupRolesResp = await fetchRequest(URL+"UserGroup/GetUserGroupRoles/" + this.props.match.params.id, getParams(), false);
        let rolesResp = await fetchRequest(URL+"Role/GetRoles", getParams(), false);

        if(validateRequest([userGroupRolesResp, rolesResp])){
            let userGroupRoles = userGroupRolesResp.body.Data;
            let roles = rolesResp.body.Data;

            //map user roles to grid values
            let mappedUserGroupRoles = this.userGroupRolesMapper(userGroupRoles.Roles, roles);
            //map all selectable items to select input {value:label} pair 
            let mappedSelectable = selectListMapper(roles, "Id", "RoleName");
            this.setState({
                readOnly:{
                    id: this.props.match.params.id,
                    GroupName: userGroupRoles.GroupName,
                    Description: userGroupRoles.Description || "",
                    selectable: mappedSelectable,
                    allRoles: roles,
                },
                isUpdatePage: true,
                roleToUserGroup: mappedUserGroupRoles,
                isLoaded: true 
            });
        }
        else{
            const { formAlert } = this.props;
            formAlert.showAlert(userGroupRolesResp.body.messages || rolesResp.body.Messages);
        }
        loader.done();
    }
    
    render() {
        return(
            <React.Fragment>
                {this.state.isLoaded ? 
                (
                    <Form data={this.state} server_url={SERVER_URL}/>
                ) 
                :null}
            </React.Fragment>
        )
    }
}
export default withFormAlert(withLoader(withRouter(Update)));