import React from 'react';
import { Label, Button, Card, CardBody, Row, Col, Table } from 'reactstrap';
import { getValue, getString } from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import PageFooter from '../../Shared/PageFooter';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { URL } from '../../Shared/SmartMe/Constants';
import { FIELD_SIZE } from '../../Shared/Constants';
import { toastError } from '../../Shared/Forms/Toaster';
import * as Yup from 'yup';
import { SMARTForm, Radio, NumberInput, withSMARTWrapper } from '../../Shared/Forms';
import { Panel } from '../../Shared/SmartMe/Components';
import { ModalConfirm } from '../../Shared/Modal';
import '../../Content/Policy/Password.css';
import { isValidForm } from '../../Shared/SmartMe/Actions';
import TitleChildren from '../../Title/TitleChildren';
import { ERROR_MSG, BUTTON_TITLE } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';

const GET_PASSWORD_POLICY_URL = URL + 'Policy/GetPasswordPolicy';
const SAVE_PASSWORD_POLICY_URL = URL + 'Policy/SavePasswordPolicy';

const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

const CUSTOM = "Custom";
const DEFAULT = "Default";

const PASSWORD_CHARACTER_REQUIREMENT_TYPES = [
    { label: "Default", value: "Default" },
    { label: "Custom", value: "Custom" }
]

const CUSTOM_ERROR_MSG = (getStaticText) => {
    return ({
        PASSWORD_LENGTH:
            getTranslationValue('Minimum password length must NOT exceed Maximum password length.',
                getStaticText, PwdPolicyTranslationKeys),

        PASSWORD_LENGTH_AT_LEAST_3:
            getTranslationValue('Minimum password length must be at least 3.',
                getStaticText, PwdPolicyTranslationKeys),

        MIN_PASSWORD_LENGTH:
            getTranslationValue('Minimum password length must NOT be less than Total minimum character length.',
                getStaticText, PwdPolicyTranslationKeys),

        MAX_PASSWORD_LENGTH:
            getTranslationValue('Maximum password length must NOT be more than Total maximum character length.',
                getStaticText, PwdPolicyTranslationKeys),

        MIN_EXCEEDS_MAX:
            getTranslationValue('Min > Max', getStaticText, PwdPolicyTranslationKeys),

        REQUIRED:
            getTranslationValue('Required', getStaticText, PwdPolicyTranslationKeys)
    })
}

const FORM_VALIDATIONS = (getStaticText) => {
    const requiredMsg = ERROR_MSG(getStaticText).REQUIRED;

    return Yup.object().shape({
        MinPasswordLength: Yup.string()
            .test('MinPasswordLength', CUSTOM_ERROR_MSG(getStaticText).PASSWORD_LENGTH,
                function () {
                    let minPasswordLength = getValue(this.resolve(Yup.ref("MinPasswordLength")));
                    let maxPasswordLength = getValue(this.resolve(Yup.ref("MaxPasswordLength")));

                    if (minPasswordLength > maxPasswordLength) return false;
                    return true;
                })
            .test('ValidateDefaultMinPasswordLength', CUSTOM_ERROR_MSG(getStaticText).PASSWORD_LENGTH_AT_LEAST_3,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === DEFAULT) {
                        let minPasswordLength = getValue(this.resolve(Yup.ref("MinPasswordLength")));

                        if (minPasswordLength < 3) return false;
                        return true;
                    }
                    return true;
                })
            .test('ValidateCustomMinPasswordLength', CUSTOM_ERROR_MSG(getStaticText).MIN_PASSWORD_LENGTH,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minPasswordLength = getValue(this.resolve(Yup.ref("MinPasswordLength")));
                        let minUppercaseCharLength = getValue(this.resolve(Yup.ref("MinUppercaseCharLength")));
                        let minLowercaseCharLength = getValue(this.resolve(Yup.ref("MinLowercaseCharLength")));
                        let minNumericCharLength = getValue(this.resolve(Yup.ref("MinNumericCharLength")));
                        let minSpecialCharLength = getValue(this.resolve(Yup.ref("MinSpecialCharLength")));

                        let totalMinCharLength =
                            minUppercaseCharLength + minLowercaseCharLength +
                            minNumericCharLength + minSpecialCharLength;

                        if (minPasswordLength < totalMinCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .nullable()
            .required(requiredMsg),
        MaxPasswordLength: Yup.string()
            .test('MaxPasswordLength', CUSTOM_ERROR_MSG(getStaticText).PASSWORD_LENGTH,
                function () {
                    let minPasswordLength = getValue(this.resolve(Yup.ref("MinPasswordLength")));
                    let maxPasswordLength = getValue(this.resolve(Yup.ref("MaxPasswordLength")));

                    if (minPasswordLength > maxPasswordLength) return false;
                    return true;
                })
            .test('ValidateCustomMaxPasswordLength', CUSTOM_ERROR_MSG(getStaticText).MAX_PASSWORD_LENGTH,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let maxPasswordLength = getValue(this.resolve(Yup.ref("MaxPasswordLength")));
                        let maxUppercaseCharLength = getValue(this.resolve(Yup.ref("MaxUppercaseCharLength")));
                        let maxLowercaseCharLength = getValue(this.resolve(Yup.ref("MaxLowercaseCharLength")));
                        let maxNumericCharLength = getValue(this.resolve(Yup.ref("MaxNumericCharLength")));
                        let maxSpecialCharLength = getValue(this.resolve(Yup.ref("MaxSpecialCharLength")));

                        let totalMaxCharLength =
                            maxUppercaseCharLength + maxLowercaseCharLength +
                            maxNumericCharLength + maxSpecialCharLength;

                        if (totalMaxCharLength > 0 && (maxPasswordLength > totalMaxCharLength)) return false;
                        return true;
                    }
                    return true;
                })
            .nullable()
            .required(requiredMsg),
        MinUppercaseCharLength: Yup.string()
            .test('MinUppercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minUppercaseCharLength = getValue(this.resolve(Yup.ref("MinUppercaseCharLength")));
                        let maxUppercaseCharLength = getValue(this.resolve(Yup.ref("MaxUppercaseCharLength")));

                        if (maxUppercaseCharLength === 0) return true;
                        if (minUppercaseCharLength > maxUppercaseCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MinUppercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minUppercaseCharLength = getValue(this.resolve(Yup.ref("MinUppercaseCharLength")));
                        if (minUppercaseCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MaxUppercaseCharLength: Yup.string()
            .test('MaxUppercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minUppercaseCharLength = getValue(this.resolve(Yup.ref("MinUppercaseCharLength")));
                        let maxUppercaseCharLength = getValue(this.resolve(Yup.ref("MaxUppercaseCharLength")));

                        if (maxUppercaseCharLength === 0) return true;
                        if (minUppercaseCharLength > maxUppercaseCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MaxUppercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let maxUppercaseCharLength = getValue(this.resolve(Yup.ref("MaxUppercaseCharLength")));
                        if (maxUppercaseCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MinLowercaseCharLength: Yup.string()
            .test('MinLowercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minLowercaseCharLength = getValue(this.resolve(Yup.ref("MinLowercaseCharLength")));
                        let maxLowercaseCharLength = getValue(this.resolve(Yup.ref("MaxLowercaseCharLength")));

                        if (maxLowercaseCharLength === 0) return true;
                        if (minLowercaseCharLength > maxLowercaseCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MinLowercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minLowercaseCharLength = getValue(this.resolve(Yup.ref("MinLowercaseCharLength")));
                        if (minLowercaseCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MaxLowercaseCharLength: Yup.string()
            .test('MaxLowercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minLowercaseCharLength = getValue(this.resolve(Yup.ref("MinLowercaseCharLength")));
                        let maxLowercaseCharLength = getValue(this.resolve(Yup.ref("MaxLowercaseCharLength")));

                        if (maxLowercaseCharLength === 0) return true;
                        if (minLowercaseCharLength > maxLowercaseCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MaxLowercaseCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let maxLowercaseCharLength = getValue(this.resolve(Yup.ref("MaxLowercaseCharLength")));
                        if (maxLowercaseCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MinNumericCharLength: Yup.string()
            .test('MinNumericCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minNumericCharLength = getValue(this.resolve(Yup.ref("MinNumericCharLength")));
                        let maxNumericCharLength = getValue(this.resolve(Yup.ref("MaxNumericCharLength")));

                        if (maxNumericCharLength === 0) return true;
                        if (minNumericCharLength > maxNumericCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MinNumericCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minNumericCharLength = getValue(this.resolve(Yup.ref("MinNumericCharLength")));
                        if (minNumericCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MaxNumericCharLength: Yup.string()
            .test('MaxNumericCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minNumericCharLength = getValue(this.resolve(Yup.ref("MinNumericCharLength")));
                        let maxNumericCharLength = getValue(this.resolve(Yup.ref("MaxNumericCharLength")));

                        if (maxNumericCharLength === 0) return true;
                        if (minNumericCharLength > maxNumericCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MaxNumericCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let maxNumericCharLength = getValue(this.resolve(Yup.ref("MaxNumericCharLength")));
                        if (maxNumericCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MinSpecialCharLength: Yup.string()
            .test('MinSpecialCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minSpecialCharLength = getValue(this.resolve(Yup.ref("MinSpecialCharLength")));
                        let maxSpecialCharLength = getValue(this.resolve(Yup.ref("MaxSpecialCharLength")));

                        if (maxSpecialCharLength === 0) return true;
                        if (minSpecialCharLength > maxSpecialCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MinSpecialCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minSpecialCharLength = getValue(this.resolve(Yup.ref("MinSpecialCharLength")));
                        if (minSpecialCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable(),
        MaxSpecialCharLength: Yup.string()
            .test('MaxSpecialCharLength', CUSTOM_ERROR_MSG(getStaticText).MIN_EXCEEDS_MAX,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let minSpecialCharLength = getValue(this.resolve(Yup.ref("MinSpecialCharLength")));
                        let maxSpecialCharLength = getValue(this.resolve(Yup.ref("MaxSpecialCharLength")));

                        if (maxSpecialCharLength === 0) return true;
                        if (minSpecialCharLength > maxSpecialCharLength) return false;
                        return true;
                    }
                    return true;
                })
            .test('MaxSpecialCharLength', CUSTOM_ERROR_MSG(getStaticText).REQUIRED,
                function () {
                    let passwordRequirementType = this.resolve(Yup.ref("PasswordCharacterRequirementType"));
                    if (passwordRequirementType === CUSTOM) {
                        let maxSpecialCharLength = getValue(this.resolve(Yup.ref("MaxSpecialCharLength")));
                        if (maxSpecialCharLength === 0) return false;
                        return true;
                    }
                    return true;
                })
            .nullable()
    });
}

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const PwdPolicyTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class PasswordPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader, getStaticText } = this.props;

        loader.start();

        let response = await fetchRequest(GET_PASSWORD_POLICY_URL, getParams(), false);

        if (response.success && response.body.IsSuccess) {
            let passwordPolicy = response.body.Data;

            this.setState({
                values: {
                    PasswordCharacterRequirementType: passwordPolicy.PasswordCharacterRequirementType,
                    MinForMinPasswordLength: passwordPolicy.PasswordCharacterRequirementType === DEFAULT ? 3 : 0,
                    MaxForMaxPasswordLength: Infinity,
                    MinPasswordLength: getString(passwordPolicy.MinPasswordLength),
                    MaxPasswordLength: getString(passwordPolicy.MaxPasswordLength),
                    MinUppercaseCharLength: getString(passwordPolicy.MinUppercaseCharLength),
                    MaxUppercaseCharLength: getString(passwordPolicy.MaxUppercaseCharLength),
                    MinLowercaseCharLength: getString(passwordPolicy.MinLowercaseCharLength),
                    MaxLowercaseCharLength: getString(passwordPolicy.MaxLowercaseCharLength),
                    MinNumericCharLength: getString(passwordPolicy.MinNumericCharLength),
                    MaxNumericCharLength: getString(passwordPolicy.MaxNumericCharLength),
                    MinSpecialCharLength: getString(passwordPolicy.MinSpecialCharLength),
                    MaxSpecialCharLength: getString(passwordPolicy.MaxSpecialCharLength),
                },
                isLoaded: true
            });
        }
        else {
            toastError(ERROR_MSG(getStaticText).SERVER_ERROR);
        }
        loader.done();
    }

    setPasswordLength = (values) => {
        if (values.PasswordCharacterRequirementType === CUSTOM) {
            let minPasswordLength = values.MinPasswordLength;

            let totalMinLength =
                values.MinLowercaseCharLength + values.MinUppercaseCharLength +
                values.MinNumericCharLength + values.MinSpecialCharLength;

            let maxPasswordLength = values.MaxPasswordLength;

            let totalMaxLength =
                values.MaxLowercaseCharLength + values.MaxUppercaseCharLength +
                values.MaxNumericCharLength + values.MaxSpecialCharLength;

            if (totalMinLength > 0 && totalMaxLength > 0 && this.isCharacterLengthValid(values)) {
                this.setState({
                    values: {
                        ...values,
                        MinForMinPasswordLength: totalMinLength,
                        MaxForMaxPasswordLength: totalMaxLength,
                        MinPasswordLength: ((minPasswordLength < totalMinLength) || //Minimum password length shouldn't be longer than Total minimum Length (Custom)
                            (minPasswordLength > totalMaxLength)) ? //Minimum password length shouldn't be longer than Total maximum Length (Custom)
                            totalMinLength : minPasswordLength,
                        MaxPasswordLength: (maxPasswordLength > totalMaxLength) ? totalMaxLength : maxPasswordLength
                    }
                })
            }
        }
    }

    isCharacterLengthValid = (values) => {
        if (values.MinLowercaseCharLength > values.MaxLowercaseCharLength) {
            return false;
        }
        if (values.MinUppercaseCharLength > values.MaxUppercaseCharLength) {
            return false;
        }
        if (values.MinNumericCharLength > values.MaxNumericCharLength) {
            return false;
        }
        if (values.MinSpecialCharLength > values.MaxSpecialCharLength) {
            return false;
        }
        return true;
    }

    renderTitleChildren = (getStaticText) => {
        const titleChildren =
            getTranslationValue("Please fill up the Password Policy.",
                getStaticText, PwdPolicyTranslationKeys);

        return (
            <TitleChildren>
                <Row className="title-custom">
                    <Col>
                        {titleChildren}
                    </Col>
                </Row>
            </TitleChildren>
        )
    }

    getTranslationText = (text) => {
        const { getStaticText } = this.props;
        return getTranslationValue(text, getStaticText, PwdPolicyTranslationKeys);
    }

    getCharacterText = () => {
        const { getStaticText } = this.props;
        return ({
            UPPERCASE: getTranslationValue("Uppercase Character", getStaticText, PwdPolicyTranslationKeys),
            LOWERCASE: getTranslationValue("Lowercase Character", getStaticText, PwdPolicyTranslationKeys),
            NUMERIC: getTranslationValue("Numeric Character", getStaticText, PwdPolicyTranslationKeys),
            SPECIAL: getTranslationValue("Special Character", getStaticText, PwdPolicyTranslationKeys)
        })
    }

    render() {
        const { modal, getStaticText } = this.props;

        const translation = {
            getStaticText: getStaticText,
            translationKeys: PwdPolicyTranslationKeys
        };

        const CHAR_TEXT = this.getCharacterText();

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Password Policy" titleChildren={this.renderTitleChildren(getStaticText)}>
                        <React.Fragment>
                            <SMARTForm
                                translation={translation}
                                formValues={this.state.values}
                                validationSchema={FORM_VALIDATIONS(getStaticText)}
                                validateOnSubmit={true}
                                serverURL={SAVE_PASSWORD_POLICY_URL}
                            >
                                {({ values, errors, onChange, onChangeField, submitForm }) => {

                                    if (values.PasswordCharacterRequirementType === DEFAULT) {
                                        values.MinForMinPasswordLength = 3;
                                        values.MaxForMaxPasswordLength = Infinity;
                                    }

                                    return (
                                        <React.Fragment>
                                            <Row className="policy-form">
                                                <Col className="body-content">
                                                    <Card className="start-card">
                                                        <CardBody className="start-card-body mb-4">
                                                            <div className="start-card-body-content container policy-body">
                                                                {values.AppId !== '' &&
                                                                    <React.Fragment>
                                                                        <Panel key={"Password length" + values.AppId}
                                                                            translation={translation}
                                                                            panelTitle="Password Length"
                                                                            panelBody={
                                                                                <React.Fragment>
                                                                                    <NumberInput
                                                                                        name="MinPasswordLength"
                                                                                        defaultValue={values.MinPasswordLength}
                                                                                        step={1}
                                                                                        min={values.MinForMinPasswordLength}
                                                                                        precision={0}
                                                                                        value={values.MinPasswordLength}
                                                                                        error={errors.MinPasswordLength}
                                                                                        onChangeField={onChangeField}
                                                                                        label="Minimum Length"
                                                                                        translation={translation}
                                                                                        inputSize={8}
                                                                                        required
                                                                                    />
                                                                                    <NumberInput
                                                                                        name="MaxPasswordLength"
                                                                                        defaultValue={values.MaxPasswordLength}
                                                                                        step={1}
                                                                                        max={values.MaxForMaxPasswordLength}
                                                                                        precision={0}
                                                                                        value={values.MaxPasswordLength}
                                                                                        error={errors.MaxPasswordLength}
                                                                                        onChangeField={onChangeField}
                                                                                        label="Maximum Length"
                                                                                        translation={translation}
                                                                                        inputSize={8}
                                                                                        required
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                        <Panel key={"Password Character Requirements" + values.AppId}
                                                                            translation={translation}
                                                                            panelTitle="Password Character Requirements"
                                                                            panelBody={
                                                                                <React.Fragment>
                                                                                    <Radio
                                                                                        name="PasswordCharacterRequirementType"
                                                                                        value={values.PasswordCharacterRequirementType}
                                                                                        options={PASSWORD_CHARACTER_REQUIREMENT_TYPES}
                                                                                        onChange={onChange}
                                                                                        labelSize={LABEL_SIZE}
                                                                                        inputSize={INPUT_SIZE}
                                                                                        label="Requirement Type"
                                                                                        translation={translation}
                                                                                    />
                                                                                    <div className="mb-4"></div>

                                                                                    {values.PasswordCharacterRequirementType === DEFAULT &&
                                                                                        <div className="default-password-policy">
                                                                                            <p> {this.getTranslationText("The password contains characters from at least three of the following Character Categories:")} </p>
                                                                                            <li> {CHAR_TEXT.UPPERCASE} </li>
                                                                                            <li> {CHAR_TEXT.LOWERCASE} </li>
                                                                                            <li> {CHAR_TEXT.NUMERIC} </li>
                                                                                            <li> {CHAR_TEXT.SPECIAL} </li>
                                                                                        </div>
                                                                                    }

                                                                                    {values.PasswordCharacterRequirementType === CUSTOM &&
                                                                                        <Table className="custom-password-policy" size="sm">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th></th>
                                                                                                    <th><Label className="required"> {this.getTranslationText("Minimum Length")} </Label></th>
                                                                                                    <th><Label className="required"> {this.getTranslationText("Maximum Length")} </Label></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>{CHAR_TEXT.UPPERCASE}</td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MinUppercaseCharLength"
                                                                                                            defaultValue={values.MinUppercaseCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MinUppercaseCharLength}
                                                                                                            error={errors.MinUppercaseCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MaxUppercaseCharLength"
                                                                                                            defaultValue={values.MaxUppercaseCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MaxUppercaseCharLength}
                                                                                                            error={errors.MaxUppercaseCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{CHAR_TEXT.LOWERCASE}</td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MinLowercaseCharLength"
                                                                                                            defaultValue={values.MinLowercaseCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MinLowercaseCharLength}
                                                                                                            error={errors.MinLowercaseCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MaxLowercaseCharLength"
                                                                                                            defaultValue={values.MaxLowercaseCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MaxLowercaseCharLength}
                                                                                                            error={errors.MaxLowercaseCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{CHAR_TEXT.NUMERIC}</td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MinNumericCharLength"
                                                                                                            defaultValue={values.MinNumericCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MinNumericCharLength}
                                                                                                            error={errors.MinNumericCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MaxNumericCharLength"
                                                                                                            defaultValue={values.MaxNumericCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MaxNumericCharLength}
                                                                                                            error={errors.MaxNumericCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{CHAR_TEXT.SPECIAL}</td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MinSpecialCharLength"
                                                                                                            defaultValue={values.MinSpecialCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MinSpecialCharLength}
                                                                                                            error={errors.MinSpecialCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <NumberInput
                                                                                                            className="mb-0"
                                                                                                            name="MaxSpecialCharLength"
                                                                                                            defaultValue={values.MaxSpecialCharLength}
                                                                                                            step={1}
                                                                                                            min={0}
                                                                                                            precision={0}
                                                                                                            value={values.MaxSpecialCharLength}
                                                                                                            error={errors.MaxSpecialCharLength}
                                                                                                            onChangeField={onChangeField}
                                                                                                            labelSize={FIELD_SIZE.NONE}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    }
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <PageFooter>
                                                <Button color="primary" type="button" onClick={() => (this.setPasswordLength(values), isValidForm(FORM_VALIDATIONS(getStaticText), values)) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                                <ModalConfirm
                                                    translation={translation}
                                                    isOpen={modal.modalState === "Submit"}
                                                    contentHeader="Save Password Policy Confirmation"
                                                    contentBody={"Are you sure to save the password policy? By doing so, the previous password policy will be discarded."}
                                                    confirmCallback={() => { modal.toggleModal("Submit"); submitForm(); }}
                                                    cancelCallback={() => modal.toggleModal("Submit")}
                                                />
                                            </PageFooter>
                                        </React.Fragment>
                                    )
                                }}
                            </SMARTForm>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}

export default withSMARTWrapper(PasswordPolicy);