import React from 'react';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';

// HoC: To provide direction context
export const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ currLang, changeLanguage, supportedLangResponse, isLangLoaded }) => {
                        return (
                            <WrappedComponent
                                context={{ currLang, changeLanguage, supportedLangResponse, isLangLoaded }}
                                {...this.props}
                            />);
                    }}
                </DirectionContextConsumer>
            );
        }
    }
    return DirectionOnPageContext;
};