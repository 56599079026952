export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Feature",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Feature"
    },
    {
        "DefaultLanguageValue": "Permission Name",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "PermissionName"
    },
    {
        "DefaultLanguageValue": "Permission Type",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "PermissionType"
    },
    {
        "DefaultLanguageValue": "Monitor Permission",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "MonitorPermission"
    },
    {
        "DefaultLanguageValue": "Inbox",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "Inbox"
    },
    {
        "DefaultLanguageValue": "Enquiry",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "Enquiry"
    },
    {
        "DefaultLanguageValue": "Payment Inbox",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "PaymentInbox"
    },
    {
        "DefaultLanguageValue": "Report Generation",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "ReportGeneration"
    },
    {
        "DefaultLanguageValue": "Admin",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "Admin"
    },
    {
        "DefaultLanguageValue": "Dashboard View",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "DashboardView"
    },
    {
        "DefaultLanguageValue": "Inbox View",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "InboxView"
    },
    {
        "DefaultLanguageValue": "Verification Workflow",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "VerificationWorkflow"
    },
    {
        "DefaultLanguageValue": "Approval Workflow",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "ApprovalWorkflow"
    },
    {
        "DefaultLanguageValue": "Payment Inbox View",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "PaymentInboxView"
    },
    {
        "DefaultLanguageValue": "Application enquiry",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "Applicationenquiry"
    },
    {
        "DefaultLanguageValue": "Licence Enquiry",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "LicenceEnquiry"
    },
    {
        "DefaultLanguageValue": "Licence Management",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "LicenceManagement"
    },
    {
        "DefaultLanguageValue": "Report Generation (Licence Report)",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "ReportGenerationLicenceReport"
    },
    {
        "DefaultLanguageValue": "Licence Application Configuration",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "LicenceApplicationConfiguration"
    },
    {
        "DefaultLanguageValue": "Licence Configuration",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "LicenceConfiguration"
    },
    {
        "DefaultLanguageValue": "Language Admin Configuration",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "LanguageAdminConfiguration"
    },
    {
        "DefaultLanguageValue": "System Configuration",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "FeatureName",
        "TranslationKey": "SystemConfiguration"
    },
    {
        "DefaultLanguageValue": "Read",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "PermissionType",
        "TranslationKey": "Read"
    },
    {
        "DefaultLanguageValue": "Write",
        "Scope": "MePermission",
        "Category": "Form",
        "SubCategory": "PermissionType",
        "TranslationKey": "Write"
    }
]