export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Contact Number",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "ContactNumber"
    },
    {
        "DefaultLanguageValue": "Fax Number",
        "Scope": "MeOrganisation",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "FaxNumber"
    },
    {
        "DefaultLanguageValue": "'Effective From' must be on or before 'Effective To' date",
        "Scope": "MeOrganisation",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "EffectiveFromOnBeforeEffectiveTo"
    }
]