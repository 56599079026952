export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Minimum Length",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "MinimumLength"
    },
    {
        "DefaultLanguageValue": "Maximum Length",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "MaximumLength"
    },
    {
        "DefaultLanguageValue": "Requirement Type",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "RequirementType"
    },
    {
        "DefaultLanguageValue": "Default",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "RequirementType",
        "TranslationKey": "Default"
    },
    {
        "DefaultLanguageValue": "Custom",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "RequirementType",
        "TranslationKey": "Custom"
    },
    {
        "DefaultLanguageValue": "The password contains characters from at least three of the following Character Categories:",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "Message",
        "TranslationKey": "PasswordCharacteristics"
    },
    {
        "DefaultLanguageValue": "Uppercase Character",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "CharacterType",
        "TranslationKey": "UppercaseCharacter"
    },
    {
        "DefaultLanguageValue": "Lowercase Character",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "CharacterType",
        "TranslationKey": "LowercaseCharacter"
    },
    {
        "DefaultLanguageValue": "Numeric Character",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "CharacterType",
        "TranslationKey": "NumericCharacter"
    },
    {
        "DefaultLanguageValue": "Special Character",
        "Scope": "PasswordPolicy",
        "Category": "Form",
        "SubCategory": "CharacterType",
        "TranslationKey": "SpecialCharacter"
    }
]