import React from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import { fetchRequest, getParams }from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';

class View extends React.Component {

	constructor(props) {
        super(props);
        this.state={
            isLoaded : false
        }
    }
    
    getUserNames = (data) => {
        let names = [];
        if(data.length > 0){
            data.map(x => names.push(x.Username));
        }
        return names;
    }

    renderUserList = () => {
        const users = this.state.values.Users.map((x, index) =>
        {
            return(
                <React.Fragment key={index}>
                    <Label>{x}</Label> <br/>
                </React.Fragment>
            );
        })

        return users;
    }

	async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        
        loader.start();
        let response = await fetchRequest(URL+"UserGroup/GetUserGroupUsers/" + this.props.match.params.id, getParams(), false);
        if(response.success && response.body.IsSuccess){
            let data = response.body.Data;
            let users = this.getUserNames(data.Users);
            this.setState({
                values: {
                    GroupName: data.GroupName,
                    Description: data.Description || "-",
                    Users: users || ""
                },
                isLoaded: true
            });
        }
        else{
            pageNotFound();
        }
        loader.done();
	}

	render() {
            return(
                <React.Fragment>
                    {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} title="User Group Users Details">
                    <React.Fragment>
                        <Row className="body-content">
                            <Col md={2}>
                                <Label className="label-default">Group Name</Label>
                            </Col>
                            <Col md={10}>
                                <Label>{this.state.values.GroupName}</Label>
                            </Col>

                            <Col md={2}>
                                <Label className="label-default">Description</Label>
                            </Col>
                            <Col md={10}>
                                <Label>{this.state.values.Description}</Label>
                            </Col>

                            <Col md={2}>
                                <Label className="label-default">Users</Label>
                            </Col>
                            <Col>
                                {
                                   this.renderUserList()
                                }
                            </Col>
                        </Row>
                        <PageFooter>
                            <Button color="primary" type="button" onClick={this.props.history.goBack}>Back</Button>
                        </PageFooter>
                    </React.Fragment>
                    </Layout>) 
                    :null}
                </React.Fragment>
            )
	}
}


export default withPageNotFound(withLoader(withRouter(View)));