export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "List of Roles",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfRoles"
    },
    {
        "DefaultLanguageValue": "New Role",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "NewRole"
    },
    {
        "DefaultLanguageValue": "Update Role",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UpdateRole"
    },
    {
        "DefaultLanguageValue": "Role Details",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "RoleDetails"
    }
]