import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { URL } from '../../Shared/SmartMe/Constants';
import { withFormAlert } from '../../Shared//Forms';
import { selectListMapper } from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withLoader } from '../../Shared/Loading';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'Role/CreateRole';

class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        let appIDRequest = await fetchRequest(URL + "App/GetAppsByUserId", getParams(), false);

        if (appIDRequest.success && appIDRequest.body.IsSuccess) {
            let selection = appIDRequest.body.Data;
            const mappedSelection = selectListMapper(selection, "AppId", "AppName");

            this.setState({
                values: {
                    AppId: "",
                    RoleName: "",
                    DisplayRoleName: "",
                    Description: "",
                    IsOrganisationSpecific: "",
                    PermissionList: ""
                },
                AppIds: mappedSelection,
                isLoaded: true
            });
        }
        else {
            const { formAlert } = this.props;
            formAlert.showAlert(appIDRequest.body.messages || appIDRequest.body.Messages);
        }
        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <React.Fragment>
                {this.state.isLoaded &&
                    <Form
                        values={this.state.values}
                        server_url={SERVER_URL}
                        title={"New Role"}
                        contentHeader={MODAL_TITLE(getStaticText).CREATE.HEADER}
                        contentBody={MODAL_TITLE(getStaticText).CREATE.BODY}
                        prevState={this.state} 
                        formAlert = {this.props.formAlert}/>
                }
            </React.Fragment>
        )
    }
}
export default withFormAlert(withLoader(withRouter(New)));