import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { serverTableNavigateTo } from '../../Shared/SmartMe/Actions';
import { withSMARTWrapper } from '../../Shared/Forms';
import Title from '../../Title';
import { URL } from '../../Shared/SmartMe/Constants';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';

const PENDING_USER_REQUESTS_GRID_URL =
    URL + 'UserActivationRequest/ListPendingUserRequests';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UATranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;
        const ID = { ID: rowData.Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => serverTableNavigateTo('/UserApproval/AmendUserRequest/', ID)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).AMEND}
                />
                <MobileCardActionButton
                    onClick={() => serverTableNavigateTo('/UserApproval/', ID)}
                    icon={faCheckSquare}
                    label={BUTTON_TITLE(getStaticText).REVIEW}
                />
            </React.Fragment>
        )
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UATranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="Pending User Requests" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ServerSideDataTable
                                translation={translation}
                                url={PENDING_USER_REQUESTS_GRID_URL}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No pending user requests found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        Username: { title: 'Username', width: '10%', DBkey: 'Username' },
                                        Email: { title: 'Email', width: '10%', DBkey: 'Email' },
                                        FirstName: { title: 'First Name', width: '8%', DBkey: 'FirstName' },
                                        MiddleName: { title: 'Middle Name', width: '10%', DBkey: 'MiddleName' },
                                        LastName: { title: 'Last Name', width: '10%', DBkey: 'LastName' },
                                        // UserType: { title: 'User Type', width: '10%', DBkey: 'UserType' },
                                        CreatedDate: { title: 'Created Date', width: '10%', DBkey: 'CreatedDate' }
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        <ActionButton tooltip={TOOLTIP_TEXT(getStaticText).REVIEW_USER_REQUEST} color="forward" onClick={() => serverTableNavigateTo('/UserApproval/', rowData)}><FontAwesomeIcon icon={faCheckSquare} /></ActionButton>
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);