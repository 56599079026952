import { GET_COUNTRIES_URL, GET_COUNTRY_CODES_URL, GET_SALUTATIONS_URL } from "../../../App/AppSettings";
import { fetchRequest, getParams } from "../../Actions";
import { toastError } from "../../Forms";

export const constructNumber = (countryCode, number) => {
    if (isEmptyValue(countryCode) && isEmptyValue(number)) {
        return null;
    }
    else {
        return "+" + countryCode + " " + number;
    }
}

export const isEmptyValue = (value) => {
    return (value === null || value === undefined || value === '');
}

export const getSalutations = async (loader) => {
    const response = await fetchRequest(GET_SALUTATIONS_URL, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;

    const salutationsOptions = [];

    if (response.success && IsSuccess) {
        const salutations = Data;
        salutations.map(salutation =>
            salutationsOptions.push({
                value: salutation.CodeValue,
                label: salutation.CodeDescription
            })
        );

    } else {
        loader.done();
        toastError(Messages);
    }

    return salutationsOptions;
};

export const getCountries = async (loader) => {
    const response = await fetchRequest(GET_COUNTRIES_URL, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;

    const countriesOptions = [];

    if (response.success && IsSuccess) {
        const countries = Data;
        countries.map(country =>
            countriesOptions.push({
                value: country.CodeDescription,
                label: country.CodeDescription
            })
        );
    } else {
        loader.done();
        toastError(Messages);
    }

    return countriesOptions;
}

export const getCountryCodes = async (loader) => {
    const response = await fetchRequest(GET_COUNTRY_CODES_URL, getParams(), false);
    const { IsSuccess, Data, Messages } = response.body;

    const countryCodesOptions = [];

    if (response.success && IsSuccess) {
        const countryCodes = Data;
        countryCodes.map(countryCode =>
            countryCodesOptions.push({
                value: countryCode.CodeValue,
                label: countryCode.CodeTitle + " (+" + countryCode.CodeValue + ")"
            })
        );
    } else {
        loader.done();
        toastError(Messages);
    }

    return countryCodesOptions;
}