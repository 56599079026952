import React from 'react';
import { fetchRequest, getParams } from '../../../Shared/Actions';
import { toastError } from '../../../Shared/Forms/Toaster';
import { getTranslationOptions } from '../../../Shared/Forms/FormUtils';

//App Constants
import { SERVER_BASE_URL } from '../../../App/AppSettings';
const GET_GENDERS_URL = SERVER_BASE_URL + 'Code/GetGenders';

class GenderOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: ""
        };

        this.onGenderClick = this.onGenderClick.bind(this);
    }

    async componentDidMount() {
        this.GenderSetup();
    }

    async GenderSetup() {
        const response = await fetchRequest(GET_GENDERS_URL, getParams(), false);
        const { IsSuccess, Data, Messages } = response.body;
			
        if(response.success && IsSuccess){
            const genders = Data;
            const gendersoptions = [];
            genders.map(g =>
                gendersoptions.push({
                    value: g.CodeValue,
                    label: g.CodeDescription
                })
            );

            const { translation } = this.props;
            const getStaticText = translation && translation.getStaticText;
            const translationKeys = translation && translation.translationKeys;

            this.setState({
                gendersoptions: getStaticText && translationKeys ? getTranslationOptions(gendersoptions, getStaticText, translationKeys) : gendersoptions
            });
        } else {
            toastError(Messages)
        }
        //#endregion

        const gender = this.props.gender;
        if (gender !== undefined) {
            this.setState({
                selected: gender
            })
        }
    }

    onGenderClick(selected) {
        this.setState({ selected });
        this.props.setGender(selected);
    }

    render() {
        let genders = []
        const gendersoptions = this.state.gendersoptions;


        if (gendersoptions !== undefined) {
            for (let i = 0; i < gendersoptions.length; i++) {
                const { value, label } = gendersoptions[i];
                genders.push(
                    <span key={i}
                        className={this.state.selected === value ? "option active" : "option"}
                        onClick={() => this.onGenderClick(value)}
                        value={value}> {label}
                    </span>
                )
            }
        }

        return genders;
    }
}

export default GenderOptions;