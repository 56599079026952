import { FormTranslationKeys } from './FormTranslationKeys';
import { WizardTitleTranslationKeys } from './WizardTitleTranslationKeys';
import { TitleTranslationKeys } from './TitleTranslationKeys';
import { SubTitleTranslationKeys } from './SubTitleTranslationKeys';
import { TableTranslationKeys } from './TableTranslationKeys';
import { ModalTranslationKeys } from './ModalTranslationKeys';

export const TranslationKeys = FormTranslationKeys
    .concat(WizardTitleTranslationKeys)
    .concat(TitleTranslationKeys)
    .concat(SubTitleTranslationKeys)
    .concat(TableTranslationKeys)
    .concat(ModalTranslationKeys);