import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE, ADDRESS, DEFAULT_ADDRESS_TYPE, OPTIONAL_ADDRESS_TYPE, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { URL } from '../../Shared/SmartMe/Constants';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { SMARTForm, Plaintext, withSMARTWrapper } from '../../Shared/Forms';
import SubSection from '../../Section/SubSection';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { constructNumber } from '../../Shared/SmartMe/Utils/FormUtils';
import AssignGlobalUserGroupsToUser from '../../Shared/SmartMe/Components/AssignGlobalUserGroupsToUser';
import AssignUserGroupsToUser from '../../Shared/SmartMe/Components/AssignUserGroupsToUser';
import { getUserGroups } from '../../Shared/SmartMe/Components/AssignUserGroupsToUser/Utils';
// import AssignRolesToUser from '../../Shared/SmartMe/Components/AssignRolesToUser';
// import { getRoles, userRolesMapper } from '../../Shared/SmartMe/Components/AssignRolesToUser/Utils';
import history from '../../Shared/History';
import { BUTTON_TITLE } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationObjects, getTranslationValue } from '../../Shared/Forms/FormUtils';


const APP_ADMIN_WIZARD_TAB_NAMES = {
    USER_INFORMATION: "User Information",
    ASSIGN_GLOBAL_USER_GROUPS: "Assign Global User Groups",
    ASSIGN_USER_GROUPS: "Assign User Groups"
    // ASSIGN_ROLES: "Assign Roles"
}

// const ORGANISATION_ADMIN_WIZARD_TAB_NAMES = {
//     USER_INFORMATION: "User Information",
//     ASSIGN_USER_GROUPS: "Assign User Groups"
//     // ASSIGN_ROLES: "Assign Roles"
// }
const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;

const FORM_INITIAL_VALUES = {
    Id: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Gender: '',
    Username: '',
    Email: '',
    DateOfBirth: '',
    Salutation: '',
    Nationality: '',
    AltEmailAddress: '',
    PrimaryContact: '',
    MobileNumber: {
        CountryCode: '',
        Number: ''
    },
    OfficeNumber: {
        CountryCode: '',
        Number: ''
    },
    HomeNumber: {
        CountryCode: '',
        Number: ''
    },
    FaxNumber: {
        CountryCode: '',
        Number: ''
    },
    IdNumbersDisplayArr: '',
    IdNumbers: '',
    Address: {
        AddressType: DEFAULT_ADDRESS_TYPE,
        PostalCode: '',
        Country: '', // Only applicable to Address Type: Overseas
        City: '',
        State: '',
        AddressLine1: ADDRESS.ADDRESS_FIELDS.LINE1,
        AddressLine2: ADDRESS.ADDRESS_FIELDS.LINE2
    },
    IsAppAdmin: false,
    IsOrganisationAdmin: false,
    OrganisationNames: [],
    GlobalUserGroups: [],
    UserGroups: []
};

const LABEL_SIZE = 4;

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UserTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();

        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(URL + "User/GetUser/" + state.ID, getParams(), false);
            // let userRolesResp = await fetchRequest(URL + "User/GetUserRoles/" + this.props.match.params.id, getParams(), false);

            const { IsSuccess, Data } = response.body;

            if (response.success && IsSuccess
                // && userRolesResp.success && userRolesResp.body.IsSuccess
            ) {

                //map user roles to grid values
                // const userRoles = userRolesResp.body.Data;
                // let rolesResponse = await getRoles(loader);
                // let mappedUserRoles = userRolesMapper(userRoles.RoleToUser, rolesResponse.allRoles);

                const profileData = Data.Profile;
                const middleName = profileData.MiddleName;

                this.setState({
                    gender: profileData.Gender,
                    values: {
                        Id: Data.Id,
                        UserType: Data.UserType,
                        FirstName: profileData.FirstName,
                        MiddleName: (middleName === '' || middleName === "null" || middleName === null) ? "" : middleName,
                        LastName: profileData.LastName,
                        DateOfBirth: profileData.DateOfBirth,
                        Gender: profileData.Gender,
                        Username: Data.Username,
                        Email: Data.Email,
                        Salutation: profileData.Salutation,
                        Nationality: profileData.Nationality,
                        AltEmailAddress: profileData.AltEmailAddress,
                        PrimaryContact: profileData.PrimaryContact,
                        MobileNumber: profileData.MobileNumber,
                        OfficeNumber: profileData.OfficeNumber,
                        HomeNumber: profileData.HomeNumber,
                        FaxNumber: profileData.FaxNumber,
                        Address: profileData.Address,
                        IdNumbers: profileData.IdNumbers === '' ? [] : JSON.parse(profileData.IdNumbers),
                        IsAppAdmin: Data.IsAppAdmin,
                        IsOrganisationAdmin: Data.IsOrganisationAdmin,
                        OrganisationNames: Data.OrganisationNames,
                        GlobalUserGroups: Data.GlobalUserGroups,
                        UserGroups: Data.UserGroups
                    },
                    // roles: mappedUserRoles
                });
            }
            else {
                pageNotFound();
            }
        }


        loader.done();
    }

    getTranslationText = (text, formatter) => {
		const { getStaticText } = this.props;

		const translatedText = getTranslationValue(text, getStaticText, UserTranslationKeys);
		return formatter === undefined ? translatedText : _.template(translatedText)(formatter);
	}

    render() {
        const { getStaticText } = this.props;
        const { MobileNumber, OfficeNumber, FaxNumber, HomeNumber } = this.state.values;

        const translation = {
            getStaticText: getStaticText,
            translationKeys: UserTranslationKeys
        };

        let mobileNumberCountryCode = MobileNumber === null ? null : MobileNumber.CountryCode;
        let mobileNumberNumber = MobileNumber === null ? null : MobileNumber.Number;
        let officeNumberCountryCode = OfficeNumber === null ? null : OfficeNumber.CountryCode;
        let officeNumberNumber = OfficeNumber === null ? null : OfficeNumber.Number;
        let faxNumberCountryCode = FaxNumber === null ? null : FaxNumber.CountryCode;
        let faxNumberNumber = FaxNumber === null ? null : FaxNumber.Number;
        let homeNumberCountryCode = HomeNumber === null ? null : HomeNumber.CountryCode;
        let homeNumberNumber = HomeNumber === null ? null : HomeNumber.Number;
        let altEmailAddress = this.state.values.AltEmailAddress;
        let idArray = [];

        if (this.state !== null && this.state.values.IdNumbers) {
            this.state.values.IdNumbers.forEach((idNumber, idx) => {
                const country = getTranslationValue(idNumber.IdCountry, getStaticText, UserTranslationKeys);
				const idValue = this.getTranslationText("<%= IdNumber %> (Issuing Country: <%= IdCountry %>)",
					{ IdNumber: idNumber.IdNumber, IdCountry: country });

                idArray.push(
                    <Plaintext
                        label={idNumber.IdType}
                        name="Profile.IdNumber"
                        value={idValue}
                        labelSize={LABEL_SIZE}
                        key={idx}
                        translation={translation}
                        translateValue={true}
                    />
                )
            })
        }

        //const { IsAppAdmin } = this.state.values;
        //const WIZARD_TAB_NAMES = IsAppAdmin ? APP_ADMIN_WIZARD_TAB_NAMES : ORGANISATION_ADMIN_WIZARD_TAB_NAMES;
        const WIZARD_TAB_NAMES = getTranslationObjects(APP_ADMIN_WIZARD_TAB_NAMES, getStaticText, UserTranslationKeys);

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} translation={translation} title="User Details">
                    <SMARTForm
                        sectionNames={WIZARD_TAB_NAMES}
                        defaultSection={WIZARD_TAB_NAMES.USER_INFORMATION}
                    >
                        {({ toggleSection, sectionState, currentStep, prevStep, nextStep }) => (
                            <>
                                <Row className="body-content">
                                    <Col className="body-content smart-me-content">
                                        <SectionWrapper
                                            type={SECTION_LAYOUT_TYPE}
                                            tabs={WIZARD_TAB_NAMES}
                                            sectionState={sectionState}
                                            toggleSection={toggleSection}>

                                            <Section
                                                type={SECTION_LAYOUT_TYPE}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.USER_INFORMATION}
                                                key={WIZARD_TAB_NAMES.USER_INFORMATION}
                                            >
                                                <SubSection orientation="left" translation={translation} title="Account Info">
                                                    <Plaintext
                                                        label="User Type"
                                                        name="UserType"
                                                        value={this.state.values.UserType}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                    <Plaintext
                                                        label="Username"
                                                        name="Username"
                                                        value={this.state.values.Username}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Organisation"
                                                        name="Organisation"
                                                        value={this.state.values.OrganisationNames}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                </SubSection>
                                                <SubSection orientation="left" translation={translation} title="Personal Details">
                                                    <Plaintext
                                                        label="Salutation"
                                                        name="Salutation"
                                                        value={this.state.values.Salutation}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="First Name"
                                                        name="FirstName"
                                                        value={this.state.values.FirstName}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Middle Name"
                                                        name="MiddleName"
                                                        value={this.state.values.MiddleName}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Last Name"
                                                        name="LastName"
                                                        value={this.state.values.LastName}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Gender"
                                                        name="Gender"
                                                        value={this.state.values.Gender}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                    <Plaintext
                                                        label="Date Of Birth"
                                                        name="DateOfBirth"
                                                        value={this.state.values.DateOfBirth}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Nationality"
                                                        name="Nationality"
                                                        value={this.state.values.Nationality}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                    {idArray}
                                                </SubSection>

                                                <SubSection orientation="left" translation={translation} title="Contact Info">
                                                    <Plaintext
                                                        label="Primary Email Address"
                                                        name="Email"
                                                        value={this.state.values.Email}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    {altEmailAddress !== null &&
                                                        <Plaintext
                                                            label="Alternate Email Address"
                                                            name="AltEmailAddress"
                                                            value={altEmailAddress}
                                                            labelSize={LABEL_SIZE}
                                                            translation={translation}
                                                        />
                                                    }
                                                    <Plaintext
                                                        label="Primary Contact"
                                                        name="Primary Contact"
                                                        value={`${this.state.values.PrimaryContact} Number`}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                    <Plaintext
                                                        label="Mobile Number"
                                                        name="Mobile Number"
                                                        value={constructNumber(mobileNumberCountryCode, mobileNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Office Number"
                                                        name="Office Number"
                                                        value={constructNumber(officeNumberCountryCode, officeNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Home Number"
                                                        name="Home Number"
                                                        value={constructNumber(homeNumberCountryCode, homeNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                    <Plaintext
                                                        label="Fax Number"
                                                        name="Fax Number"
                                                        value={constructNumber(faxNumberCountryCode, faxNumberNumber)}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                    />
                                                </SubSection>


                                                <SubSection orientation="left" translation={translation} title="Address Details">
                                                    <Plaintext
                                                        label="Address Type"
                                                        name="Profile.Address.AddressType"
                                                        value={this.state.values.Address.AddressType}
                                                        labelSize={LABEL_SIZE}
                                                        translation={translation}
                                                        translateValue={true}
                                                    />
                                                    {this.state.values.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                                        <>
                                                            <Plaintext
                                                                label="ZIP / Postal Code"
                                                                name="Profile.Address.PostalCode"
                                                                value={this.state.values.Address.PostalCode}
                                                                labelSize={LABEL_SIZE}
                                                                translation={translation}
                                                            />
                                                            {this.state.values.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                                <>
                                                                    <Plaintext
                                                                        label="Block / House No."
                                                                        name="Profile.Address.AddressLine1.BlockNo"
                                                                        value={this.state.values.Address.AddressLine1.BlockNo}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                    <Plaintext
                                                                        label="Street Name"
                                                                        name="Profile.Address.AddressLine1.StreetName"
                                                                        value={this.state.values.Address.AddressLine1.StreetName}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                    {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.LevelNo !== null &&
                                                                        <Plaintext
                                                                            label="Level"
                                                                            name="Profile.Address.AddressLine2.LevelNo"
                                                                            value={this.state.values.Address.AddressLine2.LevelNo}
                                                                            labelSize={LABEL_SIZE}
                                                                            translation={translation}
                                                                        />
                                                                    }
                                                                    {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.UnitNo !== null &&
                                                                        <Plaintext
                                                                            label="Unit No."
                                                                            name="Profile.Address.AddressLine2.UnitNo"
                                                                            value={this.state.values.Address.AddressLine2.UnitNo}
                                                                            labelSize={LABEL_SIZE}
                                                                            translation={translation}
                                                                        />
                                                                    }
                                                                    {this.state.values.Address.AddressLine2 !== null && this.state.values.Address.AddressLine2.BuildingName !== null &&
                                                                        <Plaintext
                                                                            label="Building Name"
                                                                            name="Profile.Address.AddressLine2.BuildingName"
                                                                            value={this.state.values.Address.AddressLine2.BuildingName}
                                                                            labelSize={LABEL_SIZE}
                                                                            translation={translation}
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                            {this.state.values.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                                                <>
                                                                    <Plaintext
                                                                        label="Country"
                                                                        name="Profile.Address.Country"
                                                                        value={this.state.values.Address.Country}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                        translateValue={true}
                                                                    />
                                                                    {this.state.values.Address.City !== null &&
                                                                        <Plaintext
                                                                            label="City"
                                                                            name="Profile.Address.City"
                                                                            value={this.state.values.Address.City}
                                                                            labelSize={LABEL_SIZE}
                                                                            translation={translation}
                                                                        />
                                                                    }
                                                                    <Plaintext
                                                                        label="State / Province / Region"
                                                                        name="Profile.Address.State"
                                                                        value={this.state.values.Address.State}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                    <Plaintext
                                                                        label="Address Line 1"
                                                                        name="Profile.Address.AddressLine1.AddressLine1"
                                                                        value={this.state.values.Address.AddressLine1.AddressLine1}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                    <Plaintext
                                                                        label="Address Line 2"
                                                                        name="Profile.Address.AddressLine2.AddressLine2"
                                                                        value={this.state.values.Address.AddressLine2.AddressLine2}
                                                                        labelSize={LABEL_SIZE}
                                                                        translation={translation}
                                                                    />
                                                                </>

                                                            }
                                                        </>
                                                    }
                                                </SubSection>
                                            </Section>
                                            {/* {IsAppAdmin && */}
                                                <Section
                                                    type={SECTION_LAYOUT_TYPE}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.ASSIGN_GLOBAL_USER_GROUPS}
                                                    key={WIZARD_TAB_NAMES.ASSIGN_GLOBAL_USER_GROUPS}
                                                >
                                                    <AssignGlobalUserGroupsToUser
                                                        data={this.state.values.GlobalUserGroups}
                                                        isUpdatePage={false}
                                                        translation={translation}
                                                    />
                                                </Section>
                                            {/* } */}
                                            <Section
                                                type={SECTION_LAYOUT_TYPE}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.ASSIGN_USER_GROUPS}
                                                key={WIZARD_TAB_NAMES.ASSIGN_USER_GROUPS}
                                            >
                                                <AssignUserGroupsToUser
                                                    data={getUserGroups(this.state.values.UserGroups, translation)}
                                                    isUpdatePage={false}
                                                    translation={translation}
                                                />
                                            </Section>
                                            {/* <Section
                                                type={SECTION_LAYOUT_TYPE}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.USER_ROLES}
                                                key={WIZARD_TAB_NAMES.USER_ROLES}
                                            >
                                                {this.state.roles &&
                                                    <AssignRolesToUser
                                                        data={this.state.roles}
                                                        isUpdatePage={false}
                                                    />
                                                }
                                            </Section> */}
                                        </SectionWrapper>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PageFooter>
                                            {/* Wizard Previous Button */}
                                            {((SECTION_LAYOUT_TYPE === SECTION_WRAPPER_TYPES.WIZARD) && (currentStep !== 0)) ? <Button color="backward" type="button" onClick={prevStep}>{BUTTON_TITLE(getStaticText).PREVIOUS}</Button> : ""}

                                            {/* Wizard Next Button */}
                                            {((SECTION_LAYOUT_TYPE === SECTION_WRAPPER_TYPES.WIZARD) && (currentStep !== Object.keys(WIZARD_TAB_NAMES).length - 1)) ? <Button color="forward" type="button" onClick={nextStep}>{BUTTON_TITLE(getStaticText).NEXT}</Button> : ""}

                                            {/* Back Button */}
                                            <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                                        </PageFooter>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        )
    }
}


export default compose(withSMARTWrapper, withRouter)(View);