import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { Plaintext, SelectList } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { URL } from '../../Shared/SmartMe/Constants';
import { withPageNotFound } from '../../Shared/Error';
import history from '../../Shared/History';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';
import { BUTTON_TITLE } from '../../Localization';

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const RoleTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const LabelSize = 4;

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    convertToArrayInt = (data) => {
        let values = [];
        if (data != null && data.length > 0) {
            let strArr = data.split(',');
            strArr.map(x => values.push(x));
        }
        return values;
    }

    getPermissionValue = () => {
        return this.state.values.DisplayPermissionList;
    }

    getPermissionList = () => {
        let permissions = [];
        this.state.values.DisplayPermissionList.forEach((x, idx) => {
            var obj = { label: x, value: x }
            permissions.push(obj);
        });
        return permissions;
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const { state } = history.location;

        loader.start();
        if (state === undefined || state.ID === undefined) {
            pageNotFound();
        }
        else {
            let response = await fetchRequest(URL + "Role/GetViewRole/" + state.ID, getParams(), false);
            if (response.success && response.body.IsSuccess) {
                let data = response.body.Data;

                this.setState({
                    values: {
                        RoleName: data.RoleName,
                        DisplayRoleName: data.DisplayRoleName,
                        Description: data.Description || "-",
                        AppId: data.DisplayAppId,
                        IsOrganisationSpecific: data.IsOrganisationSpecific === true ? "Yes" : "No",
                        Organisation: data.Organisation == null ? [] : data.Organisation,
                        DisplayPermissionList: this.convertToArrayInt(data.DisplayPermissionList),
                        DisplayOrganisation: data.DisplayOrganisation,
                        RoleGuid: data.RoleGuid
                    },
                    isLoaded: true
                });
            }
            else {
                pageNotFound();
            }

        }

        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: RoleTranslationKeys
        };

        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Role Details">
                        <React.Fragment>
                            <Row className="body-content">
                                <Col className="smart-me-content">
                                    <Plaintext
                                        label="Application"
                                        translation={translation}
                                        name="AppName"
                                        className="mb-1"
                                        value={this.state.values.AppId}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Role Name"
                                        translation={translation}
                                        name="RoleName"
                                        className="mb-1"
                                        value={this.state.values.RoleName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Role Guid"
                                        translation={translation}
                                        name="RoleGuid"
                                        className="mb-1"
                                        value={this.state.values.RoleGuid}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Display Role Name"
                                        translation={translation}
                                        name="DisplayRoleName"
                                        className="mb-1"
                                        value={this.state.values.DisplayRoleName}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Organisation Specific?"
                                        translation={translation}
                                        translateValue={true}
                                        name="IsOrganisationSpecific"
                                        className="mb-1"
                                        value={this.state.values.IsOrganisationSpecific}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Organisation"
                                        translation={translation}
                                        translateValue={true}
                                        name="DisplayOrganisation"
                                        className="mb-1"
                                        value={this.state.values.DisplayOrganisation}
                                        labelSize={LabelSize}
                                    />
                                    <Plaintext
                                        label="Description"
                                        translation={translation}
                                        name="Description"
                                        className="mb-1"
                                        value={this.state.values.Description}
                                        labelSize={LabelSize}
                                    />
                                    <SelectList
                                        name="Permission"
                                        value={this.getPermissionValue()}
                                        options={this.getPermissionList()}
                                        isMulti={true}
                                        isClearable={false}
                                        labelSize={LabelSize}
                                        label="Permission"
                                        translation={translation}
                                        isDisabled={true}
                                    />
                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="primary" type="button" onClick={this.props.history.goBack}>{BUTTON_TITLE(getStaticText).BACK}</Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}


export default withPageNotFound(withLoader(withRouter(View)));