import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT } from '../../../App/AppSettings';
import { FIELD_SIZE } from '../../Constants';
import { DateInput, DropdownList, Plaintext, SelectList, toastError } from '../../Forms';
import { getTranslationValue, isDateOverlapped } from '../../Forms/FormUtils';
import { fetchRequest, getParams } from '../../../Shared/Actions';
import { URL, IS_ORGANISATION_SPECIFIC } from '../../SmartMe/Constants';
import { ERROR_MSG, BUTTON_TITLE } from '../../../Localization';


const FORM_VALIDATIONS_ORG = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const effectiveEndDateValidation = ErrorMsgList.EED_BEFORE_ESD;

    return Yup.object().shape({
        Organisation: Yup.string().required(requiredMsg),
        UserToOrganisationId: Yup.string().required(requiredMsg),
        EffectiveStartDate: Yup.date().required(requiredMsg),
        EffectiveEndDate: Yup.date().when('EffectiveStartDate', {
            is: val => val !== null,
            then: Yup.date().min(Yup.ref('EffectiveStartDate'), effectiveEndDateValidation),
        })
    });
}

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;
    const effectiveEndDateValidation = ErrorMsgList.EED_BEFORE_ESD;

    return Yup.object().shape({
        UserToOrganisationId: Yup.string().required(requiredMsg),
        EffectiveStartDate: Yup.date().required(requiredMsg),
        EffectiveEndDate: Yup.date().when('EffectiveStartDate', {
            is: val => val !== null,
            then: Yup.date().min(Yup.ref('EffectiveStartDate'), effectiveEndDateValidation),
        })
    });
}

const FORM_INITIAL_VALUES = {
    UserToOrganisationId: '',
    EffectiveStartDate: '',
    EffectiveEndDate: '',
    Organisation: '',
}

const GET_USERS_URL = URL + "User/GetUsersByOrganisation?organisationId=";

class AssignUserToUserGroupModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            userList: [],
            userListOptions: [],
            values: FORM_INITIAL_VALUES,
            isOrganisationSpecific: props.isOrganisationSpecific,
            selectedOrganisation: props.selectedOrganisation,
            organisationsOptions: props.organisationsOptions
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
        const { isOrganisationSpecific, selectedOrganisation } = this.state

        if (isOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO) {

            this.setState({
                ...this.state,
                userList: [],
                userListOptions: []
            });
        }
        else {
            await this.getUsers(selectedOrganisation);
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    clearField = (name, values, setFieldValue) => {
        values[name] = '';
        setFieldValue(name, '');
    }

    // Fn: Do not display errors if user wants validation to be seen after submitForm() is called
    displayErrors = (validateOnSubmit, submitCount, errors) => {
        return (validateOnSubmit && submitCount < 1) ? '' : errors;
    };

    resetForm = () => {
        this.setState({
            ...this.state,
            values: FORM_INITIAL_VALUES
        })

        this.props.clearEditItem();
    }

    setFields = (values) => {
        const { userList, selectedOrganisation, isOrganisationSpecific, organisationsOptions } = this.state;
        const { UserToOrganisationId, EffectiveEndDate, Organisation } = values;
        const { editItem } = this.props;

        const data = userList.find(data => data.Id === UserToOrganisationId);

        let orgVal = isOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO ? Organisation : selectedOrganisation;

        values.UserGroupToUserOrganisationId = editItem !== undefined ? editItem.data.UserGroupToUserOrganisationId : 0;
        values.Fullname = data.Fullname;
        values.Username = data.Username;
        values.Email = data.Email;
        values.StrEffectiveEndDate = EffectiveEndDate === "" ? "-" : EffectiveEndDate;
        //values.UserAppEntityOption = organisationsOptions.filter(opt => orgVal === opt.value);
        values.Organisation = orgVal;
        values.OrganisationName = organisationsOptions.filter(opt => orgVal === opt.value)[0]?.label;
    }

    getInitialValues = () => {
        const { editItem } = this.props;
        if (editItem) {
            const item = Object.assign({}, editItem.data);
            return item;
        }
        return FORM_INITIAL_VALUES;
    }

    getUsers = async (organisationValue) => {

        let appIDRequest = await fetchRequest(GET_USERS_URL + organisationValue, getParams(), false);
        if (appIDRequest.success && appIDRequest.body.IsSuccess) {

            let selection = appIDRequest.body.Data;
            const mappedSelection = this.dropListMapper(selection);

            if (this._isMounted) {
                this.setState({
                    ...this.state,
                    userList: selection,
                    userListOptions: mappedSelection,
                });
            }
        }
        else {
            toastError(appIDRequest.body.messages || appIDRequest.body.Messages);
        }

    }

    dropListMapper = (dataList) => {
        const { translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const username = getTranslationValue("Username", getStaticText, translationKeys);
        const email = getTranslationValue("Email", getStaticText, translationKeys);

        let options = [];
        dataList.forEach((data, index) => {
            const option =
            {
                key: index,
                text: data.Fullname,
                value: data.Id,
                content: (
                    <div>
                        <b>{data.Fullname}</b>
                        <div>{username}: {data.Username}</div>
                        <div>{email}: {data.Email}</div>
                    </div>
                )
            };
            options.push(option);
        })
        return options;
    }

    handleOpen = async () => {
        const { isOrganisationSpecific } = this.state
        const { editItem } = this.props;

        if (isOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO) {
            if (editItem) {
                await this.getUsers(editItem.data.Organisation);
            }
        }
    }

    render() {
        const { data, addItem, editItem, saveEditItem, translation } = this.props;
        const { isOrganisationSpecific, organisationsOptions, userListOptions, selectedOrganisation } = this.state;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const modalTitle = getTranslationValue(this.props.modalTitle, getStaticText, translationKeys);

        return (
            <Modal isOpen={this.props.modalState} toggle={() => { this.resetForm(); this.props.toggle(); }} className={this.props.className} onOpened={this.handleOpen}>
                <ModalHeader toggle={() => { this.resetForm(); this.props.toggle(); }}>{modalTitle}</ModalHeader>
                <Formik
                    initialValues={this.getInitialValues()}
                    validateOnBlur={false}
                    validateOnChange={true}
                    validate={values => {
                        let errors = {};

                        const { EffectiveStartDate, EffectiveEndDate } = values;
                        if (EffectiveStartDate) {
                            var inputStart = new Date(EffectiveStartDate);
                            var inputEnd = EffectiveEndDate === '' ? null : new Date(EffectiveEndDate);

                            for (var i = 0; i < data.length; i++) {
                                var item = data[i];
                                var checkDuplicate = false;

                                const isSameUser = item.UserToOrganisationId === values.UserToOrganisationId
                                if (editItem) {
                                    // can edit own row; check duplicate of other rows
                                    const isDifferentRow = editItem.rowIndex !== i;
                                    checkDuplicate = isDifferentRow && isSameUser;
                                } else {
                                    checkDuplicate = isSameUser;
                                }

                                if (checkDuplicate) {
                                    var strDataStart = item.EffectiveStartDate;
                                    var dataStart = new Date(strDataStart);
                                    var strDataEnd = item.EffectiveEndDate;
                                    var dataEnd = strDataEnd === '' ? null : new Date(strDataEnd);

                                    if (isDateOverlapped(dataStart, inputStart, dataEnd, inputEnd)) {
                                        const effectiveFromDateMsg = "Effective from " + strDataStart;
                                        const effectiveToDateMsg = strDataEnd === '' ? "." : " to " + strDataEnd + ".";

                                        errors.DateValidationMsgPart1 =
                                            "Overlaps existing User: " + item.Fullname.trim() + ".";

                                        errors.DateValidationMsgPart2 = effectiveFromDateMsg + effectiveToDateMsg;

                                        break;
                                    }
                                }
                            }
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        this.setFields(values);
                        if (editItem) {
                            saveEditItem(values, editItem.rowIndex);
                        } else {
                            addItem(values);
                        }
                        this.resetForm();
                    }}

                    validationSchema={isOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO ? FORM_VALIDATIONS_ORG(getStaticText) : FORM_VALIDATIONS(getStaticText)}
                >
                    {(props) => {
                        const { values, errors, submitCount, handleSubmit, setFieldValue } = props;
                        const formErrors = this.displayErrors(true, submitCount, errors);

                        return (
                            <Form>
                                <ModalBody>
                                    {errors.DateValidationMsgPart1 && errors.DateValidationMsgPart2 &&
                                        <>
                                            <div className="alert alert-danger" role="alert">
                                                {errors.DateValidationMsgPart1}<br />
                                                {errors.DateValidationMsgPart2}
                                            </div>
                                        </>
                                    }
                                    {isOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO ?
                                        (<SelectList
                                            name="Organisation"
                                            value={values.Organisation}
                                            options={organisationsOptions}
                                            onChangeField={(name, value) => {
                                                setFieldValue(name, value);
                                                if (values.Organisation !== value) {
                                                    this.getUsers(value);
                                                }

                                            }}
                                            error={formErrors.Organisation}
                                            inputSize={FIELD_SIZE.NONE}
                                            placeholder="Organisation"
                                            label="Organisation"
                                            translation={translation}
                                            isMulti={false}
                                            required
                                        />)
                                        :
                                        (<Plaintext
                                            label="Organisation"
                                            translation={translation}
                                            translateValue={true}
                                            name="Organisation"
                                            value={organisationsOptions.filter(opt => selectedOrganisation === opt.value)[0]?.label}
                                        />)
                                    }

                                    <DropdownList
                                        key={values.UserToOrganisationId}
                                        name="UserToOrganisationId"
                                        value={values.UserToOrganisationId}
                                        options={userListOptions}
                                        onChangeField={setFieldValue}
                                        error={formErrors.UserToOrganisationId}
                                        inputSize={FIELD_SIZE.NONE}
                                        placeholder="User"
                                        label="User"
                                        translation={translation}
                                        required
                                    />
                                    <DateInput
                                        name="EffectiveStartDate"
                                        value={values.EffectiveStartDate}
                                        placeholder="Effective Start Date"
                                        onChangeField={setFieldValue}
                                        time={false}
                                        date={true}
                                        min={new Date()}
                                        format={DATE_DISPLAY_FORMAT.DATE}
                                        inputSize={FIELD_SIZE.NONE}
                                        label="Effective Start Date"
                                        translation={translation}
                                        error={formErrors.EffectiveStartDate}
                                        required
                                    />
                                    <DateInput
                                        name="EffectiveEndDate"
                                        value={values.EffectiveEndDate}
                                        placeholder="Effective End Date"
                                        onChangeField={setFieldValue}
                                        time={false}
                                        date={true}
                                        format={DATE_DISPLAY_FORMAT.DATE}
                                        inputSize={FIELD_SIZE.NONE}
                                        label="Effective End Date"
                                        translation={translation}
                                        error={formErrors.EffectiveEndDate}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="button" onClick={handleSubmit}>{BUTTON_TITLE(getStaticText).SAVE}</Button>
                                    <Button color="secondary" onClick={() => { this.resetForm(); this.props.toggle(); }}>{BUTTON_TITLE(getStaticText).CANCEL}</Button>
                                </ModalFooter>
                            </Form>

                        );
                    }}
                </Formik>
            </Modal>
        )
    }
}

export default AssignUserToUserGroupModal;