import React from 'react';
import { Row } from 'reactstrap';
import { LOGO_URL } from '../App/AppSettings';

class NavHeader extends React.Component {
     
    render() {
        return (
            <Row className={"navbar-header " + (this.props.className !== undefined? this.props.className: "")}>
                <img src={LOGO_URL} className="nav-header-logo col-auto" alt="header-logo"></img>
                {this.props.children}
            </Row>
        );
    }
}

export default NavHeader;