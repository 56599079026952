// Generic Level
import { SMARTMeTranslationKeys } from './SMARTMeTranslationKeys';
import { AddressTranslationKeys } from './AddressTranslationKeys';
import { CountryTranslationKeys } from './CountryTranslationKeys';
import { CountryCodeTranslationKeys } from './CountryCodeTranslationKeys';
import { TableTranslationKeys } from './TableTranslationKeys';
import { ModalTranslationKeys } from './ModalTranslationKeys';

// Module Level (Shared Translation Keys)
import { OrganisationTranslationKeys } from './OrganisationTranslationKeys';
import { UserTranslationKeys } from './UserTranslationKeys';

const GenericTranslationKeys = SMARTMeTranslationKeys
    .concat(AddressTranslationKeys)
    .concat(CountryTranslationKeys)
    .concat(CountryCodeTranslationKeys)
    .concat(TableTranslationKeys)
    .concat(ModalTranslationKeys);

const ModuleTranslationKeys = OrganisationTranslationKeys
    .concat(UserTranslationKeys);

export const GlobalTranslationKeys = GenericTranslationKeys.concat(ModuleTranslationKeys);