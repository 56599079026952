import { LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { toastError } from '../../Shared/Forms/Toaster.js';

export const RetrieveGlobalVariables = async () => {
    const RETRIEVE_GLOBAL_VARIABLES_URL = LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL;
    const response = await fetchRequest(RETRIEVE_GLOBAL_VARIABLES_URL, getParams(), false);

    const { Data, IsSuccess } = response.body;
    let globalVariableList = [];
    if (response.success) {
        if (IsSuccess) {
            Data.forEach(variable => {
                globalVariableList.push({ 'text': variable.PlaceHolderCodeTitle, 'value': variable.PlaceHolderCodeValue, url: '' });
            });
        } else {
            toastError(response.body.Messages.toString());
            return null;
        }
    } else {
        toastError("Unable to reach the server, please try again later.");
        return null;
    }
    return globalVariableList;
}