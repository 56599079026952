import { faAnchor, faAt, faBook, faBriefcase, faCalendarDay, faClipboardCheck, faClipboardList, faCode, faCog, faCogs, faIdCard, faKey, faMailBulk, faSitemap, faSms, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Nav } from 'reactstrap';
import { compose } from 'recompose';
import { LANGUAGE_APPLICATION_NAME, PATH } from '../App/AppSettings';
import { NavbarExtension } from '../Extensions/NavbarExtension';
import { fetchRequest, getParams } from '../Shared/Actions';
import { withTranslationWrapper } from '../Shared/Forms';
import { withLoader } from '../Shared/Loading';
import { URL } from '../Shared/SmartMe/Constants';
// import { NavbarAuthExtension, NavbarServiceExtension } from '../Extensions/NavbarExtension';
import NavContainer from './NavContainer';
import NavLayout1D from './NavLayout1D';
import NavLayout1M from './NavLayout1M';
import NavLayout2D from './NavLayout2D';
import NavLayout2M from './NavLayout2M';
import NavLink from './NavLink';

// Translation
import { MenuTranslationKeys } from '../App/TranslationKeys/MenuTranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../Shared/Forms/FormUtils';

const App = LANGUAGE_APPLICATION_NAME;
const MenuKeys = getTranslationKeys(App, MenuTranslationKeys);

// Constant: Navbar Item Names
const NAVITEM = {
    ROLE: 'Role',
    USER: 'User',
    USERAPPROVAL: 'User Approval',
    USERGROUP: 'User Group',
    PERMISSION: 'Permission',
    ORGANISATION: 'Organisation',
    POLICY: 'Policy',
    WEBHOOK: 'Webhook',
    SYSTEM_CONFIG: 'System Config',
    NOTIFICATION: 'Notification Template',
    LANGUAGE: 'Language Management',
    APPCONFIG: 'App Config',
    CODE: 'Code',
    ADMIN: 'Admin',
    FAQ: 'FAQ',
    PUBLICHOLIDAY: 'Public Holiday',
    WORKINGHOUR: 'Working Hour'
};

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        const { loader } = this.props;

        loader.start();

        let response = await fetchRequest(URL + 'User/GetUserAccountSettings', getParams(), false);
        if (response.success && response.body.IsSuccess) {
            let data = response.body.Data;
            this.setState({
                isAdminApprovalRequired: data.IsOneLevelAdminApproval || data.IsTwoLevelEmailActivationToAdminApproval,
                isLoaded: true
            });
        }
        else {
            loader.done();
        }
        loader.done();
    }

    NavbarContent = () => {
        const { getStaticText } = this.props;

        return (
            <Nav>
                <React.Fragment>
                    {/*<NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.ORGANISATION} icon={faSitemap} itemName={NAVITEM.ORGANISATION} >*/}
                    {/*    {getTranslationValue("Organisation", getStaticText, MenuKeys)}*/}
                    {/*</NavLink>*/}

                    {/*<NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.PERMISSION} icon={faKey} itemName={NAVITEM.PERMISSION} >*/}
                    {/*    {getTranslationValue("Permission", getStaticText, MenuKeys)}*/}
                    {/*</NavLink>*/}

                    <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.ROLE} icon={faIdCard} itemName={NAVITEM.ROLE}>
                        {getTranslationValue("Role", getStaticText, MenuKeys)}
                    </NavLink>

                    {/* <NavContainer navbarLayout={this.props.navbarLayout} path={PATH.ROLE} name="Roles" icon={faIdCard} itemName={NAVITEM.ROLE}>
                        <NavLink to='/Role' dropdownlevel="1" itemName={NAVITEM.ROLE}>List Roles</NavLink>
                        <NavLink to='/Role/Permissions/' dropdownlevel="1" itemName={NAVITEM.ROLE}>List Role Permissions</NavLink>
                    </NavContainer> */}

                    <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.USERGROUP} icon={faUsers} itemName={NAVITEM.USERGROUP} >
                        {getTranslationValue("User Group", getStaticText, MenuKeys)}
                    </NavLink>
                    {/* <NavContainer navbarLayout={this.props.navbarLayout} path={PATH.USERGROUP} name="User Group" icon={faUsers} itemName={NAVITEM.USERGROUP}>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/UserGroup' dropdownlevel="1" itemName={NAVITEM.USERGROUP}>List User Group</NavLink>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/UserGroup/Roles' dropdownlevel="1" itemName={NAVITEM.USERGROUP}>List User Group Roles</NavLink>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/UserGroup/Users' dropdownlevel="1" itemName={NAVITEM.USERGROUP}>List User Group Users</NavLink>
                    </NavContainer> */}

                    {/* <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.USER} icon={faUser} itemName={NAVITEM.USER} >User</NavLink> */}
                    <NavContainer navbarLayout={this.props.navbarLayout} path={PATH.USER.MAIN} name={getTranslationValue("User", getStaticText, MenuKeys)} icon={faUser} itemName={NAVITEM.USER}>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.USER.ACTIVE_USER} dropdownlevel="1" itemName={NAVITEM.USER}>
                            {getTranslationValue("List of Active Users", getStaticText, MenuKeys)}
                        </NavLink>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.USER.NON_ACTIVE_USER} dropdownlevel="1" itemName={NAVITEM.USER}>
                            {getTranslationValue("List of Non-Active Users", getStaticText, MenuKeys)}
                        </NavLink>
                        {/* <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/User/Roles' dropdownlevel="1" itemName={NAVITEM.USER}>List User Roles</NavLink> */}
                    </NavContainer>

                    <NavContainer isDisplay={this.state.isLoaded && this.state.isAdminApprovalRequired} navbarLayout={this.props.navbarLayout} path={PATH.USERAPPROVAL.MAIN} name={getTranslationValue("User Approval", getStaticText, MenuKeys)} icon={faClipboardCheck} itemName={NAVITEM.USERAPPROVAL}>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.USERAPPROVAL.PENDING_USER_REQUESTS} dropdownlevel="1" itemName={NAVITEM.USERAPPROVAL}>
                            {getTranslationValue("Pending User Requests", getStaticText, MenuKeys)}
                        </NavLink>
                    </NavContainer>

                    <NavContainer navbarLayout={this.props.navbarLayout} path={PATH.POLICY.MAIN} name={getTranslationValue("Policy", getStaticText, MenuKeys)} icon={faClipboardList} itemName={NAVITEM.POLICY}>
                        <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.POLICY.PASSWORD_POLICY} dropdownlevel="1" itemName={NAVITEM.POLICY}>
                            {getTranslationValue("Password Policy", getStaticText, MenuKeys)}
                        </NavLink>
                    </NavContainer>

                    {/*<NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={PATH.WEBHOOK} icon={faAnchor} itemName={NAVITEM.WEBHOOK} >*/}
                    {/*    {getTranslationValue("Webhook", getStaticText, MenuKeys)}*/}
                    {/*</NavLink>*/}

                    {/* System Config */}
                    <NavContainer navbarLayout={this.props.navbarLayout} path={PATH.SYSTEM_CONFIG.MAIN} name={getTranslationValue("System Configuration", getStaticText, MenuKeys)} icon={faCogs}>
                        <NavLink to='/SystemConfig/ApplicationConfig' dropdownlevel="1" icon={faCog} navPath={NAVITEM.APPCONFIG}
                            includePaths={['/SystemConfig/ApplicationConfig/Create', '/SystemConfig/ApplicationConfig/Update']}>
                            {getTranslationValue("Application Configuration", getStaticText, MenuKeys)}
                        </NavLink>

                        <NavLink to={PATH.SYSTEM_CONFIG.CODE.MAIN} dropdownlevel="1" icon={faCode} navPath={NAVITEM.CODE}
                            includePaths={[PATH.SYSTEM_CONFIG.CODE.MAIN + '/New', PATH.SYSTEM_CONFIG.CODE.MAIN + '/View', PATH.SYSTEM_CONFIG.CODE.MAIN + '/Update']}>
                            {getTranslationValue("Code Table", getStaticText, MenuKeys)}
                        </NavLink>

                        {/*<NavContainer navbarLayout={this.props.navbarLayout} dropdownlevel="1" path={PATH.SYSTEM_CONFIG.NOTIFICATION} name={getTranslationValue("Notification Template", getStaticText, MenuKeys)} icon={faMailBulk}>*/}
                        {/*    <NavLink to='/SystemConfig/NotificationTemplate/EmailList' dropdownlevel="2" icon={faAt} navPath={NAVITEM.NOTIFICATION}*/}
                        {/*        includePaths={[PATH.SYSTEM_CONFIG.NOTIFICATION + '/EmailCreate', PATH.SYSTEM_CONFIG.NOTIFICATION + '/EmailUpdate']}>*/}
                        {/*        {getTranslationValue("Email Template", getStaticText, MenuKeys)}*/}
                        {/*    </NavLink>*/}

                        {/*    <NavLink to='/SystemConfig/NotificationTemplate/SmsList' dropdownlevel="2" icon={faSms} navPath={NAVITEM.NOTIFICATION}*/}
                        {/*        includePaths={[PATH.SYSTEM_CONFIG.NOTIFICATION + '/SmsCreate', PATH.SYSTEM_CONFIG.NOTIFICATION + '/SmsUpdate']}>*/}
                        {/*        {getTranslationValue("SMS Template", getStaticText, MenuKeys)}*/}
                        {/*    </NavLink>*/}
                        {/*</NavContainer>*/}

                        {/* <NavContainer navbarLayout={this.props.navbarLayout} dropdownlevel="1" path={PATH.SYSTEM_CONFIG.LANGUAGE} name={getTranslationValue("Language Management", getStaticText, MenuKeys)} icon={faBook}>
                            <NavLink to='/SystemConfig/language/languageRegistration' dropdownlevel="2" icon={faBook} navPath={NAVITEM.LANGUAGE}
                                includePaths={['/SystemConfig/language/editApplicationLanguages']}>{getTranslationValue("Language Registration", getStaticText, MenuKeys)}</NavLink>
                            <NavLink to='/SystemConfig/language/list' dropdownlevel="2" icon={faBook} navPath={NAVITEM.LANGUAGE}
                                includePaths={[PATH.SYSTEM_CONFIG.LANGUAGE + '/addEntry', PATH.SYSTEM_CONFIG.LANGUAGE + '/editEntry']}>{getTranslationValue("Translation Management", getStaticText, MenuKeys)}</NavLink>
                            <NavLink to='/SystemConfig/language/scopeManagement' dropdownlevel="2" icon={faBook} navPath={NAVITEM.ADMIN}
                                includePaths={['/SystemConfig/language/scopeManagement']}>{getTranslationValue("Scope Management", getStaticText, MenuKeys)}</NavLink>
                            <NavLink to='/SystemConfig/language/categoryManagement' dropdownlevel="2" icon={faBook} navPath={NAVITEM.ADMIN}
                                includePaths={['/SystemConfig/language/categoryManagement']}>{getTranslationValue("Category Management", getStaticText, MenuKeys)}</NavLink>
                            <NavLink to='/SystemConfig/language/subcategoryManagement' dropdownlevel="2" icon={faBook} navPath={NAVITEM.ADMIN}
                                includePaths={['/SystemConfig/language/subcategoryManagement']}>{getTranslationValue("Sub-Category Management", getStaticText, MenuKeys)}</NavLink>
                            <NavLink to='/SystemConfig/language/excelUpdate' dropdownlevel="2" icon={faBook} navPath={NAVITEM.ADMIN}
                                includePaths={['/SystemConfig/language/excelUpdate']}>{getTranslationValue("Language Pack Update", getStaticText, MenuKeys)}</NavLink>
                        </NavContainer> */}

                        {/*<NavLink to='/SystemConfig/faq/faqManagement' dropdownlevel="1" icon={faBook} navPath={NAVITEM.FAQ}*/}
                        {/*    includePaths={['/SystemConfig/faq/faqManagement']}>*/}
                        {/*    {getTranslationValue("FAQ Management", getStaticText, MenuKeys)}*/}
                        {/*</NavLink>*/}

                        {/*<NavLink to={PATH.SYSTEM_CONFIG.PUBLICHOLIDAY.MAIN} dropdownlevel="1" icon={faCalendarDay} navPath={NAVITEM.PUBLICHOLIDAY}*/}
                        {/*    includePaths={[PATH.SYSTEM_CONFIG.PUBLICHOLIDAY.MAIN + '/Create', PATH.SYSTEM_CONFIG.PUBLICHOLIDAY.MAIN + '/Update']}>*/}
                        {/*    {getTranslationValue("Public Holiday", getStaticText, MenuKeys)}*/}
                        {/*</NavLink>*/}

                        {/*<NavLink to={PATH.SYSTEM_CONFIG.WORKINGHOUR.MAIN} dropdownlevel="1" icon={faBriefcase} navPath={NAVITEM.WORKINGHOUR}*/}
                        {/*    includePaths={[PATH.SYSTEM_CONFIG.WORKINGHOUR.MAIN + '/Create', PATH.SYSTEM_CONFIG.WORKINGHOUR.MAIN + '/Update', PATH.SYSTEM_CONFIG.WORKINGHOUR.MAIN + '/CreateException', PATH.WORKINGHOUR + '/UpdateException']}>*/}
                        {/*    {getTranslationValue("Working Hour", getStaticText, MenuKeys)}*/}
                        {/*</NavLink>*/}
                    </NavContainer>
                </React.Fragment>
                <NavbarExtension onClick={this.props.onClick} navbarLayout={this.props.navbarLayout}></NavbarExtension>
            </Nav>
        )
    }

    render1DNavbar = () => {
        return (
            <NavLayout1D navbarLayout={this.props.navbarLayout}>
                {this.NavbarContent()}
            </NavLayout1D>
        );
    }

    LayoutToggle = () => {
        switch (this.props.navbarLayout) {
            case "1D":
                return this.render1DNavbar();
            case "1M":
                return (
                    <NavLayout1M navbarLayout={this.props.navbarLayout} collapseNavbar={this.props.collapseNavbar} onUpdate={this.props.onUpdate} onClick={this.props.onClick}>
                        {this.NavbarContent()}
                    </NavLayout1M>
                );
            case "2D":
                return (
                    <NavLayout2D showNavDisplay={this.props.showNavDisplay} navbarLayout={this.props.navbarLayout}>
                        {this.NavbarContent()}
                    </NavLayout2D>
                );
            case "2M":
                return (
                    <NavLayout2M navbarLayout={this.props.navbarLayout} collapseNavbar={this.props.collapseNavbar} onUpdate={this.props.onUpdate} onClick={this.props.onClick}>
                        {this.NavbarContent()}
                    </NavLayout2M>
                );
            default:
                return this.render1DNavbar();
        }
    }

    render() {
        return this.LayoutToggle()

    }
}

export default compose(withTranslationWrapper, withLoader)(Navbar);