import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { navigateTo, fetchRequest, postParams } from '../../Shared/Actions';
import { toastError, toastSuccess, withSMARTWrapper } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue } from '../../Shared/Forms/FormUtils';
import { MODAL_TITLE, BUTTON_TITLE, TOOLTIP_TEXT } from '../../Localization';
import './Permission.css';
import { LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';

const DELETE_URL = URL + 'Permission/DeletePermission/';
const DELETE_MODAL = (getStaticText) => {
    return {
        hasModal: true,
        modalHeader: MODAL_TITLE(getStaticText).DELETE.HEADER,
        modalContent: MODAL_TITLE(getStaticText).DELETE.BODY
    }
};

const GRID_ENDPOINT = URL + "Permission/ListPermissions";
const VIEW_PERMISSION_URL_PAGE = "Permission/View";
const UPDATE_PERMISSION_URL_PAGE = "Permission/Update"
const NEW_PERMISSION_URL_PAGE = "/Permission/New";

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const PermissionTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleUpdate: false
        };
    }

    //Fn: Delete Row via Id
    async deleteRow(Id) {
        const { loader, getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: PermissionTranslationKeys
        };

        if (Id) {
            // Start loading
            loader.start();

            // Send request to backend to delete Row
            const response = await fetchRequest(DELETE_URL + Id, postParams(), false);

            // Done loading
            loader.done();

            const { IsSuccess, Messages } = response.body;

            // Based on response, show appropriate toaster
            (response.success && IsSuccess) ?
                toastSuccess(Messages, null, translation) :
                toastError(Messages, translation);

            if (IsSuccess) {
                this.setState({ toggleUpdate: !this.state.toggleUpdate });
            }
        }
    }

    renderMobileCardActions = (rowData) => {
        const { getStaticText } = this.props;
        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton
                    key={'View' + Id}
                    onClick={() => navigateTo(VIEW_PERMISSION_URL_PAGE, ID)}
                    icon={faEye}
                    label={BUTTON_TITLE(getStaticText).VIEW}
                />
                <MobileCardActionButton
                    key={'Update' + Id}
                    onClick={() => navigateTo(UPDATE_PERMISSION_URL_PAGE, ID)}
                    icon={faEdit}
                    label={BUTTON_TITLE(getStaticText).UPDATE}
                />
                <MobileCardActionButton
                    key={'Delete' + Id}
                    onClick={() => this.deleteRow(Id)}
                    modal={DELETE_MODAL(getStaticText)}
                    icon={faTrash}
                    label={BUTTON_TITLE(getStaticText).DELETE}
                    formatter={{ component: getTranslationValue('Permission', getStaticText, PermissionTranslationKeys) }}
                />
            </React.Fragment>
        )
    }

    setContent = (rowData) => {
        return <div className="div-link" onClick={() => navigateTo(VIEW_PERMISSION_URL_PAGE, { ID: rowData.Id })}>{rowData.PermissionName}</div>
    }

    render() {
        const { getStaticText } = this.props;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: PermissionTranslationKeys
        };

        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" translation={translation} titleValue="List of Permissions" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ActionButton className='add-button' color="neutral" onClick={() => navigateTo(NEW_PERMISSION_URL_PAGE)}><FontAwesomeIcon icon={faPlus} /></ActionButton>
                            <ServerSideDataTable
                                translation={translation}
                                toggleUpdate={this.state.toggleUpdate}
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No permissions found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '4%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        AppName: { title: 'Application', width: '15%', DBkey: 'AppName' },
                                        FeatureName: { title: 'Feature', width: '10%', DBkey: 'FeatureName', translateValue: true },
                                        PermissionName: { title: 'Permission Name', width: '15%', DBkey: 'PermissionName', setContent: this.setContent },
                                        PermissionType: { title: 'Permission Type', width: '10%', DBkey: 'PermissionType', translateValue: true },
                                        Description: { title: 'Description', width: '20%', DBkey: 'Description' },
                                        LastUpdatedDate: { title: 'Last Updated', width: '10%', DBkey: 'LastUpdatedDate' }
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        {rowData.RowData.IsEditable &&
                                            <ActionButton key={'Update' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).UPDATE} color="neutral"
                                                onClick={() => navigateTo(UPDATE_PERMISSION_URL_PAGE, { ID: rowData.ID })}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionButton>
                                        }
                                        {/* <ActionButton key={'View' + rowData.ID} tooltip="View Permission" color="forward" onClick={() => serverTableNavigateTo(VIEW_PERMISSION_URL_PAGE, rowData)}><FontAwesomeIcon icon={faEye} /></ActionButton> */}
                                        {rowData.RowData.IsDeletable &&
                                            <ActionButton key={'Delete' + rowData.ID} tooltip={TOOLTIP_TEXT(getStaticText).DELETE} color="danger"
                                                onClick={() => this.deleteRow(rowData.ID)} modal={DELETE_MODAL(getStaticText)}
                                                formatter={{ component: getTranslationValue('Permission', getStaticText, PermissionTranslationKeys) }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </ActionButton>
                                        }
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);