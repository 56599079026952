export const ModalTranslationKeys = [
    {
        "DefaultLanguageValue": "Add new Global User Group",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Title",
        "TranslationKey": "AddNewGlobalUG"
    },
    {
        "DefaultLanguageValue": "Add new User Group",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Title",
        "TranslationKey": "AddNewUG"
    },
    {
        "DefaultLanguageValue": "Edit Global User Group",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Title",
        "TranslationKey": "EditGlobalUG"
    },
    {
        "DefaultLanguageValue": "Edit User Group",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Title",
        "TranslationKey": "EditUG"
    },
    {
        "DefaultLanguageValue": "Confirm Update in Organisation?",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Header",
        "TranslationKey": "CfmUpdateOrgHeader"
    },
    {
        "DefaultLanguageValue": "Upon confirmation, selected User Groups will be cleared. Do you wish to proceed?",
        "Scope": "MeUser",
        "Category": "Modal",
        "SubCategory": "Body",
        "TranslationKey": "CfmClearOrgBody"
    }
]