import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { IS_LOCALE_ENABLED } from '../../App/AppSettings';
import { DEFAULT_APPLICATION_NAME } from '../../Shared/Constants/LanguageKeys.js';
import { withPageNotFound } from '../Error';
import { withLoader } from '../Loading';
import { withModalWrapper } from '../Modal/ModalWrapper';
import { withDirectionContext } from '../DirectionContext/DirectionContextWrapper';

export const withSMARTWrapper = (FormComponent) => {
    class SMARTWrapper extends React.Component {

        /** Action: Get Static Text based on if Locale is Enabled / Disabled - START **/

        // Get namespace based on key and value
        getLangKey = (translationKey, ...replacementKeys) => {

            //spread operator for the keys that need to be replaced in
            let arr = [...replacementKeys];
            let count = 0;

            //assign each matched substring to argument array index on FCFS basis
            const replaceMatchedSubstring = () => {
                let str = arr[count];
                count++;
                return str;
            }
            const replacedStr = translationKey.replace(/%[0-9a-zA-Z]*%/g, replaceMatchedSubstring);

            return replacedStr;
        }

        // Get Static Text based on if Locale is enabled / disabled 
        getStaticText = (namespace, defaultText, isRichText) => {
            if (IS_LOCALE_ENABLED) {
                return isRichText ? ReactHtmlParser(this.props.t(this.getLangKey(namespace, ""))) : this.props.t(this.getLangKey(namespace, ""));
            }
            else {
                return isRichText ? ReactHtmlParser(defaultText) : defaultText;
            }
        }

        // Get message or language key based on if Multi-Lang is supported
        getMessage = (messageLanguageKey, message) => {
            return (IS_LOCALE_ENABLED) ? this.getStaticText((DEFAULT_APPLICATION_NAME + "." + messageLanguageKey)) : message;
        }

        getStaticTextWithDynamicValue = (namespace, defaultText, ...keys) => {
            //spread operator for the keys that need to be replaced in
            let arr = [...keys];
            let count = 0;

            let staticText = this.getStaticText(namespace, defaultText);

            //assign each matched substring to argument array index on FCFS basis
            const replaceMatchedSubstring = () => {
                let str = arr[count];
                count++;
                return str;
            }
            const replacedStr = staticText.replace(/%[0-9a-zA-Z]*%/g, replaceMatchedSubstring);

            return replacedStr;
        }

        /** Action: Get Static Text based on if Locale is Enabled / Disabled - END **/

        render() {
            return (
                <FormComponent
                    getStaticText={this.getStaticText}
                    getStaticTextWithDynamicValue={this.getStaticTextWithDynamicValue}
                    getMessage={this.getMessage}
                    {...this.props}
                />
            );
        }
    };

    return compose(withDirectionContext, withTranslation(), withModalWrapper, withPageNotFound, withLoader)(SMARTWrapper);
};