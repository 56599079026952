import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { LAYOUT_TYPE, TRANSLATION_MANAGEMENT_EDIT_URL, TRANSLATION_MANAGEMENT_LIST, TRANSLATION_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { withSMARTWrapper, Plaintext, SMARTForm, toastError, toastSuccess } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';
import RichTextToggle from './RichTextToggle';

const FORM_CONTEXT = "EditKey";
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

class EditKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            languageValues: []
        }
        if (history.location.state) {
            let keyData = history.location.state.keyData;
            let { chosenLanguageApplication, chosenLanguage, chosenFilterValues } = history.location.state;
            this.state = {
                keyData: keyData,
                languageValues: [],
                values: {},
                chosenLanguageApplication,
                chosenLanguage,
                chosenFilterValues
            }
        }
    }

    async componentDidMount() {
        this.getExistingValues();
    }

    async getExistingValues() {
        const { keyData } = this.state;
        const params = {
            LocaleTranslationId: keyData.Id,
            ApplicationId: keyData.ApplicationId,
            LocaleScopeId: keyData.LanguageScopeId,
            LocaleCategoryId: keyData.LanguageCategoryId,
            LocaleSubCategoryId: keyData.LanguageSubCategoryId,
            TranslationKey: keyData.Key
        }
        const { loader } = this.props;
        loader.start();

        const response = await fetchRequest(TRANSLATION_MANAGEMENT_URL, getParams(params));
        const { Data, IsSuccess, MessageLanguageKey, Message } = response.body;
        if (response.success) {
            if (IsSuccess) {
                let languageValues = Data;
                let values = {
                    LanguagesInput: {}
                }

                languageValues.forEach((languageValue) => {
                    values.LanguagesInput[languageValue.LanguageCodeValue] = languageValue.Value;
                    values.Key = keyData.Key
                })

                this.setState(() => ({
                    languageValues: languageValues,
                    values: values,
                    validationSchema: this.createValidationSchema(languageValues),
                }))
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
        loader.done();
    }

    createValidationSchema = (languages) => {
        let validationObject = {
            Key: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            LanguagesInput: {}
        };
        let languageSchema = {};

        languages.forEach((language) => {
            languageSchema[language.LanguageCodeValue] = Yup.string()
            .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_VALUE, DEFAULT_TEXT.VALUE_REQUIRED))
        })
        validationObject.LanguagesInput = Yup.object().shape(languageSchema);
        return Yup.object().shape(validationObject);
    }

    massageValues = (values, submitPlainForm) => {
        const { keyData } = this.state;
        values = {
            ...values,
            "Languages": JSON.stringify(values.LanguagesInput),
            "ApplicationId": keyData.ApplicationId,
            "ScopeId": keyData.LanguageScopeId,
            "CategoryId": keyData.LanguageCategoryId,
            "SubCategoryId": keyData.LanguageSubCategoryId,
            "TranslationKey": keyData.Key
        }
        this.setState({
            values: values
        }, () => submitPlainForm())
    }

    submitCallback = ({ response }) => {
        const { chosenLanguageApplication, chosenLanguage, chosenFilterValues } = this.state;
        if (response.success) {
            const { IsSuccess, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                navigateTo(TRANSLATION_MANAGEMENT_LIST, { chosenLanguageApplication, chosenLanguage, chosenFilterValues });
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEKEY, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.KEY);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        const { languageValues, validationSchema, keyData, values, chosenLanguageApplication, chosenLanguage, chosenFilterValues } = this.state;
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITLANGVALUE, DEFAULT_TEXT.EDIT_LANG_VALUE)}>
                    <SMARTForm
                        validationSchema={validationSchema}
                        formContext={FORM_CONTEXT}
                        formValues={values}
                        validateOnSubmit={true}
                        serverURL={TRANSLATION_MANAGEMENT_EDIT_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <>
                                {values.LanguagesInput &&
                                    <Row className="body-content admin-content-wrapper">
                                        <Col lg="10">
                                            <Plaintext
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_FULLKEY, DEFAULT_TEXT.FULL_KEY)}
                                                name="Full Key"
                                                value={keyData.Namespace + "." + keyData.Key}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />
                                        </Col>
                                        <Col lg="12">
                                            {languageValues.map(language =>
                                                < div key={language.LanguageCodeValue}>
                                                    <RichTextToggle
                                                        language={language}
                                                        values={values}
                                                        onChangeField={onChangeField}
                                                        onChange={onChange}
                                                        errors={errors}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                        <Col lg="10">
                                            <Plaintext
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                                name="Last edited by"
                                                value={keyData.UpdatedBy}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />
                                            <Plaintext
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                                name="Last edited date"
                                                value={keyData.UpdatedDateFormatted}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />
                                        </Col>

                                    </Row>
                                }
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(TRANSLATION_MANAGEMENT_LIST, { chosenLanguageApplication, chosenLanguage, chosenFilterValues })}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </PageFooter>

                            </>

                        )}

                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(EditKey));
