import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import { ServerSideDataTable, ActionButton, MobileCardActionButton } from '../../Shared/DataTable';
import { serverTableNavigateTo } from '../../Shared/SmartMe/Actions';
import { withSMARTWrapper } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import Title from '../../Title';

const GRID_ENDPOINT = URL + "UserGroup/ListUserGroupRoles";

class Grid extends React.Component {

    renderMobileCardActions = (rowData) => {
        const Id = rowData.Id;
        const ID = { ID: Id };

        return (
            <React.Fragment>
                <MobileCardActionButton 
                    onClick={() => serverTableNavigateTo('Roles/View/', ID)}
                    icon={faEye} 
                    label='View'
                />
                <MobileCardActionButton 
                    onClick={() => serverTableNavigateTo('Roles/Update/', ID)}
                    icon={faEdit} 
                    label='Update'
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue="List of User Group Roles" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            <ServerSideDataTable
                                url={GRID_ENDPOINT}
                                pageSize='5'
                                minFilterChars='2'
                                rowIdentifier='Id'
                                noResultsMessage='No user group roles found'
                                columns={
                                    {
                                        RunningNumber: { title: 'No.', width: '3%' },
                                        Actions: { title: 'Actions', width: '5%' },
                                        GroupName: { title: 'GroupName', width: '15%', DBkey: 'GroupName' },
                                        Description: { title: 'Description', width: '15%', DBkey: 'Description' },
                                        Total: { title: 'Total', width: '15%', DBkey: 'Total' }
                                    }
                                }
                                renderActions={(rowData) => (
                                    <React.Fragment>
                                        <ActionButton tooltip="Update User Group Role" color="neutral" onClick={() => serverTableNavigateTo('Roles/Update/', rowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                        <ActionButton tooltip="View User Group Role" color="forward" onClick={() => serverTableNavigateTo('Roles/View/', rowData)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}


export default withSMARTWrapper(Grid);