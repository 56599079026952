import { faCog, faEdit, faEye, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'reactstrap';
import {
    ADD_FAQ_SYSTEM_URL, CREATE_DRAFT_FAQ_URL, EDIT_FAQ_SYSTEM_URL, GET_FAQ_LIST_URL,
    LAYOUT_TYPE, VIEW_FAQ_SYSTEM_URL, VIEW_FAQ_SETTINGS_SYSTEM_URL
} from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { fetchRequest, getParams, navigateTo, postParams } from '../../Shared/Actions';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { withSMARTWrapper } from '../../Shared/Forms';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { withLoader } from '../../Shared/Loading';

const PUBLISHED_STATUS = "Published";
const DRAFT_STATUS = "Draft";
const PUBLISHED_DRAFT_STATUS = "Published_Draft";
const FAQ_CREATE_DRAFT_SUCCESS = "FAQ draft has been successfully created.";

class FAQManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            data: []
        };
    }

    componentDidMount = async () => {
        this.props.loader.start();
        const response = await fetchRequest(GET_FAQ_LIST_URL, getParams());
        this.props.loader.done();

        const { Data, IsSuccess, Messages } = response.body;
        if (response.success) {
            if (IsSuccess) {
                this.setState({
                    data: Data,
                });
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };


    renderActionButtons = (data) => {

        let status = data.RowData.Status;

        switch (status) 
        {
            case PUBLISHED_STATUS:
                return (
                    <>
                        <ActionButton 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_VIEW, DEFAULT_TEXT.VIEW)}
                            color="neutral" 
                            onClick={() => navigateTo(VIEW_FAQ_SYSTEM_URL, { data: data })}>
                            <FontAwesomeIcon icon={faEye} />
                        </ActionButton> 

                        <ActionButton 
                            color="forward" 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_CREATE, DEFAULT_TEXT.CREATE_DRAFT)}
                            size="sm" 
                            type="button" 
                            onClick={() => this.massageValues(data.RowData)}>
                            <FontAwesomeIcon icon={faFileMedical} />
                        </ActionButton>
                    </>
                )

            case (DRAFT_STATUS):
                return (
                    <>
                        <ActionButton 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)}
                            color="forward" 
                            onClick={() => this.massageEditValue(data.RowData)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>

                        <ActionButton 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_SETTINGS, DEFAULT_TEXT.SETTINGS)}
                            color="forward" 
                            onClick={() => this.massageSettingsValue(data.RowData, DRAFT_STATUS)}>
                            <FontAwesomeIcon icon={faCog} />
                        </ActionButton>
                    </>
                );

            case (PUBLISHED_DRAFT_STATUS):
                return(
                    <>
                        <ActionButton 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_VIEW, DEFAULT_TEXT.VIEW)}
                            color="neutral" 
                            onClick={() => navigateTo(VIEW_FAQ_SYSTEM_URL, { data: data })}>
                            <FontAwesomeIcon icon={faEye} />
                        </ActionButton> 

                        <ActionButton
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)}
                            color="forward" 
                            onClick={() => this.massageDraftValue(data.RowData)}>
                                <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>

                        <ActionButton 
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_SETTINGS, DEFAULT_TEXT.SETTINGS)}
                            color="forward" 
                            onClick={() => this.massageSettingsValue(data.RowData, PUBLISHED_DRAFT_STATUS)}>
                                <FontAwesomeIcon icon={faCog} />
                        </ActionButton>
                    </>
                );

            default:
                return  "";
        }
    }

    renderLayout = () => {
        return (
            <DataTable
                pageSize="10"
                minFilterChars='2'
                data={this.state.data}
                noResultsMessage= {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TEXT_NORECORD, DEFAULT_TEXT.FAQ_NO_RECORD)}
                components={getCustomComponents()}
                columns={
                    {
                        RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%', DBkey: 'Id' },
                        Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '10%' },
                        Application: {  title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_APPLICATION, DEFAULT_TEXT.APPLICATION), width: '15%', DBkey: 'ApplicationName' },
                        Title: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_TITLE, DEFAULT_TEXT.TITLE), width: '20%', DBkey: 'Title' },
                        DisplayStatus: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_STATUS, DEFAULT_TEXT.STATUS), width: '10%', DBkey: 'DisplayStatus' },
                        LastPublishedDate: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_LASTPUBLISHED, DEFAULT_TEXT.LAST_PUBLISHED), width: '15%', DBkey: 'FormattedPublishDate' },
                        IsMultiLangEnabled: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_MULTILANG, DEFAULT_TEXT.MULTI_LANGUAGE), width: '15%', DBkey: 'IsLocaleEnabledToString' },
                        IsQnAMakerSyncEnabled: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_QNAMAKER, DEFAULT_TEXT.MULTI_QNAMAKER), width: '10%', DBkey: 'IsQnAMakerSyncEnabledToString' },
                    }
                }
                renderActions={(data) => (
                    <React.Fragment>
                        {this.renderActionButtons(data)}
                    </React.Fragment>
                )}
            />
        );
    }

    massageEditValue = (data) => {

        let Data = 
        {
            'IsDraft': true, 
            'Id': data.Id, 
            'Name': data.Name, 
            'Title': data.Title,
            'LanguageCode': data.LanguageCode, 
            'ApplicationName': data.ApplicationName
        };

        navigateTo(EDIT_FAQ_SYSTEM_URL, Data);
    }

    massageDraftValue = (data) => {

        let Data = 
        {
            'IsDraft': true, 
            'Id': data.NewFaqId, 
            'Name': data.Name,
            'Title': data.Title,
            'LanguageCode': data.LanguageCode, 
            'ApplicationName': data.ApplicationName
        };

        navigateTo(EDIT_FAQ_SYSTEM_URL, Data);
    }

    massageSettingsValue = (data, status) => {
        let id;

        // If status is Drafted Publish, set id as the newly created Faq Id
        if (status === PUBLISHED_DRAFT_STATUS){
            id = data.NewFaqId;
        }
        else {
            id = data.Id;
        }

        let Data = 
        { 
            'IsDraft': true, 
            'Id': id, 
            'Name': data.Name, 
            'Title': data.Title,
            'LanguageCode': data.LanguageCode, 
            'ApplicationName': data.ApplicationName 
        };

        navigateTo(VIEW_FAQ_SETTINGS_SYSTEM_URL, Data);
    }

    massageValues = async (data) => {
        const params = {
            Id: data.Id,
            ApplicationName: data.ApplicationName,
            LanguageCode: data.LanguageCode
        }

        const { loader } = this.props;
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(CREATE_DRAFT_FAQ_URL, postParams('', params));
        loader.done();
        const { IsSuccess, Messages, Data } = response.body;
        if (response.success) {
            if (IsSuccess) {
                
                let ResponseData = 
                { 
                    'IsDraft': true, 
                    'Id': Data, 
                    'Name': data.Name, 
                    'Title': data.Title,
                    'LanguageCode': data.LanguageCode, 
                    'ApplicationName': data.ApplicationName 
                };

                // change to navigate to edit page for new draft
                navigateTo(EDIT_FAQ_SYSTEM_URL, ResponseData); 

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATE, FAQ_CREATE_DRAFT_SUCCESS));
                
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    render() {
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_FAQMANAGEMENT, DEFAULT_TEXT.FAQ_MANAGEMENT)}>
                    <Row className="body-content admin-content-wrapper">
                        <Col>
                            <Col className='text-right remove-padding'>
                                <ActionButton className='language-btn' onClick={() => navigateTo(ADD_FAQ_SYSTEM_URL)}>
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_NEWFAQ, DEFAULT_TEXT.NEW_FAQ)}
                                </ActionButton>
                            </Col>
                            {this.renderLayout()}
                        </Col>
                    </Row>
                </Layout>
            </>
        )
    }
}

export default withSMARTWrapper(withLoader(FAQManagement));

