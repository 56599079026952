import React from 'react';
import { FormGroup, Button, Row, Col, Label } from 'reactstrap';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withRouter } from 'react-router-dom';
import PageFooter from '../../Shared/PageFooter';
import { LAYOUT_TYPE, DATE_DISPLAY_FORMAT, DEFAULT_ADDRESS_TYPE, ADDRESS, DEFAULT_HOME_COUNTRY, OPTIONAL_ADDRESS_TYPE, getAddressTypeOptions, LANGUAGE_APPLICATION_NAME } from '../../App/AppSettings';
import Layout from '../../Layout';
import { URL } from '../../Shared/SmartMe/Constants';
import { SMARTForm, Text, DateInput, Radio, SelectList, Plaintext, Hidden, withSMARTWrapper, toastSuccess, toastError } from '../../Shared/Forms';
import SubSection from '../../Section/SubSection';
import GenderOptions from './Components/GenderOptions';
import { isValidForm } from '../../Shared/SmartMe/Actions';
import { ModalConfirm } from '../../Shared/Modal';
import * as ValidationConfig from '../../Shared/SmartMe/Utils/Validation/ValidationConfig';
import * as ValidationUtils from '../../Shared/SmartMe/Utils/Validation/ValidationUtils';
import { getSalutations, getCountries, getCountryCodes } from '../../Shared/SmartMe/Utils/FormUtils';
import { getIdTypesConfig } from '../../Shared/SmartMe/Components/IdComponent/Utils';
import IdComponent from '../../Shared/SmartMe/Components/IdComponent';
import { BUTTON_TITLE } from '../../Localization';
import './UserApproval.css';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys } from '../../Shared/Forms/FormUtils';

const GET_USER_REQUEST_URL = URL + 'UserActivationRequest/GetUserActivationRequestByActivationKey/';
const GET_USER_VALIDATION_URL = URL + 'User/GetInternalUserValidationConfig';
const SERVER_URL = URL + 'UserActivationRequest/AmendUserRequest';

const FORM_INITIAL_VALUES = {
    Id: '',
    Email: '',
    ActivationKey: '',
    Profile: {
        FirstName: '',
        MiddleName: '',
        LastName: '',
        Gender: '',
        Username: '',
        Email: '',
        DateOfBirth: '',
        Salutation: '',
        Nationality: '',
        AltEmailAddress: '',
        PrimaryContact: '',
        MobileNumber: {
            CountryCode: '',
            Number: ''
        },
        OfficeNumber: {
            CountryCode: '',
            Number: ''
        },
        HomeNumber: {
            CountryCode: '',
            Number: ''
        },
        FaxNumber: {
            CountryCode: '',
            Number: ''
        },
        IdNumbersArr: [],
        IdNumbers: JSON.stringify([]),
        Address: {
            AddressType: DEFAULT_ADDRESS_TYPE,
            PostalCode: '',
            Country: '', // Only applicable to Address Type: Overseas
            City: '',
            State: '',
            AddressLine1: ADDRESS.ADDRESS_FIELDS.LINE1,
            AddressLine2: ADDRESS.ADDRESS_FIELDS.LINE2
        }
    }
};

const inputSize = 8;

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UATranslationKeys = getTranslationKeys(App, FormTranslationKeys);

class AmendUserRequest extends React.Component {

    constructor(props) {
        super(props);
        const { loader } = this.props;

        this.state = {
            isLoaded: false,
            loader: loader,
            values: FORM_INITIAL_VALUES,
            countriesOptions: [],
            salutationsOptions: [],
            countryCodesOptions: [],
            idTypesConfig: {},
            addressTypeOptions: getAddressTypeOptions()
        }
    }

    async componentDidMount() {
        const { loader, pageNotFound } = this.props;

        loader.start();

        let userValidationResponse = await fetchRequest(GET_USER_VALIDATION_URL, getParams(), false);
        await this.setUserValidationConfigData(userValidationResponse);

        let response = await fetchRequest(
            GET_USER_REQUEST_URL + this.props.match.params.activationkey, getParams(), false);

        const { Data, IsSuccess } = response.body;

        if (response.success && IsSuccess) {
            this.setProfile(Data, this.props.match.params.activationkey);
            this.setState({
                salutationsOptions: await getSalutations(loader),
                countriesOptions: await getCountries(loader),
                countryCodesOptions: await getCountryCodes(loader)
            }, () => {
                const { countriesOptions } = this.state;
                this.setState({
                    idTypesConfig: getIdTypesConfig(countriesOptions),
                })
            });
            this.stopLoader();
        }
        else {
            pageNotFound();
        }
        loader.done();
    }

    setUserValidationConfigData = async (response) => {
        const { getStaticText } = this.props;
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            this.setState({
                userValidation: Data,
                formValidation: ValidationUtils.constructFormValidation(Data, getStaticText)
            });
        }
        else {
            toastError(Messages);
        }
    }

    getValidationSchema = () => {
        const { formValidation, userType } = this.state;
        return formValidation && formValidation[userType];
    }

    async setProfile(Data, ActivationKey) {
        const profileData = Data.Profile;
        const middleName = profileData.MiddleName;
        const idNumbersArr = profileData.IdNumbers === '' ? [{ IdType: '', IdNumber: '', IdCountry: '' }] : JSON.parse(profileData.IdNumbers);

        this.setState({
            gender: profileData.Gender,
            values: {
                ActivationKey: ActivationKey,
                Id: Data.Id,
                Username: Data.Username,
                Email: Data.Email,
                UserType: Data.UserType,
                Profile: {
                    FirstName: profileData.FirstName,
                    MiddleName: (middleName === '' || middleName === "null" || middleName === null) ? "" : middleName,
                    LastName: profileData.LastName,
                    DateOfBirth: profileData.DateOfBirth,
                    Gender: profileData.Gender,
                    Salutation: profileData.Salutation === null ? "" : profileData.Salutation,
                    Nationality: profileData.Nationality,
                    AltEmailAddress: profileData.AltEmailAddress === null ? "" : profileData.AltEmailAddress,
                    PrimaryContact: profileData.PrimaryContact,
                    MobileNumber: {
                        CountryCode: (profileData.MobileNumber === null || profileData.MobileNumber.CountryCode === null) ? "" : profileData.MobileNumber.CountryCode,
                        Number: (profileData.MobileNumber === null || profileData.MobileNumber.Number === null) ? "" : profileData.MobileNumber.Number
                    },
                    OfficeNumber: {
                        CountryCode: (profileData.OfficeNumber === null || profileData.OfficeNumber.CountryCode === null) ? "" : profileData.OfficeNumber.CountryCode,
                        Number: (profileData.OfficeNumber === null || profileData.OfficeNumber.Number === null) ? "" : profileData.OfficeNumber.Number
                    },
                    HomeNumber: {
                        CountryCode: (profileData.HomeNumber === null || profileData.HomeNumber.CountryCode === null) ? "" : profileData.HomeNumber.CountryCode,
                        Number: (profileData.HomeNumber === null || profileData.HomeNumber.Number === null) ? "" : profileData.HomeNumber.Number
                    },
                    FaxNumber: {
                        CountryCode: (profileData.FaxNumber === null || profileData.FaxNumber.CountryCode === null) ? "" : profileData.FaxNumber.CountryCode,
                        Number: (profileData.FaxNumber === null || profileData.FaxNumber.Number === null) ? "" : profileData.FaxNumber.Number
                    },
                    Address: {
                        AddressType: profileData.Address.AddressType,
                        PostalCode: profileData.Address.PostalCode === null ? "" : profileData.Address.PostalCode,
                        Country: profileData.Address.Country === null ? "" : profileData.Address.Country, // Only applicable to Address Type: Overseas
                        City: profileData.Address.City === null ? "" : profileData.Address.City,
                        State: profileData.Address.State === null ? "" : profileData.Address.State,
                        AddressLine1: {
                            AddressLine1: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.AddressLine1 === null) ? "" : profileData.Address.AddressLine1.AddressLine1,
                            BlockNo: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.BlockNo === null) ? "" : profileData.Address.AddressLine1.BlockNo,
                            StreetName: (profileData.Address.AddressLine1 === null || profileData.Address.AddressLine1.StreetName === null) ? "" : profileData.Address.AddressLine1.StreetName
                        },
                        AddressLine2: {
                            AddressLine2: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.AddressLine2 === null) ? "" : profileData.Address.AddressLine2.AddressLine2,
                            LevelNo: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.LevelNo === null) ? "" : profileData.Address.AddressLine2.LevelNo,
                            UnitNo: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.UnitNo === null) ? "" : profileData.Address.AddressLine2.UnitNo,
                            BuildingName: (profileData.Address.AddressLine2 === null || profileData.Address.AddressLine2.BuildingName === null) ? "" : profileData.Address.AddressLine2.BuildingName,
                        }
                    },
                    IdNumbersArr: idNumbersArr,
                    IdNumbers: JSON.stringify(idNumbersArr)
                }
            }
        });
    }

    async stopLoader() {
        // End loading
        if (this.state.countriesOptions !== [] && this.state.salutationsOptions !== [] && this.state.idTypesOptions !== []) {
            this.state.loader.done();
        }
    }

    onGenderClick = (value) => {
        this.setState({
            gender: value
        })
    }

    prepareToSubmit = (values, _submitForm) => {
        values.Profile.IdNumbers = JSON.stringify(values.Profile.IdNumbersArr);
        this.resetAddressDetails(values);

        _submitForm();
    }

    resetAddressDetails = (values) => {
        let Address = values.Profile.Address;
        let AddressLine1 = values.Profile.Address.AddressLine1;
        let AddressLine2 = values.Profile.Address.AddressLine2;

        if (Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL) {
            Address.Country = Address.City = Address.State = '';
            AddressLine1.AddressLine1 = AddressLine2.AddressLine2 = '';
        }
        else if (Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS) {
            AddressLine1.BlockNo = AddressLine1.StreetName = '';
            AddressLine2.LevelNo = AddressLine2.UnitNo = AddressLine2.BuildingName = '';
        }
    }


    render() {
        const { modal, getStaticText } = this.props;
        const { salutationsOptions, countriesOptions, idTypesConfig, countryCodesOptions, userValidation, formValidation } = this.state;

        const userType = this.state.values.UserType;
        const validationConfig = userValidation && userType && userValidation[userType];
        const validationSchema = formValidation && formValidation[userType];

        const translation = {
            getStaticText: getStaticText,
            translationKeys: UATranslationKeys
        };

        return (
            <>
                {validationConfig && validationSchema &&
                    <Layout type={LAYOUT_TYPE.FORM} translation={translation} title="Amend User Request">
                        <React.Fragment>
                            <SMARTForm
                                formValues={this.state.values}
                                validationSchema={validationSchema}
                                validateOnSubmit={true}
                                serverURL={SERVER_URL}
                                submitCallback={({ response }) => {
                                    const { IsSuccess, Messages, MessageLanguageKey, MessageDataValues } = response.body;
                                    if (response.success && IsSuccess) {
                                        navigateTo('/UserApproval/' + this.state.values.ActivationKey);
                                        toastSuccess('User request has been amended successfully.', null, translation);
                                    }
                                    else {
                                        let errorMsg = Messages === '' ? 'Failed to amend user request.' : Messages;
                                        if (MessageLanguageKey) {
                                            errorMsg = getStaticText(MessageLanguageKey, Messages);
                                        }
                                        toastError(errorMsg, translation, MessageDataValues);
                                    }
                                }}
                            >
                                {({ values, errors, onChange, onChangeField, submitForm }) => {
                                    values.Profile.Gender = this.state.gender;

                                    return (
                                        <React.Fragment>
                                            <Row className="body-content">
                                                <Col>
                                                    <SubSection orientation="left" translation={translation} title="Personal Details">
                                                        <Hidden
                                                            className="d-none"
                                                            name="ActivationKey"
                                                            value={values.ActivationKey}
                                                        />
                                                        <SelectList
                                                            name="Profile.Salutation"
                                                            value={values.Profile.Salutation}
                                                            options={salutationsOptions}
                                                            onChangeField={onChangeField}
                                                            placeholder={ValidationConfig.getPlaceholder("Salutation", "Salutation", validationConfig, getStaticText)}
                                                            isMulti={false}
                                                            isClearable={true}
                                                            inputSize={FIELD_SIZE.XSMALL}
                                                            label={ValidationConfig.getLabel("Salutation", "Salutation", validationConfig, getStaticText)}
                                                            error={errors.Profile && errors.Profile.Salutation}
                                                            required={ValidationConfig.getFieldRequired(true, "Salutation", validationConfig)}
                                                        />
                                                        <Text
                                                            name="Profile.FirstName"
                                                            value={values.Profile.FirstName}
                                                            placeholder={ValidationConfig.getPlaceholder("First Name", "FirstName", validationConfig, getStaticText)}
                                                            onChange={onChange}
                                                            minLength={ValidationConfig.getMinValue(0, "FirstName", validationConfig)}
                                                            maxLength={ValidationConfig.getMaxValue(140, "FirstName", validationConfig)}
                                                            inputSize={inputSize}
                                                            label={ValidationConfig.getLabel("First Name", "FirstName", validationConfig, getStaticText)}
                                                            error={errors.Profile && errors.Profile.FirstName}
                                                            required={ValidationConfig.getFieldRequired(true, "FirstName", validationConfig)}
                                                        />
                                                        <Text
                                                            name="Profile.MiddleName"
                                                            value={values.Profile.MiddleName}
                                                            placeholder={ValidationConfig.getPlaceholder("Middle Name", "MiddleName", validationConfig, getStaticText)}
                                                            onChange={onChange}
                                                            minLength={ValidationConfig.getMinValue(0, "MiddleName", validationConfig)}
                                                            maxLength={ValidationConfig.getMaxValue(140, "MiddleName", validationConfig)}
                                                            inputSize={inputSize}
                                                            label={ValidationConfig.getLabel("Middle Name", "MiddleName", validationConfig, getStaticText)}
                                                            required={ValidationConfig.getFieldRequired(false, "MiddleName", validationConfig)}
                                                        />
                                                        <Text
                                                            name="Profile.LastName"
                                                            value={values.Profile.LastName}
                                                            placeholder={ValidationConfig.getPlaceholder("Last Name", "LastName", validationConfig, getStaticText)}
                                                            onChange={onChange}
                                                            minLength={ValidationConfig.getMinValue(0, "LastName", validationConfig)}
                                                            maxLength={ValidationConfig.getMaxValue(140, "LastName", validationConfig)}
                                                            inputSize={inputSize}
                                                            label={ValidationConfig.getLabel("Last Name", "LastName", validationConfig, getStaticText)}
                                                            error={errors.Profile && errors.Profile.LastName}
                                                            required={ValidationConfig.getFieldRequired(true, "LastName", validationConfig)}
                                                        />
                                                        <FormGroup row>
                                                            <Label lg={FIELD_SIZE.SMALL} className="label-default required">
                                                                {ValidationConfig.getLabel("Gender", "Gender", validationConfig, getStaticText)}
                                                            </Label>
                                                            <Col lg={FIELD_SIZE.MEDIUM}>
                                                                <div className="segmented-select-parent">
                                                                    <div className="segment-select">
                                                                        {this.state.gender !== undefined &&
                                                                            <GenderOptions
                                                                                setGender={this.onGenderClick}
                                                                                gender={this.state.gender}
                                                                                translation={translation}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                        <DateInput
                                                            name="Profile.DateOfBirth"
                                                            value={values.Profile.DateOfBirth}
                                                            placeholder={ValidationConfig.getPlaceholder("Date Of Birth", "DateOfBirth", validationConfig, getStaticText)}
                                                            onChangeField={onChangeField}
                                                            time={false}
                                                            date={true}
                                                            dropUp={true}
                                                            max={ValidationConfig.getMaxValue(new Date(), "DateOfBirth", validationConfig)}
                                                            format={DATE_DISPLAY_FORMAT.DATE}
                                                            inputSize={FIELD_SIZE.SMALL}
                                                            label={ValidationConfig.getLabel("Date Of Birth", "DateOfBirth", validationConfig, getStaticText)}
                                                            error={errors.Profile && errors.Profile.DateOfBirth}
                                                            disableKeyboardInput={false}
                                                            required={ValidationConfig.getFieldRequired(true, "DateOfBirth", validationConfig)}
                                                        />
                                                        {Object.keys(idTypesConfig).length > 0 &&
                                                            <IdComponent
                                                                nationalityField={{
                                                                    name: "Profile.Nationality",
                                                                    label: ValidationConfig.getLabel("Nationality", "Nationality", validationConfig, getStaticText),
                                                                    placeholder: ValidationConfig.getLabel("Nationality", "Nationality", validationConfig, getStaticText),
                                                                    value: values.Profile.Nationality,
                                                                    error: errors.Profile && errors.Profile.Nationality,
                                                                    required: ValidationConfig.getFieldRequired(true, "Nationality", validationConfig),
                                                                    countriesOptions: countriesOptions,
                                                                    translation: translation
                                                                }}
                                                                idNumbersField={{
                                                                    name: "Profile.IdNumbersArr",
                                                                    label: ValidationConfig.getLabel("Identification Number", "IdNumbers", validationConfig, getStaticText),
                                                                    placeholder: ValidationConfig.getPlaceholder("Identification Number", "IdNumbers", validationConfig, getStaticText),
                                                                    value: values.Profile.IdNumbersArr,
                                                                    minLength: ValidationConfig.getMinValue(0, "IdNumbers", validationConfig),
                                                                    maxLength: ValidationConfig.getMaxValue(140, "IdNumbers", validationConfig),
                                                                    error: errors.Profile && errors.Profile.IdNumbers,
                                                                    required: ValidationConfig.getFieldRequired(true, "IdNumbers", validationConfig)
                                                                }}
                                                                idTypesConfig={idTypesConfig}
                                                                onChangeField={onChangeField}
                                                            />
                                                        }
                                                    </SubSection>
                                                    <SubSection orientation="left" translation={translation} title="Contact Info">
                                                        <Plaintext
                                                            label="Primary Email Address"
                                                            name="Email"
                                                            value={values.Email}
                                                            translation={translation}
                                                        />
                                                        <Text
                                                            name="Profile.AltEmailAddress"
                                                            value={values.Profile.AltEmailAddress}
                                                            placeholder={ValidationConfig.getPlaceholder("Alternate Email Address", "AltEmailAddress", validationConfig, getStaticText)}
                                                            onChange={onChange}
                                                            minLength={ValidationConfig.getMinValue(0, "AltEmailAddress", validationConfig)}
                                                            maxLength={ValidationConfig.getMaxValue(140, "AltEmailAddress", validationConfig)}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={inputSize}
                                                            label={ValidationConfig.getLabel("Alternate Email Address", "AltEmailAddress", validationConfig, getStaticText)}
                                                            error={errors.Profile && errors.Profile.AltEmailAddress}
                                                            required={ValidationConfig.getFieldRequired(false, "AltEmailAddress", validationConfig)}
                                                        />
                                                        <SelectList
                                                            name="Profile.PrimaryContact"
                                                            value={values.Profile.PrimaryContact}
                                                            options={[
                                                                { label: "Office Number", value: "Office" },
                                                                { label: "Mobile Number", value: "Mobile" },
                                                                { label: "Home Number", value: "Home" }]}
                                                            onChangeField={onChangeField}
                                                            placeholder={ValidationConfig.getPlaceholder("Primary Contact", "PrimaryContact", validationConfig, getStaticText)}
                                                            isMulti={false}
                                                            inputSize={inputSize}
                                                            label={ValidationConfig.getLabel("Primary Contact", "PrimaryContact", validationConfig, getStaticText)}
                                                            translation={translation}
                                                            error={errors.Profile && errors.Profile.PrimaryContact}
                                                            required={ValidationConfig.getFieldRequired(true, "PrimaryContact", validationConfig)}
                                                        />
                                                        <Row>
                                                            <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(true, "MobileNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.MobileNumber !== undefined &&
                                                                    errors.Profile.MobileNumber.CountryCode !== undefined &&
                                                                    errors.Profile.MobileNumber.Number !== undefined ?
                                                                    "label-error" : "label-default"}`}>
                                                                {ValidationConfig.getLabel("Mobile Number", "MobileNumber", validationConfig, getStaticText)}
                                                            </Label>
                                                            <Col lg="5">
                                                                <SelectList
                                                                    name="Profile.MobileNumber.CountryCode"
                                                                    value={values.Profile.MobileNumber.CountryCode}
                                                                    options={countryCodesOptions}
                                                                    onChangeField={onChangeField}
                                                                    placeholder="Country Code"
                                                                    isMulti={false}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.MobileNumber && errors.Profile.MobileNumber.CountryCode}
                                                                />
                                                            </Col>
                                                            <Col lg="3">
                                                                <Text
                                                                    name="Profile.MobileNumber.Number"
                                                                    placeholder="Number"
                                                                    value={values.Profile.MobileNumber.Number}
                                                                    onChange={onChange}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.MobileNumber && errors.Profile.MobileNumber.Number}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "OfficeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.OfficeNumber !== undefined &&
                                                                    errors.Profile.OfficeNumber.CountryCode !== undefined &&
                                                                    errors.Profile.OfficeNumber.Number !== undefined ?
                                                                    "label-error" : "label-default"}`}>
                                                                {ValidationConfig.getLabel(" Office Number", "OfficeNumber", validationConfig, getStaticText)}
                                                            </Label>
                                                            <Col lg="5">
                                                                <SelectList
                                                                    name="Profile.OfficeNumber.CountryCode"
                                                                    value={values.Profile.OfficeNumber.CountryCode}
                                                                    options={countryCodesOptions}
                                                                    onChangeField={onChangeField}
                                                                    placeholder="Country Code"
                                                                    isClearable={true}
                                                                    isMulti={false}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.OfficeNumber && errors.Profile.OfficeNumber.CountryCode}
                                                                />
                                                            </Col>
                                                            <Col lg="3">
                                                                <Text
                                                                    name="Profile.OfficeNumber.Number"
                                                                    placeholder="Number"
                                                                    value={values.Profile.OfficeNumber.Number}
                                                                    onChange={onChange}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.OfficeNumber && errors.Profile.OfficeNumber.Number}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "HomeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.HomeNumber !== undefined &&
                                                                    errors.Profile.HomeNumber.CountryCode !== undefined &&
                                                                    errors.Profile.HomeNumber.Number !== undefined ?
                                                                    "label-error" : "label-default"}`}>
                                                                {ValidationConfig.getLabel(" Home Number", "HomeNumber", validationConfig, getStaticText)}
                                                            </Label>
                                                            <Col lg="5">
                                                                <SelectList
                                                                    name="Profile.HomeNumber.CountryCode"
                                                                    value={values.Profile.HomeNumber.CountryCode}
                                                                    options={countryCodesOptions}
                                                                    onChangeField={onChangeField}
                                                                    placeholder="Country Code"
                                                                    isClearable={true}
                                                                    isMulti={false}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.HomeNumber && errors.Profile.HomeNumber.CountryCode}
                                                                />
                                                            </Col>
                                                            <Col lg="3">
                                                                <Text
                                                                    name="Profile.HomeNumber.Number"
                                                                    placeholder="Number"
                                                                    value={values.Profile.HomeNumber.Number}
                                                                    onChange={onChange}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.HomeNumber && errors.Profile.HomeNumber.Number}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label lg="3" className={` 
                                                                ${ValidationConfig.getFieldRequired(false, "HomeNumber", validationConfig) ? "required " : " "} 
                                                                ${errors.Profile !== undefined && errors.Profile.FaxNumber !== undefined &&
                                                                    errors.Profile.FaxNumber.CountryCode !== undefined &&
                                                                    errors.Profile.FaxNumber.Number !== undefined ?
                                                                    "label-error" : "label-default"}`}>
                                                                {ValidationConfig.getLabel(" Fax Number", "FaxNumber", validationConfig, getStaticText)}
                                                            </Label>
                                                            <Col lg="5">
                                                                <SelectList
                                                                    name="Profile.FaxNumber.CountryCode"
                                                                    value={values.Profile.FaxNumber.CountryCode}
                                                                    options={countryCodesOptions}
                                                                    onChangeField={onChangeField}
                                                                    placeholder="Country Code"
                                                                    isClearable={true}
                                                                    isMulti={false}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.FaxNumber && errors.Profile.FaxNumber.CountryCode}
                                                                />
                                                            </Col>
                                                            <Col lg="3">
                                                                <Text
                                                                    name="Profile.FaxNumber.Number"
                                                                    placeholder="Number"
                                                                    value={values.Profile.FaxNumber.Number}
                                                                    onChange={onChange}
                                                                    labelSize={FIELD_SIZE.NONE}
                                                                    inputSize={FIELD_SIZE.MAX}
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.FaxNumber && errors.Profile.FaxNumber.Number}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </SubSection>
                                                    <SubSection orientation="left" translation={translation} title="Address Details">
                                                        <Radio
                                                            name="Profile.Address.AddressType"
                                                            value={values.Profile.Address.AddressType}
                                                            options={ValidationConfig.getAddressTypeOptions(true, "Address", validationConfig, values)}
                                                            onChange={onChange}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            label="Address Type"
                                                            translation={translation}
                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressType}
                                                            required
                                                        />
                                                        {values.Profile.Address.AddressType !== OPTIONAL_ADDRESS_TYPE.NONE &&
                                                            <>
                                                                <Text
                                                                    name="Profile.Address.PostalCode"
                                                                    value={values.Profile.Address.PostalCode}
                                                                    onChange={onChange}
                                                                    labelSize={FIELD_SIZE.SMALL}
                                                                    inputSize={FIELD_SIZE.SMALL}
                                                                    placeholder="ZIP / Postal Code"
                                                                    label="ZIP / Postal Code"
                                                                    translation={translation}
                                                                    error={errors.Profile && errors.Profile.Address && errors.Profile.Address.PostalCode}
                                                                    required
                                                                />
                                                                {values.Profile.Address.AddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                                                    <>
                                                                        <Text
                                                                            name="Profile.Address.AddressLine1.BlockNo"
                                                                            value={values.Profile.Address.AddressLine1.BlockNo}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.SMALL}
                                                                            placeholder="Block / House No."
                                                                            label="Block / House No."
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.BlockNo}
                                                                            required
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine1.StreetName"
                                                                            value={values.Profile.Address.AddressLine1.StreetName}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                                            placeholder="Street Name"
                                                                            label="Street Name"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.StreetName}
                                                                            required
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine2.LevelNo"
                                                                            value={values.Profile.Address.AddressLine2.LevelNo}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.XSMALL}
                                                                            placeholder="Level"
                                                                            label="Level"
                                                                            translation={translation}
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine2.UnitNo"
                                                                            value={values.Profile.Address.AddressLine2.UnitNo}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.XSMALL}
                                                                            placeholder="Unit No."
                                                                            label="Unit No."
                                                                            translation={translation}
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine2.BuildingName"
                                                                            value={values.Profile.Address.AddressLine2.BuildingName}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                                            placeholder="Building Name"
                                                                            label="Building Name"
                                                                            translation={translation}
                                                                        />
                                                                    </>
                                                                }
                                                                {values.Profile.Address.AddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                                                    <>
                                                                        <SelectList
                                                                            name="Profile.Address.Country"
                                                                            value={values.Profile.Address.Country}
                                                                            options={countriesOptions.filter((country) => {
                                                                                return country.value !== DEFAULT_HOME_COUNTRY
                                                                            })}
                                                                            onChangeField={onChangeField}
                                                                            placeholder="Country"
                                                                            isMulti={false}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.LARGE}
                                                                            label="Country"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.Country}
                                                                            required
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.City"
                                                                            value={values.Profile.Address.City}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.SMALL}
                                                                            placeholder="City"
                                                                            label="City"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.City}
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.State"
                                                                            value={values.Profile.Address.State}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.SMALL}
                                                                            placeholder="State / Province / Region"
                                                                            label="State / Province / Region"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.State}
                                                                            required
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine1.AddressLine1"
                                                                            value={values.Profile.Address.AddressLine1.AddressLine1}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.LARGE}
                                                                            placeholder="Address Line 1"
                                                                            // helpLabel="Address Line 1"
                                                                            label="Address Line 1"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine1 && errors.Profile.Address.AddressLine1.AddressLine1}
                                                                            required
                                                                        />
                                                                        <Text
                                                                            name="Profile.Address.AddressLine2.AddressLine2"
                                                                            value={values.Profile.Address.AddressLine2.AddressLine2}
                                                                            onChange={onChange}
                                                                            labelSize={FIELD_SIZE.SMALL}
                                                                            inputSize={FIELD_SIZE.LARGE}
                                                                            placeholder="Address Line 2"
                                                                            // helpLabel="Address Line 2"
                                                                            label="Address Line 2"
                                                                            translation={translation}
                                                                            error={errors.Profile && errors.Profile.Address && errors.Profile.Address.AddressLine2 && errors.Profile.Address.AddressLine2.AddressLine2}
                                                                            required
                                                                        />
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </SubSection>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <PageFooter>
                                                    <Button color="primary" type="button" onClick={() => navigateTo('/UserApproval/' + values.ActivationKey)}>
                                                        {BUTTON_TITLE(getStaticText).REVIEW}
                                                    </Button>
                                                    <Button color="forward" type="button"
                                                        onClick={
                                                            () => {
                                                                if (isValidForm(validationSchema, values)) {
                                                                    this.resetAddressDetails(values);
                                                                    modal.toggleModal("Submit")
                                                                } else {
                                                                    submitForm()
                                                                }
                                                            }}>
                                                        {BUTTON_TITLE(getStaticText).SUBMIT}
                                                    </Button>
                                                    <ModalConfirm
                                                        translation={translation}
                                                        isOpen={modal.modalState === "Submit"}
                                                        contentHeader="User Approval Confirmation"
                                                        contentBody="Are you sure you want to amend this user request?"
                                                        confirmCallback={() => {
                                                            modal.toggleModal("Submit");
                                                            this.prepareToSubmit(values, submitForm);
                                                        }}
                                                        cancelCallback={() => modal.toggleModal("Submit")}
                                                    />
                                                </PageFooter>
                                            </Row>
                                        </React.Fragment>
                                    )
                                }}
                            </SMARTForm>
                        </React.Fragment>
                    </Layout>
                }
            </>
        )
    }
}


export default withSMARTWrapper(withRouter(AmendUserRequest));