export const TranslationKeys = [
    {
        "DefaultLanguageValue": "ID Type",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IdentificationPlaceholder",
        "TranslationKey": "IDType"
    },
    {
        "DefaultLanguageValue": "NRIC",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IDType",
        "TranslationKey": "NRIC"
    },
    {
        "DefaultLanguageValue": "Passport",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IDType",
        "TranslationKey": "Passport"
    },
    {
        "DefaultLanguageValue": "ID Number",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IdentificationPlaceholder",
        "TranslationKey": "IDNumber"
    },
    {
        "DefaultLanguageValue": "Issuing Country",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "IdentificationPlaceholder",
        "TranslationKey": "IssuingCountry"
    }
]