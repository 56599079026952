export const TitleTranslationKeys = [
    {
        "DefaultLanguageValue": "Pending User Requests",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "PendingUserRequests"
    },
    {
        "DefaultLanguageValue": "User Approval",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "UserApproval"
    },
    {
        "DefaultLanguageValue": "Amend User Request",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "AmendUserRequest"
    }
]