export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "Role Name",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "RoleName"
    },
    {
        "DefaultLanguageValue": "Display Role Name",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "DisplayRoleName"
    },
    {
        "DefaultLanguageValue": "Organisation Specific?",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "OrganisationSpecific"
    },
    {
        "DefaultLanguageValue": "Role Guid",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "RoleGuid"
    },
    {
        "DefaultLanguageValue": "Permission",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Permission"
    },
    {
        "DefaultLanguageValue": "Organisation Specific is required.",
        "Scope": "MeRole",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "OrganisationSpecificRequired"
    }
]