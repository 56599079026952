import { Component } from 'react';
import AuthHelper from '../../Shared/Authentication/AuthHelper';
import { withRouter } from 'react-router-dom'

class Logout extends Component {
  componentDidMount() {
    AuthHelper.getManager().signoutRedirect();
  }

  render() {
    return null;
  }
}

export default withRouter(Logout);