import React from 'react';
import Form from './Form.js';
import { withRouter } from 'react-router-dom';
import { withLoader } from '../../Shared/Loading';
import { withFormAlert } from '../../Shared//Forms';
import { URL } from '../../Shared/SmartMe/Constants';
import { selectListMapper } from '../../Shared/SmartMe/Actions';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { MODAL_TITLE } from '../../Localization';

const SERVER_URL = URL + 'UserGroup/CreateUserGroup';

class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            values: {
                Id: 0,
                GroupName: '',
                Description: '',
                Organisation: '',
                AppId: '',
                IsMeOrganisationAdmin: '',
                Roles: new Map(),
                UsersToUserGroup: []
            },
            AppIds: []
        }
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        let appIDRequest = await fetchRequest(URL + "App/GetAppsByUserId", getParams(), false);

        if (appIDRequest.success && appIDRequest.body.IsSuccess) {
            let selection = appIDRequest.body.Data;
            const mappedSelection = selectListMapper(selection, "AppId", "AppName");

            this.setState({
                ...this.state,
                AppIds: mappedSelection,
                isLoaded: true
            });
        }
        else {
            const { formAlert } = this.props;
            formAlert.showAlert(appIDRequest.body.messages || appIDRequest.body.Messages);
        }
        loader.done();
    }

    render() {
        const { getStaticText } = this.props;
        return (
            <>
                {this.state.isLoaded &&
                    <Form
                        values={this.state.values}
                        server_url={SERVER_URL}
                        title="New User Group"
                        contentHeader={MODAL_TITLE(getStaticText).CREATE.HEADER}
                        contentBody={"Confirm creation of User Group with the following roles?"}
                        prevState={this.state}
                        action="New"
                    />
                }
            </>
        )
    }
}
export default withFormAlert(withLoader(withRouter(New)));
















