import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { withTranslationWrapper } from './TranslationWrapper';
import { withBaseControl } from './BaseControl';
import { getTranslationValue } from '../../Shared/Forms/FormUtils';
import { DEFAULT_APPLICATION_NAME } from '../Constants/LanguageKeys'

const SHOW = "SHOW";
const SHOW_KEY = `${DEFAULT_APPLICATION_NAME}.SMARTMe.Navigation.Button.Show`;

const HIDE = "HIDE";
const HIDE_KEY = `${DEFAULT_APPLICATION_NAME}.SMARTMe.Navigation.Button.Hide`;

class Password extends React.Component {
    constructor(props) {
        super(props);
        this.state = { passwordType: 'password' }
    }

    showHidePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordType: this.state.passwordType === 'input' ? 'password' : 'input'
        })
    }

    render() {
        const { placeholder, getStaticText, translation } = this.props;
        const translationKeys = translation && translation.translationKeys;
        const placeholderValue = getStaticText && translationKeys ? getTranslationValue(placeholder, getStaticText, translationKeys) : placeholder;

        return (
            <div className="password-container">
                <Input
                    type={this.state.passwordType}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={placeholderValue}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                />
                <span className="show-hide-password" onClick={this.showHidePassword}>
                    {
                        this.state.passwordType === 'input' ?
                            getStaticText(HIDE_KEY, HIDE) :
                            getStaticText(SHOW_KEY, SHOW)
                    }
                </span>
            </div>
        );
    }
}

// PropTypes: For prop validation
Password.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};


export default compose(withTranslationWrapper, withBaseControl)(Password);