import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class Hidden extends React.Component {
    
    render() {
        return (
            <InputGroup>
                <Input
                    type="hidden"
                    name={this.props.name}
                    value={this.props.value}
                />
            </InputGroup>
        );
    }
}

// PropTypes: For prop validation
Hidden.propTypes = {
    name: PropTypes.string
};


export default withBaseControl(Hidden);