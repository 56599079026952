export const TranslationKeys = [
    {
        "DefaultLanguageValue": "Organisation",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Organisation"
    },
    {
        "DefaultLanguageValue": "Please fill up your Identification Number.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "IdRequired"
    },
    {
        "DefaultLanguageValue": "Please fill up all Identification Number fields.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "IdIncomplete"
    },
    {
        "DefaultLanguageValue": "Country Code is required.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "CountryCodeRequired"
    },
    {
        "DefaultLanguageValue": "Contact Number is required.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "ContactNumberRequired"
    },
    {
        "DefaultLanguageValue": "Contact Number is an invalid number.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "ContactNumberInvalid"
    },
    {
        "DefaultLanguageValue": "Postal Code is an invalid number.",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Validation",
        "TranslationKey": "PostalCodeInvalid"
    }    
]