import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getTranslationValue } from '../../Shared/Forms/FormUtils';
import { withBaseControl } from './BaseControl';
import { DATE_DISPLAY_FORMAT } from '../Constants';


momentLocalizer();

class DateInput extends React.Component {
    state = {
        isOpen: false
    };

    clearField = () => {
        const { onChange, onChangeField, name } = this.props;
        if (onChange)
            onChange('');

        if (onChangeField)
            onChangeField(name, '');
    }

    formatDateToString = (dateFormat, e) => {
        const { onChange, onChangeField } = this.props;
        const eMoment = moment(e).format(dateFormat);
        e = this.isValidDate(eMoment) ? eMoment.toString() : '';

        if (onChange)
            onChange(e);

        if (onChangeField)
            onChangeField(this.props.name, e);
    }

    onChangeDate = (e) => {
        if (this.props.time === true && this.props.date === true) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.DATE_TIME, e);
        } else if (this.props.time === false && this.props.date === true) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.DATE, e);
        } else if (this.props.time === true && this.props.date === false) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.TIME, e);
        }
        this.setState({ isOpen: false });
    };

    isValidDate = (eMoment) => {
        if (eMoment === "Invalid date") {
            return false;
        }
        return true;
    };

    render() {
        const { placeholder, translation } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const placeholderValue = getStaticText && translationKeys ? getTranslationValue(placeholder, getStaticText, translationKeys) : placeholder;

        return (
            <div className={(this.props.date && this.props.time) ? "datepicker" : "datetimepicker"}>
                <DateTimePicker
                    placeholder={placeholderValue}
                    name={this.props.name}
                    value={this.props.value ? moment(this.props.value, this.props.format).toDate() : null}
                    onChange={e => this.onChangeDate(e)}
                    time={this.props.time}
                    date={this.props.date}
                    dropUp={this.props.dropUp}
                    format={this.props.format}
                    timeFormat={this.props.time ? this.props.format : ''}
                    step={this.props.timeIntervalMins}
                    min={new Date(this.props.min)}
                    max={new Date(this.props.max)}
                    inputProps={{ readOnly: this.props.disableKeyboardInput }}
                    disabled={this.props.disabled}
                    onToggle={this.props.onToggle}
                    onFocus={this.props.disabled ? () => { } :
                        () => {
                            this.setState({
                                isOpen: true,
                            })
                        }}
                    onBlur={() => { this.setState({ isOpen: false }) }}
                />
                {this.props.clearFieldEnabled && !this.props.disabled ? ((this.props.value !== "") ?
                    <span className="clearField" onClick={() => this.clearField()}><FontAwesomeIcon className="" icon={faTimes} /></span>
                    : "") : ""}
            </div>
        );
    }
}

//PropTypes: For prop validation
DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    timeIntervalMins: PropTypes.number,
    clearFieldEnabled: PropTypes.bool,
    disableKeyboardInput: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
DateInput.defaultProps = {
    min: "1900-01-01 00:00",
    max: "2100-12-31 00:00",
    clearFieldEnabled: true,
    disableKeyboardInput: true,
    disabled: false,
    onToggle: () => { }
};


export default withBaseControl(DateInput);


