import React from 'react';
import { Collapse } from 'reactstrap';
class NavLayout1M extends React.Component {
    
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }
    
    //Mobile Responsive Listener
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight });
    };
  
    constructor(props) {
        super(props);
        this.toggleSidebar.bind(this);
        this.toggleIsCollapsing.bind(this);
        this.state = {
            height: window.innerHeight,
        }
    }

    //Toggle navbar
    toggleSidebar(){
        this.props.onClick();
    }

    //Prevent navbar from being opened immediately after being closed
    toggleIsCollapsing(){
        this.props.onUpdate();
    }

    //Closes Navbar on any click outside of the navbar
    handleClickOutside = (e) => {
        if (!this.node.contains(e.target) && this.props.collapseNavbar === true) {
            this.toggleSidebar();
        }
    }

    render() {
        return (
            <div ref={node => this.node = node}>
                <Collapse 
                isOpen={this.props.collapseNavbar} className="layout-1m"
                onExiting ={() => {this.toggleIsCollapsing()}}  
                onExited ={() => {this.toggleIsCollapsing()}}
                style={{maxHeight: (this.state.height-50) + "px"}} // inline style used so that max height will change on screen resize
                >
                    <div className="layout-1m-container"> 
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        );
    }
}


export default NavLayout1M;