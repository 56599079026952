import 'core-js/es/object';
import React from 'react';
import { Router } from 'react-router-dom';
import Content from '../Content';
import { AuthProvider } from '../Shared/Authentication/AuthContext';
import { withDirectionContext } from '../Shared/DirectionContext/DirectionContextWrapper';
import history from '../Shared/History';
import Css from '../Css';

class App extends React.Component {
  render() {
    const { isLangLoaded } = this.props.context;

    return (
      <>
        { isLangLoaded &&
          <Router history={history}>
            <AuthProvider>
              <Css />
              <Content />
            </AuthProvider>
          </Router>
        }
      </>
    );
  }
}

export default withDirectionContext(App);