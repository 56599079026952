import { FormTranslationKeys } from './FormTranslationKeys';
import { TitleTranslationKeys } from './TitleTranslationKeys';
import { SubTitleTranslationKeys } from './SubTitleTranslationKeys';
import { ModalTranslationKeys } from './ModalTranslationKeys';
import { ErrorTranslationKeys } from './ErrorTranslationKeys';

export const TranslationKeys = FormTranslationKeys
    .concat(TitleTranslationKeys)
    .concat(SubTitleTranslationKeys)
    .concat(ModalTranslationKeys)
    .concat(ErrorTranslationKeys);