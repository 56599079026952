import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, CardBody, Collapse, Col, Row } from 'reactstrap';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import ReactHtmlParser from 'react-html-parser';
import { ActionButton } from '../../Shared/DataTable';
import './Faq.css';

class QuestionSetCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            collapseIcon: faAngleDown
        };
    }

    Caret = () => {
        return (this.props.active) ? faAngleUp : faAngleDown;
    };

    toggleCollapse = () => {
        this.setState((prevState) => {
            return {
                active: (!prevState.active),
                collapseIcon: ((prevState.active) ? (faAngleDown) : (faAngleUp))
            }
        });
    };

    render() {
        const { keyData } = this.props;

        return (
            <>
                <Card className="card-container qnset-card-container">
                    <CardBody className="key-card-body">
                        <Row>
                            <Col xs="1">
                                <div className="key-card-action-button action-button-group">
                                    <ActionButton 
                                        className="caret-icon" 
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_DISPLAYANSWER, DEFAULT_TEXT.DISPLAY_ANSWER)}
                                        color="link" 
                                        onClick={() => this.toggleCollapse()}>
                                        <FontAwesomeIcon fixedWidth icon={this.state.collapseIcon} />
                                    </ActionButton>
                                </div>
                            </Col>
                            <Col xs="8">
                                <div><b>{keyData.PrimaryQuestion}</b></div>
                            </Col>
                            <Col xs="3">
                                <div className="key-card-action-button action-button-group">
                                    {this.props.children}
                                </div>
                            </Col>
                        </Row>

                        <Collapse isOpen={this.state.active}>
                            <Row className="qnset-key-row">
                                <Col xs={{ size: 8, offset: 1 }}>
                                    {ReactHtmlParser(keyData.Answer)}
                                </Col>
                            </Row>
                        </Collapse>
                    </CardBody>
                </Card>

            </>
        );
    }
}

export default withSMARTWrapper(QuestionSetCard);