export const AddressTranslationKeys = [
    {
        "DefaultLanguageValue": "None",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "AddressType",
        "TranslationKey": "None"
    },
    {
        "DefaultLanguageValue": "Local",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "AddressType",
        "TranslationKey": "Local"
    },
    {
        "DefaultLanguageValue": "Overseas",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "AddressType",
        "TranslationKey": "Overseas"
    },
    {
        "DefaultLanguageValue": "Address Type",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "AddressType"
    },
    {
        "DefaultLanguageValue": "ZIP / Postal Code",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "ZIPPostalCode"
    },
    {
        "DefaultLanguageValue": "Block / House No.",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "BlockNo"
    },
    {
        "DefaultLanguageValue": "Street Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "StreetName"
    },
    {
        "DefaultLanguageValue": "Level",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Level"
    },
    {
        "DefaultLanguageValue": "Unit No.",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "UnitNo"
    },
    {
        "DefaultLanguageValue": "Building Name",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "BuildingName"
    },
    {
        "DefaultLanguageValue": "Country",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "Country"
    },
    {
        "DefaultLanguageValue": "City",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "City"
    },
    {
        "DefaultLanguageValue": "State / Province / Region",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "StateProvinceRegion"
    },
    {
        "DefaultLanguageValue": "Address Line 1",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "AddressLine1"
    },
    {
        "DefaultLanguageValue": "Address Line 2",
        "Scope": "SMARTMe",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "AddressLine2"
    }
]