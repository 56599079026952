import React from 'react';
import { Form } from 'reactstrap';
import { navigateTo } from '../Actions';
import { withBaseForm } from './BaseForm';
import history from '../History';


class SMARTView extends React.Component {
    
    render() {
        const { baseForm, formContext, formValues } = this.props;
        const { sectionState, toggleSection, submitForm } = baseForm;

        return (
            <Form className="smart-content">
                {this.props.children({
                    sectionState,
                    toggleSection,
                    submitForm,
                    exitPreview: () => navigateTo(history.location.state.from, { [formContext]: formValues })
                })}
            </Form>
        );
    }
}

// PropTypes: refer to BaseForm.js


export default withBaseForm(SMARTView);