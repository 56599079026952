import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { withBaseControl } from './BaseControl';
import { getTranslationValue } from './FormUtils';


class Plaintext extends React.Component {

    displayTextOrLink = (name, value, isFile, unitOfMeasurement) => {

        const displayedValue = (value) ? (`${(value.name) ? (value.name) : ((value.Filename) ? value.Filename : value)} ${unitOfMeasurement}`) : ('-');

        if (isFile && value) {
            if (value.Filename !== undefined) {
                return (<a href={this.props.getFileURL + "id=" + value.Id + "&fileName=" + value.Filename} className="download-link-details">Download File ({value.ContentLength}KB/{value.ContentType.toUpperCase()})</a>);
            }
            else {
                return (
                    <Label
                        className={"plaintext form-control-plaintext" + (this.props.className ? " " + this.props.className : "")}
                        name={name}
                    >
                        {(this.props.isUpper) ? displayedValue.toUpperCase() : displayedValue}
                    </Label>
                );
            }
        }
        else {
            return (
                <Label
                    className={"plaintext form-control-plaintext" + (this.props.className ? " " + this.props.className : "")}
                    name={name}
                >
                    {(this.props.isUpper) ? displayedValue.toUpperCase() : displayedValue}
                </Label>
            );
        }
    }

    render() {
        const { name, value, isFile, unitOfMeasurement, translation, translateValue } = this.props;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        const valueText = translateValue && getStaticText && translationKeys ? 
            getTranslationValue(value, getStaticText, translationKeys) : value;

        return (
            this.displayTextOrLink(name, valueText, isFile, unitOfMeasurement)
        );
    }
}

// PropTypes: For prop validation
Plaintext.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    unitOfMeasurement: PropTypes.string,
    isFile: PropTypes.bool,
    isUpper: PropTypes.bool,
    translateValue: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
Plaintext.defaultProps = {
    unitOfMeasurement: "",
    isFile: false,
    isUpper: false,
    translateValue: false
};


export default withBaseControl(Plaintext);