import * as Yup from 'yup';
import _ from 'lodash';
import { ERROR, REQUIRED_VALIDATION_MESSAGE, INVALID_NUMBER_MESSAGE } from '../../../../Localization';
import { DEFAULT_NAMESPACE } from '../../../../Shared/Constants/LanguageKeys.js';
import { ADDRESS, USER_TYPE, LANGUAGE_APPLICATION_NAME } from '../../../../App/AppSettings';
import { isEmptyValue, getTranslationKeys, getStaticTextFn } from '../../../Forms/FormUtils';
import { GlobalTranslationKeys } from '../../../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';

const App = LANGUAGE_APPLICATION_NAME;
const ValidationKeys = getTranslationKeys(App, GlobalTranslationKeys.concat(TranslationKeys));

const COUNTRY_CODE_VALIDATION = REQUIRED_VALIDATION_MESSAGE("Country Code");
const CONTACT_NUMBER_VALIDATION = REQUIRED_VALIDATION_MESSAGE("Contact Number");
const CONTACT_NUMBER_INVALID_NUMBER = INVALID_NUMBER_MESSAGE("Contact Number");
const POSTAL_CODE_INVALID_NUMBER = INVALID_NUMBER_MESSAGE("Postal Code");

const stringValidationRule = (getStaticText) => {
    const requiredMsg = getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED);
    return Yup.string().required(requiredMsg);
};

const IDIncompleteValidationRule = (getStaticText) => {
    const defaultIncompleteMsg = getStaticTextFn(ValidationKeys, getStaticText, 'Please fill up all Identification Number fields.');

    return (
        Yup.array().test('IdNumberIncomplete', defaultIncompleteMsg,
            function () {
                const idNumbers = this.resolve(Yup.ref("IdNumbersArr"));
                let isCompletedId = true;

                // Check: Id numbers filled up properly
                idNumbers.forEach(idNo => {
                    const emptyId =
                        !idNo.IdType && !idNo.IdNumber && !idNo.IdCountry;

                    const completedId =
                        idNo.IdType && idNo.IdNumber && idNo.IdCountry;

                    if (!emptyId && !completedId) {
                        isCompletedId = false;
                        return;
                    }
                })
                return isCompletedId;
            })
    )
}

const IDIncompletedAndRequiredValidationRule = (getStaticText) => {

    const defaultRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, 'Please fill up your Identification Number.');
    const defaultIncompleteMsg = getStaticTextFn(ValidationKeys, getStaticText, 'Please fill up all Identification Number fields.');

    return (
        Yup.array()
            .test('IdNumberIncomplete', defaultIncompleteMsg,
                function () {
                    const idNumbers = this.resolve(Yup.ref("IdNumbersArr"));
                    let isCompletedId = true;

                    // Check: Id numbers filled up properly
                    idNumbers.forEach(idNo => {
                        const emptyId =
                            !idNo.IdType && !idNo.IdNumber && !idNo.IdCountry;

                        const completedId =
                            idNo.IdType && idNo.IdNumber && idNo.IdCountry;

                        if (!emptyId && !completedId) {
                            isCompletedId = false;
                            return;
                        }
                    })
                    return isCompletedId;
                })
            .test('IdNumberRequired', defaultRequiredMsg,
                function () {
                    const idNumbers = this.resolve(Yup.ref("IdNumbersArr"));
                    let completeIdCount = 0;
                    let isCompletedId = true;

                    // Check: Id numbers not filled
                    idNumbers.forEach(idNo => {
                        const emptyId =
                            !idNo.IdType && !idNo.IdNumber && !idNo.IdCountry;

                        const completedId =
                            idNo.IdType && idNo.IdNumber && idNo.IdCountry;

                        if (!emptyId && !completedId) {
                            isCompletedId = false;
                            return;
                        }

                        if (!emptyId && completedId) {
                            completeIdCount += 1;
                        }
                    })
                    return !isCompletedId ? true : completeIdCount > 0 ? true : false;
                })
    )
}

const MobileNumberValidation = (getStaticText) => {
    const countryCodeRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, COUNTRY_CODE_VALIDATION);
    const contactNoInvalidMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_INVALID_NUMBER);
    const contactNoRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_VALIDATION);

    return Yup.object().shape({
        CountryCode: Yup.string().required(countryCodeRequiredMsg),
        Number: Yup.string()
            .matches(/^[0-9 ]*$/, contactNoInvalidMsg)
            .required(contactNoRequiredMsg)
    })
}

const OfficeNumberValidation = (getStaticText) => {
    const countryCodeRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, COUNTRY_CODE_VALIDATION);
    const contactNoInvalidMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_INVALID_NUMBER);
    const contactNoRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_VALIDATION);

    return Yup.object().shape({
        CountryCode: Yup.string()
            .test('OfficeNumberCountryCodeRequired', countryCodeRequiredMsg,
                function () {
                    let OfficeNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let OfficeNumber = this.resolve(Yup.ref("Number"));
                    //Office Number Country Code & Office Number must be both empty or filled
                    return (isEmptyValue(OfficeNumber)) || (!isEmptyValue(OfficeNumber) && !isEmptyValue(OfficeNumberCountryCode));
                }),
        Number: Yup.string()
            .matches(/^[0-9 ]*$/, contactNoInvalidMsg)
            .test('OfficeNumberRequired', contactNoRequiredMsg,
                function () {
                    let OfficeNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let OfficeNumber = this.resolve(Yup.ref("Number"));
                    //Office Number Country Code & Office Number must be both empty or filled
                    return (isEmptyValue(OfficeNumberCountryCode)) || (!isEmptyValue(OfficeNumber) && !isEmptyValue(OfficeNumberCountryCode));
                })
    })
}

const HomeNumberValidation = (getStaticText) => {
    const countryCodeRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, COUNTRY_CODE_VALIDATION);
    const contactNoInvalidMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_INVALID_NUMBER);
    const contactNoRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_VALIDATION);

    return Yup.object().shape({
        CountryCode: Yup.string()
            .test('HomeNumberCountryCodeRequired', countryCodeRequiredMsg,
                function () {
                    let HomeNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let HomeNumber = this.resolve(Yup.ref("Number"));

                    //Home Number Country Code & Home Number must be both empty or filled
                    return (isEmptyValue(HomeNumber)) || (!isEmptyValue(HomeNumber) && !isEmptyValue(HomeNumberCountryCode));
                }),
        Number: Yup.string()
            .matches(/^[0-9 ]*$/, contactNoInvalidMsg)
            .test('HomeNumberRequired', contactNoRequiredMsg,
                function () {
                    let HomeNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let HomeNumber = this.resolve(Yup.ref("Number"));

                    //Home Number Country Code & Home Number must be both empty or filled
                    return (isEmptyValue(HomeNumberCountryCode)) || (!isEmptyValue(HomeNumber) && !isEmptyValue(HomeNumberCountryCode));
                })
    })
}

export const FaxNumberValidation = (getStaticText) => {
    const countryCodeRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, COUNTRY_CODE_VALIDATION);
    const contactNoInvalidMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_INVALID_NUMBER);
    const contactNoRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_VALIDATION);

    return Yup.object().shape({
        CountryCode: Yup.string()
            .test('FaxNumberCountryCodeRequired', countryCodeRequiredMsg,
                function () {
                    let FaxNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let FaxNumber = this.resolve(Yup.ref("Number"));

                    //Fax Number Country Code & Fax Number must be both empty or filled
                    return (isEmptyValue(FaxNumber)) || (!isEmptyValue(FaxNumber) && !isEmptyValue(FaxNumberCountryCode));
                }),
        Number: Yup.string()
            .matches(/^[0-9 ]*$/, contactNoInvalidMsg)
            .test('FaxNumberRequired', contactNoRequiredMsg,
                function () {
                    let FaxNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let FaxNumber = this.resolve(Yup.ref("Number"));

                    //Fax Number Country Code & Fax Number must be both empty or filled
                    return (isEmptyValue(FaxNumberCountryCode)) || (!isEmptyValue(FaxNumber) && !isEmptyValue(FaxNumberCountryCode));
                })
    })
}

export const ContactNumberValidation = (getStaticText) => {
    const countryCodeRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, COUNTRY_CODE_VALIDATION);
    const contactNoRequiredMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_VALIDATION);
    const contactNoInvalidMsg = getStaticTextFn(ValidationKeys, getStaticText, CONTACT_NUMBER_INVALID_NUMBER);

    return Yup.object().shape({
        CountryCode: Yup.string()
            .test('ContactNumberCountryCodeRequired', countryCodeRequiredMsg,
                function () {
                    let ContactNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let ContactNumber = this.resolve(Yup.ref("Number"));

                    //Home Number Country Code & Home Number must be both empty or filled
                    return (isEmptyValue(ContactNumber)) || (!isEmptyValue(ContactNumber) && !isEmptyValue(ContactNumberCountryCode));
                }),
        Number: Yup.string()
            .matches(/^[0-9 ]*$/, contactNoInvalidMsg)
            .test('ContactNumberRequired', contactNoRequiredMsg,
                function () {
                    let ContactNumberCountryCode = this.resolve(Yup.ref("CountryCode"));
                    let ContactNumber = this.resolve(Yup.ref("Number"));

                    //Home Number Country Code & Home Number must be both empty or filled
                    return (isEmptyValue(ContactNumberCountryCode)) || (!isEmptyValue(ContactNumber) && !isEmptyValue(ContactNumberCountryCode));
                })
    })
}

export const AddressValidation = (getStaticText) => {
    const requiredMsg = getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED);

    var compiled = _.template(requiredMsg);
    const addressTypeRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Address Type") });

    // Local
    const postalCodeRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "ZIP / Postal Code") });
    const postalCodeInvalid = getStaticTextFn(ValidationKeys, getStaticText, POSTAL_CODE_INVALID_NUMBER);
    const blockNoRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Block / House No.") });
    const streetNameRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Street Name") });

    // Overseas
    const addressLine1Required = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Address Line 1") });
    const addressLine2Required = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Address Line 2") });
    const countryRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Country") });
    const stateRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "State / Province / Region") });

    return Yup.object().shape({
        AddressType: Yup.string().required(addressTypeRequired),
        PostalCode: Yup.string()
            .when('AddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string()
                    .required(postalCodeRequired)
                    .matches(/^[0-9 ]*$/, postalCodeInvalid)
            })
            .when('AddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.string()
                    .required(postalCodeRequired)
                    .matches(/^[0-9 ]*$/, postalCodeInvalid)
            }),
        AddressLine1: Yup.object()
            .when('AddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.object({
                    AddressLine1: Yup.string().required(addressLine1Required),
                })
            })
            .when('AddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.object({
                    BlockNo: Yup.string().required(blockNoRequired),
                    StreetName: Yup.string().required(streetNameRequired)
                })
            }),
        AddressLine2: Yup.object()
            .when('AddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.object({
                    AddressLine2: Yup.string().required(addressLine2Required),
                })
            }),
        Country: Yup.string().when('AddressType', {
            is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
            then: Yup.string().required(countryRequired)
        }),
        State: Yup.string().when('AddressType', {
            is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
            then: Yup.string().required(stateRequired)
        })
    })
}

const buildValidation = (getStaticText) => {
    return {
        "UserType": stringValidationRule(getStaticText),
        "Username": stringValidationRule(getStaticText),
        "Email": stringValidationRule(getStaticText),
        "DateOfBirth": stringValidationRule(getStaticText),
        "Salutation": stringValidationRule(getStaticText),
        "FirstName": stringValidationRule(getStaticText),
        "LastName": stringValidationRule(getStaticText),
        "Gender": stringValidationRule(getStaticText),
        "Nationality": stringValidationRule(getStaticText),
        "PrimaryContact": stringValidationRule(getStaticText),
        "MobileNumber": MobileNumberValidation(getStaticText),
        "OfficeNumber": OfficeNumberValidation(getStaticText),
        "HomeNumber": HomeNumberValidation(getStaticText),
        "FaxNumber": FaxNumberValidation(getStaticText),
        "Address": AddressValidation(getStaticText),
        "PhotoIdentification": stringValidationRule(getStaticText)
    }
};

export const constructFormValidation = (validationConfig, getStaticText) => {

    const internalValidationConfig = validationConfig[USER_TYPE.INTERNAL];
    const internalUserFormValidation = internalValidationConfig &&
        constructUserFormValidation(validationConfig[USER_TYPE.INTERNAL], getStaticText);

    const externalValidationConfig = validationConfig[USER_TYPE.EXTERNAL];
    const externalUserFormValidation = externalValidationConfig &&
        constructUserFormValidation(validationConfig[USER_TYPE.EXTERNAL], getStaticText);

    return ({
        [USER_TYPE.INTERNAL]: internalUserFormValidation,
        [USER_TYPE.EXTERNAL]: externalUserFormValidation
    });
}

const constructUserFormValidation = (validationConfig, getStaticText) => {

    let validationObject = {};
    let profileValidationObject = {};

    const validationFields = validationConfig.Fields;

    Object.keys(validationFields).forEach((field) => {
        const fieldRequired = validationFields[field].Required;

        if (field === 'IdNumbers') {
            profileValidationObject[field] = fieldRequired ?
                IDIncompletedAndRequiredValidationRule(getStaticText) :
                IDIncompleteValidationRule(getStaticText);
        }
        else if (field === 'OfficeNumber' || field === 'HomeNumber' || field === 'FaxNumber' || field === 'Address') {
            profileValidationObject[field] = buildValidation(getStaticText)[field];
        }

        // Validation for Alternate Email Address
        profileValidationObject.AltEmailAddress =
            Yup.string().email("Invalid Email Address");

        // Validation for Organisation
        const requiredMsg = getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED);
        var compiled = _.template(requiredMsg);
        const organisationRequired = compiled({ label: getStaticTextFn(ValidationKeys, getStaticText, "Organisation") });

        validationObject.Organisation =
            Yup.string()
                .when('OrganisationRequired', {
                    is: "true",
                    then: Yup.string().required(organisationRequired)
                })

        // Required
        if (fieldRequired) {
            if (field === "UserType" || field === "Username" || field === "Email" || field === "PhotoIdentification") {
                validationObject[field] = buildValidation(getStaticText)[field];
            }
            else {
                if (profileValidationObject[field] === undefined) {
                    profileValidationObject[field] = buildValidation(getStaticText)[field];
                }
            }
        }
    })
    validationObject.Profile = Yup.object().shape(profileValidationObject);

    return Yup.object().shape(validationObject);
}