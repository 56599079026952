import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import { LANGUAGE_APPLICATION_NAME, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SMARTForm, Text, toastSuccess, toastError, withSMARTWrapper, Plaintext, SelectList, Radio, SMARTView } from '../../Shared//Forms';
import { isValidForm, selectListMapper } from '../../Shared/SmartMe/Actions';
import { URL, IS_ORGANISATION_SPECIFIC } from '../../Shared/SmartMe/Constants';
import PageFooter from '../../Shared/PageFooter';
import { ModalConfirm } from '../../Shared/Modal';
import { FIELD_SIZE, SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import Section from '../../Section';
import SubSection from '../../Section/SubSection';
import SectionWrapper from '../../SectionWrapper';
import { DataTable } from '../../Shared/DataTable';
import AssignUserToUserGroup from '../../Shared/SmartMe/Components/AssignUserToUserGroup';
import { ERROR_MSG, BUTTON_TITLE } from '../../Localization';

// Translation Keys
import { GlobalTranslationKeys } from '../../App/TranslationKeys';
import { TranslationKeys } from './TranslationKeys';
import { getTranslationKeys, getTranslationValue, getTranslationObjects } from '../../Shared/Forms/FormUtils';

const GET_USER_ORGANISATION_DETAILS_URL = URL + 'Organisation/GetUserOrganisationDetails?';
const GET_ROLES_BY_SCOPE_URL = URL + 'Role/GetRolesByScope?';

const IS_ORGANISATION_ADMIN = {
    YES: "Yes",
    NO: "No"
}

const App = LANGUAGE_APPLICATION_NAME;
const FormTranslationKeys = GlobalTranslationKeys.concat(TranslationKeys);
const UGTranslationKeys = getTranslationKeys(App, FormTranslationKeys);

const FORM_VALIDATIONS = (getStaticText) => {
    const ErrorMsgList = ERROR_MSG(getStaticText);
    const requiredMsg = ErrorMsgList.REQUIRED;

    return Yup.object().shape({
        AppId: Yup.string().required(requiredMsg),
        GroupName: Yup.string().required(requiredMsg),
        IsOrganisationSpecific: Yup.string().required(requiredMsg),
        IsMeOrganisationAdmin: Yup.string().required(requiredMsg),
        Organisation: Yup.string()
            .when('IsOrganisationSpecific', {
                is: IS_ORGANISATION_SPECIFIC.YES,
                then: Yup.string().required(requiredMsg)
            })
    });
}

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.TAB;
let SECTION_NAMES = {
    ROLES: "Roles",
    USERS: "Users"
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            server_url: props.server_url,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            prevState: props.prevState,
            isAppAdmin: false,
            isOrganisationAdmin: false,
            organisations: [],
            isOrganisationsLoaded: false,
            roles: [],
            isRolesLoaded: false,
            action: props.action,
            updatePageLoader: props.loader,
            updateUserGrid: false,
            width: window.innerWidth
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);

        const { action, values } = this.state;
        const { AppId } = values;

        if (action === 'Update') {
            await this.getAgencySpecific(AppId, false);
            await this.setupRoles(values);
        }
    }

    //Mobile Responsive Listener
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    stopLoader = () => {
        const { updatePageLoader, isOrganisationsLoaded, isRolesLoaded } = this.state;

        if (isOrganisationsLoaded && isRolesLoaded) {
            updatePageLoader.done();
        }
    }

    getAgencySpecific = async (AppId, onChange) => {
        const { loader } = this.props;
        if (onChange) {
            loader.start();
        }

        let param = { appId: AppId };
        let response = await fetchRequest(GET_USER_ORGANISATION_DETAILS_URL, getParams(param), false);

        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            if (onChange) {
                loader.done();
            }

            const { IsOrganisationSpecific } = this.state.values;
            const isAppAdmin = Data.IsAppAdmin;
            const isOrganisationAdmin = isAppAdmin === false && Data.IsOrganisationAdmin;
            const isOrganisationSpecific = isOrganisationAdmin ? IS_ORGANISATION_SPECIFIC.YES : IsOrganisationSpecific;

            this.setState({
                isAppAdmin: isAppAdmin,
                isOrganisationAdmin: isOrganisationAdmin,
                organisations: selectListMapper(Data.OrganisationList, "Id", "FullName"),
                values: {
                    ...this.state.values,
                    AppId: AppId,
                    IsOrganisationSpecific: isOrganisationSpecific,
                },
                isOrganisationsLoaded: true
            });

            this.stopLoader();
        }
        else {
            loader.done();
            toastError(Messages);
        }
    }

    clearUsers = (values) => {
        //if(values.UsersToUserGroup &&  values.UsersToUserGroup.length>0){
        this.setState({
            updateUserGrid: Math.random()
        });
        //}        
        values.UsersToUserGroup = [];
    }

    clearOrganisation = (values, onChangeField) => {
        values.Organisation = '';
        onChangeField('Organisation', '');
    }

    clearRoles = (values, onChangeField) => {
        values.Roles = new Map();
        onChangeField('Roles', new Map());
    }

    setupRoles = async (values) => {
        await this.getRoles(values, false);
        this.stopLoader();
    }

    getRoles = async (values, onChange) => {
        const { loader } = this.props;

        if (onChange) {
            loader.done();//stop existing loader
            loader.start();
        }

        const { AppId, Organisation, ExistingRoles } = values;

        let param = { AppId: AppId, Organisation: Organisation };
        let rolesResponse = await fetchRequest(GET_ROLES_BY_SCOPE_URL, getParams(param), false);

        const { IsSuccess, Data, Messages } = rolesResponse.body;

        if (rolesResponse.success && IsSuccess) {
            if (onChange) {
                loader.done();
            }

            if (ExistingRoles && ExistingRoles.length > 0) {
                values.Roles = new Map(ExistingRoles.map(id => [id, Data.find(d => d.Id === id)]));
            }

            this.setState({
                ...this.state,
                roles: Data,
                isRolesLoaded: true
            });
        }
        else {
            loader.done();
            toastError(Messages);
        }
    }

    isRenderSection = (values) => {
        if (values) {
            if (this.state.isRolesLoaded) {
                if (values.IsOrganisationSpecific === IS_ORGANISATION_SPECIFIC.YES && values.Organisation !== '') {
                    return true;
                }
                if (values.IsOrganisationSpecific === IS_ORGANISATION_SPECIFIC.NO) {
                    return true;
                }
            }

        }
        return false;
    }

    prepareToSubmit = async (values, _submitForm) => {
        // Assign roles to User Group
        const roles = Array.from(values.Roles.keys());
        values = Object.assign(values, { Roles: roles });

        _submitForm();
    }

    getConfirmContentBody = (values, message, translation) => {
        const dataRoles = Array.from(values.Roles.values());
        const dataUsers = values.UsersToUserGroup;

        const getStaticText = translation && translation.getStaticText;
        const translationKeys = translation && translation.translationKeys;

        message = getTranslationValue(message, getStaticText, translationKeys);
        SECTION_NAMES = getTranslationObjects(SECTION_NAMES, getStaticText, UGTranslationKeys);

        return (
            <>
                <p>{message}</p>
                <SMARTView
                    sectionNames={SECTION_NAMES}
                    defaultSection={SECTION_NAMES.ROLES}
                >
                    {({ toggleSection, sectionState }) => (
                        <SectionWrapper
                            type={SECTION_LAYOUT_TYPE}
                            tabs={SECTION_NAMES}
                            sectionState={sectionState}
                            toggleSection={toggleSection}
                        >
                            <Section
                                type={SECTION_LAYOUT_TYPE}
                                sectionState={sectionState}
                                sectionName={SECTION_NAMES.ROLES}
                                toggleSection={toggleSection}
                            >
                                <DataTable
                                    translation={translation}
                                    data={dataRoles}
                                    pageSize='5'
                                    minFilterChars='2'
                                    rowIdentifier='Id'
                                    noResultsMessage='No roles found'
                                    columns={
                                        {
                                            RunningNumber: { title: 'No.', width: '3%' },
                                            IsOrganisationSpecific: { title: 'Organisation Specific', width: '5%', DBkey: 'IsOrganisationSpecific', translateValue: true },
                                            RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' }
                                        }
                                    }
                                />
                            </Section>

                            <Section
                                type={SECTION_LAYOUT_TYPE}
                                sectionState={sectionState}
                                sectionName={SECTION_NAMES.USERS}
                                toggleSection={toggleSection}
                            >
                                <DataTable
                                    translation={translation}
                                    data={dataUsers}
                                    pageSize='5'
                                    minFilterChars='2'
                                    rowIdentifier='Id'
                                    noResultsMessage='No users found'
                                    columns={
                                        {
                                            RunningNumber: { title: 'No.', width: '3%' },
                                            OrganisationName: { title: 'Organisation Name', DBkey: 'OrganisationName', translateValue: true },
                                            Fullname: { title: 'Fullname', DBkey: 'Fullname' },
                                            Username: { title: 'Username', DBkey: 'Username' }
                                        }
                                    }
                                />
                            </Section>
                        </SectionWrapper>
                    )}
                </SMARTView>
            </>
        )
    }

    render() {
        const { server_url, isAppAdmin, isOrganisationAdmin, organisations, roles, updateUserGrid, width } = this.state;
        const { modal, getStaticText } = this.props;

        const isMobile = width <= 992;
        const translation = {
            getStaticText: getStaticText,
            translationKeys: UGTranslationKeys
        };

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} translation={translation} title={this.state.title}>
                    <SMARTForm
                        formValues={this.state.values}
                        validationSchema={FORM_VALIDATIONS(getStaticText)}
                        validateOnSubmit={true}
                        serverURL={server_url}
                        isDummy={false}
                        submitCallback={async ({ response }) => {
                            if (response.success && response.body.IsSuccess) {

                                navigateTo('/UserGroup'); // return back to grid
                                toastSuccess(response.body.Messages, null, translation)
                            }
                            else {
                                toastError(response.body.Messages, translation)
                            }
                        }}
                    >
                        {({ values, errors, onChangeField, onChange, submitForm }) => (
                            <React.Fragment>
                                <Row className="body-content">
                                    <Col>
                                        <SubSection orientation="left" translation={translation} title="User Group Details">
                                            {this.state.prevState.AppIds !== "" ?
                                                <SelectList
                                                    name="AppId"
                                                    value={values.AppId}
                                                    options={this.state.prevState.AppIds}
                                                    onChangeField={(name, value) => {
                                                        onChangeField(name, value);
                                                        if (values.AppId !== value) { // get latest agency info once
                                                            this.getAgencySpecific(value, true);
                                                        }
                                                        this.clearOrganisation(values, onChangeField);
                                                        this.clearRoles(values, onChangeField);
                                                        this.clearUsers(values);
                                                    }}
                                                    isMulti={false}
                                                    error={errors.AppId}
                                                    label="Application"
                                                    translation={translation}
                                                    required
                                                /> :
                                                <Plaintext
                                                    label="Application"
                                                    translation={translation}
                                                    name="AppName"
                                                    value={values.AppName}
                                                />
                                            }
                                            <Text
                                                name="GroupName"
                                                value={values.GroupName}
                                                placeholder="Group Name"
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Group Name"
                                                translation={translation}
                                                error={errors.GroupName}
                                                required
                                            />
                                            <Text
                                                name="Description"
                                                value={values.Description}
                                                placeholder="Description"
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={7}
                                                label="Description"
                                                translation={translation}
                                                error={errors.Description}
                                            />
                                            {isOrganisationAdmin &&
                                                <Plaintext
                                                    label="Organisation Specific"
                                                    translation={translation}
                                                    translateValue={true}
                                                    name="IsOrganisationSpecific"
                                                    value={IS_ORGANISATION_SPECIFIC.YES}
                                                />
                                            }
                                            {isAppAdmin &&
                                                <Radio
                                                    name="IsOrganisationSpecific"
                                                    value={values.IsOrganisationSpecific}
                                                    options={[
                                                        { label: "Yes", value: IS_ORGANISATION_SPECIFIC.YES },
                                                        { label: "No", value: IS_ORGANISATION_SPECIFIC.NO }]}
                                                    onChange={async (e) => {
                                                        onChange(e);
                                                        this.clearOrganisation(values, onChangeField);
                                                        this.clearRoles(values, onChangeField);
                                                        this.clearUsers(values);
                                                        if (e.target.value === IS_ORGANISATION_SPECIFIC.NO) {
                                                            this.getRoles(values, true);
                                                        }
                                                    }}
                                                    inputSize={FIELD_SIZE.LARGE}
                                                    label="Organisation Specific"
                                                    translation={translation}
                                                    error={errors.IsOrganisationSpecific}
                                                    disabled={isOrganisationAdmin}
                                                    required
                                                />
                                            }
                                            {values.IsOrganisationSpecific === IS_ORGANISATION_SPECIFIC.YES && organisations &&
                                                <SelectList
                                                    name="Organisation"
                                                    value={values.Organisation}
                                                    options={organisations}
                                                    onChangeField={(name, value) => {
                                                        onChangeField(name, value);
                                                        this.clearUsers(values);
                                                        if (values.Organisation !== '') {
                                                            this.getRoles(values, true);
                                                        }
                                                    }}
                                                    isMulti={false}
                                                    inputSize={FIELD_SIZE.LARGE}
                                                    error={errors.Organisation}
                                                    label="Organisation"
                                                    translation={translation}
                                                    menuPlacement="top"
                                                    required
                                                />
                                            }

                                            <Radio
                                                name="IsMeOrganisationAdmin"
                                                value={values.IsMeOrganisationAdmin}
                                                options={[
                                                    { label: "Yes", value: IS_ORGANISATION_ADMIN.YES },
                                                    { label: "No", value: IS_ORGANISATION_ADMIN.NO }]}
                                                onChange={onChange}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label="Organisation Admin"
                                                translation={translation}
                                                error={errors.IsMeOrganisationAdmin}
                                                required
                                            />


                                        </SubSection>
                                        {this.isRenderSection(values) &&
                                            <SubSection orientation="left" translation={translation} title="Assign Roles to User Group">
                                                <DataTable
                                                    key={isMobile}
                                                    translation={translation}
                                                    data={roles}
                                                    pageSize='5'
                                                    minFilterChars='2'
                                                    rowIdentifier='Id'
                                                    noResultsMessage='No roles found'
                                                    columns={
                                                        {
                                                            RunningNumber: { title: 'No.', width: '3%', visible: !isMobile },
                                                            Checkbox: {
                                                                enableCheckAllSettings: true,
                                                                width: '5%',
                                                                checkbox:
                                                                {
                                                                    name: 'Roles',
                                                                    onChangeField: onChangeField,
                                                                    values: values
                                                                }
                                                            },
                                                            IsOrganisationSpecific: { title: 'Organisation Specific', width: '5%', DBkey: 'IsOrganisationSpecific', translateValue: true },
                                                            RoleName: { title: 'Role Name', width: '15%', DBkey: 'RoleName' },
                                                            DisplayRoleName: { title: 'Display Role Name', width: '25%', DBkey: 'DisplayRoleName', visible: !isMobile },
                                                            Description: { title: 'Description', width: '15%', DBkey: 'Description', visible: !isMobile }
                                                        }
                                                    }
                                                />
                                            </SubSection>
                                        }
                                        {this.isRenderSection(values) && updateUserGrid !== undefined &&
                                            <SubSection orientation="left" className="pt-3" translation={translation} title="Assign Users to User Group">
                                                <AssignUserToUserGroup
                                                    translation={translation}
                                                    key={updateUserGrid}
                                                    data={values.UsersToUserGroup}
                                                    isUpdatePage={true}
                                                    isOrganisationSpecific={values.IsOrganisationSpecific}
                                                    selectedOrganisation={values.Organisation}
                                                    organisationsOptions={organisations}
                                                />
                                            </SubSection>
                                        }
                                    </Col>
                                </Row>
                                <PageFooter>
                                    <Button color="primary" type="button" onClick={() => isValidForm(FORM_VALIDATIONS(getStaticText), values) ? modal.toggleModal("Submit") : submitForm()}>{BUTTON_TITLE(getStaticText).SUBMIT}</Button>
                                </PageFooter>
                                <ModalConfirm
                                    isOpen={modal.modalState === "Submit"}
                                    contentHeader={this.state.contentHeader}
                                    contentBody={this.getConfirmContentBody(values, this.state.contentBody, translation)}
                                    confirmCallback={() => { modal.toggleModal("Submit"); this.prepareToSubmit(values, submitForm); }}
                                    cancelCallback={() => modal.toggleModal("Submit")}
                                    formatter={{ component: getTranslationValue('User Group', getStaticText, UGTranslationKeys) }}
                                />
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(Form);