import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form} from 'reactstrap';
import { DateInput, SelectList } from '../../Forms';
import { DATE_DISPLAY_FORMAT } from '../../../App/AppSettings';
import { FIELD_SIZE } from '../../Constants';
import { Formik } from 'formik';
import * as Yup from 'yup';

//modularise formmodal.

const FORM_VALIDATIONS = Yup.object().shape({
    effectiveStartDate: Yup.date().required("Effective Start Date Required"),
    role: Yup.string().required("Role name is required"),
    effectiveEndDate: Yup.date().when('effectiveStartDate', {
        is: val => val !== null,
        then: Yup.date().min(Yup.ref('effectiveStartDate'), "Effective End Date should not be earlier than Effective Start Date"), 
    })
});

class AssignRolesToUserModal extends React.Component{
    render(){
        return(
            <React.Fragment>
            <Modal isOpen={this.props.modalState} toggle={this.props.toggle} className={this.props.className}>
            <ModalHeader toggle={this.props.toggle}>{this.props.modalTitle}</ModalHeader>
            <Formik
                initialValues={this.props.initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validate={values => {
                    let errors = {};
                    var inputStart = new Date(values.effectiveStartDate)

                    var inputEnd = values.effectiveEndDate === "" ? new Date(8640000000000000) : new Date(values.effectiveEndDate)

                    if(this.props.data !== null){
                        this.props.data.map(x=>{
                            if(x["Id"] === values.role[0]){
                                var dataStart = new Date(x["EffectiveStartDate"])
                                var dataEnd = x["EffectiveEndDate"] === "" ? new Date(8640000000000000) : new Date(x["EffectiveEndDate"])
                             
                                if((inputStart <= dataEnd) && inputEnd >= dataStart){
                                    errors.role = "Role have overlapping effective dates"
                                    return null;
                                }
                            }
                            return null;
                        })
                        return errors;
                    }
                    
                }}
                onSubmit={(values) => {
                    this.props.addItem(values)
                }}
                validationSchema={FORM_VALIDATIONS}
                >
                {(props) => {
                    const { values,errors,handleSubmit,setFieldValue} = props;
                    return (
                    <Form>
                        <ModalBody>
                        <SelectList
                            name="role"
                            value={values.role}
                            options={this.props.selection}
                            onChangeField={setFieldValue}
                            isMulti={false}
                            inputSize={FIELD_SIZE.NONE}
                            label="Role Name"
                            error={errors.role}
                            required
                        />

                        <DateInput
                            name="effectiveStartDate"
                            value={values.effectiveStartDate}
                            placeholder="Effective Start Date"
                            onChangeField={setFieldValue}
                            time={false}
                            date={true}
                            min={new Date()}
                            format={DATE_DISPLAY_FORMAT.DATE}
                            inputSize={FIELD_SIZE.NONE}
                            label="Effective Start Date"
                            error={errors.effectiveStartDate}
                            required
                        />

                        <DateInput
                            name="effectiveEndDate"
                            value={values.effectiveEndDate}
                            placeholder="Effective End Date"
                            onChangeField={setFieldValue}
                            time={false}
                            date={true}
                            format={DATE_DISPLAY_FORMAT.DATE}
                            inputSize={FIELD_SIZE.NONE}
                            label="Effective End Date"
                            error={errors.effectiveEndDate}
                        />
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" type="button" onClick={handleSubmit}>Save</Button>
                        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Form>
                    );
                }}
            </Formik>
            </Modal>
            </React.Fragment>
        )
    }
}

export default AssignRolesToUserModal;