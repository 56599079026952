import i18n from "i18next";
import BackendAdapter from 'i18next-multiload-backend-adapter';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from "react-i18next";
import { GET_LANGUAGE_URL, GET_TRANSLATION_URL, DEFAULT_LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE, 
    LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE_KEY, LANGUAGE_PACK_CONTENT_KEY } from './Components/App/AppSettings';
import { fetchRequest, getParams } from './Components/Shared/Actions';
import { GetLangKey, ERROR } from "./Components/Shared/Constants/LanguageKeys";
import { toastError } from './Components/Shared/Forms';
import AuthHelper from "./Components/Shared/Authentication/AuthHelper";

const loadCurrent = (url, options, callback) => {
    let lastPublishedDate = window.localStorage.getItem(LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE_KEY);

    if (lastPublishedDate === null) {
        url = `${url}&lastPublishedUtcDate=${DEFAULT_LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE}`;
    }
    else {
        if (lastPublishedDate.slice(-1) === "Z" || lastPublishedDate.slice(-1) === "z") {
            lastPublishedDate = lastPublishedDate.slice(0, -1);
        }
        url = `${url}&lastPublishedUtcDate=${encodeURIComponent(lastPublishedDate)}`
    }
    
    fetchRequest(url, getParams(), false)
        .then(response => {
            if (response.success) {
                if (response.body.IsSuccess) {
                    let dataToReturn = {};
                    let defaultNs = {};

                    window.localStorage.setItem(LANGUAGE_PACK_LAST_PUBLISHED_UTC_DATE_KEY, response.body.LastPublishedUtcDate);

                    if (response.body.IsNewData) {
                        window.localStorage.setItem(LANGUAGE_PACK_CONTENT_KEY, JSON.stringify(response.body.Data))
                    }

                    const translationData = JSON.parse(window.localStorage.getItem(LANGUAGE_PACK_CONTENT_KEY));
                    defaultNs['translation'] = translationData.Translation;
                    const lang = translationData.Metadata.Language;
                    dataToReturn[lang] = defaultNs;
                    callback(dataToReturn, { status: response.status });
                }
                else {
                    toastError(i18n.t(GetLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Message)), response.body.Message);
                }
            } else {
                toastError(i18n.t(ERROR.SERVER_UNREACHABLE));
            }
        });
};

const getLanguageCode = async () => {
    var mgr = AuthHelper.getManager();
    var user = await mgr.getUser();

    if (user !== null) {
        const response = await fetchRequest(GET_LANGUAGE_URL, getParams(), false);
        const { Data, IsSuccess } = response.body;
        if (response.success && IsSuccess) {
            return Data;
        }
    }
    return "en-us"; //Default language
}

loadi18n();

async function loadi18n() {
    var langCode = await getLanguageCode();

    i18n
        .use(BackendAdapter)
        .use(initReactI18next)// passes i18n down to react-i18next
        .init({
            lng: langCode,
            backend: {
                backend: XHR,
                backendOption: {
                    loadPath: GET_TRANSLATION_URL,
                    ajax: loadCurrent,
                    parse: (data) => data,
                }
            },
            load: 'currentOnly',
            lowerCaseLng: true,
            fallbackLng: false,
            defaultValue: '',
            parseMissingKeyHandler: () => {
                return '';
            },
            debug: true,
            react: {
                useSuspense: true,
                bindI18nStore: 'added'
            },
        });
}
export default loadi18n;