export const FormTranslationKeys = [
    {
        "DefaultLanguageValue": "List of Users",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Title",
        "TranslationKey": "ListOfUsers"
    },
    {
        "DefaultLanguageValue": "User Type",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Label",
        "TranslationKey": "UserType"
    },
    {
        "DefaultLanguageValue": "Please select one or more organisation under User Information (Account Info).",
        "Scope": "MeUser",
        "Category": "Form",
        "SubCategory": "Info",
        "TranslationKey": "UGInfo"
    },
    {
        "DefaultLanguageValue": "External User Exist",
        "Scope": "MeUser",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "ExternalUserExist"
    },
    {
        "DefaultLanguageValue": "Overlaps existing User Group:  <%= group %>.",
        "Scope": "MeUser",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "OverlappingUGValidation"
    },
    {
        "DefaultLanguageValue": "Effective from <%= ESD %> to <%= EED %>.",
        "Scope": "MeUser",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "EffectiveFromToValidation"
    },
    {
        "DefaultLanguageValue": "Effective from <%= ESD %>.",
        "Scope": "MeUser",
        "Category": "Error",
        "SubCategory": "Title",
        "TranslationKey": "EffectiveFromValidation"
    }
]